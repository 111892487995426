import { FC, useState } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { ProgramForMedia, Campaign, CampaignApiForGeneral } from '@/utils/MoneybackClient';
import { getDateStr, getCampaignEndedDate, getPrevDate2359 } from '@/utils/utilFunctions';
import { getEnvVariables } from '@/utils/envVariables';
import Button from '@/components/0_atom/Button';
import { DetailsItem } from '@/components/0_atom/DetailsItem';
import { MediaReward } from '@/components/1_mol/MediaReward';
import { ProgramCard } from '@/components/2_org/ProgramCard';
import { ProgramModal } from '@/components/2_org/ProgramModal';
import { CampaignModalEndModal } from '@/components/2_org/CampaignModal/EndModal';
// import { CampaignModalRefreshUriModal } from '@/components/2_org/CampaignModal/RefreshUriModal';

const useStyles = makeStyles((theme) => ({
    root: {},
    pageUrlContent: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    openPage: {
        color: '#0a7',
        textDecoration: 'underline',
    },
    refreshUriButton: {
        margin: '-14px -14px -14px 0',
    },
    bottomActions: {
        borderTop: 'solid 2px rgba(0,0,0,.05)',
        marginTop: 62,
        paddingTop: 12,
    },
    endButton: {
        color: theme.palette.error.main,
        marginLeft: -12,
    },
}));

const getIsCampaignApiForGeneral = (campaign: Campaign | CampaignApiForGeneral): campaign is CampaignApiForGeneral => {
    return campaign instanceof CampaignApiForGeneral;
};

export const CampaignModalContentDetails: FC<{
    program: ProgramForMedia;
    campaign: Campaign | CampaignApiForGeneral;
    updateCampaignAPI: ((updatedCampaignApiForGeneral: CampaignApiForGeneral) => void) | undefined;
}> = ({ program, campaign, updateCampaignAPI }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const [isProgramModalOpen, setIsProgramModalOpen] = useState(false);
    // const [isRefreshUriModalOpen, setIsRefreshUriModalOpen] = useState(false);
    const [isEndModalOpen, setIsEndModalOpen] = useState(false);

    // DATA
    const endedDate = getCampaignEndedDate({ program, campaign });

    return (
        <>
            <ProgramCard
                program={program}
                enabledFields={{
                    schedule: true,
                }}
                onClick={() => setIsProgramModalOpen(true)}
            />
            <ProgramModal
                program={isProgramModalOpen ? program : undefined}
                onClose={() => setIsProgramModalOpen(false)}
                isWithCreateCampaignButton={false}
            />
            <DetailsItem
                label={'メディア報酬'}
                content={<MediaReward mediaReward={campaign.mediaReward} />}
                variant={'full'}
                direction={'column'}
            />
            <DetailsItem label={'広告主'} content={program.advertiserName} variant={'full'} direction={'row'} />
            <DetailsItem
                label={'CB金額'}
                content={`¥ ${campaign.cashbackAmount.toLocaleString()}`}
                variant={'full'}
                direction={'row'}
            />
            <DetailsItem
                label={'開始日'}
                content={getDateStr({ date: campaign.createdAt, isWithDay: true })}
                variant={'full'}
                direction={'row'}
            />
            {endedDate && (
                <DetailsItem
                    label={'終了日'}
                    content={getDateStr({ date: getPrevDate2359({ date: endedDate }), isWithDay: true })}
                    variant={'full'}
                    direction={'row'}
                />
            )}
            <DetailsItem
                label={'ページURL'}
                content={
                    <div className={c.pageUrlContent}>
                        <a
                            href={`${getEnvVariables().wwwRootUrl}/campaigns/${campaign.uri}`}
                            target={'_blank'}
                            rel="noreferrer noopener"
                            className={c.openPage}
                        >
                            {'ページを開く'}
                        </a>
                        {/* {getIsCampaignApiForGeneral(campaign) && !campaign.endReceptionAt && (
                            <Button onClick={() => setIsRefreshUriModalOpen(true)} className={c.refreshUriButton}>
                                {'変更'}
                            </Button>
                        )} */}
                    </div>
                }
                variant={'full'}
                direction={'row'}
            />
            {getIsCampaignApiForGeneral(campaign) && updateCampaignAPI && (
                <>
                    {!campaign.endReceptionAt && (
                        <div className={c.bottomActions}>
                            <Button className={c.endButton} onClick={() => setIsEndModalOpen(true)}>
                                {'このキャンペーンの受付を終了する'}
                            </Button>
                        </div>
                    )}
                    {!campaign.endReceptionAt && (
                        <CampaignModalEndModal
                            isOpen={isEndModalOpen}
                            onClose={() => setIsEndModalOpen(false)}
                            program={program}
                            campaignAPI={campaign}
                            updateCampaignAPI={updateCampaignAPI}
                        />
                    )}
                    {/* {!campaign.endReceptionAt && (
                        <CampaignModalRefreshUriModal
                            isOpen={isRefreshUriModalOpen}
                            onClose={() => setIsRefreshUriModalOpen(false)}
                            program={program}
                            campaignAPI={campaign}
                            updateCampaignAPI={updateCampaignAPI}
                        />
                    )} */}
                </>
            )}
        </>
    );
};
CampaignModalContentDetails.displayName = 'CampaignModalContentDetails';
