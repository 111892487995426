import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { useUserCtx, useHealthCtx } from '@/utils/ctxs';
import { LoadingIndicator } from '@/components/0_atom/LoadingIndicator';
import { Brand } from '@/components/0_atom/Brand';
import { PreConsoleTemplateSignin } from '@/components/3_template/PreConsoleTemplate/Signin';
import { PreConsoleTemplateMembers } from '@/components/3_template/PreConsoleTemplate/Members';
import { PreConsoleTemplateNoUserDisplay } from '@/components/3_template/PreConsoleTemplate/NoUserDisplay';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateY(-50%) translateX(-50%)',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: 400,
    },
    brand: {
        width: 140,
    },
    content: {
        marginTop: 60,
    },
}));

export const PreConsoleTemplate: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { fetchStatus } = useHealthCtx();
    const { isFetching, mediaMembers, firebaseUser } = useUserCtx();

    return (
        <div className={c.root}>
            <Brand className={c.brand} />
            <div className={c.content}>
                {isFetching || fetchStatus === 'fetching' ? (
                    <LoadingIndicator />
                ) : fetchStatus === 'fetchError' ? (
                    'サーバーに接続できません'
                ) : !firebaseUser ? (
                    <PreConsoleTemplateSignin />
                ) : mediaMembers ? (
                    <PreConsoleTemplateMembers />
                ) : (
                    <PreConsoleTemplateNoUserDisplay />
                )}
            </div>
        </div>
    );
};
PreConsoleTemplate.displayName = 'PreConsoleTemplate';
