import { MutationValidationResult } from '@/utils/MoneybackClient/graphql/generated/graphqlClient';
import { GraphQLSdk } from '@/utils/MoneybackClient/graphql/graphqlSdk';
import { GqlDateTimeData } from '@/utils/MoneybackClient/graphql/customScalars';
import { Campaign, CampaignType } from '@/utils/MoneybackClient/models/Campaign/data/Campaign';

type CampaignApiForGeneralType = {
    refreshUri: (input: { mediaId: string; programId: string }) => Promise<{
        result: MutationValidationResult;
    }>;
    endReception: (input: { mediaId: string; programId: string }) => Promise<void>;
};

type ConstructorInput = CampaignType & {
    graphqlSdk: GraphQLSdk;
};

export class CampaignApiForGeneral extends Campaign implements CampaignApiForGeneralType {
    protected _graphqlSdk: GraphQLSdk;

    constructor(input: ConstructorInput) {
        super(input);
        this._graphqlSdk = input.graphqlSdk;
    }

    async refreshUri({ mediaId, programId }: { mediaId: string; programId: string }) {
        const { refreshCampaignUri } = await this._graphqlSdk.refreshCampaignUri({
            input: {
                mediaId,
                programId,
                campaignId: this.id,
                oldValue: this.uri,
                requiredInterval: 5,
            },
        });
        const { result, campaign } = refreshCampaignUri;
        this._uri = campaign.uri;
        return {
            result,
        };
    }

    async endReception({ mediaId, programId }: { mediaId: string; programId: string }) {
        const { endReceptionCampaign } = await this._graphqlSdk.endReceptionCampaign({
            input: {
                mediaId,
                programId,
                campaignId: this.id,
            },
        });
        const { campaign } = endReceptionCampaign;
        this._endReceptionAt = campaign.endReceptionAt ? GqlDateTimeData.serialize(campaign.endReceptionAt) : undefined;
    }
}
