import { FC, useState } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { LoadingIndicator } from '@/components/0_atom/LoadingIndicator';
import MutationButton from '@/components/0_atom/MutationButton';
import { useAdminCtx } from '@/utils/ctxs';

const useStyles = makeStyles((theme) => ({
    root: {},
}));

export const ConsoleTemplateAdminCron: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { adminClient } = useAdminCtx();
    const [isClickedRunAutoCron1day, setIsClickedRunAutoCron1day] = useState(false);
    const [isClickedRunManualCronForNewCashback, setIsClickedRunManualCronForNewCashback] = useState(false);
    const [isClickedRunManualCronForSync, setIsClickedRunManualCronForSync] = useState(false);
    const [isClickedRunManualCronForMediaReward, setIsClickedRunManualCronForMediaReward] = useState(false);
    const [isClickedRunManualCronForRetryingMediaReward, setIsClickedRunManualCronForRetryingMediaReward] =
        useState(false);

    return (
        <>
            {adminClient ? (
                <>
                    <MutationButton
                        mutation={{
                            description: 'runAutoCron1day',
                            run: async () => {
                                await adminClient.runAutoCron1day();
                                setIsClickedRunAutoCron1day(true);
                                return 'success';
                            },
                        }}
                        disabled={isClickedRunAutoCron1day}
                    >
                        {'AutoCron1day'}
                    </MutationButton>
                    <MutationButton
                        mutation={{
                            description: 'runManualCronForNewCashback',
                            run: async () => {
                                await adminClient.runManualCronForNewCashback();
                                setIsClickedRunManualCronForNewCashback(true);
                                return 'success';
                            },
                        }}
                        disabled={isClickedRunManualCronForNewCashback}
                    >
                        {'NewCashback'}
                    </MutationButton>
                    <MutationButton
                        mutation={{
                            description: 'runManualCronForSync',
                            run: async () => {
                                await adminClient.runManualCronForSync();
                                setIsClickedRunManualCronForSync(true);
                                return 'success';
                            },
                        }}
                        disabled={isClickedRunManualCronForSync}
                    >
                        {'Sync'}
                    </MutationButton>
                    <MutationButton
                        mutation={{
                            description: 'runManualCronForMediaReward',
                            run: async () => {
                                await adminClient.runManualCronForMediaReward();
                                setIsClickedRunManualCronForMediaReward(true);
                                return 'success';
                            },
                        }}
                        disabled={isClickedRunManualCronForMediaReward}
                    >
                        {'MediaReward'}
                    </MutationButton>
                    <MutationButton
                        mutation={{
                            description: 'runManualCronForRetryingMediaReward',
                            run: async () => {
                                await adminClient.runManualCronForRetryingMediaReward();
                                setIsClickedRunManualCronForRetryingMediaReward(true);
                                return 'success';
                            },
                        }}
                        disabled={isClickedRunManualCronForRetryingMediaReward}
                    >
                        {'RetryingMediaReward'}
                    </MutationButton>
                </>
            ) : (
                <LoadingIndicator />
            )}
        </>
    );
};
ConsoleTemplateAdminCron.displayName = 'ConsoleTemplateAdminCron';
