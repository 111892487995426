import { GqlDateTimeData } from '@/utils/MoneybackClient/graphql/customScalars';
import {
    getProgramForAdminFromGql,
    ProgramApiForAdmin,
    CashbackAmountRangeType,
    PriceType,
    ProgramConditionType,
    ProgramCondition,
    CashbackAmountRange,
    TicketWithCampaign,
    getTicketWithCampaignFromGql,
} from '@/utils/MoneybackClient/models';
import { MoneybackMediaUserClient } from '@/utils/MoneybackClient/clients/MediaUser';
import { getDate0000 } from '@/utils/utilFunctions/getDate';

export type CreateProgramInput = {
    mediaFund: number;
    cashbackAmountRange: CashbackAmountRangeType;
    title: string;
    description: string;
    descriptionForMedia: string | undefined;
    overviewMd: string | undefined;
    advertiserName: string;
    url: string;
    endReceptionAt?: Date;
    closeAt?: Date;
    condition: ProgramConditionType;
    trackingParamKey: string;
    advertiserProgramId?: string;
    totalPrice: PriceType;
    whitelistedMediaIds?: string[];
    isPublicVisible: boolean;
    advertiserIcon: File | undefined;
    eyecatch: File | undefined;
};

type MoneybackAdminClientType = {
    getProgramAPIs: () => Promise<ProgramApiForAdmin[]>;
    getTickets: (input: { advertiserProgramId: string }) => Promise<TicketWithCampaign[]>;
    createProgram: (input: CreateProgramInput) => Promise<ProgramApiForAdmin>;
    runManualCronForNewCashback: () => Promise<boolean>;
    runManualCronForSync: () => Promise<boolean>;
    runManualCronForMediaReward: () => Promise<boolean>;
    runManualCronForRetryingMediaReward: () => Promise<boolean>;
    runAutoCron1day: () => Promise<boolean>;
};

export class MoneybackAdminClient extends MoneybackMediaUserClient implements MoneybackAdminClientType {
    async getProgramAPIs() {
        const { getPrograms } = await this._graphqlSdk.getPrograms();
        const { programs } = getPrograms;
        return Promise.all(
            programs.map(
                async (program) =>
                    new ProgramApiForAdmin({
                        graphqlSdk: this._graphqlSdk,
                        ...(await getProgramForAdminFromGql(program)).data,
                    })
            )
        );
    }

    async getTickets({
        advertiserProgramId,
        after,
        before,
    }: {
        advertiserProgramId: string;
        after?: Date;
        before?: Date;
    }) {
        const { getTickets } = await this._graphqlSdk.getTickets({
            input: {
                advertiserProgramId,
                before: before ? GqlDateTimeData.parseValue(before) : undefined,
                after: after ? GqlDateTimeData.parseValue(after) : undefined,
            },
        });
        const { tickets } = getTickets;
        return Promise.all(
            tickets.map(
                async (ticket) =>
                    new TicketWithCampaign({
                        ...(await getTicketWithCampaignFromGql(ticket)).data,
                    })
            )
        );
    }

    async createProgram(input: CreateProgramInput) {
        const { createProgram } = await this._graphqlSdk.createProgram({
            input: {
                mediaFund: input.mediaFund,
                cashbackAmountRange: new CashbackAmountRange(input.cashbackAmountRange).data,
                title: input.title,
                description: input.description,
                descriptionForMedia: input.descriptionForMedia,
                overviewMd: input.overviewMd,
                advertiserName: input.advertiserName,
                url: input.url,
                endReceptionAt: input.endReceptionAt
                    ? GqlDateTimeData.parseValue(getDate0000({ date: input.endReceptionAt }))
                    : undefined,
                closeAt: input.closeAt ? GqlDateTimeData.parseValue(getDate0000({ date: input.closeAt })) : undefined,
                condition: new ProgramCondition(input.condition).data,
                trackingParamKey: input.trackingParamKey,
                advertiserProgramId: input.advertiserProgramId,
                totalPrice: {
                    beforeTax: input.totalPrice.beforeTax,
                    decimalRoundRule: input.totalPrice.decimalRoundRule,
                    taxRate: input.totalPrice.taxRate,
                },
                whitelistedMediaIds: input.whitelistedMediaIds,
                isPublicVisible: input.isPublicVisible,
            },
        });
        const { program } = createProgram;
        const programAPI = new ProgramApiForAdmin({
            graphqlSdk: this._graphqlSdk,
            ...(await getProgramForAdminFromGql(program)).data,
        });
        await Promise.all([
            (async () => {
                if (input.advertiserIcon) {
                    await programAPI.updateAdvertiserIcon({ newFile: input.advertiserIcon });
                }
            })(),
            (async () => {
                if (input.eyecatch) {
                    await programAPI.updateEyecatch({ newFile: input.eyecatch });
                }
            })(),
        ]);
        return programAPI;
    }

    async runManualCronForNewCashback() {
        await this._graphqlSdk.runManualCronForNewCashback();
        return true;
    }

    async runManualCronForSync() {
        await this._graphqlSdk.runManualCronForSync();
        return true;
    }

    async runManualCronForMediaReward() {
        await this._graphqlSdk.runManualCronForMediaReward();
        return true;
    }

    async runManualCronForRetryingMediaReward() {
        await this._graphqlSdk.runManualCronForRetryingMediaReward();
        return true;
    }

    async runAutoCron1day() {
        await this._graphqlSdk.runAutoCron1day();
        return true;
    }
}
