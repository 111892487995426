import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import { MoneybackOwnerClient } from '@/utils/MoneybackClient';
import { Modal } from '@/components/0_atom/Modal';
import { ModifyUrlModalContent } from '@/components/3_template/ConsoleTemplate/Config/ModifyUrlModal/Content';

const useStyles = makeStyles((theme) => ({
    root: {},
    heading: {
        fontSize: 18,
        margin: '0 0 60px 0',
        textAlign: 'center',
    },
}));

export const ModifyUrlModal: FC<{
    client: MoneybackOwnerClient;
    isOpen: boolean;
    onClose: () => void;
}> = ({ client, isOpen, onClose }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <Modal open={isOpen} onClose={onClose} anchor={'bottom'}>
            <MuiTypography variant={'h1'} className={c.heading}>
                {'メディアのURLを編集'}
            </MuiTypography>
            <ModifyUrlModalContent client={client} onClose={onClose} />
        </Modal>
    );
};
ModifyUrlModal.displayName = 'ModifyUrlModal';
