import { FC, ComponentProps } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { IntInput } from '@/components/0_atom/Input';

const useStyles = makeStyles((theme) => ({
    root: {},
    label: {
        fontWeight: 600,
        fontSize: theme.typography.body1.fontSize,
        display: 'block',
        marginBottom: 3,
    },
}));

export const IntInputWithLabel: FC<
    ComponentProps<typeof IntInput> & {
        labelText: string;
    }
> = ({ labelText, className, ...otherProps }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div className={className}>
            <span className={c.label}>{labelText}</span>
            <IntInput {...otherProps} fullWidth />
        </div>
    );
};
IntInputWithLabel.displayName = 'IntInputWithLabel';
