import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiList from '@material-ui/core/List';
import MuiListItem from '@material-ui/core/ListItem';
import MuiListItemText from '@material-ui/core/ListItemText';
import { useUserCtxAbsolutely, useMediaCtxAbsolutely, useAdminCtx, useStaffCtx } from '@/utils/ctxs';
import { useRouting } from '@/utils/customHooks';
import { ButtonWithTextMenu } from '@/components/1_mol/ButtonWithMenu';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '0 39px',
        '& .MuiTypography-root': {
            fontWeight: 600,
            textAlign: 'center',
        },
    },
    list1: {
        padding: 0,
        '& .MuiListItem-root': {
            padding: '16px 0',
            borderRadius: 4,
            '& svg': {
                width: 21,
                height: 21,
                marginRight: 8,
            },
            // '&:not(:last-child)': {
            //     borderBottom: 'solid 1px rgba(0,0,0,.05)',
            // },
        },
    },
    foot: {
        marginTop: 10,
        padding: '10px 0 0 0',
        textAlign: 'right',
        borderTop: 'solid 2px rgba(0,0,0,.03)',
        '& > span': {
            fontSize: theme.typography.body2.fontSize,
            marginRight: 12,
        },
    },
    mediaSwitcher: {
        border: 'solid 2px rgba(0,0,0,.03)',
    },
}));

export const GlobalHeaderMobileContent: FC<{
    close: () => void;
}> = ({ close }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { mediaMembers } = useUserCtxAbsolutely();
    const { adminClient } = useAdminCtx();
    const { staffClient } = useStaffCtx();
    const { mediaClient } = useMediaCtxAbsolutely();
    const { switchView, mediaId, switchMediaId } = useRouting();
    console.log({ staffClient });
    return (
        <div className={c.root}>
            <MuiList className={c.list1}>
                <MuiListItem
                    button
                    onClick={() => {
                        mediaId && switchView({ mediaId, viewName: 'campaigns' });
                        close();
                    }}
                >
                    <MuiListItemText>{'キャンペーン一覧'}</MuiListItemText>
                </MuiListItem>
                <MuiListItem
                    button
                    onClick={() => {
                        mediaId && switchView({ mediaId, viewName: 'programs' });
                        close();
                    }}
                >
                    <MuiListItemText>{'プログラムを探す'}</MuiListItemText>
                </MuiListItem>
                <MuiListItem
                    button
                    onClick={() => {
                        mediaId && switchView({ mediaId, viewName: 'balances' });
                        close();
                    }}
                >
                    <MuiListItemText>{'売上'}</MuiListItemText>
                </MuiListItem>
                <MuiListItem
                    button
                    onClick={() => {
                        mediaId && switchView({ mediaId, viewName: 'config' });
                        close();
                    }}
                >
                    <MuiListItemText>{'メディアの情報'}</MuiListItemText>
                </MuiListItem>
                <MuiListItem
                    button
                    onClick={() => {
                        mediaId && switchView({ mediaId, viewName: 'resources' });
                        close();
                    }}
                >
                    <MuiListItemText>{'その他の情報'}</MuiListItemText>
                </MuiListItem>
                {staffClient && (
                    <>
                        <MuiListItem
                            button
                            onClick={() => {
                                mediaId && switchView({ mediaId, viewName: 'staffuserinfo' });
                                close();
                            }}
                        >
                            <MuiListItemText>{'Staff: ユーザー調査'}</MuiListItemText>
                        </MuiListItem>
                    </>
                )}
                {adminClient && (
                    <>
                        <MuiListItem
                            button
                            onClick={() => {
                                mediaId && switchView({ mediaId, viewName: 'adminprograms' });
                                close();
                            }}
                        >
                            <MuiListItemText>{'Admin: プログラム管理'}</MuiListItemText>
                        </MuiListItem>
                        <MuiListItem
                            button
                            onClick={() => {
                                mediaId && switchView({ mediaId, viewName: 'adminreports' });
                                close();
                            }}
                        >
                            <MuiListItemText>{'Admin: レポート'}</MuiListItemText>
                        </MuiListItem>
                        <MuiListItem
                            button
                            onClick={() => {
                                mediaId && switchView({ mediaId, viewName: 'admincron' });
                                close();
                            }}
                        >
                            <MuiListItemText>{'Admin: CRON'}</MuiListItemText>
                        </MuiListItem>
                    </>
                )}
            </MuiList>
            <div className={c.foot}>
                <span>{'ログイン中のメディア'}</span>
                <ButtonWithTextMenu
                    className={c.mediaSwitcher}
                    items={mediaMembers.map(({ media }) => ({
                        text: media.name,
                        onClick: () => {
                            close();
                            if (media.id !== mediaClient.id) {
                                switchMediaId({ mediaId: media.id });
                            }
                        },
                        isSelected: media.id === mediaClient.id,
                    }))}
                    menuEmptyPlaceholder={'unexpected'}
                >
                    {mediaClient.name}
                </ButtonWithTextMenu>
            </div>
        </div>
    );
};
GlobalHeaderMobileContent.displayName = 'GlobalHeaderMobileContent';
