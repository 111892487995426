import { ProgramForPublic, ProgramForMedia, ProgramForAdmin, ProgramApiForAdmin } from '@/utils/MoneybackClient';

export const getProgramClosedDate = ({
    program,
}: {
    program: ProgramForPublic | ProgramForMedia | ProgramForAdmin | ProgramApiForAdmin;
}) => {
    const now = new Date();
    const programClosedDate = (() => {
        const { closeAt } = program;
        if (closeAt && closeAt <= now) {
            return closeAt;
        }
        return undefined;
    })();
    const programClosureDate = (() => {
        const { closure } = program;
        if (closure) {
            return closure.endedAt;
        }
        return undefined;
    })();
    const dates = [programClosedDate, programClosureDate].filter((date) => !!date) as Date[];
    if (dates.length > 0) {
        return dates.sort((a, b) => (a > b ? 1 : -1))[0];
    }
    return undefined;
};
