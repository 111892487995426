import { useState, useCallback, useEffect } from 'react';
import { useAdminCtx } from '@/utils/ctxs';
import { TicketsArrRow, getTicketsArrRow } from '@/components/3_template/ConsoleTemplate/Admin/Reports/getTicketsArr';

export const getToday = () => {
    const refDate = new Date();
    return new Date(refDate.getFullYear(), refDate.getMonth(), refDate.getDate());
};
export const getAMonthAgo = () => {
    const refDate = new Date();
    refDate.setMonth(refDate.getMonth() - 1);
    return new Date(refDate.getFullYear(), refDate.getMonth(), refDate.getDate());
};

export const useTicketsArr = () => {
    // HOOKS
    const { adminClient } = useAdminCtx();
    const [ticketsArrRows, setTicketsArrRows] = useState<TicketsArrRow[] | undefined>(undefined);
    const [advertiserProgramId, setAdvertiserProgramId] = useState<string | undefined>('862884');
    const [after, setAfter] = useState<Date>(getAMonthAgo());
    const [before, setBefore] = useState<Date>(getToday());
    const [isFetching, setIsFetching] = useState(false);

    // CALLBACK
    const initialize = useCallback(async () => {
        if (adminClient && advertiserProgramId) {
            setIsFetching(true);
            const trueBefore = before
                ? (() => {
                      const date = new Date(before.getFullYear(), before.getMonth(), before.getDate());
                      date.setDate(date.getDate() + 1); // こうすることで、「次の日の午前０時まで」＝ 見た目上「beforeの日いっぱいまで」となる。
                      return date;
                  })()
                : undefined;
            const ticketWithCampaigns = await adminClient.getTickets({
                advertiserProgramId,
                after,
                before: trueBefore,
            });
            // console.log({ ticketWithCampaigns });
            setTicketsArrRows(
                ticketWithCampaigns
                    .sort((a, b) => (a.ticket.createdAt > b.ticket.createdAt ? 1 : -1))
                    .map((ticketWithCampaign) => getTicketsArrRow(ticketWithCampaign))
            );
            setIsFetching(false);
        }
    }, [adminClient, advertiserProgramId, after, before]);

    return {
        ticketsArrRows,
        advertiserProgramId,
        setAdvertiserProgramId,
        after,
        setAfter,
        before,
        setBefore,
        initialize,
        isFetching,
    };
};
