import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import { Modal } from '@/components/0_atom/Modal';
import { CreateProgramModalContent } from '@/components/3_template/ConsoleTemplate/Admin/Programs/CreateProgramModal/Content';

const useStyles = makeStyles((theme) => ({
    root: {},
    heading: {
        fontSize: 18,
        margin: '0 0 60px 0',
        textAlign: 'center',
    },
}));

export const CreateProgramModal: FC<{
    isOpen: boolean;
    onClose: () => void;
    onComplete: () => Promise<void>;
}> = ({ isOpen, onClose, onComplete }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <Modal open={isOpen} onClose={onClose} anchor={'bottom'}>
            <MuiTypography variant={'h1'} className={c.heading}>
                {'新しいプログラムを作成'}
            </MuiTypography>
            <CreateProgramModalContent onClose={onClose} onComplete={onComplete} />
        </Modal>
    );
};
CreateProgramModal.displayName = 'CreateProgramModal';
