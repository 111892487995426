import { getCSV } from '@/utils/utilFunctions';
import { TicketsArrRow, headerRow } from '@/components/3_template/ConsoleTemplate/Admin/Reports/getTicketsArr';

const getCsvDateStr = (date: Date) => {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

export const parepareRecordsCsv = ({ ticketsArrRows }: { ticketsArrRows: TicketsArrRow[] }) => {
    const csv = getCSV([
        headerRow,
        ...ticketsArrRows.map((ticketsArrRow) =>
            ticketsArrRow.map((value) => {
                if (value instanceof Date) {
                    return getCsvDateStr(value);
                }
                return value;
            })
        ),
    ]);
    return csv;
};
