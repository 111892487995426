import { FC, useState, useMemo } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import MuiIconButton from '@material-ui/core/IconButton';
import SvgChevron from '@/svgs/fa-chevron-up-regular';
import AdminProvider from '@/providers/AdminProvider';
import { Modal } from '@/components/0_atom/Modal';
import { Brand } from '@/components/0_atom/Brand';
import { GlobalHeaderMobileContent } from '@/components/2_org/GlobalHeader/Mobile/Content';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '22px 10px 22px 10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        left: '50%',
        right: '50%',
        transform: 'translate(-50%, 0)',
        width: '100%',
        zIndex: 1299,
        background: '#fff',
        maxWidth: 558,
        transition: 'top .6s',
        borderRadius: '0 0 23px 23px',
    },
    rootShadow: {
        height: 82,
        position: 'fixed',
        top: 0,
        left: '50%',
        right: '50%',
        transform: 'translate(-50%, 0)',
        width: '100%',
        zIndex: 1298,
        background: '#fff',
        maxWidth: 558,
        borderRadius: '0 0 23px 23px',
        boxShadow: '0 0 8px 0 rgba(0,0,0,.1)',
        transition: 'top .6s',
    },
    button: {
        position: 'absolute',
        right: 10,
        top: 34 - 12,
        '& svg': {
            width: 14,
            height: 14,
            transform: 'rotateX(180deg)',
            transitionDuration: '.5s',
            transitionProperty: 'transform',
        },
    },
    svgOpen: {
        transform: 'rotateX(0deg) !important',
    },
    brandA: {},
    brand: {
        width: 150,
    },
}));

export const GlobalHeaderMobile: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const [isOpen, setIsOpen] = useState(false);
    const isScrolledUp = useScrollTrigger();

    // MEMO
    const content = useMemo(
        () => (
            <>
                <header
                    className={c.root}
                    style={{
                        top: isScrolledUp ? -90 : 0,
                    }}
                >
                    <a href={'/'} onClick={() => setIsOpen(false)} className={c.brandA}>
                        <Brand className={c.brand} />
                    </a>
                    <MuiIconButton onClick={() => setIsOpen(!isOpen)} className={c.button}>
                        <SvgChevron className={isOpen ? c.svgOpen : undefined} />
                    </MuiIconButton>
                </header>
                <div
                    className={c.rootShadow}
                    style={{
                        top: isScrolledUp ? -90 : 0,
                    }}
                />
                <Modal
                    anchor={'top'}
                    open={isOpen}
                    onClose={() => setIsOpen(false)}
                    drawerZIndex={1298}
                    PaperProps={{
                        style: {
                            maxWidth: 558,
                        },
                    }}
                >
                    <AdminProvider>
                        <GlobalHeaderMobileContent close={() => setIsOpen(false)} />
                    </AdminProvider>
                </Modal>
            </>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isOpen, isScrolledUp]
    );

    return content;
};
GlobalHeaderMobile.displayName = 'GlobalHeaderMobile';
