import { useState, useEffect } from 'react';

import { ServiceHealthCtxType, useErrorCtx } from '@/utils/ctxs';
import { getServiceHealth } from '@/utils/MoneybackClient';

export const useHealth = () => {
    // HOOKS
    const { setIs500 } = useErrorCtx();
    const [health, setHealth] = useState<ServiceHealthCtxType>({
        fetchStatus: 'fetching',
    });

    // USEEFFECT (INTERVAL)
    useEffect(() => {
        getServiceHealth()
            .then((health) => {
                setHealth({
                    fetchStatus: 'fetched',
                    health,
                });
            })
            .catch(() => {
                setHealth({
                    fetchStatus: 'fetchError',
                });
            });
    }, []);
    useEffect(() => {
        if (health.health && health.health.code !== 'ok') {
            setIs500(true);
        }
    }, [health, setIs500]);

    return health;
};
