import { FC, ReactNode, useState, useEffect, useCallback } from 'react';

import { UserCtx, useHealthCtx } from '@/utils/ctxs';
import { useFirebaseUser } from '@/utils/customHooks';
import {
    initMoneybackMediaUserClient,
    MoneybackMediaUserClient,
    initMoneybackFirebaseUserClient,
    Media,
    MediaMember,
} from '@/utils/MoneybackClient';

// initializeをuseEffect内とreturnに使いたいので、useCallbackを使用。
// https://ja.reactjs.org/docs/hooks-faq.html

const UserProvider: FC<{
    children: ReactNode;
}> = ({ children }) => {
    // HOOKS
    const { health } = useHealthCtx();
    const { firebaseUser, signOut } = useFirebaseUser();
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [isFirebaseError, setIsFirebaseError] = useState<boolean | undefined>(undefined);
    const [userClient, setUserClient] = useState<MoneybackMediaUserClient | undefined>(undefined);
    const [members, setMembers] = useState<{ media: Media; mediaMember: MediaMember }[] | undefined>(undefined);

    // CALLBACK
    const initialize = useCallback(async () => {
        const { idToken } = firebaseUser;
        if (idToken && health?.code === 'ok') {
            setIsFetching(true);
            const userClient = await initMoneybackMediaUserClient({
                firebaseUserIdToken: idToken,
            }).catch(() => {
                const firebaseUserClient = initMoneybackFirebaseUserClient({
                    firebaseUserIdToken: idToken,
                });
                return firebaseUserClient.signup();
            });
            setUserClient(userClient);
            if (userClient) {
                const fetchedMembers = await userClient.getMediaMembers();
                setMembers(fetchedMembers);
            }
            setIsFetching(false);
        } else {
            setUserClient(undefined);
            setIsFetching(firebaseUser.isFetching);
        }
    }, [firebaseUser, health]);

    // USEEFFECT
    useEffect(() => {
        initialize();
    }, [initialize]);
    useEffect(() => {
        setIsFirebaseError(firebaseUser.isError);
    }, [firebaseUser.isError]);

    return (
        <UserCtx.Provider
            value={{
                userClient,
                isFetching,
                firebaseUser: (() => {
                    const { uid, email, idToken } = firebaseUser;
                    if (uid && email && idToken) {
                        return {
                            uid,
                            email,
                            idToken,
                        };
                    }
                    return undefined;
                })(),
                reInitialize: initialize,
                signOut: () => {
                    signOut();
                    setUserClient(undefined);
                },
                isFirebaseError,
                mediaMembers: members,
                refetchMediaMembers: async () => {
                    const fetchedMembers = await userClient?.getMediaMembers();
                    setMembers(fetchedMembers);
                },
            }}
        >
            {children}
        </UserCtx.Provider>
    );
};
UserProvider.displayName = 'UserProvider';
export default UserProvider;
