/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/iframe-has-title */
import { FC } from 'react';

export const getGoogleTagManagerHead = () => {
    if (process.env.NODE_ENV === 'production') {
        const inHeadScript = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-57SSJWT');`;
        return <script>{inHeadScript}</script>;
    }
    return <></>;
};

export const GoogleTagManagerBody: FC = () => {
    if (process.env.NODE_ENV === 'production') {
        const inBodyScript = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-57SSJWT"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
        return <noscript dangerouslySetInnerHTML={{ __html: inBodyScript }} />;
    }

    return <></>;
};
GoogleTagManagerBody.displayName = 'GoogleTagManagerBody';
