import { GraphQLSdk } from '@/utils/MoneybackClient/graphql/graphqlSdk';
import {
    MediaUser,
    MediaUserType,
    Media,
    MediaMember,
    getMediaFromGql,
    getMediaMemberFromGql,
    BankAccountInput,
} from '@/utils/MoneybackClient/models';

type MoneybackMediaUserClientType = {
    getMediaMembers: () => Promise<
        {
            mediaMember: MediaMember;
            media: Media;
        }[]
    >;
    createMedia: (input: { name: string; url: string; bankAccount: BankAccountInput | undefined }) => Promise<{
        mediaMember: MediaMember;
        media: Media;
    }>;
};

type ConstructorInput = MediaUserType & {
    graphqlSdk: GraphQLSdk;
};

export class MoneybackMediaUserClient extends MediaUser implements MoneybackMediaUserClientType {
    protected _graphqlSdk: GraphQLSdk;

    constructor(input: ConstructorInput) {
        super(input);
        this._graphqlSdk = input.graphqlSdk;
    }

    async getMediaMembers() {
        const { getMediaMembersForMediaUser } = await this._graphqlSdk.getMediaMembersForMediaUser();
        const { mediaMemberWithMedias } = getMediaMembersForMediaUser;
        return mediaMemberWithMedias.map(({ media, mediaMember }) => ({
            media: getMediaFromGql(media),
            mediaMember: getMediaMemberFromGql(mediaMember),
        }));
    }

    async createMedia({
        name,
        url,
        bankAccount,
    }: {
        name: string;
        url: string;
        bankAccount: BankAccountInput | undefined;
    }) {
        const { createMedia } = await this._graphqlSdk.createMedia({
            input: {
                name,
                url,
                bankAccount: bankAccount?.data,
            },
        });
        const { media, mediaMember } = createMedia;
        return {
            media: getMediaFromGql(media),
            mediaMember: getMediaMemberFromGql(mediaMember),
        };
    }
}
