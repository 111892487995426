import { FC, useCallback, useState } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import { BankAccountInputType, MoneybackOwnerClient } from '@/utils/MoneybackClient';
import { Modal } from '@/components/0_atom/Modal';
import Button from '@/components/0_atom/Button';
import MutationButton from '@/components/0_atom/MutationButton';
import { BankAccountForm } from '@/components/1_mol/BankAccountForm';

const useStyles = makeStyles((theme) => ({
    root: {},
    heading: {
        textAlign: 'center',
        marginBottom: 50,
    },
    form: {},
    buttons: {
        display: 'flex',
        marginTop: 37,
    },
    cancelButton: {
        background: 'rgba(0,0,0,.05)',
        fontWeight: 600,
        padding: 14,
        width: '50%',
        marginRight: 7,
    },
    submitButton: {
        background: theme.palette.text.primary,
        fontWeight: 600,
        color: '#fff',
        padding: 14,
        width: '50%',
        '&.Mui-disabled': {
            background: 'rgba(0,0,0,.2)',
            color: '#fff',
        },
    },
}));

export const EditBankAccountModal: FC<{
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    mediaClient: MoneybackOwnerClient;
}> = ({ isOpen, setIsOpen, mediaClient }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const [isHasError, setIsHasError] = useState(false);
    const [bankAccount, setBankAccount] = useState<BankAccountInputType | undefined>(mediaClient.bankAccount?.data);

    // CALLBACK
    const runSubmit = useCallback(async () => {
        const { result } = await mediaClient.updateBankAccount({ bankAccount });
        if (result === 'success') {
            setIsOpen(false);
        }
        return result;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bankAccount, mediaClient]);

    return (
        <Modal anchor={'bottom'} open={isOpen} onClose={() => setIsOpen(false)}>
            <MuiTypography variant={'h1'} className={c.heading}>
                {'銀行口座を編集'}
            </MuiTypography>
            <BankAccountForm
                className={c.form}
                initialBankAccount={mediaClient.bankAccount}
                setBankAccount={setBankAccount}
                setIsHasError={setIsHasError}
            />
            <div className={c.buttons}>
                <Button onClick={() => setIsOpen(false)} className={c.cancelButton}>
                    {'キャンセル'}
                </Button>
                <MutationButton
                    mutation={{
                        description: '銀行口座を編集',
                        run: runSubmit,
                    }}
                    disabled={!bankAccount || isHasError}
                    className={c.submitButton}
                >
                    {'保存'}
                </MutationButton>
            </div>
        </Modal>
    );
};
EditBankAccountModal.displayName = 'EditBankAccountModal';
