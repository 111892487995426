import { FC, useState } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { ProgramForMedia, MoneybackGeneralClient, MoneybackOwnerClient } from '@/utils/MoneybackClient';
import { getMediaReward } from '@/utils/utilFunctions';
import { DetailsItem } from '@/components/0_atom/DetailsItem';
import { IntInputWithLabel } from '@/components/1_mol/InputWithLabel';
import { MediaReward } from '@/components/1_mol/MediaReward';
import { ProgramCard } from '@/components/2_org/ProgramCard';
import { CreateCampaignModalSubmitButton } from '@/components/2_org/CreateCampaignModal/SubmitButton';

const useStyles = makeStyles((theme) => ({
    root: {},
    cashbackAmount: {
        marginTop: 40,
        marginBottom: 14,
    },
}));

export const CreateCampaignModalContent: FC<{
    program: ProgramForMedia;
    client: MoneybackGeneralClient | MoneybackOwnerClient;
}> = ({ program, client }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const [cashbackAmount, setCashbackAmount] = useState<number | undefined>(undefined);

    // DATA
    const isConflictLowLimit = (() => {
        if (typeof cashbackAmount === 'number' && typeof program.cashbackAmountRange.low === 'number') {
            return cashbackAmount < program.cashbackAmountRange.low;
        }
        return false;
    })();
    const isConflictHighLimit = (() => {
        if (typeof cashbackAmount === 'number' && typeof program.cashbackAmountRange.high === 'number') {
            return cashbackAmount > program.cashbackAmountRange.high;
        }
        return false;
    })();
    const isHigherThanMediaFund = !!cashbackAmount && cashbackAmount > program.mediaFund;
    const isSatisfied = !!cashbackAmount && !isHigherThanMediaFund && !isConflictLowLimit && !isConflictHighLimit;
    const mediaReward =
        cashbackAmount && isSatisfied
            ? getMediaReward({
                  cashbackAmount,
                  mediaFund: program.mediaFund,
                  decimalRoundRule: 'down',
                  taxRate: 0.1,
              })
            : undefined;

    return (
        <>
            <ProgramCard
                program={program}
                enabledFields={{
                    mediaFund: true,
                    cashbackAmountRange: true,
                }}
            />
            <IntInputWithLabel
                className={c.cashbackAmount}
                value={cashbackAmount}
                setValue={setCashbackAmount}
                messages={[
                    {
                        message: `このプログラムのキャッシュバック最低金額は${program.cashbackAmountRange.low?.toLocaleString()}円です。`,
                        isError: isConflictLowLimit,
                    },
                    {
                        message: `このプログラムのキャッシュバック最高金額は${(
                            program.cashbackAmountRange.high || program.mediaFund
                        ).toLocaleString()}円です。`,
                        isError: isConflictHighLimit || isHigherThanMediaFund,
                    },
                ]}
                isSatisfied={isSatisfied}
                labelText={'キャッシュバック金額'}
                startAdornment={'¥'}
                placeholder={'キャッシュバック金額'}
            />
            <DetailsItem
                label={'メディア報酬'}
                content={<MediaReward mediaReward={mediaReward} />}
                variant={'full'}
                direction={'column'}
            />
            <CreateCampaignModalSubmitButton
                program={program}
                cashbackAmount={cashbackAmount || 0}
                client={client}
                isDisabled={!isSatisfied}
            />
        </>
    );
};
CreateCampaignModalContent.displayName = 'CreateCampaignModalContent';
