import { FC, useState } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { CreateProgramInput } from '@/utils/MoneybackClient';
import { getDateStr, getPrice } from '@/utils/utilFunctions';
import {
    TextInputWithLabel,
    IntInputWithLabel,
    DateInputWithLabel,
    BooleanInputWithLabel,
    ImgInputWithLabel,
    MarkdownInputWithLabel,
} from '@/components/1_mol/InputWithLabel';
import { CreateProgramModalConfirmationButton } from '@/components/3_template/ConsoleTemplate/Admin/Programs/CreateProgramModal/ConfirmationButton';

const useStyles = makeStyles((theme) => ({
    root: {},
    input: {
        marginTop: 40,
        marginBottom: 14,
    },
}));

export const CreateProgramModalContent: FC<{
    onClose: () => void;
    onComplete: () => Promise<void>;
}> = ({ onClose, onComplete }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const [mediaFund, setMediaFund] = useState<number | undefined>(undefined);
    const [cashbackAmountRangeLow, setCashbackAmountRangeLow] = useState<number | undefined>(undefined);
    const [cashbackAmountRangeHigh, setCashbackAmountRangeHigh] = useState<number | undefined>(undefined);
    const [title, setTitle] = useState<string | undefined>(undefined);
    const [description, setDescription] = useState<string | undefined>(undefined);
    const [descriptionForMedia, setDescriptionForMedia] = useState<string | undefined>(undefined);
    const [overviewMd, setOverviewMd] = useState<string | undefined>(undefined);
    const [advertiserName, setAdvertiserName] = useState<string | undefined>(undefined);
    const [url, setUrl] = useState<string | undefined>(undefined);
    const [endReceptionAt, setEndReceptionAt] = useState<Date | undefined>(undefined);
    const [closeAt, setCloseAt] = useState<Date | undefined>(undefined);
    const [trackingParamKey, setTrackingParamKey] = useState<string | undefined>('add');
    const [advertiserProgramId, setAdvertiserProgramId] = useState<string | undefined>(undefined);
    const [totalPriceBeforeTax, setTotalPriceBeforeTax] = useState<number | undefined>(undefined);
    const [whitelistedMediaIds, setWhitelistedMediaIds] = useState<string[] | undefined>(undefined);
    const [isPublicVisible, setIsPublicVisible] = useState(false);
    const [conditionToRecognize, setConditionToRecognize] = useState<string | undefined>(undefined);
    const [conditionToComplete, setConditionToComplete] = useState<string | undefined>(undefined);
    const [conditionPrecaution, setConditionPrecaution] = useState<string | undefined>(undefined);
    const [conditionCompleteLimitDays, setConditionCompleteLimitDays] = useState<number | undefined>(undefined);
    const [advertiserIcon, setAdvertiserIcon] = useState<File | undefined>(undefined);
    const [eyecatch, setEyecatch] = useState<File | undefined>(undefined);

    // DATA
    const createData: CreateProgramInput | undefined = (() => {
        if (!mediaFund) {
            return undefined;
        }
        if (!url) {
            return undefined;
        }
        if (!title) {
            return undefined;
        }
        if (!description) {
            return undefined;
        }
        if (!advertiserName) {
            return undefined;
        }
        if (!trackingParamKey) {
            return undefined;
        }
        if (!conditionToRecognize) {
            return undefined;
        }
        if (!totalPriceBeforeTax) {
            return undefined;
        }
        if (conditionCompleteLimitDays === 0) {
            return undefined;
        }
        return {
            mediaFund,
            cashbackAmountRange: {
                low: cashbackAmountRangeLow,
                high: cashbackAmountRangeHigh,
            },
            title,
            description,
            descriptionForMedia,
            overviewMd,
            advertiserName,
            url,
            endReceptionAt,
            closeAt,
            condition: {
                toRecognize: conditionToRecognize,
                toComplete: conditionToComplete,
                precaution: conditionPrecaution,
                completeLimitDays: conditionCompleteLimitDays,
            },
            trackingParamKey,
            advertiserProgramId,
            totalPrice: getPrice({
                beforeTax: totalPriceBeforeTax,
                decimalRoundRule: 'down',
                taxRate: 0.1,
            }),
            whitelistedMediaIds,
            isPublicVisible,
            advertiserIcon,
            eyecatch,
        };
    })();

    return (
        <>
            <TextInputWithLabel
                className={c.input}
                value={advertiserName}
                setValue={setAdvertiserName}
                messages={[
                    {
                        message: '必須フィールド',
                        isError: !advertiserName,
                    },
                ]}
                isSatisfied={!!advertiserName}
                labelText={'広告主の名称'}
                placeholder={'広告主の名称'}
            />
            <TextInputWithLabel
                className={c.input}
                value={title}
                setValue={setTitle}
                messages={[
                    {
                        message: '必須フィールド',
                        isError: !title,
                    },
                ]}
                isSatisfied={!!title}
                labelText={'タイトル'}
                placeholder={'タイトル'}
            />
            <TextInputWithLabel
                className={c.input}
                value={description}
                setValue={setDescription}
                messages={[
                    {
                        message: '必須フィールド',
                        isError: !description,
                    },
                ]}
                isSatisfied={!!description}
                labelText={'説明'}
                placeholder={'説明'}
            />
            <MarkdownInputWithLabel
                className={c.input}
                textMd={overviewMd || ''}
                setTextMd={(newValue) => setOverviewMd(newValue || undefined)}
                labelText={'概要'}
                placeholder={'概要'}
            />
            <ImgInputWithLabel
                className={c.input}
                labelText={'広告主のアイコン'}
                file={advertiserIcon}
                setFile={setAdvertiserIcon}
            />
            <ImgInputWithLabel className={c.input} labelText={'アイキャッチ'} file={eyecatch} setFile={setEyecatch} />
            <TextInputWithLabel
                className={c.input}
                value={url}
                setValue={setUrl}
                messages={[
                    {
                        message: '必須フィールド',
                        isError: !url,
                    },
                ]}
                isSatisfied={!!url}
                labelText={'遷移先URL'}
                placeholder={'遷移先URL'}
            />
            <IntInputWithLabel
                className={c.input}
                value={totalPriceBeforeTax}
                setValue={setTotalPriceBeforeTax}
                messages={[
                    {
                        message: '1円以上にする必要があります',
                        isError: typeof totalPriceBeforeTax === 'number' && totalPriceBeforeTax < 1,
                    },
                    {
                        message: '必須フィールド',
                        isError: totalPriceBeforeTax === undefined,
                    },
                    {
                        message: 'メディア単価が総単価を超えています',
                        isError: !!totalPriceBeforeTax && !!mediaFund && totalPriceBeforeTax < mediaFund,
                    },
                ]}
                isSatisfied={!!totalPriceBeforeTax}
                labelText={'総単価(税抜)'}
                placeholder={'総単価(税抜)'}
                startAdornment={'¥'}
            />
            <IntInputWithLabel
                className={c.input}
                value={mediaFund}
                setValue={setMediaFund}
                messages={[
                    {
                        message: '1円以上にする必要があります',
                        isError: typeof mediaFund === 'number' && mediaFund < 1,
                    },
                    {
                        message: '必須フィールド',
                        isError: mediaFund === undefined,
                    },
                    {
                        message: '総単価を超えています',
                        isError: !!totalPriceBeforeTax && !!mediaFund && totalPriceBeforeTax < mediaFund,
                    },
                ]}
                isSatisfied={!!mediaFund}
                labelText={'メディア単価(税抜)'}
                placeholder={'メディア単価(税抜)'}
                startAdornment={'¥'}
            />
            <IntInputWithLabel
                className={c.input}
                value={cashbackAmountRangeLow}
                setValue={setCashbackAmountRangeLow}
                messages={[
                    {
                        message: '1円以上にする必要があります',
                        isError: typeof cashbackAmountRangeLow === 'number' && cashbackAmountRangeLow < 1,
                    },
                    {
                        message: 'メディア単価を超えています',
                        isError: !!cashbackAmountRangeLow && !!mediaFund && mediaFund < cashbackAmountRangeLow,
                    },
                ]}
                isSatisfied={undefined}
                labelText={'キャッシュバック金額の下限'}
                placeholder={'下限'}
                startAdornment={'¥'}
                endAdornment={'以上'}
            />
            <IntInputWithLabel
                className={c.input}
                value={cashbackAmountRangeHigh}
                setValue={setCashbackAmountRangeHigh}
                messages={[
                    {
                        message: 'メディア単価を超えています',
                        isError: !!cashbackAmountRangeHigh && !!mediaFund && mediaFund < cashbackAmountRangeHigh,
                    },
                ]}
                isSatisfied={undefined}
                labelText={'キャッシュバック金額の上限'}
                placeholder={'上限'}
                startAdornment={'¥'}
                endAdornment={'以下'}
            />
            <TextInputWithLabel
                className={c.input}
                value={conditionToRecognize}
                setValue={setConditionToRecognize}
                messages={[
                    {
                        message: '必須フィールド',
                        isError: !conditionToRecognize,
                    },
                ]}
                isSatisfied={!!conditionToRecognize}
                labelText={'ブラウザ条件(condition.toRecognize)'}
                placeholder={'参加ボタンクリックからブラウザを開いたまま完了すべき条件'}
                multiline
            />
            <TextInputWithLabel
                className={c.input}
                value={conditionToComplete}
                setValue={setConditionToComplete}
                messages={undefined}
                isSatisfied={!!conditionToComplete}
                labelText={'達成条件(condition.toComplete)'}
                placeholder={'ブラウザを閉じた後でもよい条件'}
                multiline
            />
            <TextInputWithLabel
                className={c.input}
                value={conditionPrecaution}
                setValue={setConditionPrecaution}
                messages={undefined}
                isSatisfied={!!conditionPrecaution}
                labelText={'注意点(condition.precaution)'}
                placeholder={'注意点'}
                multiline
            />
            <TextInputWithLabel
                className={c.input}
                value={descriptionForMedia}
                setValue={setDescriptionForMedia}
                messages={undefined}
                isSatisfied={undefined}
                labelText={'メディア向け説明'}
                placeholder={'メディア向け説明'}
                multiline
            />
            <IntInputWithLabel
                className={c.input}
                value={conditionCompleteLimitDays}
                setValue={setConditionCompleteLimitDays}
                messages={[
                    {
                        message: '0日は指定できません',
                        isError: conditionCompleteLimitDays === 0,
                    },
                ]}
                isSatisfied={undefined}
                labelText={'達成期日(condition.completeLimitDays)'}
                placeholder={'達成期日'}
                endAdornment={'日以内'}
            />
            <TextInputWithLabel
                className={c.input}
                value={trackingParamKey}
                setValue={setTrackingParamKey}
                messages={[
                    {
                        message: '必須フィールド',
                        isError: !trackingParamKey,
                    },
                ]}
                isSatisfied={!!trackingParamKey}
                labelText={'trackingParamKey'}
                placeholder={'trackingParamKey'}
            />
            <TextInputWithLabel
                className={c.input}
                value={whitelistedMediaIds?.reduce((acc, prev, i) => {
                    if (i === 0) {
                        return prev;
                    }
                    return `${acc}\n${prev}`;
                }, '')}
                setValue={(newValue) => {
                    if (newValue) {
                        setWhitelistedMediaIds(newValue.replace(/ */g, '').replace(/\n+/g, '\n').split('\n'));
                    } else {
                        setWhitelistedMediaIds(undefined);
                    }
                }}
                messages={undefined}
                isSatisfied={undefined}
                labelText={'メディアホワイトリスト'}
                placeholder={'メディアホワイトリスト(改行区切り)'}
                multiline
            />
            <BooleanInputWithLabel
                className={c.input}
                labelText={'ホワイトリスト外にも表示'}
                value={isPublicVisible}
                setValue={setIsPublicVisible}
                texts={{
                    true: 'ホワイトリスト外にも表示',
                    false: 'ホワイトリスト外にも表示',
                }}
                isDisabled={!whitelistedMediaIds}
            />
            <TextInputWithLabel
                className={c.input}
                value={advertiserProgramId}
                setValue={setAdvertiserProgramId}
                messages={undefined}
                isSatisfied={undefined}
                labelText={'advertiserProgramId'}
                placeholder={'advertiserProgramId'}
            />
            <DateInputWithLabel
                className={c.input}
                date={endReceptionAt}
                setDate={setEndReceptionAt}
                labelText={'受付終了予定日'}
            >
                {endReceptionAt ? getDateStr({ date: endReceptionAt, isWithDay: true }) : '未定'}
            </DateInputWithLabel>
            <DateInputWithLabel className={c.input} date={closeAt} setDate={setCloseAt} labelText={'終了予定日'}>
                {closeAt ? getDateStr({ date: closeAt, isWithDay: true }) : '未定'}
            </DateInputWithLabel>
            <CreateProgramModalConfirmationButton
                createData={createData}
                onComplete={async () => {
                    await onComplete();
                    onClose();
                }}
            />
        </>
    );
};
CreateProgramModalContent.displayName = 'CreateProgramModalContent';
