import { FC, useState } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import { useUserCtxAbsolutely } from '@/utils/ctxs';
import { useRouting, useFirebaseUser } from '@/utils/customHooks';
import Button from '@/components/0_atom/Button';
import { CreateMediaModal } from '@/components/2_org/CreateMediaModal';

const useStyles = makeStyles((theme) => ({
    root: {},
    heading: {
        fontWeight: 600,
        textAlign: 'center',
    },
    buttons: {
        marginTop: 56,
        '& .MuiButton-root': {
            display: 'flex',
            justifyContent: 'center',
            boxShadow: '0 0 4px 0 rgba(0,0,0,.02)',
            padding: 16,
            background: '#fff',
            fontWeight: 600,
            '&:not(:last-child)': {
                marginBottom: 14,
            },
        },
    },
    empty: {
        marginTop: 56,
        color: theme.palette.text.disabled,
    },
    addMediaButton: {
        marginTop: 56,
        padding: '12px 24px',
    },
    signOutArea: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 28,
    },
    signOutAreaOr: {},
    signOutButton: {
        marginTop: 12,
        color: theme.palette.text.secondary,
        cursor: 'pointer',
        textDecoration: 'underline',
    },
}));

export const PreConsoleTemplateMembers: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { signOut } = useFirebaseUser();
    const { mediaMembers, refetchMediaMembers, userClient } = useUserCtxAbsolutely();
    const { switchMediaId } = useRouting();
    const [isCreateMediaModalOpen, setIsCreateMediaModalOpen] = useState(false);

    return (
        <>
            <div className={c.root}>
                <MuiTypography className={c.heading}>{'メディアを選択'}</MuiTypography>
                <div className={c.buttons}>
                    {mediaMembers.length > 0 ? (
                        mediaMembers.map(({ media, mediaMember }) => (
                            <Button key={mediaMember.id} onClick={() => switchMediaId({ mediaId: media.id })} fullWidth>
                                {media.name}
                            </Button>
                        ))
                    ) : (
                        <MuiTypography className={c.empty}>{'メディアの登録がありません。'}</MuiTypography>
                    )}
                </div>
                <Button
                    onClick={() => setIsCreateMediaModalOpen(true)}
                    className={c.addMediaButton}
                    role={'submit'}
                    fullWidth
                >
                    {'メディアを追加'}
                </Button>

                <div className={c.signOutArea}>
                    <div className={c.signOutAreaOr}>{'または'}</div>
                    <div className={c.signOutButton} onClick={() => signOut()}>
                        {'ログアウト'}
                    </div>
                </div>
            </div>
            <CreateMediaModal
                userClient={userClient}
                isOpen={isCreateMediaModalOpen}
                onClose={() => setIsCreateMediaModalOpen(false)}
                onComplete={refetchMediaMembers}
            />
        </>
    );
};
PreConsoleTemplateMembers.displayName = 'PreConsoleTemplateMembers';
