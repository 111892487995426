import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import { ProgramForMedia, CampaignApiForGeneral } from '@/utils/MoneybackClient';
import { useMediaCtxAbsolutely } from '@/utils/ctxs';
import { ConfirmationModal } from '@/components/1_mol/ConfirmationModal';
import { CampaignCard } from '@/components/2_org/CampaignCard';

const useStyles = makeStyles((theme) => ({
    root: {},
    description: {
        marginBottom: 24,
    },
}));

export const CampaignModalEndModal: FC<{
    isOpen: boolean;
    onClose: () => void;
    program: ProgramForMedia;
    campaignAPI: CampaignApiForGeneral;
    updateCampaignAPI: (updatedCampaignApiForGeneral: CampaignApiForGeneral) => void;
}> = ({ isOpen, onClose, program, campaignAPI, updateCampaignAPI }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { mediaClient } = useMediaCtxAbsolutely();

    // run
    const run = async () => {
        await campaignAPI.endReception({
            programId: program.id,
            mediaId: mediaClient.id,
        });
        updateCampaignAPI(campaignAPI);
        return 'success' as const;
    };

    return (
        <ConfirmationModal
            isOpen={isOpen}
            onClose={onClose}
            title={'キャンペーンを受付終了する'}
            mutation={{
                run,
                description: 'キャンペーンの受付を終了',
            }}
            submitText={'このキャンペーンの受付を終了'}
            isWarning
        >
            <MuiTypography className={c.description}>
                {'このキャンペーンの受付を終了しますか？この変更は元に戻せません。'}
            </MuiTypography>
            <CampaignCard program={program} campaign={campaignAPI} />
        </ConfirmationModal>
    );
};
CampaignModalEndModal.displayName = 'CampaignModalEndModal';
