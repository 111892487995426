import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import { ProgramForMedia, MoneybackGeneralClient, MoneybackOwnerClient } from '@/utils/MoneybackClient';
import { Modal } from '@/components/0_atom/Modal';
import { LoadingIndicator } from '@/components/0_atom/LoadingIndicator';
import { CreateCampaignModalContent } from '@/components/2_org/CreateCampaignModal/Content';

const useStyles = makeStyles((theme) => ({
    root: {},
    heading: {
        fontSize: 18,
        margin: '0 0 60px 0',
        textAlign: 'center',
    },
}));

export const CreateCampaignModal: FC<{
    program: ProgramForMedia | undefined;
    client: MoneybackGeneralClient | MoneybackOwnerClient;
    onClose: () => void;
}> = ({ program, client, onClose }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <Modal open={!!program} onClose={onClose} anchor={'bottom'}>
            <MuiTypography variant={'h1'} className={c.heading}>
                {'キャンペーンを作成'}
            </MuiTypography>
            {program ? <CreateCampaignModalContent program={program} client={client} /> : <LoadingIndicator />}
        </Modal>
    );
};
CreateCampaignModal.displayName = 'CreateCampaignModal';
