import { FC, ReactNode } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& th': {
            textAlign: 'left',
            fontWeight: 'normal',
            verticalAlign: 'top',
        },
        '& td': {
            textAlign: 'right',
            fontWeight: 'normal',
            verticalAlign: 'top',
        },
    },
}));

export const Simple2ColumnsTable: FC<{
    className?: string;
    items: [ReactNode, ReactNode][];
}> = ({ className, items }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <table className={clsx(c.root, className)}>
            <tbody>
                {items.map((item, i) => (
                    <tr key={i}>
                        <th>{item[0]}</th>
                        <td>{item[1]}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};
Simple2ColumnsTable.displayName = 'Simple2ColumnsTable';
