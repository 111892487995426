import { MediaUser as GqlMediaUser } from '@/utils/MoneybackClient/graphql/generated/graphqlClient';

export type MediaUserType = {
    id: string;
    email: string;
};

export class MediaUser implements MediaUserType {
    protected _id: string;

    protected _email: string;

    get id() {
        return this._id;
    }

    get email() {
        return this._email;
    }

    get data() {
        return {
            id: this.id,
            email: this.email,
        };
    }

    constructor({ id, email }: MediaUserType) {
        this._id = id;
        this._email = email;
    }
}

export const getMediaUserFromGql = ({ id, email }: GqlMediaUser) => {
    return new MediaUser({
        id,
        email,
    });
};
