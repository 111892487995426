import { FC, useState } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { MoneybackOwnerClient } from '@/utils/MoneybackClient';
import { useMediaCtxAbsolutely } from '@/utils/ctxs';
import { HorizontalTable } from '@/components/0_atom/HorizontalTable';
import Button from '@/components/0_atom/Button';
import { BankAccountCard } from '@/components/2_org/BankAccountCard';
import { EditBankAccountModal } from '@/components/2_org/EditBankAccountModal';
import { ModifyNameModal } from '@/components/3_template/ConsoleTemplate/Config/ModifyNameModal';
import { ModifyUrlModal } from '@/components/3_template/ConsoleTemplate/Config/ModifyUrlModal';

const useStyles = makeStyles((theme) => ({
    root: {
        background: '#fff',
        padding: '29px 19px',
        borderRadius: 4,
    },
    bankAccountButton: {
        padding: 0,
    },
    bankAccountButtonText: {
        padding: '6px 24px',
        color: theme.palette.text.disabled,
        border: 'solid 2px rgba(0,0,0,.05)',
        borderRadius: 4,
        fontWeight: 600,
    },
    tableContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& > a': {
            color: '#0a7',
            textDecoration: 'underline',
        },
        '& > .MuiButton-root': {
            // color: '#0a7',
            background: '#fafafa',
            fontSize: theme.typography.body2.fontSize,
            '&:hover': {
                background: '#eee',
            },
        },
    },
}));

export const ConsoleTemplateConfig: FC = () => {
    // STYLE

    const c = useStyles(useTheme());

    // HOOKS
    const { mediaClient } = useMediaCtxAbsolutely();
    const [isNameModalOpen, setIsNameModalOpen] = useState(false);
    const [isUrlModalOpen, setIsUrlModalOpen] = useState(false);
    const [isBankAccountModalOpen, setIsBankAccountModalOpen] = useState(false);

    return (
        <>
            <div className={c.root}>
                <HorizontalTable
                    items={[
                        {
                            label: '名称',
                            children: (
                                <span className={c.tableContent}>
                                    <span>{mediaClient.name}</span>
                                    {mediaClient instanceof MoneybackOwnerClient && (
                                        <Button onClick={() => setIsNameModalOpen(true)}>{'編集'}</Button>
                                    )}
                                </span>
                            ),
                        },
                        {
                            label: 'URL',
                            children: (
                                <span className={c.tableContent}>
                                    <a href={mediaClient.url} target={'_blank'} rel="noreferrer noopener">
                                        {mediaClient.url}
                                    </a>
                                    {mediaClient instanceof MoneybackOwnerClient && (
                                        <Button onClick={() => setIsUrlModalOpen(true)}>{'編集'}</Button>
                                    )}
                                </span>
                            ),
                        },
                        {
                            label: '銀行口座',
                            children:
                                mediaClient instanceof MoneybackOwnerClient ? (
                                    <Button
                                        onClick={() => setIsBankAccountModalOpen(true)}
                                        className={c.bankAccountButton}
                                    >
                                        {mediaClient.bankAccount ? (
                                            <BankAccountCard bankAccount={mediaClient.bankAccount} />
                                        ) : (
                                            <span className={c.bankAccountButtonText}>{'未設定'}</span>
                                        )}
                                    </Button>
                                ) : (
                                    <>
                                        {mediaClient.bankAccount ? (
                                            <BankAccountCard bankAccount={mediaClient.bankAccount} />
                                        ) : (
                                            '未設定'
                                        )}
                                    </>
                                ),
                        },
                    ]}
                />
            </div>
            {mediaClient instanceof MoneybackOwnerClient && (
                <>
                    <ModifyNameModal
                        client={mediaClient}
                        isOpen={isNameModalOpen}
                        onClose={() => setIsNameModalOpen(false)}
                    />
                    <ModifyUrlModal
                        client={mediaClient}
                        isOpen={isUrlModalOpen}
                        onClose={() => setIsUrlModalOpen(false)}
                    />
                    <EditBankAccountModal
                        isOpen={isBankAccountModalOpen}
                        setIsOpen={setIsBankAccountModalOpen}
                        mediaClient={mediaClient}
                    />
                </>
            )}
        </>
    );
};
ConsoleTemplateConfig.displayName = 'ConsoleTemplateConfig';
