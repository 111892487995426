import { Price, DecimalRoundRule } from '@/utils/MoneybackClient';
import { getPrice } from '@/utils/utilFunctions/getPrice';

export const getMediaReward = ({
    cashbackAmount,
    mediaFund,
    decimalRoundRule,
    taxRate,
}: {
    cashbackAmount: number;
    mediaFund: number;
    decimalRoundRule: DecimalRoundRule;
    taxRate: number;
}): Price => {
    const diff = mediaFund - cashbackAmount;
    const floatNetReward = diff / (1 + taxRate);
    const intNetReward = (() => {
        const floored = Math.floor(floatNetReward);
        const flooredTax = Math.floor(floored * taxRate);
        if (floored + flooredTax === diff) {
            return floored;
        }
        const flooredPlus1 = floored + 1;
        const flooredPlus1Tax = Math.floor(flooredPlus1 * taxRate);
        if (flooredPlus1 + flooredPlus1Tax === diff) {
            return flooredPlus1;
        }
        return floored;
    })();
    return getPrice({
        beforeTax: intNetReward,
        taxRate,
        decimalRoundRule,
    });
};
