import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { ProgramForMedia, Campaign, CampaignApiForGeneral } from '@/utils/MoneybackClient';
import { useTicketForCampaigns } from '@/utils/customHooks';
import { LoadingIndicator } from '@/components/0_atom/LoadingIndicator';
import { CampaignModalContentTicketsTicketCard } from '@/components/2_org/CampaignModal/ContentTickets/TicketCard';

const useStyles = makeStyles((theme) => ({
    root: {},
    tableHead: {
        fontWeight: 600,
        padding: '0 22px',
        marginBottom: 17,
        color: theme.palette.text.disabled,
        fontSize: theme.typography.body2.fontSize,
        display: 'flex',
        justifyContent: 'space-between',
    },
    tableHeadRight: {
        display: 'flex',
        '& > span:first-child': {
            width: 83,
        },
        '& > span:last-child': {
            width: 83,
        },
    },
    empty: {
        fontWeight: 600,
        color: theme.palette.text.disabled,
    },
    ticket: {
        '&:not(:last-child)': {
            marginBottom: 7,
        },
    },
}));

export const CampaignModalContentTickets: FC<{
    program: ProgramForMedia;
    campaign: Campaign | CampaignApiForGeneral;
}> = ({ program, campaign }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { tickets } = useTicketForCampaigns({
        programId: program.id,
        campaignId: campaign.id,
    });

    return (
        <>
            {tickets ? (
                tickets.length > 0 ? (
                    <>
                        <div className={c.tableHead}>
                            <span>{'発生日'}</span>
                            <span className={c.tableHeadRight}>
                                <span>{'報酬(税込)'}</span>
                                <span>{'状況'}</span>
                            </span>
                        </div>
                        {tickets.map((ticket) => (
                            <CampaignModalContentTicketsTicketCard
                                key={ticket.id}
                                program={program}
                                campaign={campaign}
                                ticket={ticket}
                                className={c.ticket}
                            />
                        ))}
                    </>
                ) : (
                    <p className={c.empty}>{'このキャンペーンにはまだ参加者がいません。'}</p>
                )
            ) : (
                <LoadingIndicator />
            )}
        </>
    );
};
CampaignModalContentTickets.displayName = 'CampaignModalContentTickets';
