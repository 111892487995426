import { GraphQLSdk } from '@/utils/MoneybackClient/graphql/graphqlSdk';
import {
    MediaType,
    Campaign,
    getCampaignFromGql,
    CampaignApiForGeneral,
    ProgramForMedia,
    getProgramForMediaFromGql,
} from '@/utils/MoneybackClient/models';
import { MoneybackObserverClient } from '@/utils/MoneybackClient/clients/Observer';

type MoneybackGeneralClientType = {
    getCampaignAPIs: () => Promise<
        {
            campaign: CampaignApiForGeneral;
            program: ProgramForMedia;
        }[]
    >;
    createCampaign: (input: { programId: string; cashbackAmount: number }) => Promise<Campaign>;
};

type ConstructorInput = MediaType & {
    graphqlSdk: GraphQLSdk;
};

export class MoneybackGeneralClient extends MoneybackObserverClient implements MoneybackGeneralClientType {
    protected _graphqlSdk: GraphQLSdk;

    constructor(input: ConstructorInput) {
        super(input);
        this._graphqlSdk = input.graphqlSdk;
    }

    async getCampaignAPIs() {
        const { getCampaignsForMedia } = await this._graphqlSdk.getCampaignsForMedia({
            input: {
                mediaId: this.id,
            },
        });
        const { campaigns } = getCampaignsForMedia;
        return Promise.all(
            campaigns.map(async ({ program, campaign }) => ({
                program: await getProgramForMediaFromGql(program),
                campaign: new CampaignApiForGeneral({
                    graphqlSdk: this._graphqlSdk,
                    ...getCampaignFromGql(campaign).data,
                }),
            }))
        );
    }

    async createCampaign({ programId, cashbackAmount }: { programId: string; cashbackAmount: number }) {
        const { createCampaign } = await this._graphqlSdk.createCampaign({
            input: {
                mediaId: this.id,
                programId,
                cashbackAmount,
            },
        });
        const { campaign } = createCampaign;
        return getCampaignFromGql(campaign);
    }
}
