import { FC, useState } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { MoneybackOwnerClient } from '@/utils/MoneybackClient';
import { DetailsItem } from '@/components/0_atom/DetailsItem';
import { TextInputWithLabel } from '@/components/1_mol/InputWithLabel';
import { ModifyUrlModalSubmitButton } from '@/components/3_template/ConsoleTemplate/Config/ModifyUrlModal/SubmitButton';

const useStyles = makeStyles((theme) => ({
    root: {},
    input: {
        marginTop: 40,
        marginBottom: 14,
    },
}));

export const ModifyUrlModalContent: FC<{
    client: MoneybackOwnerClient;
    onClose: () => void;
}> = ({ client, onClose }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const [newValue, setNewValue] = useState<string | undefined>(client.url);

    // DATA
    const isUrlValid = !!newValue && !!newValue.match(/^https:\/\/.+/);
    const isOk = isUrlValid && client.url !== newValue;

    return (
        <>
            <DetailsItem label={'これまでのURL'} content={client.url} variant={'full'} direction={'column'} />
            <TextInputWithLabel
                className={c.input}
                value={newValue}
                setValue={setNewValue}
                messages={[
                    {
                        message: 'メディアのURLは https:// で始まる必要があります。',
                        isError: !!newValue && !isUrlValid,
                    },
                ]}
                isSatisfied={isOk}
                labelText={'新しいURL'}
                placeholder={'新しいURL'}
            />
            <ModifyUrlModalSubmitButton
                client={client}
                newValue={newValue || ''}
                isDisabled={!isOk}
                onComplete={onClose}
            />
        </>
    );
};
ModifyUrlModalContent.displayName = 'ModifyUrlModalContent';
