import { GqlDateTimeData } from '@/utils/MoneybackClient/graphql/customScalars';
import { Media as GqlMedia } from '@/utils/MoneybackClient/graphql/generated/graphqlClient';
import { BankAccount, getBankAccountFromGql } from '@/utils/MoneybackClient/models/BankAccount';

export type MediaType = {
    id: string;
    name: string;
    url: string;
    bankAccount?: BankAccount;
    archivedAt?: Date;
};

export class Media implements MediaType {
    protected _id: string;

    protected _name: string;

    protected _url: string;

    protected _bankAccount?: BankAccount;

    protected _archivedAt?: Date;

    get id() {
        return this._id;
    }

    get name() {
        return this._name;
    }

    get url() {
        return this._url;
    }

    get bankAccount() {
        return this._bankAccount;
    }

    get archivedAt() {
        return this._archivedAt;
    }

    get data() {
        return {
            id: this.id,
            name: this.name,
            url: this.url,
            bankAccount: this.bankAccount,
            archivedAt: this.archivedAt,
        };
    }

    constructor({ id, name, url, bankAccount, archivedAt }: MediaType) {
        this._id = id;
        this._name = name;
        this._url = url;
        this._bankAccount = bankAccount;
        this._archivedAt = archivedAt;
    }
}

export const getMediaFromGql = ({ id, name, url, bankAccount, archivedAt }: GqlMedia) => {
    return new Media({
        id,
        name,
        url,
        bankAccount: bankAccount ? getBankAccountFromGql(bankAccount) : undefined,
        archivedAt: archivedAt ? GqlDateTimeData.serialize(archivedAt) : undefined,
    });
};
