import { FC, useState } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import { ProgramForMedia } from '@/utils/MoneybackClient';
import { useMediaCtxAbsolutely } from '@/utils/ctxs';
import {
    getDateStr,
    getProgramEndedDate,
    getIsClientGeneralOrOwner,
    getProgramClosedDate,
    getPrevDate2359,
} from '@/utils/utilFunctions';
import Button from '@/components/0_atom/Button';
import { DetailsItem } from '@/components/0_atom/DetailsItem';
import { ProgramConditionText } from '@/components/1_mol/ProgramConditionText';
import { CreateCampaignModal } from '@/components/2_org/CreateCampaignModal';
import { EndSchedule } from '@/components/1_mol/EndSchedule';
import { ProgramDescriptionForMedia } from '@/components/1_mol/ProgramDescriptionForMedia';
import { ProgramHeading } from '@/components/1_mol/ProgramHeading';
import { ProgramAdvertiser } from '@/components/1_mol/ProgramAdvertiser';
import { ProgramOverview } from '@/components/1_mol/ProgramOverview';

const useStyles = makeStyles((theme) => ({
    root: {},
    eyecatch: {
        maxHeight: 200,
        objectFit: 'contain',
        borderRadius: 4,
        marginBottom: 40,
        marginLeft: 0,
        marginRight: 'auto',
        border: 'solid 1px rgba(0,0,0,.05)',
    },
    title: {
        fontWeight: 600,
    },
    description: {
        fontSize: theme.typography.body2.fontSize,
        marginTop: 11,
    },
    programUrl: {
        textAlign: 'right',
        textDecoration: 'underline',
        color: '#0a7',
        margin: '14px 0',
        fontSize: theme.typography.body2.fontSize,
    },
    createButton: {
        marginTop: 40,
    },
    end: {
        fontSize: theme.typography.body2.fontSize,
        color: theme.palette.text.disabled,
    },
    overview: {
        background: '#fff',
        // border: 'solid 2px rgba(0,0,0,.03)',
        padding: '14px 0',
        marginBottom: 14,
    },
}));

export const ProgramModalContent: FC<{
    program: ProgramForMedia;
    isWithCreateCampaignButton: boolean;
}> = ({ program, isWithCreateCampaignButton }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { mediaClient } = useMediaCtxAbsolutely();
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

    // DATA
    const endedDate = getProgramEndedDate({ program });
    const closedDate = getProgramClosedDate({ program });

    return (
        <>
            {program.eyecatchSrc && <img src={program.eyecatchSrc} alt={'eyecatch'} className={c.eyecatch} />}
            {closedDate ? (
                <MuiTypography className={c.end}>
                    {`${getDateStr({ date: getPrevDate2359({ date: closedDate }), isWithDay: true })}で終了(成果締切)`}
                </MuiTypography>
            ) : endedDate ? (
                <MuiTypography className={c.end}>
                    {`${getDateStr({ date: getPrevDate2359({ date: endedDate }), isWithDay: true })}で受付終了`}
                </MuiTypography>
            ) : (
                <></>
            )}
            <ProgramHeading program={program} />
            <MuiTypography className={c.description}>
                {program.description.replace(/\[cashbackAmount\]/g, '<CB金額>')}
            </MuiTypography>
            <div className={c.programUrl}>
                <a href={program.url} target={'_blank'} rel="noreferrer noopener">
                    {'遷移先を開く'}
                </a>
            </div>
            <DetailsItem
                label={'概要'}
                content={<ProgramOverview overviewMd={program.overviewMd} />}
                variant={'full'}
                direction={'column'}
                contentClassName={c.overview}
            />
            <DetailsItem
                label={'報酬全額'}
                content={`¥ ${program.mediaFund.toLocaleString()}`}
                variant={'full'}
                direction={'row'}
            />
            <DetailsItem
                label={'広告主'}
                content={<ProgramAdvertiser name={program.advertiserName} iconSrc={program.advertiserIconSrc} />}
                variant={'full'}
                direction={'row'}
            />
            <DetailsItem
                label={'終了日'}
                content={<EndSchedule program={program} campaign={undefined} />}
                variant={'full'}
                direction={'row'}
            />
            {(program.cashbackAmountRange.low || program.cashbackAmountRange.high) && (
                <DetailsItem
                    label={'CB金額制限'}
                    content={(() => {
                        let str = '';
                        if (program.cashbackAmountRange.low) {
                            str += `${program.cashbackAmountRange.low.toLocaleString()}円以上`;
                        }
                        if (program.cashbackAmountRange.high) {
                            if (program.cashbackAmountRange.low) {
                                str += ' ';
                            }
                            str += `${program.cashbackAmountRange.high.toLocaleString()}円以下`;
                        }
                        return str;
                    })()}
                    variant={'full'}
                    direction={'row'}
                />
            )}
            <DetailsItem
                label={'成果期限'}
                content={`${
                    program.condition.completeLimitDays
                        ? `応募から${program.condition.completeLimitDays}日以内`
                        : '条件に記載の通り'
                }。ただし、キャンペーンが終了(条件達成締切)した場合はその時点まで。`}
                variant={'full'}
                direction={'row'}
            />
            <DetailsItem
                label={'条件'}
                content={<ProgramConditionText condition={program.condition} />}
                variant={'full'}
                direction={'column'}
            />
            <DetailsItem
                label={'メディア向け説明'}
                content={<ProgramDescriptionForMedia descriptionForMedia={program.descriptionForMedia} />}
                variant={'full'}
                direction={'column'}
            />
            {getIsClientGeneralOrOwner(mediaClient) && isWithCreateCampaignButton && (
                <>
                    <Button
                        className={c.createButton}
                        onClick={() => setIsCreateModalOpen(true)}
                        role={'submit'}
                        disabled={!program.isAbleToCreateCampaign}
                    >
                        {program.isAbleToCreateCampaign
                            ? 'キャンペーンを作成'
                            : 'このプログラムは審査制です。提携をご希望の場合は、担当までご連絡ください。'}
                    </Button>
                    <CreateCampaignModal
                        program={isCreateModalOpen ? program : undefined}
                        client={mediaClient}
                        onClose={() => setIsCreateModalOpen(false)}
                    />
                </>
            )}
        </>
    );
};
ProgramModalContent.displayName = 'ProgramModalContent';
