import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { ProgramCondition } from '@/utils/MoneybackClient';
import { getListedTextFromPlain } from '@/utils/utilFunctions';
import MarkdownViewer from '@/components/0_atom/MarkdownViewer';

const useStyles = makeStyles((theme) => ({
    root: {
        '& p': {
            margin: 0,
            '&:not(:last-child)': {
                marginBottom: 14,
            },
        },
        '& ul': {
            margin: 0,
            padding: '0 0 0 14px',
            '&:not(:last-child)': {
                marginBottom: 14,
            },
            '& li': {
                listStyleType: 'none',
                position: 'relative',
                '&::before': {
                    content: "'\u30fb'",
                    display: 'block',
                    position: 'absolute',
                    left: -14,
                },
                '&:not(:last-child)': {
                    marginBottom: 14,
                },
            },
        },
    },
    precaution: {
        marginTop: '32px !important',
        fontWeight: 600,
    },
}));

export const ProgramConditionText: FC<{
    condition: ProgramCondition;
    disabledFields?: {
        toRecognize?: boolean;
        toComplete?: boolean;
        precaution?: boolean;
    };
}> = ({ condition, disabledFields }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div className={c.root}>
            <div>
                {!disabledFields?.toRecognize && (
                    <MarkdownViewer textMd={getListedTextFromPlain(condition.toRecognize)} />
                )}
                {!disabledFields?.toComplete && condition.toComplete && (
                    <MarkdownViewer textMd={getListedTextFromPlain(condition.toComplete)} />
                )}
            </div>
            {!disabledFields?.precaution && condition.precaution && (
                <>
                    <p className={c.precaution}>{'【注意点】'}</p>
                    <MarkdownViewer textMd={getListedTextFromPlain(condition.precaution)} />
                </>
            )}
        </div>
    );
};
ProgramConditionText.displayName = 'ProgramConditionText';
