import { GraphQLSdk } from '@/utils/MoneybackClient/graphql/graphqlSdk';

type MoneybackPublicClientType = {
    getTosForMedia: () => Promise<string>;
    getPrivacypolicy: () => Promise<string>;
};

type ConstructorInput = {
    graphqlSdk: GraphQLSdk;
};

export class MoneybackPublicClient implements MoneybackPublicClientType {
    protected _graphqlSdk: GraphQLSdk;

    constructor({ graphqlSdk }: ConstructorInput) {
        this._graphqlSdk = graphqlSdk;
    }

    async getTosForMedia() {
        const { getTosForMedia } = await this._graphqlSdk.getTosForMedia();
        const { tosForMedia } = getTosForMedia;
        return tosForMedia;
    }

    async getPrivacypolicy() {
        const { getPrivacypolicy } = await this._graphqlSdk.getPrivacypolicy();
        const { privacypolicy } = getPrivacypolicy;
        return privacypolicy;
    }
}
