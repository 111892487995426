import { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useMediaCtx, useUserCtx, useHealthCtx, useErrorCtx } from '@/utils/ctxs';
import HealthProvider from '@/providers/HealthProvider';
import MutationsProvider from '@/providers/MutationsProvider';
import ErrorProvider from '@/providers/ErrorProvider';
import UserProvider from '@/providers/UserProvider';
import MediaProvider from '@/providers/MediaProvider';
import { GoogleTagManagerBody, getGoogleTagManagerHead } from '@/components/0_atom/GoogleTagManager';
import NetworkIndicator from '@/components/2_org/NetworkIndicator';
import { ConsoleTemplate } from '@/components/3_template/ConsoleTemplate';
import { PreConsoleTemplate } from '@/components/3_template/PreConsoleTemplate';
import { ErrorTemplate } from '@/components/3_template/ErrorTemplate';
import { LoadingTemplate } from '@/components/3_template/LoadingTemplate';

import muiTheme from '@/styles/muiTheme';

const Handler: FC = () => {
    // HOOKS
    const { isFetching: isFetchingUser } = useUserCtx();
    const { fetchStatus } = useHealthCtx();
    const { is404, is500 } = useErrorCtx();
    const { mediaClient, isFetching: isFetchingMedia } = useMediaCtx();

    if (fetchStatus === 'fetching' || isFetchingUser || isFetchingMedia) {
        return <LoadingTemplate />;
    }
    if (is404) {
        return <ErrorTemplate errorCode={404} />;
    }
    if (is500) {
        return <ErrorTemplate errorCode={500} />;
    }
    return mediaClient ? <ConsoleTemplate /> : <PreConsoleTemplate />;
};

const AppRoot: FC = () => {
    // return (
    //     <>
    //         <GoogleTagManagerBody />
    //         <ErrorProvider>
    //             <HealthProvider>
    //                 <MutationsProvider>
    //                     <UserProvider>
    //                         <BrowserRouter>
    //                             <MediaProvider>
    //                                 <ThemeProvider theme={muiTheme}>
    //                                     <CssBaseline />
    //                                     <NetworkIndicator />
    //                                     <Handler />
    //                                 </ThemeProvider>
    //                             </MediaProvider>
    //                         </BrowserRouter>
    //                     </UserProvider>
    //                 </MutationsProvider>
    //             </HealthProvider>
    //         </ErrorProvider>
    //     </>
    // );
    return (
        <HelmetProvider>
            <Helmet>
                {/* {(() => {
                    const g = getGoogleTagManagerHead();
                    return g || <></>;
                })()} */}
                {getGoogleTagManagerHead()}
            </Helmet>
            <>
                <GoogleTagManagerBody />
                <ErrorProvider>
                    <HealthProvider>
                        <MutationsProvider>
                            <UserProvider>
                                <BrowserRouter>
                                    <MediaProvider>
                                        <ThemeProvider theme={muiTheme}>
                                            <CssBaseline />
                                            <NetworkIndicator />
                                            <Handler />
                                        </ThemeProvider>
                                    </MediaProvider>
                                </BrowserRouter>
                            </UserProvider>
                        </MutationsProvider>
                    </HealthProvider>
                </ErrorProvider>
            </>
        </HelmetProvider>
    );
};
AppRoot.displayName = 'AppRoot';
export default AppRoot;
