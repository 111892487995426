import { FC, useState } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { ProgramApiForAdmin } from '@/utils/MoneybackClient';
import Button from '@/components/0_atom/Button';
import { ConsoleTemplateAdminProgramData } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramData';
import { CloseModal } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramModal/CloseModal';
import { ModifyCloseAtModal } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramModal/ModifyCloseAt';
import { ModifyConditionCompleteLimitDaysModal } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramModal/ModifyConditionCompleteLimitDays';
import { ModifyConditionPrecautionModal } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramModal/ModifyConditionPrecaution';
import { ModifyConditionToCompleteModal } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramModal/ModifyConditionToCompleteModal';
import { ModifyConditionToRecognizeModal } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramModal/ModifyConditionToRecognizeModal';
import { ModifyEndReceptionAtModal } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramModal/ModifyEndReceptionAt';
import { ModifyDescriptionModal } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramModal/ModifyDescriptionModal';
import { ModifyDescriptionForMediaModal } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramModal/ModifyDescriptionForMediaModal';
import { ModifyWhitelistedMediaIdsModal } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramModal/ModifyWhitelistedMediaIdsModal';
import { ModifyIsPublicVisibleModal } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramModal/ModifyIsPublicVisibleModal';
import { ModifyOverviewMdModal } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramModal/ModifyOverviewMdModal';
import { ModifyAdvertiserIconModal } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramModal/ModifyAdvertiserIconModal';
import { ModifyEyecatchModal } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramModal/ModifyEyecatchModal';

const useStyles = makeStyles((theme) => ({
    root: {},
    bottomActions: {
        borderTop: 'solid 2px rgba(0,0,0,.05)',
        marginTop: 62,
        paddingTop: 12,
    },
    modifyButton: {
        marginLeft: -12,
        display: 'flex',
    },
    closeButton: {
        color: theme.palette.error.main,
        marginLeft: -12,
        display: 'flex',
    },
}));

export const ConsoleTemplateAdminProgramsAdminProgramModalContent: FC<{
    programAPI: ProgramApiForAdmin;
}> = ({ programAPI }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const [openingModal, setOpeningModal] = useState<
        | 'modEndReceptionAt'
        | 'modCloseAt'
        | 'modConditionToRecognize'
        | 'modConditionToComplete'
        | 'modConditionPrecaution'
        | 'modConditionCompleteLimitDays'
        | 'close'
        | 'description'
        | 'descriptionForMedia'
        | 'whitelistedMediaIds'
        | 'isPublicVisible'
        | 'overviewMd'
        | 'advertiserIcon'
        | 'eyecatch'
        | undefined
    >(undefined);

    return (
        <>
            <ConsoleTemplateAdminProgramData program={programAPI} />
            <div className={c.bottomActions}>
                <Button className={c.modifyButton} onClick={() => setOpeningModal('description')}>
                    {'説明を編集'}
                </Button>
                <Button className={c.modifyButton} onClick={() => setOpeningModal('descriptionForMedia')}>
                    {'メディア向け説明を編集'}
                </Button>
                <Button className={c.modifyButton} onClick={() => setOpeningModal('modConditionToRecognize')}>
                    {'ブラウザ条件を編集'}
                </Button>
                <Button className={c.modifyButton} onClick={() => setOpeningModal('modConditionToComplete')}>
                    {'達成条件を編集'}
                </Button>
                <Button className={c.modifyButton} onClick={() => setOpeningModal('modConditionPrecaution')}>
                    {'注意点を編集'}
                </Button>
                <Button className={c.modifyButton} onClick={() => setOpeningModal('modConditionCompleteLimitDays')}>
                    {'達成期限を編集'}
                </Button>
                <Button className={c.modifyButton} onClick={() => setOpeningModal('modEndReceptionAt')}>
                    {'受付終了日を編集'}
                </Button>
                <Button className={c.modifyButton} onClick={() => setOpeningModal('modCloseAt')}>
                    {'終了日を編集'}
                </Button>
                <Button className={c.modifyButton} onClick={() => setOpeningModal('whitelistedMediaIds')}>
                    {'メディアホワイトリストを編集'}
                </Button>
                <Button className={c.modifyButton} onClick={() => setOpeningModal('isPublicVisible')}>
                    {'ホワイトリスト外表示を編集'}
                </Button>
                <Button className={c.modifyButton} onClick={() => setOpeningModal('overviewMd')}>
                    {'概要を編集'}
                </Button>
                <Button className={c.modifyButton} onClick={() => setOpeningModal('advertiserIcon')}>
                    {'広告主アイコンを編集'}
                </Button>
                <Button className={c.modifyButton} onClick={() => setOpeningModal('eyecatch')}>
                    {'アイキャッチを編集'}
                </Button>
                {!programAPI.closure && (
                    <Button className={c.closeButton} onClick={() => setOpeningModal('close')}>
                        {'このプログラムを終了'}
                    </Button>
                )}
            </div>
            <ModifyConditionToRecognizeModal
                programAPI={openingModal === 'modConditionToRecognize' ? programAPI : undefined}
                onClose={() => setOpeningModal(undefined)}
            />
            <ModifyConditionToCompleteModal
                programAPI={openingModal === 'modConditionToComplete' ? programAPI : undefined}
                onClose={() => setOpeningModal(undefined)}
            />
            <ModifyConditionPrecautionModal
                programAPI={openingModal === 'modConditionPrecaution' ? programAPI : undefined}
                onClose={() => setOpeningModal(undefined)}
            />
            <ModifyConditionCompleteLimitDaysModal
                programAPI={openingModal === 'modConditionCompleteLimitDays' ? programAPI : undefined}
                onClose={() => setOpeningModal(undefined)}
            />
            <ModifyEndReceptionAtModal
                programAPI={openingModal === 'modEndReceptionAt' ? programAPI : undefined}
                onClose={() => setOpeningModal(undefined)}
            />
            <ModifyCloseAtModal
                programAPI={openingModal === 'modCloseAt' ? programAPI : undefined}
                onClose={() => setOpeningModal(undefined)}
            />
            <CloseModal
                programAPI={openingModal === 'close' ? programAPI : undefined}
                onClose={() => setOpeningModal(undefined)}
            />
            <ModifyDescriptionModal
                programAPI={openingModal === 'description' ? programAPI : undefined}
                onClose={() => setOpeningModal(undefined)}
            />
            <ModifyDescriptionForMediaModal
                programAPI={openingModal === 'descriptionForMedia' ? programAPI : undefined}
                onClose={() => setOpeningModal(undefined)}
            />
            <ModifyWhitelistedMediaIdsModal
                programAPI={openingModal === 'whitelistedMediaIds' ? programAPI : undefined}
                onClose={() => setOpeningModal(undefined)}
            />
            <ModifyIsPublicVisibleModal
                programAPI={openingModal === 'isPublicVisible' ? programAPI : undefined}
                onClose={() => setOpeningModal(undefined)}
            />
            <ModifyOverviewMdModal
                programAPI={openingModal === 'overviewMd' ? programAPI : undefined}
                onClose={() => setOpeningModal(undefined)}
            />
            <ModifyAdvertiserIconModal
                programAPI={openingModal === 'advertiserIcon' ? programAPI : undefined}
                onClose={() => setOpeningModal(undefined)}
            />
            <ModifyEyecatchModal
                programAPI={openingModal === 'eyecatch' ? programAPI : undefined}
                onClose={() => setOpeningModal(undefined)}
            />
        </>
    );
};
ConsoleTemplateAdminProgramsAdminProgramModalContent.displayName =
    'ConsoleTemplateAdminProgramsAdminProgramModalContent';
