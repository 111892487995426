import { FC, useState, useEffect } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { LoadingIndicator } from '@/components/0_atom/LoadingIndicator';
import MutationButton from '@/components/0_atom/MutationButton';
import { TextInput } from '@/components/0_atom/Input';
import { BankAccountCard } from '@/components/2_org/BankAccountCard';
import { getEnvVariables } from '@/utils/envVariables';
import { useStaffCtx } from '@/utils/ctxs';
import { ServiceUser, TicketWithCampaign, ProgramForAdmin, Ticket } from '@/utils/MoneybackClient';

const useStyles = makeStyles((theme) => ({
    root: {},
    inputBox: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    input: {
        width: '100%',
    },
    submit: {
        height: 65,
        marginLeft: 4,
        paddingLeft: 36,
        paddingRight: 36,
        flexGrow: 1,
        whiteSpace: 'nowrap',
    },
    result: {},
    user: {},
    tickets: {},
    noData: {
        color: theme.palette.text.disabled,
    },
    table: {
        borderCollapse: 'collapse',
        textAlign: 'left',
        width: '100%',
        outline: 'solid 1px rgba(0,0,0,.5)',
        // borderTop: 'solid 1px rgba(0,0,0,.05)',
        // borderRight: 'solid 1px rgba(0,0,0,.05)',
        // borderLeft: 'solid 1px rgba(0,0,0,.05)',
        fontSize: 12,
        '& tr': {
            borderBottom: 'solid 1px rgba(0,0,0,.05)',
        },
        '& th': {
            borderRight: 'solid 1px rgba(0,0,0,.05)',
            whiteSpace: 'nowrap',
            padding: '4px 8px',
        },
        '& td': {
            padding: '4px 8px',
            '& a': {
                color: '#0a7',
                textDecoration: 'underline',
            },
        },
    },
    userTable: {},
    ticketTable: {
        '&:not(:last-child)': {
            marginBottom: 23,
        },
    },
    h2: {
        margin: '48px 0 18px 0',
        fontSize: 18,
    },
}));

const { wwwRootUrl } = getEnvVariables();

export const ConsoleTemplateStaffUserInfo: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { staffClient } = useStaffCtx();
    const [phone, setPhone] = useState<string | undefined>(undefined);
    const [fetchResult, setFetchResult] = useState<{
        givenPhone: string | undefined;
        user: ServiceUser | undefined;
        ticketWithCampaigns: TicketWithCampaign[] | undefined;
    }>({
        givenPhone: undefined,
        user: undefined,
        ticketWithCampaigns: undefined,
    });

    // DATA
    const isPhoneFormatCorrect = phone && phone.length === 11 && /^\d*$/.test(phone);

    // MUTATION
    const run = async () => {
        if (staffClient && phone) {
            // setIsMissing(false);
            const { user, ticketWithCampaigns } = await staffClient.getUserInfo({ phone }).catch(() => ({
                user: undefined,
                ticketWithCampaigns: undefined,
            }));
            setFetchResult({
                givenPhone: phone,
                user,
                ticketWithCampaigns,
            });
            return 'success' as const;
        }
        throw new Error('never');
    };
    console.log({ fetchResult });

    // UTIL

    const getApproveStatus = ({ program, ticket }: { program: ProgramForAdmin; ticket: Ticket }) => {
        return ticket.settlement?.judgement === 'approve'
            ? 'approved'
            : ticket.settlement
            ? 'rejected'
            : ticket.recognizedAt
            ? 'recognized'
            : (() => {
                  // 未承認放置を「却下」表示
                  const { completeLimitDays } = program.condition;
                  if (completeLimitDays !== undefined) {
                      const today = new Date();
                      const limitDate = (() => {
                          const date = new Date(ticket.createdAt);
                          date.setDate(date.getDate() + completeLimitDays); // 期限日
                          date.setMonth(date.getMonth() + 2); // 翌月末の反映なので
                          date.setDate(1); // 翌月末の反映なので
                          return date;
                      })();
                      return limitDate < today;
                  }
                  const closeDate = program.closure?.endedAt || program.closeAt;
                  if (closeDate) {
                      const today = new Date();
                      const limitDate = (() => {
                          const date = new Date(closeDate);
                          date.setMonth(date.getMonth() + 2); // 翌月末の反映なので
                          date.setDate(1); // 翌月末の反映なので
                          return date;
                      })();
                      return limitDate < today;
                  }
                  return false;
              })()
            ? 'displayAsRejected'
            : undefined;
    };
    const getApproveStatusText = (status: 'approved' | 'rejected' | 'recognized' | 'displayAsRejected' | undefined) => {
        if (status === 'approved') {
            return '承認済み';
        }
        if (status === 'rejected') {
            return '却下';
        }
        if (status === 'recognized') {
            return '認識済み';
        }
        if (status === 'displayAsRejected') {
            return '却下（表示上は却下、実際は未承認放置）';
        }
        return '未承認';
    };

    return (
        <>
            {staffClient ? (
                <div className={c.root}>
                    <div className={c.inputBox}>
                        <div className={c.input}>
                            <TextInput
                                fullWidth
                                value={phone}
                                setValue={setPhone}
                                messages={[
                                    {
                                        isError: (!!phone && phone.length > 11) || (!!phone && !/^\d*$/.test(phone)),
                                        message: '電話番号は0X0XXXXYYYYの型式で入力してください。',
                                    },
                                    {
                                        isError:
                                            !!fetchResult.givenPhone &&
                                            !fetchResult.user &&
                                            phone === fetchResult.givenPhone,
                                        message: `${fetchResult.givenPhone || ''} のユーザーは見つかりませんでした`,
                                    },
                                ]}
                                error={
                                    !!fetchResult.givenPhone && !fetchResult.user && phone === fetchResult.givenPhone
                                }
                            />
                        </div>
                        <MutationButton
                            className={c.submit}
                            mutation={{
                                description: 'ユーザー情報を取得',
                                run,
                            }}
                            disabled={
                                phone?.length !== 11 ||
                                (!!fetchResult.givenPhone && !fetchResult.user && phone === fetchResult.givenPhone) ||
                                !isPhoneFormatCorrect
                            }
                            role={'submit'}
                        >
                            {'取得'}
                        </MutationButton>
                    </div>
                    <div className={c.result}>
                        {fetchResult.user && (
                            <div className={c.user}>
                                <h2 className={c.h2}>{'ユーザー情報'}</h2>
                                {
                                    <table className={clsx(c.table, c.userTable)}>
                                        <tbody>
                                            <tr>
                                                <th>{'ユーザーID'}</th>
                                                <td>{fetchResult.user.id}</td>
                                            </tr>
                                            <tr>
                                                <th>{'アカウント作成日時'}</th>
                                                <td>{fetchResult.user.createdAt.toLocaleString()}</td>
                                            </tr>
                                            <tr>
                                                <th>{'電話番号'}</th>
                                                <td>{fetchResult.user.phone}</td>
                                            </tr>
                                            <tr>
                                                <th>{'銀行口座'}</th>
                                                <td>
                                                    {fetchResult.user.bankAccount ? (
                                                        <BankAccountCard bankAccount={fetchResult.user.bankAccount} />
                                                    ) : (
                                                        '登録なし'
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{'アカウント状態'}</th>
                                                <td>
                                                    {fetchResult.user.removedAt
                                                        ? `${fetchResult.user.removedAt.toLocaleString()}に削除`
                                                        : '有効'}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                }
                            </div>
                        )}
                        {fetchResult.ticketWithCampaigns && (
                            <div className={c.tickets}>
                                <h2 className={c.h2}>{'参加済みのキャンペーン'}</h2>
                                <div>
                                    {fetchResult.ticketWithCampaigns.map(({ ticket, campaign, program, media }) => (
                                        <table key={ticket.id} className={clsx(c.table, c.ticketTable)}>
                                            <tbody>
                                                <tr>
                                                    <th>{'チケットID'}</th>
                                                    <td>{ticket.id}</td>
                                                </tr>
                                                <tr>
                                                    <th>{'参加日時'}</th>
                                                    <td>{ticket.createdAt.toLocaleString()}</td>
                                                </tr>
                                                <tr>
                                                    <th>{'トラッキングコード'}</th>
                                                    <td>{ticket.trackingCode}</td>
                                                </tr>
                                                <tr>
                                                    <th>{'ユーザーエージェント'}</th>
                                                    <td>{ticket.userAgent}</td>
                                                </tr>
                                                <tr>
                                                    <th>{'リファラ'}</th>
                                                    <td>{ticket.data.referer}</td>
                                                </tr>
                                                <tr>
                                                    <th>{'広告主による認識'}</th>
                                                    <td>
                                                        {ticket.recognizedAt
                                                            ? `${ticket.recognizedAt.toLocaleString()}に認識`
                                                            : 'なし'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>{'承認状況'}</th>
                                                    <td>
                                                        {getApproveStatusText(getApproveStatus({ ticket, program }))}
                                                    </td>
                                                </tr>
                                                {ticket.transfer && (
                                                    <tr>
                                                        <th>{'振込状況'}</th>
                                                        <td>{ticket.transfer.result?.code || '処理中'}</td>
                                                    </tr>
                                                )}
                                                <tr>
                                                    <th>{'キャッシュバック金額'}</th>
                                                    <td>{`¥ ${campaign.data.cashbackAmount.toLocaleString()}`}</td>
                                                </tr>
                                                <tr>
                                                    <th>{'キャンペーンページ'}</th>
                                                    <td>
                                                        <a
                                                            href={`${wwwRootUrl}/campaigns/${campaign.uri}`}
                                                            target={'_blank'}
                                                            rel="noreferrer noopener"
                                                        >
                                                            {'開く'}
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>{'掲載メディア'}</th>
                                                    <td>
                                                        <a href={media.url} target={'_blank'} rel="noreferrer noopener">
                                                            {media.name}
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <LoadingIndicator />
            )}
        </>
    );
};
ConsoleTemplateStaffUserInfo.displayName = 'ConsoleTemplateStaffUserInfo';
