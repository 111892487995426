import { getGraphqlSdk } from '@/utils/MoneybackClient/graphql/graphqlSdk';
import { MoneybackGeneralClient } from '@/utils/MoneybackClient/clients';
import { getMediaFromGql } from '@/utils/MoneybackClient/models';

export const initMoneybackGeneralClient = async (input: { firebaseUserIdToken: string; mediaId: string }) => {
    const graphqlSdk = getGraphqlSdk(input);
    const { getMedia } = await graphqlSdk
        .getMedia({
            input: {
                mediaId: input.mediaId,
            },
        })
        .catch(({ message }) => {
            console.error('message->', message);
            throw new Error('initMoneybackGeneralClient: invalid firebaseUserIdToken');
        });
    const { media } = getMedia;
    if (media) {
        return new MoneybackGeneralClient({
            graphqlSdk,
            ...getMediaFromGql(media).data,
        });
    }
    throw new Error('initMoneybackGeneralClient: No user account');
};
