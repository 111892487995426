import { useEffect, useState, useCallback } from 'react';

import { useMediaCtxAbsolutely } from '@/utils/ctxs';
import { ProgramForMedia } from '@/utils/MoneybackClient';

export const usePrograms = () => {
    // HOOKS
    const { mediaClient } = useMediaCtxAbsolutely();
    const [programs, setPrograms] = useState<ProgramForMedia[] | undefined>(undefined);

    // CALLBACK
    const initialize = useCallback(async () => {
        const fetchedPrograms = await mediaClient.getPrograms();
        setPrograms(fetchedPrograms);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // USEEFFECT
    useEffect(() => {
        initialize();
    }, [initialize]);

    return {
        programs,
    };
};
