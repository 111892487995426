import { FC, useState } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { ProgramApiForAdmin } from '@/utils/MoneybackClient';
import { DetailsItem } from '@/components/0_atom/DetailsItem';
import { TextInputWithLabel } from '@/components/1_mol/InputWithLabel';
import { ProgramConditionText } from '@/components/1_mol/ProgramConditionText';
import { ConsoleTemplateAdminProgramsAdminProgramCard } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramCard';
import { ModifyDescriptionModalSubmitButton } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramModal/ModifyDescriptionModal/SubmitButton';

const useStyles = makeStyles((theme) => ({
    root: {},
    input: {
        marginTop: 40,
        marginBottom: 14,
    },
}));

export const ModifyDescriptionModalContent: FC<{
    programAPI: ProgramApiForAdmin;
    onClose: () => void;
}> = ({ programAPI, onClose }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const [newValue, setNewValue] = useState<string | undefined>(programAPI.description);

    // DATA
    const isOk = newValue && programAPI.description !== newValue;

    return (
        <>
            <ConsoleTemplateAdminProgramsAdminProgramCard
                programAPI={programAPI}
                enabledFields={{
                    advertiserProgramId: true,
                }}
            />
            <DetailsItem
                label={'これまでの説明'}
                content={programAPI.description.replace(/\[cashbackAmount\]/g, '<CB金額>')}
                variant={'full'}
                direction={'column'}
            />
            <TextInputWithLabel
                className={c.input}
                value={newValue}
                setValue={setNewValue}
                messages={undefined}
                isSatisfied={true}
                labelText={'新しい説明'}
                placeholder={'説明'}
                multiline
            />
            <ModifyDescriptionModalSubmitButton
                programAPI={programAPI}
                newValue={newValue || ''}
                isDisabled={!isOk}
                onComplete={onClose}
            />
        </>
    );
};
ModifyDescriptionModalContent.displayName = 'ModifyDescriptionModalContent';
