import { FC, ReactNode } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import SvgCheck from '@/svgs/check-brand-circle';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            width: 32,
            height: 32,
            display: 'block',
            marginRight: 14,
        },
        '& span': {},
    },
}));

export const CustomH2: FC<Partial<{ children: ReactNode[] }>> = ({ children }) => {
    // STYLE
    const c = useStyles(useTheme());
    return (
        <h2 className={c.root}>
            <SvgCheck />
            <span>{children}</span>
        </h2>
    );
};
CustomH2.displayName = 'CustomH2';
