export const getDate0000 = ({ date }: { date: Date }) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
};

export const getDate2359 = ({ date }: { date: Date }) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 0);
};

export const getPrevDate2359 = ({ date }: { date: Date }) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1, 23, 59, 59, 0);
};
