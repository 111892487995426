import { useEffect, useCallback } from 'react';

import { ProgramForMedia, Campaign, CampaignApiForGeneral } from '@/utils/MoneybackClient';
import { useMediaCtxAbsolutely } from '@/utils/ctxs';
import { useLazyItems } from '@/utils/customHooks';
import { getIsClientGeneralOrOwner } from '@/utils/utilFunctions';

export type CampaignItem = {
    id: string;
    campaign: Campaign | CampaignApiForGeneral;
    program: ProgramForMedia;
};

export const useCampaignItems = () => {
    // HOOKS
    const { mediaClient } = useMediaCtxAbsolutely();
    const { items, setLazyItems } = useLazyItems<CampaignItem>(undefined);

    // CALLBACK
    const initialize = useCallback(async () => {
        const fetchedItems = await (async () => {
            if (getIsClientGeneralOrOwner(mediaClient)) {
                return mediaClient.getCampaignAPIs();
            }
            return mediaClient.getCampaigns();
        })();
        const sortedItems = fetchedItems.sort((a) => {
            // 受付終了は後ろ
            return a.campaign.endReceptionAt ? 1 : -1;
        });
        setLazyItems(
            sortedItems.map((item) => ({
                id: item.campaign.id,
                ...item,
            }))
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // USEEFFECT
    useEffect(() => {
        initialize();
    }, [initialize]);

    return {
        campaignIds: items?.ids,
        currentCampaignItem: items?.currentItem,
        getCampaignItem: items?.getItem,
        getCampaignItemAbsolutely: items?.getItemAbsolutely,
        setSelectedCampaignItem: items?.setSelectedItem,
        updateCampaignItem: items?.updateItem,
        removeCampaignItem: items?.removeItem,
    };
};
