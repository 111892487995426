import { FC } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { getListedTextFromPlain } from '@/utils/utilFunctions';
import MarkdownViewer from '@/components/0_atom/MarkdownViewer';

const useStyles = makeStyles((theme) => ({
    root: {
        '& p': {
            margin: 0,
            '&:not(:last-child)': {
                marginBottom: 14,
            },
        },
        '& ul': {
            margin: 0,
            padding: '0 0 0 14px',
            '&:not(:last-child)': {
                marginBottom: 14,
            },
            '& li': {
                listStyleType: 'none',
                position: 'relative',
                '&::before': {
                    content: "'\u30fb'",
                    display: 'block',
                    position: 'absolute',
                    left: -14,
                },
                '&:not(:last-child)': {
                    marginBottom: 14,
                },
            },
        },
    },
    // lightColor: {
    //     color: theme.palette.text.disabled,
    // },
    a: {
        color: '#0a7',
        textDecoration: 'underline',
    },
}));

export const ProgramDescriptionForMedia: FC<{
    className?: string;
    descriptionForMedia: string | undefined;
}> = ({ className, descriptionForMedia }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div
            className={clsx(
                c.root,
                // {
                //     [c.lightColor]: !descriptionForMedia,
                // },
                className
            )}
        >
            {descriptionForMedia && <MarkdownViewer textMd={getListedTextFromPlain(descriptionForMedia)} />}
            <ul>
                <li>
                    {
                        'ポイントサイト一般の注意事項（ブラウザの設定、最後まで同じブラウザで操作すること）をユーザー向けに案内いただけると、計測漏れ対策に効果的です。（'
                    }
                    <a
                        href={'https://xn--sfc--886fp990a.com/pointsite-configuration/'}
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                        className={c.a}
                    >
                        {'参考ページ'}
                    </a>
                    {'）'}
                </li>
            </ul>
        </div>
    );
};
ProgramDescriptionForMedia.displayName = 'ProgramDescriptionForMedia';
