import { FC, useEffect } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { useErrorCtx, useMediaCtxAbsolutely } from '@/utils/ctxs';
import { useCampaignItems, useRouting } from '@/utils/customHooks';
import { LoadingIndicator } from '@/components/0_atom/LoadingIndicator';
import { CampaignCard } from '@/components/2_org/CampaignCard';
import { CampaignModal } from '@/components/2_org/CampaignModal';

const useStyles = makeStyles((theme) => ({
    root: {},
    empty: {
        fontWeight: 600,
        color: theme.palette.text.disabled,
    },
    card: {
        '&:not(:last-child)': {
            marginBottom: 14,
        },
    },
}));

export const ConsoleTemplateCampaigns: FC = () => {
    // STYLE

    const c = useStyles(useTheme());

    // HOOKS
    const { mediaClient } = useMediaCtxAbsolutely();
    const { setIs404 } = useErrorCtx();
    const { campaignIds, setSelectedCampaignItem, currentCampaignItem, getCampaignItemAbsolutely, updateCampaignItem } =
        useCampaignItems();
    const { setViewSlug1, viewSlug1 } = useRouting();

    // USEEFFECT
    useEffect(() => {
        if (campaignIds && viewSlug1) {
            const target = campaignIds.find((id) => id === viewSlug1);
            if (target && setSelectedCampaignItem) {
                setSelectedCampaignItem(target);
            } else {
                setIs404(true);
            }
        } else if (campaignIds && !viewSlug1 && setSelectedCampaignItem) {
            setSelectedCampaignItem(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewSlug1, campaignIds, setSelectedCampaignItem]);

    return (
        <>
            {campaignIds && getCampaignItemAbsolutely ? (
                campaignIds.length > 0 ? (
                    campaignIds.map((campaignId) => {
                        const campaignItem = getCampaignItemAbsolutely(campaignId);
                        return (
                            <CampaignCard
                                className={c.card}
                                key={campaignId}
                                program={campaignItem.program}
                                campaign={campaignItem.campaign}
                                onClick={() => setViewSlug1({ slug1: campaignId })}
                            />
                        );
                    })
                ) : (
                    <p className={c.empty}>{`現在${mediaClient.name}が実施中のキャンペーンはありません。`}</p>
                )
            ) : (
                <LoadingIndicator />
            )}
            <CampaignModal
                program={currentCampaignItem?.program}
                campaign={currentCampaignItem?.campaign}
                onClose={() => setViewSlug1({ slug1: undefined })}
                updateCampaignAPI={
                    currentCampaignItem &&
                    updateCampaignItem &&
                    ((newCampaign) =>
                        updateCampaignItem({
                            ...currentCampaignItem,
                            campaign: newCampaign,
                        }))
                }
            />
        </>
    );
};
ConsoleTemplateCampaigns.displayName = 'ConsoleTemplateCampaigns';
