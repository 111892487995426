import { FC } from 'react';

import { useAdminCtx } from '@/utils/ctxs';
import AdminProvider from '@/providers/AdminProvider';
import { LoadingIndicator } from '@/components/0_atom/LoadingIndicator';
import { ConsoleTemplateAdminPrograms } from '@/components/3_template/ConsoleTemplate/Admin/Programs';
import { ConsoleTemplateAdminReports } from '@/components/3_template/ConsoleTemplate/Admin/Reports';
import { ConsoleTemplateAdminCron } from '@/components/3_template/ConsoleTemplate/Admin/Cron';

type AdminViewName = 'programs' | 'reports' | 'cron';

const ConsoleTemplateAdminInner: FC<{
    viewName: AdminViewName;
}> = ({ viewName }) => {
    // HOOKS
    const { adminClient } = useAdminCtx();

    // DATA
    const children = (() => {
        if (!adminClient) {
            return <LoadingIndicator />;
        }
        if (viewName === 'programs') {
            return <ConsoleTemplateAdminPrograms />;
        }
        if (viewName === 'reports') {
            return <ConsoleTemplateAdminReports />;
        }
        if (viewName === 'cron') {
            return <ConsoleTemplateAdminCron />;
        }
        return <></>;
    })();

    return <AdminProvider>{children}</AdminProvider>;
};
ConsoleTemplateAdminInner.displayName = 'ConsoleTemplateAdminInner';

export const ConsoleTemplateAdmin: FC<{
    viewName: AdminViewName;
}> = ({ viewName }) => {
    return (
        <AdminProvider>
            <ConsoleTemplateAdminInner viewName={viewName} />
        </AdminProvider>
    );
};
ConsoleTemplateAdmin.displayName = 'ConsoleTemplateAdmin';
