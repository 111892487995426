import { GqlDateTimeData } from '@/utils/MoneybackClient/graphql/customScalars';
import {
    MediaMember as GqlMediaMember,
    MediaMemberRole,
} from '@/utils/MoneybackClient/graphql/generated/graphqlClient';

export type MediaMemberType = {
    id: string;
    createdAt: Date;
    updatedAt?: Date;
    role: MediaMemberRole;
};

export class MediaMember implements MediaMemberType {
    protected _id: string;

    protected _createdAt: Date;

    protected _updatedAt?: Date;

    protected _role: MediaMemberRole;

    get id() {
        return this._id;
    }

    get createdAt() {
        return this._createdAt;
    }

    get updatedAt() {
        return this._updatedAt;
    }

    get role() {
        return this._role;
    }

    get data() {
        return {
            id: this.id,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            role: this.role,
        };
    }

    constructor({ id, createdAt, updatedAt, role }: MediaMemberType) {
        this._id = id;
        this._createdAt = createdAt;
        this._updatedAt = updatedAt;
        this._role = role;
    }
}

export const getMediaMemberFromGql = ({ id, createdAt, updatedAt, role }: GqlMediaMember) => {
    return new MediaMember({
        id,
        createdAt: GqlDateTimeData.serialize(createdAt),
        updatedAt: updatedAt ? GqlDateTimeData.serialize(updatedAt) : undefined,
        role,
    });
};
