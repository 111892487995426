import { GqlDateTimeData } from '@/utils/MoneybackClient/graphql/customScalars';
import {
    TicketSettlementForMedia as GqlTicketSettlementForMedia,
    TicketJudgement,
} from '@/utils/MoneybackClient/graphql/generated/graphqlClient';
import { Price, getPriceFromGql } from '@/utils/MoneybackClient/models/Price';

export type TicketSettlementForMediaType = {
    at: Date;
    mediaReward: Price;
    judgement: TicketJudgement;
};

export class TicketSettlementForMedia implements TicketSettlementForMediaType {
    protected _at: Date;

    protected _mediaReward: Price;

    protected _judgement: TicketJudgement;

    get at() {
        return this._at;
    }

    get mediaReward() {
        return this._mediaReward;
    }

    get judgement() {
        return this._judgement;
    }

    get data() {
        return {
            at: this.at,
            mediaReward: this.mediaReward,
            judgement: this._judgement,
        };
    }

    constructor({ at, mediaReward, judgement }: TicketSettlementForMediaType) {
        this._at = at;
        this._mediaReward = mediaReward;
        this._judgement = judgement;
    }
}

export const getTicketSettlementForMediaFromGql = ({ at, mediaReward, judgement }: GqlTicketSettlementForMedia) => {
    return new TicketSettlementForMedia({
        at: GqlDateTimeData.serialize(at),
        mediaReward: getPriceFromGql(mediaReward),
        judgement,
    });
};
