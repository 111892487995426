import { GqlDateTimeData } from '@/utils/MoneybackClient/graphql/customScalars';
import {
    MediaBalance as GqlMediaBalance,
    MediaBalanceEventType,
} from '@/utils/MoneybackClient/graphql/generated/graphqlClient';
import {
    TicketWithCampaignForMedia,
    getTicketWithCampaignForMediaFromGql,
} from '@/utils/MoneybackClient/models/Ticket';
import { Transfer, getTransferFromGql } from '@/utils/MoneybackClient/models/Transfer';

export type MediaBalanceType = {
    id: string;
    createdAt: Date;
    serialNumber: number;
    eventType: MediaBalanceEventType;
    currentAmount: number;
    currentReserve: number;
    amountIncrease: number;
    reserveIncrease: number;
    ticket?: TicketWithCampaignForMedia;
    transfer?: Transfer;
};

export class MediaBalance implements MediaBalanceType {
    protected _id: string;

    protected _createdAt: Date;

    protected _serialNumber: number;

    protected _eventType: MediaBalanceEventType;

    protected _currentAmount: number;

    protected _currentReserve: number;

    protected _amountIncrease: number;

    protected _reserveIncrease: number;

    protected _ticket?: TicketWithCampaignForMedia;

    protected _transfer?: Transfer;

    get id() {
        return this._id;
    }

    get createdAt() {
        return this._createdAt;
    }

    get serialNumber() {
        return this._serialNumber;
    }

    get eventType() {
        return this._eventType;
    }

    get currentAmount() {
        return this._currentAmount;
    }

    get currentReserve() {
        return this._currentReserve;
    }

    get amountIncrease() {
        return this._amountIncrease;
    }

    get reserveIncrease() {
        return this._reserveIncrease;
    }

    get ticket() {
        return this._ticket;
    }

    get transfer() {
        return this._transfer;
    }

    get data() {
        return {
            id: this.id,
            createdAt: this.createdAt,
            serialNumber: this.serialNumber,
            eventType: this.eventType,
            currentAmount: this.currentAmount,
            currentReserve: this.currentReserve,
            amountIncrease: this.amountIncrease,
            reserveIncrease: this.reserveIncrease,
            ticket: this.ticket,
            transfer: this.transfer,
        };
    }

    constructor({
        id,
        createdAt,
        serialNumber,
        eventType,
        currentAmount,
        currentReserve,
        amountIncrease,
        reserveIncrease,
        ticket,
        transfer,
    }: MediaBalanceType) {
        this._id = id;
        this._createdAt = createdAt;
        this._serialNumber = serialNumber;
        this._eventType = eventType;
        this._currentAmount = currentAmount;
        this._currentReserve = currentReserve;
        this._amountIncrease = amountIncrease;
        this._reserveIncrease = reserveIncrease;
        this._ticket = ticket;
        this._transfer = transfer;
    }
}

export const getMediaBalanceFromGql = async ({
    id,
    createdAt,
    serialNumber,
    eventType,
    currentAmount,
    currentReserve,
    amountIncrease,
    reserveIncrease,
    ticket,
    transfer,
}: GqlMediaBalance) => {
    return new MediaBalance({
        id,
        createdAt: GqlDateTimeData.serialize(createdAt),
        serialNumber,
        eventType,
        currentAmount,
        currentReserve,
        amountIncrease,
        reserveIncrease,
        ticket: ticket ? await getTicketWithCampaignForMediaFromGql(ticket) : undefined,
        transfer: transfer ? getTransferFromGql(transfer) : undefined,
    });
};
