import { FC } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MarkdownViewer from '@/components/0_atom/MarkdownViewer';
import { CustomH2 } from '@/components/1_mol/ProgramOverview/CustomH2';

const useStyles = makeStyles((theme) => ({
    root: {
        '& h2': {
            fontSize: theme.typography.body1.fontSize,
            margin: 0,
            marginBottom: 24,
            '&:not(:first-child)': {
                marginTop: 32,
            },
        },
        '& p': {
            margin: 0,
            '&:not(:last-child)': {
                marginBottom: 14,
            },
        },
        '& ul': {
            margin: 0,
            padding: '0 0 0 14px',
            '&:not(:last-child)': {
                marginBottom: 14,
            },
            '& li': {
                listStyleType: 'none',
                position: 'relative',
                '&::before': {
                    content: "'\u30fb'",
                    display: 'block',
                    position: 'absolute',
                    left: -14,
                },
                '&:not(:last-child)': {
                    marginBottom: 14,
                },
            },
        },
    },
    lightColor: {
        color: theme.palette.text.disabled,
    },
    a: {
        color: '#0a7',
        textDecoration: 'underline',
    },
}));

export const ProgramOverview: FC<{
    className?: string;
    overviewMd: string | undefined;
}> = ({ className, overviewMd }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div
            className={clsx(
                c.root,
                {
                    [c.lightColor]: !overviewMd,
                },
                className
            )}
        >
            {overviewMd ? (
                <MarkdownViewer
                    textMd={overviewMd}
                    options={{
                        remarkReactComponents: {
                            h2: CustomH2,
                        },
                    }}
                />
            ) : (
                '特になし'
            )}
        </div>
    );
};
ProgramOverview.displayName = 'ProgramOverview';
