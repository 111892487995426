import { FC, useState } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import Button from '@/components/0_atom/Button';
import FirebaseAuth from '@/components/0_atom/FirebaseAuth';
import { TosForMediaModal } from '@/components/2_org/TosForMediaModal';
import { ImportantNotice } from '@/components/2_org/ImportantNotice';

const useStyles = makeStyles((theme) => ({
    root: {},
    heading: {
        fontWeight: 600,
        textAlign: 'center',
    },
    auth: {
        display: 'flex',
        justifyContent: 'center',
    },
    description: {
        marginTop: 24,
        color: theme.palette.text.disabled,
        fontSize: theme.typography.body2.fontSize,
    },
    tosButtonBox: {
        textAlign: 'center',
        marginTop: 14,
    },
    tosButton: {
        textDecoration: 'underline',
    },
}));

export const PreConsoleTemplateSignin: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const [isTosOpen, setIsTosOpen] = useState(false);

    return (
        <div className={c.root}>
            <ImportantNotice
                title={'ログイン時の注意点'}
                description={
                    'ブラウザのポップアップブロック機能が有効になっている際に、ログインできない場合があります。ログインがうまくいかない場合はブラウザの設定をご確認ください。'
                }
            />
            <div className={c.auth}>
                <FirebaseAuth />
            </div>
            <MuiTypography className={c.description}>
                {
                    '本サービスを利用するには、利用規約への同意が必要です。上のボタンをクリックしてログインすることで、以下の利用規約に同意したものとみなします。'
                }
            </MuiTypography>
            <div className={c.tosButtonBox}>
                <Button onClick={() => setIsTosOpen(true)} className={c.tosButton}>
                    {'利用規約'}
                </Button>
            </div>
            <TosForMediaModal isOpen={isTosOpen} setIsOpen={setIsTosOpen} />
        </div>
    );
};
PreConsoleTemplateSignin.displayName = 'PreConsoleTemplateSignin';
