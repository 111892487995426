import { User as GqlServiceUser } from '@/utils/MoneybackClient/graphql/generated/graphqlClient';
import { GqlDateTimeData } from '@/utils/MoneybackClient/graphql/customScalars';
import { BankAccount, getBankAccountFromGql } from '@/utils/MoneybackClient/models/BankAccount';

export type ServiceUserType = {
    id: string;
    createdAt: Date;
    updatedAt: Date | undefined;
    phone: string;
    bankAccount: BankAccount | undefined;
    removedAt: Date | undefined;
};

export class ServiceUser implements ServiceUserType {
    readonly id: string;

    readonly createdAt: Date;

    readonly updatedAt: Date | undefined;

    readonly phone: string;

    readonly bankAccount: BankAccount | undefined;

    readonly removedAt: Date | undefined;

    constructor({ id, createdAt, updatedAt, phone, bankAccount, removedAt }: ServiceUserType) {
        this.id = id;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.phone = phone;
        this.bankAccount = bankAccount;
        this.removedAt = removedAt;
    }
}

export const getServiceUserFromGql = ({ id, createdAt, updatedAt, phone, bankAccount, removedAt }: GqlServiceUser) => {
    return new ServiceUser({
        id,
        createdAt: GqlDateTimeData.serialize(createdAt),
        updatedAt: updatedAt ? GqlDateTimeData.serialize(updatedAt) : undefined,
        phone,
        bankAccount: bankAccount ? getBankAccountFromGql(bankAccount) : undefined,
        removedAt: removedAt ? GqlDateTimeData.serialize(removedAt) : undefined,
    });
};
