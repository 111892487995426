import { MutationValidationResult } from '@/utils/MoneybackClient/graphql/generated/graphqlClient';
import { GraphQLSdk } from '@/utils/MoneybackClient/graphql/graphqlSdk';
import { GqlDateTimeData } from '@/utils/MoneybackClient/graphql/customScalars';
import { getProgramConditionFromGql, getProgramClosureFromGql } from '@/utils/MoneybackClient/models/Program/data/Edge';
import { ProgramForAdmin, ProgramForAdminType } from '@/utils/MoneybackClient/models/Program/data/ProgramForAdmin';
import { getDate0000 } from '@/utils/utilFunctions/getDate';
import {
    getProgramAdvertiserIconSrc,
    getProgramEyecatchSrc,
    removeProgramAdvertiserIcon,
    removeProgramEyecatch,
    getExtention,
} from '@/utils/GoogleCloud/storage';
import { getEnvVariables } from '@/utils/envVariables';

type ProgramApiForAdminType = {
    modifyDescription: (input: { newValue: string }) => Promise<{
        result: MutationValidationResult;
    }>;
    modifyDescriptionForMedia: (input: { newValue: string | undefined }) => Promise<{
        result: MutationValidationResult;
    }>;
    modifyOverviewMd: (input: { newValue: string | undefined }) => Promise<{
        result: MutationValidationResult;
    }>;
    modifyWhitelistedMediaIds: (input: { newValue: string[] | undefined }) => Promise<{
        result: MutationValidationResult;
    }>;
    modifyIsPublicVisible: (input: { newValue: boolean }) => Promise<{
        result: MutationValidationResult;
    }>;
    modifyEndReceptionAt: (input: { newValue: Date | undefined }) => Promise<{
        result: MutationValidationResult;
    }>;
    modifyCloseAt: (input: { newValue: Date | undefined }) => Promise<{
        result: MutationValidationResult;
    }>;
    modifyConditionToRecognize: (input: { newValue: string }) => Promise<{
        result: MutationValidationResult;
    }>;
    modifyConditionToComplete: (input: { newValue: string | undefined }) => Promise<{
        result: MutationValidationResult;
    }>;
    modifyConditionPrecaution: (input: { newValue: string | undefined }) => Promise<{
        result: MutationValidationResult;
    }>;
    modifyConditionCompleteLimitDays: (input: { newValue: number | undefined }) => Promise<{
        result: MutationValidationResult;
    }>;
    close: (input: { reason: string }) => Promise<{
        result: MutationValidationResult;
    }>;
    updateAdvertiserIcon: (input: { newFile: File | undefined }) => Promise<{
        result: 'success';
    }>;
    updateEyecatch: (input: { newFile: File | undefined }) => Promise<{
        result: 'success';
    }>;
};

type ConstructorInput = ProgramForAdminType & {
    graphqlSdk: GraphQLSdk;
};

export class ProgramApiForAdmin extends ProgramForAdmin implements ProgramApiForAdminType {
    protected _graphqlSdk: GraphQLSdk;

    constructor(input: ConstructorInput) {
        super(input);
        this._graphqlSdk = input.graphqlSdk;
    }

    async modifyDescription({ newValue }: { newValue: string }) {
        const { modifyProgramDescription } = await this._graphqlSdk.modifyProgramDescription({
            input: {
                programId: this.id,
                newValue,
                oldValue: this.description,
                requiredInterval: 5,
            },
        });
        const { result, program } = modifyProgramDescription;
        this._description = program.description;
        return {
            result,
        };
    }

    async modifyDescriptionForMedia({ newValue }: { newValue: string | undefined }) {
        const { modifyProgramDescriptionForMedia } = await this._graphqlSdk.modifyProgramDescriptionForMedia({
            input: {
                programId: this.id,
                newValue,
                oldValue: this.descriptionForMedia,
                requiredInterval: 5,
            },
        });
        const { result, program } = modifyProgramDescriptionForMedia;
        this._descriptionForMedia = program.descriptionForMedia || undefined;
        return {
            result,
        };
    }

    async modifyOverviewMd({ newValue }: { newValue: string | undefined }) {
        const { modifyProgramOverviewMd } = await this._graphqlSdk.modifyProgramOverviewMd({
            input: {
                programId: this.id,
                newValue,
                oldValue: this.overviewMd,
                requiredInterval: 5,
            },
        });
        const { result, program } = modifyProgramOverviewMd;
        this._overviewMd = program.overviewMd || undefined;
        return {
            result,
        };
    }

    async modifyWhitelistedMediaIds({ newValue }: { newValue: string[] | undefined }) {
        const { modifyProgramWhitelistedMediaIds } = await this._graphqlSdk.modifyProgramWhitelistedMediaIds({
            input: {
                programId: this.id,
                newValue,
                oldValue: this.whitelistedMediaIds,
                requiredInterval: 5,
            },
        });
        const { result, program } = modifyProgramWhitelistedMediaIds;
        this._whitelistedMediaIds = program.whitelistedMediaIds || undefined;
        return {
            result,
        };
    }

    async modifyIsPublicVisible({ newValue }: { newValue: boolean }) {
        const { modifyProgramIsPublicVisible } = await this._graphqlSdk.modifyProgramIsPublicVisible({
            input: {
                programId: this.id,
                newValue,
                oldValue: this.isPublicVisible,
                requiredInterval: 5,
            },
        });
        const { result, program } = modifyProgramIsPublicVisible;
        this._isPublicVisible = program.isPublicVisible;
        return {
            result,
        };
    }

    async modifyEndReceptionAt({ newValue }: { newValue: Date | undefined }) {
        const { modifyProgramEndReceptionAt } = await this._graphqlSdk.modifyProgramEndReceptionAt({
            input: {
                programId: this.id,
                newValue: newValue ? GqlDateTimeData.parseValue(getDate0000({ date: newValue })) : undefined,
                oldValue: this.endReceptionAt ? GqlDateTimeData.parseValue(this.endReceptionAt) : undefined,
                requiredInterval: 5,
            },
        });
        const { result, program } = modifyProgramEndReceptionAt;
        this._endReceptionAt = program.endReceptionAt ? GqlDateTimeData.serialize(program.endReceptionAt) : undefined;
        return {
            result,
        };
    }

    async modifyCloseAt({ newValue }: { newValue: Date | undefined }) {
        const { modifyProgramCloseAt } = await this._graphqlSdk.modifyProgramCloseAt({
            input: {
                programId: this.id,
                newValue: newValue ? GqlDateTimeData.parseValue(getDate0000({ date: newValue })) : undefined,
                oldValue: this.closeAt ? GqlDateTimeData.parseValue(this.closeAt) : undefined,
                requiredInterval: 5,
            },
        });
        const { result, program } = modifyProgramCloseAt;
        this._closeAt = program.closeAt ? GqlDateTimeData.serialize(program.closeAt) : undefined;
        return {
            result,
        };
    }

    async modifyConditionToRecognize({ newValue }: { newValue: string }) {
        const { modifyProgramConditionToRecognize } = await this._graphqlSdk.modifyProgramConditionToRecognize({
            input: {
                programId: this.id,
                newValue,
                oldValue: this.condition.toRecognize,
                requiredInterval: 5,
            },
        });
        const { result, program } = modifyProgramConditionToRecognize;
        this._condition = getProgramConditionFromGql(program.condition);
        return {
            result,
        };
    }

    async modifyConditionToComplete({ newValue }: { newValue: string | undefined }) {
        const { modifyProgramConditionToComplete } = await this._graphqlSdk.modifyProgramConditionToComplete({
            input: {
                programId: this.id,
                newValue,
                oldValue: this.condition.toComplete,
                requiredInterval: 5,
            },
        });
        const { result, program } = modifyProgramConditionToComplete;
        this._condition = getProgramConditionFromGql(program.condition);
        return {
            result,
        };
    }

    async modifyConditionPrecaution({ newValue }: { newValue: string | undefined }) {
        const { modifyProgramConditionPrecaution } = await this._graphqlSdk.modifyProgramConditionPrecaution({
            input: {
                programId: this.id,
                newValue,
                oldValue: this.condition.precaution,
                requiredInterval: 5,
            },
        });
        const { result, program } = modifyProgramConditionPrecaution;
        this._condition = getProgramConditionFromGql(program.condition);
        return {
            result,
        };
    }

    async modifyConditionCompleteLimitDays({ newValue }: { newValue: number | undefined }) {
        const { modifyProgramConditionCompleteLimitDays } =
            await this._graphqlSdk.modifyProgramConditionCompleteLimitDays({
                input: {
                    programId: this.id,
                    newValue,
                    oldValue: this.condition.completeLimitDays,
                    requiredInterval: 5,
                },
            });
        const { result, program } = modifyProgramConditionCompleteLimitDays;
        this._condition = getProgramConditionFromGql(program.condition);
        return {
            result,
        };
    }

    async close({ reason }: { reason: string }) {
        const { closeProgram } = await this._graphqlSdk.closeProgram({
            input: {
                programId: this.id,
                reason,
            },
        });
        const { result, program } = closeProgram;
        this._closure = program.closure ? getProgramClosureFromGql(program.closure) : undefined;
        return {
            result,
        };
    }

    async updateAdvertiserIcon({ newFile }: { newFile: File | undefined }) {
        if (!newFile) {
            removeProgramAdvertiserIcon({ programId: this.id });
            this._advertiserIconSrc = undefined;
            return {
                result: 'success' as const,
            };
        }
        const extentionAndContentType = getExtention(newFile);
        if (!extentionAndContentType) {
            throw new Error('ProgramApiForAdmin.updateAdvertiserIcon: invalid file extention');
        }
        const [extensionName, contentType] = extentionAndContentType;
        const { getSignedUrlToUploadProgramAdvertiserIcon } =
            await this._graphqlSdk.getSignedUrlToUploadProgramAdvertiserIcon({
                input: {
                    programId: this.id,
                    extensionName,
                },
            });
        const { signedUrl } = getSignedUrlToUploadProgramAdvertiserIcon;
        await fetch(signedUrl, {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': contentType,
                origin: getEnvVariables().rootUrl,
            }),
            body: newFile,
        }).catch(() => {
            throw new Error('ProgramApiForAdmin.updateAdvertiserIcon: upload failed');
        });
        const newSrc = await getProgramAdvertiserIconSrc({ programId: this.id });
        this._advertiserIconSrc = newSrc;
        return {
            result: 'success' as const,
        };
    }

    async updateEyecatch({ newFile }: { newFile: File | undefined }) {
        if (!newFile) {
            removeProgramEyecatch({ programId: this.id });
            this._advertiserIconSrc = undefined;
            return {
                result: 'success' as const,
            };
        }
        const extentionAndContentType = getExtention(newFile);
        if (!extentionAndContentType) {
            throw new Error('ProgramApiForAdmin.updateEyecatch: invalid file extention');
        }
        const [extensionName, contentType] = extentionAndContentType;
        const { getSignedUrlToUploadProgramEyecatch } = await this._graphqlSdk.getSignedUrlToUploadProgramEyecatch({
            input: {
                programId: this.id,
                extensionName,
            },
        });
        const { signedUrl } = getSignedUrlToUploadProgramEyecatch;
        await fetch(signedUrl, {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': contentType,
                origin: getEnvVariables().rootUrl,
            }),
            body: newFile,
        }).catch(() => {
            throw new Error('ProgramApiForAdmin.updateEyecatch: upload failed');
        });
        const newSrc = await getProgramEyecatchSrc({ programId: this.id });
        this._eyecatchSrc = newSrc;
        return {
            result: 'success' as const,
        };
    }
}
