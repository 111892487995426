import { GqlDateTimeData } from '@/utils/MoneybackClient/graphql/customScalars';
import { TicketForMedia as GqlTicketForMedia } from '@/utils/MoneybackClient/graphql/generated/graphqlClient';
import { Transfer, getTransferFromGql } from '@/utils/MoneybackClient/models/Transfer';
import {
    TicketSettlementForMedia,
    getTicketSettlementForMediaFromGql,
} from '@/utils/MoneybackClient/models/Ticket/data/Edge';

export type TicketForMediaType = {
    id: string;
    createdAt: Date;
    updatedAt?: Date;
    trackingCode: string;
    recognizedAt?: Date;
    settlement?: TicketSettlementForMedia;
    transfer?: Transfer;
    userAgent?: string;
    referer?: string;
};

export class TicketForMedia implements TicketForMediaType {
    protected _id: string;

    protected _createdAt: Date;

    protected _updatedAt?: Date;

    protected _trackingCode: string;

    protected _recognizedAt?: Date;

    protected _settlement?: TicketSettlementForMedia;

    protected _transfer?: Transfer;

    protected _userAgent?: string;

    protected _referer?: string;

    get id() {
        return this._id;
    }

    get createdAt() {
        return this._createdAt;
    }

    get updatedAt() {
        return this._updatedAt;
    }

    get trackingCode() {
        return this._trackingCode;
    }

    get recognizedAt() {
        return this._recognizedAt;
    }

    get settlement() {
        return this._settlement;
    }

    get transfer() {
        return this._transfer;
    }

    get userAgent() {
        return this._userAgent;
    }

    get referer() {
        return this._referer;
    }

    get data() {
        return {
            id: this.id,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            trackingCode: this._trackingCode,
            recognizedAt: this._recognizedAt,
            settlement: this._settlement,
            transfer: this._transfer,
            userAgent: this._userAgent,
            referer: this._referer,
        };
    }

    constructor({
        id,
        createdAt,
        updatedAt,
        trackingCode,
        recognizedAt,
        settlement,
        transfer,
        userAgent,
        referer,
    }: TicketForMediaType) {
        this._id = id;
        this._createdAt = createdAt;
        this._updatedAt = updatedAt;
        this._trackingCode = trackingCode;
        this._recognizedAt = recognizedAt;
        this._settlement = settlement;
        this._transfer = transfer;
        this._userAgent = userAgent;
        this._referer = referer;
    }
}

export const getTicketForMediaFromGql = ({
    id,
    createdAt,
    updatedAt,
    trackingCode,
    recognizedAt,
    settlement,
    transfer,
    userAgent,
    referer,
}: GqlTicketForMedia) => {
    return new TicketForMedia({
        id,
        createdAt: GqlDateTimeData.serialize(createdAt),
        updatedAt: updatedAt ? GqlDateTimeData.serialize(updatedAt) : undefined,
        trackingCode,
        recognizedAt: recognizedAt ? GqlDateTimeData.serialize(recognizedAt) : undefined,
        settlement: settlement ? getTicketSettlementForMediaFromGql(settlement) : undefined,
        transfer: transfer ? getTransferFromGql(transfer) : undefined,
        userAgent: userAgent || undefined,
        referer: referer || undefined,
    });
};
