import { GraphQLSdk } from '@/utils/MoneybackClient/graphql/graphqlSdk';
import { getMediaUserFromGql } from '@/utils/MoneybackClient/models';
import { MoneybackMediaUserClient } from '@/utils/MoneybackClient/clients/MediaUser';

type MoneybackFirebaseUserClientType = {
    signup: () => Promise<MoneybackMediaUserClient>;
};

type ConstructorInput = {
    graphqlSdk: GraphQLSdk;
};

export class MoneybackFirebaseUserClient implements MoneybackFirebaseUserClientType {
    protected _graphqlSdk: GraphQLSdk;

    constructor({ graphqlSdk }: ConstructorInput) {
        this._graphqlSdk = graphqlSdk;
    }

    async signup() {
        const { createMediaUser } = await this._graphqlSdk.createMediaUser();
        const { mediaUser } = createMediaUser;
        return new MoneybackMediaUserClient({
            graphqlSdk: this._graphqlSdk,
            ...getMediaUserFromGql(mediaUser).data,
        });
    }
}
