import { FC } from 'react';

import { useStaffCtx } from '@/utils/ctxs';
import AdminProvider from '@/providers/AdminProvider';
import { LoadingIndicator } from '@/components/0_atom/LoadingIndicator';
import { ConsoleTemplateStaffUserInfo } from '@/components/3_template/ConsoleTemplate/Staff/UserInfo';

type StaffViewName = 'userinfo';

const ConsoleTemplateStaffInner: FC<{
    viewName: StaffViewName;
}> = ({ viewName }) => {
    // HOOKS
    const { staffClient } = useStaffCtx();

    // DATA
    const children = (() => {
        if (!staffClient) {
            return <LoadingIndicator />;
        }
        if (viewName === 'userinfo') {
            return <ConsoleTemplateStaffUserInfo />;
        }
        return <></>;
    })();

    return <AdminProvider>{children}</AdminProvider>;
};
ConsoleTemplateStaffInner.displayName = 'ConsoleTemplateStaffInner';

export const ConsoleTemplateStaff: FC<{
    viewName: StaffViewName;
}> = ({ viewName }) => {
    return (
        <AdminProvider>
            <ConsoleTemplateStaffInner viewName={viewName} />
        </AdminProvider>
    );
};
ConsoleTemplateStaff.displayName = 'ConsoleTemplateStaff';
