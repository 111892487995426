import { FC } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { ProgramForPublic, ProgramForMedia, ProgramForAdmin } from '@/utils/MoneybackClient';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    icon: {
        borderRadius: '50%',
        width: 48,
        height: 48,
        display: 'block',
        objectFit: 'cover',
        border: 'solid 1px rgba(0,0,0,.05)',
        flexShrink: 0,
        marginRight: 8,
    },
    texts: {
        flexGrow: 1,
    },
    advertiserName: {
        borderBottom: 'solid 2px rgba(0,0,0,.03)',
        fontWeight: 600,
        fontSize: 18,
        paddingTop: 4,
        paddingBottom: 4,
    },
    title: {
        fontWeight: 600,
        fontSize: theme.typography.body1.fontSize,
        paddingTop: 4,
    },
}));

export const ProgramHeading: FC<{
    className?: string;
    program: ProgramForPublic | ProgramForMedia | ProgramForAdmin;
}> = ({ className, program }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div className={clsx(c.root, className)}>
            {program.advertiserIconSrc && (
                <img className={c.icon} src={program.advertiserIconSrc} alt={`${program.advertiserName}のアイコン`} />
            )}
            <div className={c.texts}>
                <div className={c.advertiserName}>{program.advertiserName}</div>
                <div className={c.title}>{program.title}</div>
            </div>
        </div>
    );
};
ProgramHeading.displayName = 'ProgramHeading';
