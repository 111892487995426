import { FC, useState } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { ProgramApiForAdmin } from '@/utils/MoneybackClient';
import { getStrWithBr } from '@/utils/utilFunctions';
import { DetailsItem } from '@/components/0_atom/DetailsItem';
import { TextInputWithLabel } from '@/components/1_mol/InputWithLabel';
import { ConsoleTemplateAdminProgramsAdminProgramCard } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramCard';
import { ModifyWhitelistedMediaIdsModalSubmitButton } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramModal/ModifyWhitelistedMediaIdsModal/SubmitButton';

const useStyles = makeStyles((theme) => ({
    root: {},
    input: {
        marginTop: 40,
        marginBottom: 14,
    },
}));

export const ModifyWhitelistedMediaIdsModalContent: FC<{
    programAPI: ProgramApiForAdmin;
    onClose: () => void;
}> = ({ programAPI, onClose }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const [newValue, setWhitelistedMediaIds] = useState<string[] | undefined>(programAPI.whitelistedMediaIds);

    // DATA
    // const isOk = programAPI.whitelistedMediaIds !== newValue;

    return (
        <>
            <ConsoleTemplateAdminProgramsAdminProgramCard
                programAPI={programAPI}
                enabledFields={{
                    advertiserProgramId: true,
                }}
            />
            <DetailsItem
                label={'これまでのメディアホワイトリスト'}
                content={getStrWithBr(
                    programAPI.whitelistedMediaIds?.reduce((acc, prev, i) => {
                        if (i === 0) {
                            return prev;
                        }
                        return `${acc}\n${prev}`;
                    }, '') || 'なし'
                )}
                variant={'full'}
                direction={'column'}
            />
            <TextInputWithLabel
                className={c.input}
                value={newValue?.reduce((acc, prev, i) => {
                    if (i === 0) {
                        return prev;
                    }
                    return `${acc}\n${prev}`;
                }, '')}
                setValue={(newValue) => {
                    if (newValue) {
                        setWhitelistedMediaIds(newValue.replace(/ */g, '').replace(/\n+/g, '\n').split('\n'));
                    } else {
                        setWhitelistedMediaIds(undefined);
                    }
                }}
                messages={undefined}
                isSatisfied={true}
                labelText={'新しいメディアホワイトリスト'}
                placeholder={'説明'}
                multiline
            />
            <ModifyWhitelistedMediaIdsModalSubmitButton
                programAPI={programAPI}
                newValue={newValue}
                // isDisabled={!isOk}
                onComplete={onClose}
            />
        </>
    );
};
ModifyWhitelistedMediaIdsModalContent.displayName = 'ModifyWhitelistedMediaIdsModalContent';
