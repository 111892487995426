import { FC, useState } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { ProgramApiForAdmin } from '@/utils/MoneybackClient';
import { DetailsItem } from '@/components/0_atom/DetailsItem';
import { TextInputWithLabel } from '@/components/1_mol/InputWithLabel';
import { ProgramConditionText } from '@/components/1_mol/ProgramConditionText';
import { ConsoleTemplateAdminProgramsAdminProgramCard } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramCard';
import { ModifyConditionToRecognizeModalSubmitButton } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramModal/ModifyConditionToRecognizeModal/SubmitButton';

const useStyles = makeStyles((theme) => ({
    root: {},
    input: {
        marginTop: 40,
        marginBottom: 14,
    },
}));

export const ModifyConditionToRecognizeModalContent: FC<{
    programAPI: ProgramApiForAdmin;
    onClose: () => void;
}> = ({ programAPI, onClose }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const [newValue, setNewValue] = useState<string | undefined>(programAPI.condition.toRecognize);

    // DATA
    const isOk = !!newValue && programAPI.condition.toRecognize !== newValue;

    return (
        <>
            <ConsoleTemplateAdminProgramsAdminProgramCard
                programAPI={programAPI}
                enabledFields={{
                    advertiserProgramId: true,
                }}
            />
            <DetailsItem
                label={'これまでのブラウザ条件(condition.toRecognize)'}
                content={
                    <ProgramConditionText
                        condition={programAPI.condition}
                        disabledFields={{
                            toComplete: true,
                            precaution: true,
                        }}
                    />
                }
                variant={'full'}
                direction={'column'}
            />
            <TextInputWithLabel
                className={c.input}
                value={newValue}
                setValue={setNewValue}
                messages={undefined}
                isSatisfied={!!newValue}
                labelText={'新しいブラウザ条件(condition.toRecognize)'}
                placeholder={'参加ボタンクリックからブラウザを開いたまま完了すべき条件'}
                multiline
            />
            <ModifyConditionToRecognizeModalSubmitButton
                programAPI={programAPI}
                newValue={newValue || ''}
                isDisabled={!isOk}
                onComplete={onClose}
            />
        </>
    );
};
ModifyConditionToRecognizeModalContent.displayName = 'ModifyConditionToRecognizeModalContent';
