import { FC, useState } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { HorizontalTable } from '@/components/0_atom/HorizontalTable';
import Button from '@/components/0_atom/Button';
import { TosForMediaModal } from '@/components/2_org/TosForMediaModal';

const useStyles = makeStyles((theme) => ({
    root: {
        background: '#fff',
        padding: '29px 19px',
        borderRadius: 4,
    },
    button: {
        border: 'solid 2px rgba(0,0,0,.05)',
        fontWeight: 600,
        padding: '6px 24px',
    },
}));

export const ConsoleTemplateResources: FC = () => {
    // STYLE

    const c = useStyles(useTheme());

    // HOOKS
    const [isTosOpen, setIsTosOpen] = useState(false);

    return (
        <>
            <div className={c.root}>
                <HorizontalTable
                    items={[
                        {
                            label: 'メディア利用規約',
                            children: (
                                <Button onClick={() => setIsTosOpen(true)} className={c.button}>
                                    {'開く'}
                                </Button>
                            ),
                        },
                    ]}
                />
            </div>
            <TosForMediaModal isOpen={isTosOpen} setIsOpen={setIsTosOpen} />
        </>
    );
};
ConsoleTemplateResources.displayName = 'ConsoleTemplateResources';
