/* eslint-disable import/no-extraneous-dependencies */
import dotenv from 'dotenv';

dotenv.config();

type UodkaEnv = 'development' | 'staging' | 'production';

const getUodkaEnv = (): UodkaEnv => {
    const str = process.env.REACT_APP_UODKA_ENV;
    if (str === 'production') {
        return 'production';
    }
    if (str === 'staging') {
        return 'staging';
    }
    if (str === 'development') {
        return 'development';
    }
    throw new Error(`getUodkaEnv: Unexpected process.env.UODKA_ENV got->${str}`);
};

type EnvVariables = {
    uodkaEnv: UodkaEnv;
    rootUrl: string;
    wwwRootUrl: string;
    api: {
        graphqlEndpointUrl: string;
    };
    firebase: {
        core: {
            projectId: string;
            storageBucket: string;
        };
        mediaAuth: {
            projectId: string;
            authDomain: string;
            apiKey: string;
        };
    };
    systemAdmin: {
        mediaId: string;
    };
};

export const getEnvVariables = (): EnvVariables => {
    return {
        uodkaEnv: getUodkaEnv(),
        rootUrl: process.env.REACT_APP_ROOT_URL || '',
        wwwRootUrl: process.env.REACT_APP_WWW_ROOT_URL || '',
        api: {
            graphqlEndpointUrl: process.env.REACT_APP_API_GRAPHQL_ENDPOINT_URL || '',
        },
        firebase: {
            core: {
                projectId: process.env.REACT_APP_FIREBASE_CORE_PROJECT_ID || '',
                storageBucket: process.env.REACT_APP_FIREBASE_CORE_STORAGE_BUCKET || '',
            },
            mediaAuth: {
                projectId: process.env.REACT_APP_FIREBASE_MEDIAAUTH_PROJECT_ID || '',
                authDomain: process.env.REACT_APP_FIREBASE_MEDIAAUTH_AUTH_DOMAIN || '',
                apiKey: process.env.REACT_APP_FIREBASE_MEDIAAUTH_API_KEY || '',
            },
        },
        systemAdmin: {
            mediaId: process.env.REACT_APP_SYSTEMADMIN_MEDIAID || '',
        },
    };
};
