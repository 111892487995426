import { FC, useState } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import MuiTabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';
import { ProgramForMedia, Campaign, CampaignApiForGeneral } from '@/utils/MoneybackClient';
import { Modal } from '@/components/0_atom/Modal';
import { LoadingIndicator } from '@/components/0_atom/LoadingIndicator';
import { CampaignModalContentDetails } from '@/components/2_org/CampaignModal/ContentDetails';
import { CampaignModalContentTickets } from '@/components/2_org/CampaignModal/ContentTickets';

const useStyles = makeStyles((theme) => ({
    root: {},
    heading: {
        fontSize: 18,
        margin: '0 0 60px 0',
        textAlign: 'center',
    },
    tabs: {
        '& .MuiTab-root': {
            fontSize: theme.typography.body1.fontSize,
            borderTop: 'solid 2px transparent',
            borderBottom: 'solid 2px rgba(0,0,0,.05)',
            fontWeight: 600,
            '&:not(.Mui-selected)': {
                color: 'rgba(0,0,0,.2)',
                opacity: 1,
            },
        },
    },
    tabIndicator: {
        background: 'linear-gradient(90deg, #4EE5DC, #F746FB)',
    },
    content: {
        paddingTop: 43,
    },
}));

export const CampaignModal: FC<{
    program: ProgramForMedia | undefined;
    campaign: Campaign | CampaignApiForGeneral | undefined;
    onClose: () => void;
    updateCampaignAPI: ((updatedCampaignApiForGeneral: CampaignApiForGeneral) => void) | undefined;
}> = ({ program, campaign, onClose, updateCampaignAPI }) => {
    // STYLE

    const c = useStyles(useTheme());

    // HOOKS
    const [index, setIndex] = useState<0 | 1>(0);

    return (
        <Modal open={!!program} onClose={onClose} anchor={'bottom'}>
            <MuiTypography variant={'h1'} className={c.heading}>
                {'キャンペーン'}
            </MuiTypography>
            <MuiTabs
                value={index}
                className={c.tabs}
                variant={'fullWidth'}
                TabIndicatorProps={{ className: c.tabIndicator }}
            >
                <MuiTab label={'詳細'} onClick={() => setIndex(0)} value={0} />
                <MuiTab label={'成果'} onClick={() => setIndex(1)} value={1} />
            </MuiTabs>
            <div className={clsx(c.content, 'org_campaignmodal')}>
                {program && campaign ? (
                    index === 0 ? (
                        <CampaignModalContentDetails
                            program={program}
                            campaign={campaign}
                            updateCampaignAPI={updateCampaignAPI}
                        />
                    ) : index === 1 ? (
                        <CampaignModalContentTickets program={program} campaign={campaign} />
                    ) : (
                        <></>
                    )
                ) : (
                    <LoadingIndicator />
                )}
            </div>
        </Modal>
    );
};
CampaignModal.displayName = 'CampaignModal';
