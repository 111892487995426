import { TicketWithCampaignForMedia as GqlTicketWithCampaignForMedia } from '@/utils/MoneybackClient/graphql/generated/graphqlClient';
import { TicketForMedia, getTicketForMediaFromGql } from '@/utils/MoneybackClient/models/Ticket/data/TicketForMedia';
import { Campaign, getCampaignFromGql } from '@/utils/MoneybackClient/models/Campaign';
import { ProgramForMedia, getProgramForMediaFromGql } from '@/utils/MoneybackClient/models/Program';

export type TicketWithCampaignForMediaType = {
    ticket: TicketForMedia;
    campaign: Campaign;
    program: ProgramForMedia;
};

export class TicketWithCampaignForMedia implements TicketWithCampaignForMediaType {
    protected _ticket: TicketForMedia;

    protected _campaign: Campaign;

    protected _program: ProgramForMedia;

    get ticket() {
        return this._ticket;
    }

    get campaign() {
        return this._campaign;
    }

    get program() {
        return this._program;
    }

    get data() {
        return {
            ticket: this.ticket,
            campaign: this.campaign,
            program: this.program,
        };
    }

    constructor({ ticket, campaign, program }: TicketWithCampaignForMediaType) {
        this._ticket = ticket;
        this._campaign = campaign;
        this._program = program;
    }
}

export const getTicketWithCampaignForMediaFromGql = async ({
    ticket,
    campaign,
    program,
}: GqlTicketWithCampaignForMedia) => {
    return new TicketWithCampaignForMedia({
        ticket: getTicketForMediaFromGql(ticket),
        campaign: getCampaignFromGql(campaign),
        program: await getProgramForMediaFromGql(program),
    });
};
