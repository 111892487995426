import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {},
    heading: {
        textAlign: 'center',
        fontWeight: 600,
    },
    description: {
        color: theme.palette.text.disabled,
        marginTop: 14,
    },
}));

export const PreConsoleTemplateNoUserDisplay: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div className={c.root}>
            <MuiTypography className={c.heading}>{'登録がありません'}</MuiTypography>
            <MuiTypography className={c.description}>
                {'moneybackは現在、ご招待をお送りしたメディア様以外はご利用いただけません。'}
            </MuiTypography>
        </div>
    );
};
PreConsoleTemplateNoUserDisplay.displayName = 'PreConsoleTemplateNoUserDisplay';
