import { FC, useState } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { ProgramApiForAdmin } from '@/utils/MoneybackClient';
import { DetailsItem } from '@/components/0_atom/DetailsItem';
import { IntInputWithLabel } from '@/components/1_mol/InputWithLabel';
import { ConsoleTemplateAdminProgramsAdminProgramCard } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramCard';
import { ModifyConditionCompleteLimitDaysModalSubmitButton } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramModal/ModifyConditionCompleteLimitDays/SubmitButton';

const useStyles = makeStyles((theme) => ({
    root: {},
    input: {
        marginTop: 40,
        marginBottom: 14,
    },
}));

export const ModifyConditionCompleteLimitDaysModalContent: FC<{
    programAPI: ProgramApiForAdmin;
    onClose: () => void;
}> = ({ programAPI, onClose }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const [newValue, setNewValue] = useState<number | undefined>(programAPI.condition.completeLimitDays);

    // DATA
    const isOk = programAPI.condition.completeLimitDays !== newValue;

    return (
        <>
            <ConsoleTemplateAdminProgramsAdminProgramCard
                programAPI={programAPI}
                enabledFields={{
                    advertiserProgramId: true,
                }}
            />
            <DetailsItem
                label={'これまでの達成期限'}
                content={
                    typeof programAPI.condition.completeLimitDays === 'number'
                        ? `${programAPI.condition.completeLimitDays}日以内`
                        : '記載なし'
                }
                variant={'full'}
                direction={'row'}
            />
            <IntInputWithLabel
                className={c.input}
                value={newValue}
                setValue={setNewValue}
                labelText={'新しい達成期限'}
                endAdornment={'日以内'}
                messages={[
                    {
                        message: '0日は指定できません',
                        isError: newValue === 0,
                    },
                ]}
            />
            <ModifyConditionCompleteLimitDaysModalSubmitButton
                programAPI={programAPI}
                newValue={newValue}
                isDisabled={!isOk}
                onComplete={onClose}
            />
        </>
    );
};
ModifyConditionCompleteLimitDaysModalContent.displayName = 'ModifyConditionCompleteLimitDaysModalContent';
