import { ProgramForMedia, ProgramForAdmin, ProgramApiForAdmin } from '@/utils/MoneybackClient';

export const getProgramEndReceptionDate = ({
    program,
}: {
    program: ProgramForMedia | ProgramForAdmin | ProgramApiForAdmin;
}) => {
    const { endReceptionAt: programEndReceptionAt, closeAt } = program;
    const dates = [programEndReceptionAt, closeAt].filter((date) => !!date) as Date[];
    if (dates.length > 0) {
        return dates.sort((a, b) => (a > b ? 1 : -1))[0];
    }
    return undefined;
};
