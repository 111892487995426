import { useEffect, useState, useCallback } from 'react';

import { useMediaCtxAbsolutely } from '@/utils/ctxs';
import { MediaBalance } from '@/utils/MoneybackClient';

export const useRecentBalance = () => {
    // HOOKS
    const { mediaClient } = useMediaCtxAbsolutely();
    const [balance, setBalance] = useState<MediaBalance | undefined | 'fetching'>('fetching');

    // CALLBACK
    const initialize = useCallback(async () => {
        const fetchedBalance = await mediaClient.getRecentBalance();
        setBalance(fetchedBalance);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // USEEFFECT
    useEffect(() => {
        initialize();
    }, [initialize]);

    return {
        recentBalance: balance,
    };
};
