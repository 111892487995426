import { useEffect, useCallback } from 'react';

import { ProgramApiForAdmin } from '@/utils/MoneybackClient';
import { useAdminCtx } from '@/utils/ctxs';
import { useLazyItems } from '@/utils/customHooks';

export const useAdminProgramApiItems = () => {
    // HOOKS
    const { adminClient } = useAdminCtx();
    const { items, setLazyItems } = useLazyItems<ProgramApiForAdmin>(undefined);

    // CALLBACK
    const initialize = useCallback(async () => {
        if (items) {
            setLazyItems(undefined);
        }
        if (adminClient) {
            const fetchedProgramAPIs = await adminClient.getProgramAPIs();
            setLazyItems(fetchedProgramAPIs);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adminClient]);

    // USEEFFECT
    useEffect(() => {
        initialize();
    }, [initialize]);

    return {
        items,
        reInitialize: initialize,
    };
};
