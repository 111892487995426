import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { LoadingIndicator } from '@/components/0_atom/LoadingIndicator';
import Button from '@/components/0_atom/Button';
import { TextInput } from '@/components/0_atom/Input';
import { DatePickerButton } from '@/components/0_atom/DatePickerButton';
import { headerRow } from '@/components/3_template/ConsoleTemplate/Admin/Reports/getTicketsArr';
import {
    useTicketsArr,
    getToday,
    getAMonthAgo,
} from '@/components/3_template/ConsoleTemplate/Admin/Reports/useTicketsArr';
import { parepareRecordsCsv } from '@/components/3_template/ConsoleTemplate/Admin/Reports/parepareRecordsCsv';
import { downloadTextFile, getDateStr } from '@/utils/utilFunctions';

const getJstDate = (date: Date) => {
    const jstTimezonOffsetMin = 9 * 60; // U:540 J:540
    const machineTimezonOffsetMin = date.getTimezoneOffset(); // U:0 || J:-540
    const jstDiffMin = machineTimezonOffsetMin + jstTimezonOffsetMin; // U:540 || J:0
    const now = date.getTime() + jstDiffMin * 60 * 1000; // U: utf+540 min * 60ms || J: jst+0
    return new Date(now);
};

const getFormattedDateStr = (date: Date) => {
    const jstDate = getJstDate(date);
    const YYYY = jstDate.getFullYear();
    const MM = `00${jstDate.getMonth() + 1}`.slice(-2);
    const DD = `00${jstDate.getDate()}`.slice(-2);
    return `${YYYY}${MM}${DD}`;
};

const getCsvDateStr = (date: Date) => {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

const useStyles = makeStyles((theme) => ({
    root: {},
    empty: {
        fontWeight: 600,
        color: theme.palette.text.disabled,
        textAlign: 'center',
    },
    newProgramButton: {
        position: 'fixed',
        bottom: 24,
        right: 24,
    },
    card: {
        '&:not(:last-child)': {
            marginBottom: 14,
        },
    },
}));

export const ConsoleTemplateAdminReports: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const {
        ticketsArrRows,
        advertiserProgramId,
        setAdvertiserProgramId,
        after,
        before,
        setAfter,
        setBefore,
        isFetching,
        initialize,
    } = useTicketsArr();

    return (
        <>
            <div>
                <DatePickerButton
                    date={after}
                    setDate={(newValue) => setAfter(newValue || getAMonthAgo())}
                    isEnablePast={true}
                    isDisableFuture={true}
                >
                    {getDateStr({ date: after, isWithDay: true, isWithTime: false })}
                </DatePickerButton>
                {'->'}
                <DatePickerButton
                    date={before}
                    setDate={(newValue) => setBefore(newValue || getToday())}
                    isEnablePast={true}
                    isDisableFuture={true}
                >
                    {getDateStr({ date: before, isWithDay: true, isWithTime: false })}
                </DatePickerButton>
                <TextInput
                    value={advertiserProgramId}
                    setValue={setAdvertiserProgramId}
                    messages={[
                        {
                            isError: !advertiserProgramId,
                            message: '必須フィールド',
                        },
                    ]}
                    placeholder={'広告主プログラムID'}
                />
                <Button onClick={initialize} disabled={isFetching}>
                    {'Fetch'}
                </Button>
            </div>
            {isFetching ? (
                <LoadingIndicator />
            ) : ticketsArrRows ? (
                <>
                    <table>
                        <tbody>
                            <tr>
                                {headerRow.map((headerRowCellValue, i) => (
                                    <th key={i}>{headerRowCellValue}</th>
                                ))}
                            </tr>
                            {ticketsArrRows.map((ticketsArrRow, i) => (
                                <tr key={i}>
                                    {ticketsArrRow.map((ticketsArrRowCelValue, ii) => (
                                        <td key={ii}>
                                            {ticketsArrRowCelValue instanceof Date
                                                ? getCsvDateStr(ticketsArrRowCelValue)
                                                : ticketsArrRowCelValue === undefined
                                                ? '-'
                                                : ticketsArrRowCelValue}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Button
                        onClick={() => {
                            const csv = parepareRecordsCsv({ ticketsArrRows });
                            downloadTextFile({
                                fileName: `moneyback_成果データ_${getFormattedDateStr(new Date())}`,
                                text: csv,
                                type: 'text/csv',
                            });
                        }}
                    >
                        {'ダウンロード'}
                    </Button>
                </>
            ) : (
                <>{'no data'}</>
            )}
        </>
    );
};
ConsoleTemplateAdminReports.displayName = 'ConsoleTemplateAdminReports';
