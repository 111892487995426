import { FC, ComponentProps } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { DatePickerButton } from '@/components/0_atom/DatePickerButton';

const useStyles = makeStyles((theme) => ({
    root: {},
    label: {
        fontWeight: 600,
        fontSize: theme.typography.body1.fontSize,
        display: 'block',
        marginBottom: 3,
    },
    button: {
        width: '100%',
        border: 'solid 2px rgba(0,0,0,.05)',
        fontWeight: 600,
    },
}));

export const DateInputWithLabel: FC<
    ComponentProps<typeof DatePickerButton> & {
        labelText: string;
    }
> = ({ labelText, className, ...otherProps }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div className={className}>
            <span className={c.label}>{labelText}</span>
            <DatePickerButton {...otherProps} className={c.button} />
        </div>
    );
};
DateInputWithLabel.displayName = 'DateInputWithLabel';
