import { FC, useState } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { BankAccountInputType, BankAccount } from '@/utils/MoneybackClient';
import Button from '@/components/0_atom/Button';
import { EditBankAccountModal } from '@/components/1_mol/InputWithLabel/BankAccountInputWithLabel/EditBankAccountModal';
import { BankAccountCard } from '@/components/2_org/BankAccountCard';

const useStyles = makeStyles((theme) => ({
    root: {},
    label: {
        fontWeight: 600,
        fontSize: theme.typography.body1.fontSize,
        display: 'block',
        marginBottom: 3,
    },
    button: {
        width: '100%',
        fontWeight: 600,
        border: 'none',
        padding: 0,
    },
    bankAccountButtonText: {
        padding: '12px 24px',
        color: theme.palette.text.disabled,
        border: 'solid 2px rgba(0,0,0,.05)',
        borderRadius: 4,
        fontWeight: 600,
        width: '100%',
    },
}));

export const BankAccountInputWithLabel: FC<{
    className?: string;
    labelText: string;
    bankAccount: BankAccountInputType | undefined;
    setBankAccount: (bankAccount: BankAccountInputType | undefined) => void;
}> = ({ className, labelText, bankAccount, setBankAccount }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={className}>
            <span className={c.label}>{labelText}</span>
            <Button onClick={() => setIsOpen(true)} className={c.button}>
                {bankAccount ? (
                    <BankAccountCard bankAccount={new BankAccount(bankAccount)} />
                ) : (
                    <span className={c.bankAccountButtonText}>{'未設定'}</span>
                )}
            </Button>
            <EditBankAccountModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                bankAccount={bankAccount}
                setBankAccount={setBankAccount}
            />
        </div>
    );
};
BankAccountInputWithLabel.displayName = 'BankAccountInputWithLabel';
