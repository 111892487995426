import { FC, ReactNode } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useColumnStyles = makeStyles((theme) => ({
    root: {
        fontSize: theme.typography.body2.fontSize,
        // display: 'flex',
        '&:not(:first-child)': {
            marginTop: 18,
        },
    },
    label: {
        // width: 74,
        fontWeight: 600,
        padding: '0 0 4px 0',
        flexShrink: 0,
    },
    content: {
        background: '#fafafa',
        borderRadius: 4,
        padding: 14,
        flexGrow: 1,
    },
    lightColor: {
        color: theme.palette.text.disabled,
    },
    collapsed: {
        '& > div > :not(:first-child)': {
            display: 'none',
        },
    },
    dots: {
        paddingLeft: 14,
    },
}));
const useRowStyles = makeStyles((theme) => ({
    root: {
        fontSize: theme.typography.body2.fontSize,
        display: 'flex',
        '&:not(:first-child)': {
            marginTop: 18,
        },
    },
    label: {
        width: 84,
        fontWeight: 600,
        padding: '14px 7px 14px 0',
        flexShrink: 0,
    },
    content: {
        background: '#fafafa',
        borderRadius: 4,
        padding: 14,
        flexGrow: 1,
    },
    lightColor: {
        color: theme.palette.text.disabled,
    },
    collapsed: {
        '& > div > :not(:first-child)': {
            display: 'none',
        },
    },
    dots: {
        paddingLeft: 14,
    },
}));

export const DetailsItem: FC<{
    contentClassName?: string;
    label: string;
    content: ReactNode;
    variant?: 'full' | 'collapsed';
    direction: 'column' | 'row';
    isLightColor?: boolean;
}> = ({ contentClassName, label, content, variant, direction, isLightColor }) => {
    // STYLE
    const t = useTheme();
    const columnStyles = useColumnStyles(t);
    const rowStyles = useRowStyles(t);
    const c = direction === 'row' ? rowStyles : columnStyles;

    // DATA

    return (
        <div
            className={c.root}
            style={{
                fontSize: variant === 'full' ? 14 : undefined,
            }}
        >
            <div className={c.label}>{label}</div>
            <div
                className={clsx(
                    c.content,
                    {
                        [c.collapsed]: variant === 'collapsed',
                        [c.lightColor]: isLightColor,
                    },
                    contentClassName
                )}
            >
                {content}
                {variant === 'collapsed' && <span className={c.dots}>{'...'}</span>}
            </div>
        </div>
    );
};
DetailsItem.displayName = 'DetailsItem';
