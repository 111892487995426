import { FC } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'block',
        margin: '0 0 2em 0',
        fontSize: 13,
        // color: 'rgba(0,0,0,.4)',
        // fontSize: 14,
        padding: '1.5em',
        background: 'rgba(0,0,0,.02)',
        borderRadius: 8,
        // border: 'solid 1px #ff9800',
        border: 'solid 1px rgba(0,0,0,.05)',
        textAlign: 'center',
    },
    heading: {
        fontWeight: 600,
        marginBottom: '1em',
    },
    description: {},
}));

export const ImportantNotice: FC<{
    className?: string;
    title: string;
    description?: string;
    url?: string;
}> = ({ className, title, description, url }) => {
    // STYLE
    const c = useStyles(useTheme());

    return url ? (
        <a className={clsx(c.root, className)} href={url}>
            <div className={c.heading}>{title}</div>
            <div className={c.description}>{description}</div>
        </a>
    ) : (
        <div className={clsx(c.root, className)}>
            <div className={c.heading}>{title}</div>
            <div className={c.description}>{description}</div>
        </div>
    );
};
ImportantNotice.displayName = 'ImportantNotice';
