import { FC } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import { ProgramForMedia, Campaign } from '@/utils/MoneybackClient';
import { getDateStr, getCampaignEndedDate, getProgramClosedDate, getPrevDate2359 } from '@/utils/utilFunctions';
import { DetailsItem } from '@/components/0_atom/DetailsItem';
import { Card } from '@/components/0_atom/Card';
import { Simple2ColumnsTable } from '@/components/0_atom/Simple2ColumnsTable';
import { EndSchedule } from '@/components/1_mol/EndSchedule';
import { ProgramHeading } from '@/components/1_mol/ProgramHeading';

const useStyles = makeStyles((theme) => ({
    root: {
        background: '#fff',
    },
    title: {
        fontWeight: 600,
    },
    description: {
        fontSize: theme.typography.body2.fontSize,
        marginTop: 11,
    },
    end: {
        fontSize: theme.typography.body2.fontSize,
        color: theme.palette.text.disabled,
    },
}));

export const CampaignCard: FC<{
    className?: string;
    program: ProgramForMedia;
    campaign: Campaign;
    onClick?: () => void;
}> = ({ className, program, campaign, onClick }) => {
    // STYLE
    const c = useStyles(useTheme());

    // DATA
    const endedDate = getCampaignEndedDate({ program, campaign });
    const closedDate = getProgramClosedDate({ program });

    return (
        <Card className={clsx(c.root, className)} onClick={onClick}>
            {closedDate ? (
                <MuiTypography className={c.end}>
                    {`${getDateStr({ date: getPrevDate2359({ date: closedDate }), isWithDay: true })}で終了(成果締切)`}
                </MuiTypography>
            ) : endedDate ? (
                <MuiTypography className={c.end}>
                    {`${getDateStr({ date: getPrevDate2359({ date: endedDate }), isWithDay: true })}で受付終了`}
                </MuiTypography>
            ) : (
                <></>
            )}
            <ProgramHeading program={program} />
            <MuiTypography className={c.description}>
                {program.description.replace(/\[cashbackAmount\]/g, campaign.cashbackAmount.toLocaleString())}
            </MuiTypography>
            <DetailsItem
                label={'金額'}
                content={
                    <Simple2ColumnsTable
                        items={[
                            ['CB', `¥ ${campaign.cashbackAmount.toLocaleString()}`],
                            ['報酬(税込)', `¥ ${campaign.mediaReward.total.toLocaleString()}`],
                        ]}
                    />
                }
                variant={'full'}
                direction={'row'}
            />
            <DetailsItem
                label={'終了日'}
                content={<EndSchedule program={program} campaign={campaign} />}
                variant={'full'}
                direction={'row'}
            />
        </Card>
    );
};
CampaignCard.displayName = 'CampaignCard';
