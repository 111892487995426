import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { ProgramApiForAdmin } from '@/utils/MoneybackClient';
import MutationButton from '@/components/0_atom/MutationButton';

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: 40,
    },
}));

export const ModifyOverviewMdModalSubmitButton: FC<{
    programAPI: ProgramApiForAdmin;
    newValue: string | undefined;
    isDisabled?: boolean;
    onComplete: () => void;
}> = ({ programAPI, newValue, isDisabled, onComplete }) => {
    // STYLE
    const c = useStyles(useTheme());

    // MUTATION
    const run = async () => {
        const { result } = await programAPI.modifyOverviewMd({
            newValue,
        });
        if (result === 'success') {
            onComplete();
        }
        return result;
    };

    return (
        <MutationButton
            className={c.button}
            mutation={{
                run,
                description: 'プログラムの概要を変更',
            }}
            disabled={isDisabled}
            role={'submit'}
        >
            {'変更'}
        </MutationButton>
    );
};
ModifyOverviewMdModalSubmitButton.displayName = 'ModifyOverviewMdModalSubmitButton';
