import { GqlDateTimeData } from '@/utils/MoneybackClient/graphql/customScalars';
import { Program as GqlProgramForAdmin } from '@/utils/MoneybackClient/graphql/generated/graphqlClient';
import {
    CashbackAmountRange,
    getCashbackAmountRangeFromGql,
    ProgramClosure,
    ProgramCondition,
    getProgramClosureFromGql,
    getProgramConditionFromGql,
} from '@/utils/MoneybackClient/models/Program/data/Edge';
import { Price, getPriceFromGql } from '@/utils/MoneybackClient/models/Price';
import { getProgramAdvertiserIconSrc, getProgramEyecatchSrc } from '@/utils/GoogleCloud/storage';

export type ProgramForAdminType = {
    id: string;
    createdAt: Date;
    updatedAt?: Date;
    mediaFund: number;
    cashbackAmountRange: CashbackAmountRange;
    title: string;
    description: string;
    descriptionForMedia: string | undefined;
    overviewMd: string | undefined;
    advertiserName: string;
    url: string;
    endReceptionAt?: Date;
    closeAt?: Date;
    closure?: ProgramClosure;
    condition: ProgramCondition;
    trackingParamKey: string;
    advertiserProgramId?: string;
    totalPrice: Price;
    whitelistedMediaIds?: string[];
    isPublicVisible: boolean;
    advertiserIconSrc: string | undefined;
    eyecatchSrc: string | undefined;
};

export class ProgramForAdmin implements ProgramForAdminType {
    protected _id: string;

    protected _createdAt: Date;

    protected _updatedAt?: Date;

    protected _mediaFund: number;

    protected _cashbackAmountRange: CashbackAmountRange;

    protected _title: string;

    protected _description: string;

    protected _descriptionForMedia: string | undefined;

    protected _overviewMd: string | undefined;

    protected _advertiserName: string;

    protected _url: string;

    protected _endReceptionAt?: Date;

    protected _closeAt?: Date;

    protected _closure?: ProgramClosure;

    protected _condition: ProgramCondition;

    protected _trackingParamKey: string;

    protected _advertiserProgramId?: string;

    protected _totalPrice: Price;

    protected _whitelistedMediaIds?: string[];

    protected _isPublicVisible: boolean;

    protected _advertiserIconSrc: string | undefined;

    protected _eyecatchSrc: string | undefined;

    get id() {
        return this._id;
    }

    get createdAt() {
        return this._createdAt;
    }

    get updatedAt() {
        return this._updatedAt;
    }

    get title() {
        return this._title;
    }

    get mediaFund() {
        return this._mediaFund;
    }

    get cashbackAmountRange() {
        return this._cashbackAmountRange;
    }

    get description() {
        return this._description;
    }

    get descriptionForMedia() {
        return this._descriptionForMedia;
    }

    get overviewMd() {
        return this._overviewMd;
    }

    get advertiserName() {
        return this._advertiserName;
    }

    get url() {
        return this._url;
    }

    get endReceptionAt() {
        return this._endReceptionAt;
    }

    get closeAt() {
        return this._closeAt;
    }

    get closure() {
        return this._closure;
    }

    get condition() {
        return this._condition;
    }

    get trackingParamKey() {
        return this._trackingParamKey;
    }

    get advertiserProgramId() {
        return this._advertiserProgramId;
    }

    get totalPrice() {
        return this._totalPrice;
    }

    get whitelistedMediaIds() {
        return this._whitelistedMediaIds;
    }

    get isPublicVisible() {
        return this._isPublicVisible;
    }

    get advertiserIconSrc() {
        return this._advertiserIconSrc;
    }

    get eyecatchSrc() {
        return this._eyecatchSrc;
    }

    get data() {
        return {
            id: this.id,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            title: this._title,
            mediaFund: this._mediaFund,
            cashbackAmountRange: this._cashbackAmountRange,
            description: this._description,
            descriptionForMedia: this._descriptionForMedia,
            overviewMd: this._overviewMd,
            advertiserName: this._advertiserName,
            url: this._url,
            endReceptionAt: this._endReceptionAt,
            closeAt: this._closeAt,
            closure: this._closure,
            condition: this._condition,
            trackingParamKey: this._trackingParamKey,
            advertiserProgramId: this._advertiserProgramId,
            totalPrice: this._totalPrice,
            whitelistedMediaIds: this._whitelistedMediaIds,
            isPublicVisible: this._isPublicVisible,
            advertiserIconSrc: this._advertiserIconSrc,
            eyecatchSrc: this._eyecatchSrc,
        };
    }

    constructor({
        id,
        createdAt,
        updatedAt,
        title,
        mediaFund,
        cashbackAmountRange,
        description,
        descriptionForMedia,
        overviewMd,
        advertiserName,
        url,
        endReceptionAt,
        closeAt,
        closure,
        condition,
        trackingParamKey,
        advertiserProgramId,
        totalPrice,
        whitelistedMediaIds,
        isPublicVisible,
        advertiserIconSrc,
        eyecatchSrc,
    }: ProgramForAdminType) {
        this._id = id;
        this._createdAt = createdAt;
        this._updatedAt = updatedAt;
        this._title = title;
        this._mediaFund = mediaFund;
        this._cashbackAmountRange = cashbackAmountRange;
        this._description = description;
        this._descriptionForMedia = descriptionForMedia;
        this._overviewMd = overviewMd;
        this._advertiserName = advertiserName;
        this._url = url;
        this._endReceptionAt = endReceptionAt;
        this._closeAt = closeAt;
        this._closure = closure;
        this._condition = condition;
        this._trackingParamKey = trackingParamKey;
        this._advertiserProgramId = advertiserProgramId;
        this._totalPrice = totalPrice;
        this._whitelistedMediaIds = whitelistedMediaIds;
        this._isPublicVisible = isPublicVisible;
        this._advertiserIconSrc = advertiserIconSrc;
        this._eyecatchSrc = eyecatchSrc;
    }
}

export const getProgramForAdminFromGql = async ({
    id,
    createdAt,
    updatedAt,
    title,
    mediaFund,
    cashbackAmountRange,
    description,
    descriptionForMedia,
    overviewMd,
    advertiserName,
    url,
    endReceptionAt,
    closeAt,
    closure,
    condition,
    trackingParamKey,
    advertiserProgramId,
    totalPrice,
    whitelistedMediaIds,
    isPublicVisible,
}: GqlProgramForAdmin) => {
    const [advertiserIconSrc, eyecatchSrc] = await Promise.all([
        getProgramAdvertiserIconSrc({ programId: id }),
        getProgramEyecatchSrc({ programId: id }),
    ]);
    return new ProgramForAdmin({
        id,
        createdAt: GqlDateTimeData.serialize(createdAt),
        updatedAt: updatedAt ? GqlDateTimeData.serialize(updatedAt) : undefined,
        title,
        mediaFund,
        cashbackAmountRange: getCashbackAmountRangeFromGql(cashbackAmountRange),
        description,
        descriptionForMedia: descriptionForMedia || undefined,
        overviewMd: overviewMd || undefined,
        advertiserName,
        url,
        endReceptionAt: endReceptionAt ? GqlDateTimeData.serialize(endReceptionAt) : undefined,
        closeAt: closeAt ? GqlDateTimeData.serialize(closeAt) : undefined,
        closure: closure ? getProgramClosureFromGql(closure) : undefined,
        condition: getProgramConditionFromGql(condition),
        trackingParamKey,
        advertiserProgramId: advertiserProgramId || undefined,
        totalPrice: getPriceFromGql(totalPrice),
        whitelistedMediaIds: whitelistedMediaIds || undefined,
        isPublicVisible,
        advertiserIconSrc,
        eyecatchSrc,
    });
};
