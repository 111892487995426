import { CashbackAmountRange as GqlCashbackAmountRange } from '@/utils/MoneybackClient/graphql/generated/graphqlClient';

export type CashbackAmountRangeType = {
    low?: number;
    high?: number;
};

export class CashbackAmountRange implements CashbackAmountRangeType {
    protected _low?: number;

    protected _high?: number;

    get low() {
        return this._low;
    }

    get high() {
        return this._high;
    }

    get data() {
        return {
            low: this.low,
            high: this.high,
        };
    }

    constructor({ low, high }: CashbackAmountRangeType) {
        this._low = low;
        this._high = high;
    }
}

export const getCashbackAmountRangeFromGql = ({ low, high }: GqlCashbackAmountRange) => {
    return new CashbackAmountRange({
        low: typeof low === 'number' ? low : undefined,
        high: typeof high === 'number' ? high : undefined,
    });
};
