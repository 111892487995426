import { FC, useState } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import { ProgramApiForAdmin } from '@/utils/MoneybackClient';
import { useAdminProgramApiItems } from '@/utils/customHooks';
import { LoadingIndicator } from '@/components/0_atom/LoadingIndicator';
import Button from '@/components/0_atom/Button';
import { ConsoleTemplateAdminProgramsAdminProgramCard } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramCard';
import { ConsoleTemplateAdminProgramsAdminProgramModal } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramModal';
import { CreateProgramModal } from '@/components/3_template/ConsoleTemplate/Admin/Programs/CreateProgramModal';

const useStyles = makeStyles((theme) => ({
    root: {},
    empty: {
        fontWeight: 600,
        color: theme.palette.text.disabled,
        textAlign: 'center',
    },
    newProgramButton: {
        position: 'fixed',
        bottom: 24,
        right: 24,
    },
    card: {
        '&:not(:last-child)': {
            marginBottom: 14,
        },
    },
}));

export const ConsoleTemplateAdminPrograms: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { items, reInitialize } = useAdminProgramApiItems();
    const [selectedProgramAPI, setSelectedProgramAPI] = useState<ProgramApiForAdmin | undefined>(undefined);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

    return (
        <>
            {items ? (
                items.ids.length > 0 ? (
                    items.ids.map((id) => {
                        const programAPI = items.getItemAbsolutely(id);
                        return (
                            <ConsoleTemplateAdminProgramsAdminProgramCard
                                className={c.card}
                                key={id}
                                programAPI={programAPI}
                                onClick={() => setSelectedProgramAPI(programAPI)}
                                enabledFields={{
                                    advertiserProgramId: true,
                                    totalPrice: true,
                                    mediaFund: true,
                                    advertiserName: true,
                                    schedule: true,
                                }}
                            />
                        );
                    })
                ) : (
                    <MuiTypography className={c.empty}>{'プログラムがありません'}</MuiTypography>
                )
            ) : (
                <LoadingIndicator />
            )}
            <Button className={c.newProgramButton} role={'submit'} onClick={() => setIsCreateModalOpen(true)}>
                {'プログラムを作成'}
            </Button>
            <ConsoleTemplateAdminProgramsAdminProgramModal
                programAPI={selectedProgramAPI}
                onClose={() => setSelectedProgramAPI(undefined)}
            />
            <CreateProgramModal
                isOpen={isCreateModalOpen}
                onClose={() => setIsCreateModalOpen(false)}
                onComplete={reInitialize}
            />
        </>
    );
};
ConsoleTemplateAdminPrograms.displayName = 'ConsoleTemplateAdminPrograms';
