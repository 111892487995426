import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import { ProgramApiForAdmin } from '@/utils/MoneybackClient';
import { Modal } from '@/components/0_atom/Modal';
import { LoadingIndicator } from '@/components/0_atom/LoadingIndicator';
import { ModifyConditionCompleteLimitDaysModalContent } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramModal/ModifyConditionCompleteLimitDays/Content';

const useStyles = makeStyles((theme) => ({
    root: {},
    heading: {
        fontSize: 18,
        margin: '0 0 60px 0',
        textAlign: 'center',
    },
}));

export const ModifyConditionCompleteLimitDaysModal: FC<{
    programAPI: ProgramApiForAdmin | undefined;
    onClose: () => void;
}> = ({ programAPI, onClose }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <Modal open={!!programAPI} onClose={onClose} anchor={'bottom'}>
            <MuiTypography variant={'h1'} className={c.heading}>
                {'達成期限を編集'}
            </MuiTypography>
            {programAPI ? (
                <ModifyConditionCompleteLimitDaysModalContent programAPI={programAPI} onClose={onClose} />
            ) : (
                <LoadingIndicator />
            )}
        </Modal>
    );
};
ModifyConditionCompleteLimitDaysModal.displayName = 'ModifyConditionCompleteLimitDaysModal';
