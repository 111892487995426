import { FC, useState } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { ProgramApiForAdmin } from '@/utils/MoneybackClient';
import { getDateStr } from '@/utils/utilFunctions';
import { DetailsItem } from '@/components/0_atom/DetailsItem';
import { DateInputWithLabel } from '@/components/1_mol/InputWithLabel';
import { ConsoleTemplateAdminProgramsAdminProgramCard } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramCard';
import { ModifyCloseAtModalSubmitButton } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramModal/ModifyCloseAt/SubmitButton';

const useStyles = makeStyles((theme) => ({
    root: {},
    input: {
        marginTop: 40,
        marginBottom: 14,
    },
}));

export const ModifyCloseAtModalContent: FC<{
    programAPI: ProgramApiForAdmin;
    onClose: () => void;
}> = ({ programAPI, onClose }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const [newValue, setNewValue] = useState<Date | undefined>(programAPI.closeAt);

    // DATA
    const isOk = programAPI.closeAt !== newValue;

    return (
        <>
            <ConsoleTemplateAdminProgramsAdminProgramCard
                programAPI={programAPI}
                enabledFields={{
                    advertiserProgramId: true,
                }}
            />
            <DetailsItem
                label={'これまでの終了日'}
                content={programAPI.closeAt ? getDateStr({ date: programAPI.closeAt, isWithDay: true }) : '未定'}
                variant={'full'}
                direction={'row'}
            />
            <DateInputWithLabel className={c.input} date={newValue} setDate={setNewValue} labelText={'新しい終了日'}>
                {newValue ? getDateStr({ date: newValue, isWithDay: true }) : '未定'}
            </DateInputWithLabel>
            <ModifyCloseAtModalSubmitButton
                programAPI={programAPI}
                newValue={newValue}
                isDisabled={!isOk}
                onComplete={onClose}
            />
        </>
    );
};
ModifyCloseAtModalContent.displayName = 'ModifyCloseAtModalContent';
