import { FC, useCallback, useEffect } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import {
    BankAccountInputType,
    getIsValidAccountNumber,
    getIsValidBankCode,
    getIsValidBranchCode,
    getValidRemitterName,
} from '@/utils/MoneybackClient';
import { useFormField } from '@/utils/customHooks';
import { TextInputWithLabel } from '@/components/1_mol/InputWithLabel';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > div:not(:last-child)': {
            marginBottom: 16,
        },
        '& input': {
            fontSize: 18,
        },
    },
    description: {
        marginBottom: 32,
        '& a': {
            textDecoration: 'underline',
            fontWeight: 600,
            color: '#0a7',
        },
    },
}));

export const BankAccountForm: FC<{
    className?: string;
    initialBankAccount?: BankAccountInputType;
    setBankAccount: (bankAccount: BankAccountInputType) => void;
    setIsHasError: (isHasError: boolean) => void;
}> = ({ className, initialBankAccount, setBankAccount, setIsHasError }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const [bankCode, setBankCode] = useFormField(initialBankAccount?.bankCode);
    const [branchCode, setBranchCode] = useFormField(initialBankAccount?.branchCode);
    const [accountNumber, setAccountNumber] = useFormField(initialBankAccount?.accountNumber);
    const [accountHolderName, setAccountHolderName] = useFormField(initialBankAccount?.accountHolderName);

    // DATA
    const validBankCode = (() => {
        if (bankCode && getIsValidBankCode(bankCode)) {
            return bankCode;
        }
        return undefined;
    })();
    const validBranchCode = (() => {
        if (branchCode && getIsValidBranchCode(branchCode)) {
            return branchCode;
        }
        return undefined;
    })();
    const validAccountNumber = (() => {
        if (accountNumber && getIsValidAccountNumber(accountNumber)) {
            return accountNumber;
        }
        return undefined;
    })();
    const validAccountHolderName = (() => {
        if (accountHolderName) {
            const { isValid, fullWidthKatakana } = getValidRemitterName(accountHolderName);
            if (isValid && fullWidthKatakana) {
                return fullWidthKatakana;
            }
        }
        return undefined;
    })();

    // CALLBACK
    const handleSubmit = useCallback(() => {
        if (validBankCode && validBranchCode && validAccountNumber && validAccountHolderName) {
            setBankAccount({
                bankCode: validBankCode,
                branchCode: validBranchCode,
                accountNumber: validAccountNumber,
                accountHolderName: validAccountHolderName,
            });
            setIsHasError(false);
        } else {
            setIsHasError(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validBankCode, validBranchCode, validAccountNumber, validAccountHolderName]);

    // USEEFFECT
    useEffect(() => {
        handleSubmit();
    }, [handleSubmit]);

    return (
        <div className={clsx(c.root, className)}>
            <MuiTypography className={c.description}>
                {'銀行コードや支店コードがわからない場合は、ゆうちょ銀行の場合は'}
                <a
                    href={'https://www.jp-bank.japanpost.jp/kojin/sokin/furikomi/kouza/kj_sk_fm_kz_1.html'}
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                >
                    {'こちら'}
                </a>
                {'、その他の銀行の場合は'}
                <a href={'https://zengin.ajtw.net/s1.php'} target={'_blank'} rel={'noopener noreferrer'}>
                    {'こちら'}
                </a>
                {'から調べてご入力ください。'}
            </MuiTypography>
            <TextInputWithLabel
                labelText={'銀行コード'}
                placeholder={'銀行コード（４ケタ）'}
                value={bankCode}
                setValue={setBankCode}
                messages={[
                    {
                        message: '銀行コードは半角数字４桁です',
                        isError: !!bankCode && !validBankCode,
                    },
                ]}
                isSatisfied={!!validBankCode}
                inputMode={'numeric'}
            />
            <TextInputWithLabel
                labelText={'支店コード（または店番）'}
                placeholder={'支店コードまたは店番（３ケタ）'}
                value={branchCode}
                setValue={setBranchCode}
                messages={[
                    {
                        message: '支店コード（または店番）は半角数字３桁です',
                        isError: !!branchCode && !validBranchCode,
                    },
                ]}
                isSatisfied={!!validBranchCode}
                inputMode={'numeric'}
            />
            <TextInputWithLabel
                labelText={'口座番号'}
                placeholder={'口座番号（７ケタ）'}
                value={accountNumber}
                setValue={setAccountNumber}
                messages={[
                    {
                        message: '口座番号は半角数字７桁です',
                        isError: !!accountNumber && !validAccountNumber,
                    },
                ]}
                isSatisfied={!!validAccountNumber}
                inputMode={'numeric'}
            />
            <TextInputWithLabel
                labelText={'口座名義人'}
                placeholder={'口座名義人（かな）'}
                value={accountHolderName}
                setValue={setAccountHolderName}
                messages={[
                    {
                        message: '口座名義はかなで正確に入力してください',
                        isError: !!accountHolderName && !validAccountHolderName,
                    },
                ]}
                isSatisfied={!!validAccountHolderName}
            />
        </div>
    );
};
BankAccountForm.displayName = 'BankAccountForm';
