import { createContext, useContext } from 'react';

import { MoneybackAdminClient } from '@/utils/MoneybackClient';

type AdminCtxType = {
    adminClient?: MoneybackAdminClient;
};
export const AdminCtx = createContext<AdminCtxType>({
    adminClient: undefined,
});
export const useAdminCtx = () => {
    return useContext(AdminCtx);
};
export const useAdminCtxAbsolutely = () => {
    const { adminClient } = useAdminCtx();
    if (!adminClient) {
        throw new Error('useAdminCtxAbsolutely: !adminClient.');
    }
    return {
        adminClient,
    };
};
