import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import { getEnvVariables } from '@/utils/envVariables';

export const getFirebaseCore = () => {
    const existinApp = firebase.apps.find((app) => app.name === 'core');
    if (existinApp) {
        return existinApp;
    }
    const { projectId, storageBucket } = getEnvVariables().firebase.core;
    return firebase.initializeApp(
        {
            projectId,
            storageBucket,
        },
        'core'
    );
};

export const getFirebaseMedia = () => {
    const existinApp = firebase.apps.find((app) => app.name === 'mediaauth');
    if (existinApp) {
        return existinApp;
    }
    const { apiKey, authDomain, projectId } = getEnvVariables().firebase.mediaAuth;
    return firebase.initializeApp(
        {
            projectId,
            apiKey,
            authDomain,
        },
        'mediaauth'
    );
};

export const getFirebaseAuth = (language?: 'en' | 'ja') => {
    const app = getFirebaseMedia();
    const auth = firebase.auth(app);
    auth.languageCode = language || 'ja';
    return auth;
};

export const handleStartAuth = async ({ redirectUrl, language }: { redirectUrl?: string; language?: 'en' | 'ja' }) => {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({
        languageCode: language || 'ja',
        hl: language || 'ja',
        // redirect_uri: getEnvVariables().rootUrl + (redirectUrl || ''),
        // state: 'abcdefg'
    });
    const auth = getFirebaseAuth(language);

    // auth.signInWithRedirect(provider);
    return auth.signInWithPopup(provider);
};

export const getFirebaseStorage = () => {
    const app = getFirebaseCore();
    return firebase.storage(app);
};
