import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
    root: {},
    label: {
        fontWeight: 600,
        fontSize: theme.typography.body1.fontSize,
        display: 'block',
        marginBottom: 3,
    },
    button: {
        width: '100%',
        border: 'solid 2px rgba(0,0,0,.05)',
        fontWeight: 600,
    },
}));

export const BooleanInputWithLabel: FC<{
    className?: string;
    labelText: string;
    value: boolean;
    setValue: (value: boolean) => void;
    texts: {
        true: string;
        false: string;
    };
    isDisabled?: boolean;
}> = ({ className, labelText, value, setValue, texts, isDisabled }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div className={className}>
            <span className={c.label}>{labelText}</span>
            <FormControlLabel
                disabled={isDisabled}
                control={<Checkbox checked={value} onChange={() => setValue(!value)} />}
                label={value ? texts.true : texts.false}
            />
        </div>
    );
};
BooleanInputWithLabel.displayName = 'BooleanInputWithLabel';
