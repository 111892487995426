import { forwardRef, ComponentProps } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiCard from '@material-ui/core/Card';
import MuiCardActionArea from '@material-ui/core/CardActionArea';
import MuiCardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles((theme) => ({
    root: {
        border: 'solid 2px rgba(0,0,0,.07)',
        boxShadow: 'none',
        overflow: 'visible',
        '&:hover': {
            boxShadow: '0 16px 16px 0 rgb(0,0,0,.1)',
            borderColor: 'rgba(0,0,0,.15)',
        },
    },
    cardActionArea: {
        '& .MuiCardActionArea-focusHighlight': {
            background: '#fff',
        },
        '&:hover': {
            '& .MuiCardActionArea-focusHighlight': {
                // opacity: 0.3,
            },
        },
    },
}));

export const Card = forwardRef<
    HTMLDivElement,
    Omit<ComponentProps<typeof MuiCard>, 'onClick'> & {
        onClick?: () => void;
    }
>(({ onClick, children, className, ...otherProps }, ref) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <MuiCard ref={ref} {...otherProps} className={clsx(c.root, className)}>
            {onClick ? (
                <MuiCardActionArea
                    disableRipple
                    onClick={onClick}
                    className={c.cardActionArea}
                    // classes={{
                    //     focusHighlight: {
                    //         backgroundColor: '#fff',
                    //     } as any,
                    // }}
                >
                    <MuiCardContent>{children}</MuiCardContent>
                </MuiCardActionArea>
            ) : (
                <MuiCardContent>{children}</MuiCardContent>
            )}
        </MuiCard>
    );
});
Card.displayName = 'Card';
