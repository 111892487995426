import { FC } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { ProgramForMedia, TicketForMedia, Campaign, CampaignApiForGeneral } from '@/utils/MoneybackClient';
import { getDateStr, getProgramClosedDate, getTimeStr, getPrevDate2359 } from '@/utils/utilFunctions';

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: 4,
        border: 'solid 2px rgba(0,0,0,.03)',
        padding: '12px 20px',
        fontWeight: 600,
        fontSize: theme.typography.body2.fontSize,
    },
    upper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    upperRight: {
        display: 'flex',
        alignItems: 'center',
    },
    createdAt: {},
    reward: {
        width: 83,
        color: theme.palette.text.disabled,
    },
    rewardApproved: {
        color: `${theme.palette.text.primary} !important`,
    },
    date: {
        display: 'block',
    },
    time: {
        color: theme.palette.text.disabled,
        // marginLeft: 10,
        display: 'block',
    },
    status: {
        background: theme.palette.warning.main,
        color: '#fff',
        padding: '9px 0',
        borderRadius: 4,
        width: 83,
        textAlign: 'center',
    },
    approved: {
        background: theme.palette.success.main,
    },
    reducedApprove: {
        background: theme.palette.success.main,
    },
    rejected: {
        background: theme.palette.error.main,
    },
    recognized: {
        background: theme.palette.success.light,
    },
    lower: {},
}));

export const CampaignModalContentTicketsTicketCard: FC<{
    className?: string;
    program: ProgramForMedia;
    campaign: Campaign | CampaignApiForGeneral;
    ticket: TicketForMedia;
}> = ({ className, program, campaign, ticket }) => {
    // STYLE
    const c = useStyles(useTheme());

    // DATA
    const status =
        ticket.settlement?.judgement === 'approve'
            ? 'approved'
            : ticket.settlement?.judgement === 'reducedApprove'
            ? 'reducedApprove'
            : ticket.settlement
            ? 'rejected'
            : ticket.recognizedAt
            ? 'recognized'
            : undefined;
    const closedDate = getProgramClosedDate({ program });

    return (
        <div className={clsx(c.root, className, 'org_campaignmodal_ticketcard')}>
            <div className={c.upper}>
                <span className={c.createdAt}>
                    <span className={clsx(c.date, 'org_campaignmodal_ticketcard_date')}>
                        {getDateStr({ date: ticket.createdAt, isWithDay: false })}
                    </span>
                    <span className={clsx(c.time, 'org_campaignmodal_ticketcard_time')}>
                        {getTimeStr({ date: ticket.createdAt })}
                    </span>
                </span>
                <div className={c.upperRight}>
                    <span
                        className={clsx(c.reward, 'org_campaignmodal_ticketcard_reward', {
                            [c.rewardApproved]: status === 'approved',
                        })}
                    >
                        {`¥ ${(ticket.settlement?.mediaReward || campaign.mediaReward).total.toLocaleString()}`}
                    </span>
                    <span
                        className={clsx(c.status, 'org_campaignmodal_ticketcard_status', {
                            [c.approved]: status === 'approved',
                            [c.reducedApprove]: status === 'reducedApprove',
                            [c.rejected]: status === 'rejected',
                            [c.recognized]: status === 'recognized',
                        })}
                    >
                        {status === 'approved'
                            ? '承認'
                            : status === 'reducedApprove'
                            ? '減額承認'
                            : status === 'rejected'
                            ? '却下'
                            : status === 'recognized'
                            ? '未承認(認識済)'
                            : '未承認(未認識)'}
                    </span>
                </div>
            </div>
            {closedDate && (
                <div className={c.lower}>
                    {`このキャンペーンは${getDateStr({
                        date: getPrevDate2359({ date: closedDate }),
                        isWithDay: false,
                    })}で終了したため、それ以前に条件を達成していない場合、却下となります。(減額で承認される場合がありますが、この場合はユーザーへのキャッシュバックはありません)`}
                </div>
            )}
        </div>
    );
};
CampaignModalContentTicketsTicketCard.displayName = 'CampaignModalContentTicketsTicketCard';
