import { getGraphqlSdk } from '@/utils/MoneybackClient/graphql/graphqlSdk';
import { MoneybackMediaUserClient } from '@/utils/MoneybackClient/clients';
import { getMediaUserFromGql } from '@/utils/MoneybackClient/models';

export const initMoneybackMediaUserClient = async (input: { firebaseUserIdToken: string }) => {
    const graphqlSdk = getGraphqlSdk(input);
    const { getMediaUser } = await graphqlSdk.getMediaUser().catch(({ message }) => {
        console.error('message->', message);
        throw new Error('initMoneybackMediaUserClient: invalid firebaseUserIdToken');
    });
    const { mediaUser } = getMediaUser;
    if (mediaUser) {
        return new MoneybackMediaUserClient({
            graphqlSdk,
            ...getMediaUserFromGql(mediaUser).data,
        });
    }
    throw new Error('initMoneybackMediaUserClient: No user account');
};
