import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import { useBalances, useRecentBalance } from '@/utils/customHooks';
import { LoadingIndicator } from '@/components/0_atom/LoadingIndicator';
import { ConsoleTemplateBalancesBalanceCard } from '@/components/3_template/ConsoleTemplate/Balances/BalanceCard';

const useStyles = makeStyles((theme) => ({
    root: {},
    recent: {
        fontWeight: 600,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: '#fff',
        borderRadius: 4,
        padding: '30px 14px',
    },
    recentTitle: {
        fontWeight: 600,
    },
    recentLoading: {
        padding: 0,
        fontWeight: 600,
        marginTop: 7,
    },
    recentAmount: {
        fontSize: 24,
        fontWeight: 600,
        marginTop: 7,
    },
    recentDescription: {
        fontWeight: 600,
        color: theme.palette.text.disabled,
        fontSize: theme.typography.body2.fontSize,
        marginTop: 14,
    },
    empty: {
        fontWeight: 600,
        color: theme.palette.text.disabled,
        textAlign: 'center',
    },
    balances: {
        marginTop: 50,
    },
    tableHead: {
        fontWeight: 600,
        padding: '0 22px',
        marginBottom: 17,
        color: theme.palette.text.disabled,
        fontSize: theme.typography.body2.fontSize,
        display: 'flex',
        justifyContent: 'space-between',
        '& > :nth-child(1)': {
            width: 100,
        },
        '& > :nth-child(2)': {
            width: 'calc(100% - 200px)',
        },
        '& > :nth-child(3)': {
            width: 100,
            textAlign: 'right',
        },
    },
}));

export const ConsoleTemplateBalances: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { recentBalance } = useRecentBalance();
    // const { balances } = useBalances();
    // console.log({ balances });
    return (
        <>
            <div className={c.recent}>
                <MuiTypography className={c.recentTitle}>{'未出金の売上'}</MuiTypography>
                {recentBalance === 'fetching' ? (
                    <LoadingIndicator className={c.recentLoading} />
                ) : (
                    <MuiTypography className={c.recentAmount}>{`¥ ${(
                        (recentBalance?.currentAmount || 0) + (recentBalance?.currentReserve || 0)
                    ).toLocaleString()}`}</MuiTypography>
                )}
                <MuiTypography className={c.recentDescription}>
                    {'出金は毎月月末締め、翌月15日までに自動で行われます。'}
                </MuiTypography>
            </div>
            {/* <div className={c.balances}>
                {balances ? (
                    balances.length > 0 ? (
                        <>
                            <div className={c.tableHead}>
                                <span>{'確定日'}</span>
                                <span>{'プログラム名'}</span>
                                <span>{'金額(税込)'}</span>
                            </div>
                            {balances.map((balance) => (
                                <ConsoleTemplateBalancesBalanceCard key={balance.id} balance={balance} />
                            ))}
                        </>
                    ) : (
                        <p className={c.empty}>{'売上・出金がありません。'}</p>
                    )
                ) : (
                    <LoadingIndicator />
                )}
            </div> */}
        </>
    );
};
ConsoleTemplateBalances.displayName = 'ConsoleTemplateBalances';
