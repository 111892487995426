import { createContext, useContext } from 'react';

import { MoneybackObserverClient, MoneybackGeneralClient, MoneybackOwnerClient, Media } from '@/utils/MoneybackClient';

export type InitializeClient = (input: {
    mediaId: string;
    role: 'observer' | 'general' | 'owner';
}) => Promise<MoneybackObserverClient | MoneybackGeneralClient | MoneybackOwnerClient | never>;
interface MediaCtxType {
    mediaClient?: MoneybackObserverClient | MoneybackGeneralClient | MoneybackOwnerClient;
    isFetching: boolean;
    reInitialize: InitializeClient;
}
export const MediaCtx = createContext<MediaCtxType>({
    mediaClient: undefined,
    isFetching: true,
    reInitialize: () =>
        new Promise(() => {
            throw new Error('MediaCtx.reInitialize: not initialized.');
        }),
});
export const useMediaCtx = () => {
    return useContext(MediaCtx);
};
export const useMediaCtxAbsolutely = () => {
    const { mediaClient, ...otherProps } = useMediaCtx();
    if (!mediaClient) {
        throw new Error('useMediaCtxAbsolutely: !mediaClient.');
    }
    return {
        mediaClient,
        ...otherProps,
    };
};
