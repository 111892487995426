import { GraphQLSdk } from '@/utils/MoneybackClient/graphql/graphqlSdk';
import {
    Media,
    MediaType,
    ProgramForMedia,
    getProgramForMediaFromGql,
    Campaign,
    getCampaignFromGql,
    TicketForMedia,
    getTicketForMediaFromGql,
    MediaBalance,
    getMediaBalanceFromGql,
} from '@/utils/MoneybackClient/models';

type MoneybackObserverClientType = {
    getPrograms: () => Promise<ProgramForMedia[]>;
    getCampaigns: () => Promise<
        {
            campaign: Campaign;
            program: ProgramForMedia;
        }[]
    >;
    getTicketsForCampaign: (input: { programId: string; campaignId: string }) => Promise<TicketForMedia[]>;
    getBalances: () => Promise<MediaBalance[]>;
    getRecentBalance: () => Promise<MediaBalance | undefined>;
};

type ConstructorInput = MediaType & {
    graphqlSdk: GraphQLSdk;
};

export class MoneybackObserverClient extends Media implements MoneybackObserverClientType {
    protected _graphqlSdk: GraphQLSdk;

    constructor(input: ConstructorInput) {
        super(input);
        this._graphqlSdk = input.graphqlSdk;
    }

    async getPrograms() {
        const { getProgramsForMedia } = await this._graphqlSdk.getProgramsForMedia({
            input: {
                mediaId: this.id,
            },
        });
        const { programs } = getProgramsForMedia;
        return Promise.all(programs.map((program) => getProgramForMediaFromGql(program)));
    }

    async getCampaigns() {
        const { getCampaignsForMedia } = await this._graphqlSdk.getCampaignsForMedia({
            input: {
                mediaId: this.id,
            },
        });
        const { campaigns } = getCampaignsForMedia;
        return Promise.all(
            campaigns.map(async ({ program, campaign }) => ({
                program: await getProgramForMediaFromGql(program),
                campaign: getCampaignFromGql(campaign),
            }))
        );
    }

    async getTicketsForCampaign({ programId, campaignId }: { programId: string; campaignId: string }) {
        const { getTicketsForCampaignForMedia } = await this._graphqlSdk.getTicketsForCampaignForMedia({
            input: {
                mediaId: this.id,
                programId,
                campaignId,
            },
        });
        const { tickets } = getTicketsForCampaignForMedia;
        return tickets.map((ticket) => getTicketForMediaFromGql(ticket));
    }

    async getBalances() {
        const { getMediaBalances } = await this._graphqlSdk.getMediaBalances({
            input: {
                mediaId: this.id,
            },
        });
        const { balances } = getMediaBalances;
        return Promise.all(balances.map((balance) => getMediaBalanceFromGql(balance)));
    }

    async getRecentBalance() {
        const { getRecentMediaBalance } = await this._graphqlSdk.getRecentMediaBalance({
            input: {
                mediaId: this.id,
            },
        });
        const { balance } = getRecentMediaBalance;
        return balance ? getMediaBalanceFromGql(balance) : undefined;
    }
}
