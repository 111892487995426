import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { MoneybackMediaUserClient, BankAccountInputType, BankAccountInput } from '@/utils/MoneybackClient';
import MutationButton from '@/components/0_atom/MutationButton';

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: 40,
    },
}));

export const CreateMediaModalSubmitButton: FC<{
    userClient: MoneybackMediaUserClient;
    isDisabled?: boolean;
    name: string;
    url: string;
    bankAccount: BankAccountInputType | undefined;
    onComplete: () => void;
}> = ({ userClient, isDisabled, name, url, bankAccount, onComplete }) => {
    // STYLE
    const c = useStyles(useTheme());

    // MUTATION
    const run = async () => {
        await userClient.createMedia({
            name,
            url,
            bankAccount: bankAccount ? new BankAccountInput(bankAccount) : undefined,
        });
        onComplete();
        return 'success' as const;
    };

    return (
        <MutationButton
            className={c.button}
            mutation={{
                run,
                description: '新しいメディアを追加',
            }}
            disabled={isDisabled}
            role={'submit'}
        >
            {'作成'}
        </MutationButton>
    );
};
CreateMediaModalSubmitButton.displayName = 'CreateMediaModalSubmitButton';
