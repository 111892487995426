import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { FileDropZone } from '@/components/0_atom/FileDropZone';
import Button from '@/components/0_atom/Button';
import SvgImagePlus from '@/svgs/image-plus';

const useStyles = makeStyles((theme) => ({
    root: {},
    label: {
        fontWeight: 600,
        fontSize: theme.typography.body1.fontSize,
        display: 'block',
        marginBottom: 3,
    },
    button: {
        width: '100%',
        border: 'solid 2px rgba(0,0,0,.05)',
        fontWeight: 600,
        padding: 14,
    },
    icon: {
        // width: 40,
        height: 40,
        display: 'block',
        margin: '0 auto',
    },
}));

export const ImgInputWithLabel: FC<{
    className?: string | undefined;
    labelText: string;
    isDisabled?: boolean | undefined;
    file: File | undefined;
    setFile: (file: File | undefined) => void;
}> = ({ className, labelText, isDisabled, file, setFile }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div className={className}>
            <span className={c.label}>{labelText}</span>
            <FileDropZone setFiles={(files) => setFile(files[0])} isDisabled={isDisabled}>
                <Button className={c.button}>
                    {file ? (
                        <img src={window.URL.createObjectURL(file)} alt={'Uploaded icon'} className={c.icon} />
                    ) : (
                        <SvgImagePlus className={c.icon} />
                    )}
                </Button>
            </FileDropZone>
        </div>
    );
};
ImgInputWithLabel.displayName = 'ImgInputWithLabel';
