import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import { ProgramForAdmin } from '@/utils/MoneybackClient';
import {
    getDateStr,
    getProgramEndedDate,
    getProgramClosedDate,
    getStrWithBr,
    getPrevDate2359,
} from '@/utils/utilFunctions';
import { DetailsItem } from '@/components/0_atom/DetailsItem';
import { ProgramConditionText } from '@/components/1_mol/ProgramConditionText';
import { EndSchedule } from '@/components/1_mol/EndSchedule';
import { ProgramDescriptionForMedia } from '@/components/1_mol/ProgramDescriptionForMedia';
import { ProgramOverview } from '@/components/1_mol/ProgramOverview';
import { ProgramHeading } from '@/components/1_mol/ProgramHeading';

const useStyles = makeStyles((theme) => ({
    root: {},
    eyecatch: {
        maxHeight: 200,
        objectFit: 'contain',
        borderRadius: 4,
        marginBottom: 40,
        marginLeft: 0,
        marginRight: 'auto',
        border: 'solid 1px rgba(0,0,0,.05)',
    },
    description: {
        fontSize: theme.typography.body2.fontSize,
        marginTop: 21,
    },
    programUrl: {
        textAlign: 'right',
        textDecoration: 'underline',
        color: '#0a7',
        margin: '14px 0',
        fontSize: theme.typography.body2.fontSize,
    },
    end: {
        fontSize: theme.typography.body2.fontSize,
        color: theme.palette.text.disabled,
    },
    overview: {
        background: '#fff',
        // border: 'solid 2px rgba(0,0,0,.03)',
        padding: '14px 0',
        marginBottom: 14,
    },
}));

export const ConsoleTemplateAdminProgramData: FC<{
    program: ProgramForAdmin;
}> = ({ program }) => {
    // STYLE
    const c = useStyles(useTheme());

    // DATA
    const endedDate = getProgramEndedDate({ program });
    const closedDate = getProgramClosedDate({ program });

    return (
        <>
            {program.eyecatchSrc && <img src={program.eyecatchSrc} alt={'eyecatch'} className={c.eyecatch} />}
            {closedDate ? (
                <MuiTypography className={c.end}>
                    {`${getDateStr({ date: getPrevDate2359({ date: closedDate }), isWithDay: true })}で終了(成果締切)`}
                </MuiTypography>
            ) : endedDate ? (
                <MuiTypography className={c.end}>
                    {`${getDateStr({ date: getPrevDate2359({ date: endedDate }), isWithDay: true })}で受付終了`}
                </MuiTypography>
            ) : (
                <></>
            )}
            <ProgramHeading program={program} />
            <MuiTypography className={c.description}>
                {program.description.replace(/\[cashbackAmount\]/g, '<CB金額>')}
            </MuiTypography>
            <div className={c.programUrl}>
                <a href={program.url} target={'_blank'} rel="noreferrer noopener">
                    {'遷移先を開く'}
                </a>
            </div>
            <DetailsItem
                label={'概要'}
                content={<ProgramOverview overviewMd={program.overviewMd} />}
                variant={'full'}
                direction={'column'}
                contentClassName={c.overview}
            />
            <DetailsItem
                label={'広告主プログラムID'}
                content={program.advertiserProgramId || 'なし'}
                variant={'full'}
                direction={'row'}
                isLightColor={!program.advertiserProgramId}
            />
            <DetailsItem
                label={'メディア単価'}
                content={`¥ ${program.mediaFund.toLocaleString()}`}
                variant={'full'}
                direction={'row'}
            />
            <DetailsItem
                label={'粗利益(税抜)'}
                content={`¥ ${(program.totalPrice.beforeTax - program.mediaFund).toLocaleString()}`}
                variant={'full'}
                direction={'row'}
            />
            <DetailsItem
                label={'終了日'}
                content={<EndSchedule program={program} campaign={undefined} />}
                variant={'full'}
                direction={'row'}
            />
            <DetailsItem
                label={'稼働状況'}
                content={
                    program.closure
                        ? `${getDateStr({ date: program.closure.endedAt, isWithDay: true })}で終了`
                        : '稼働中'
                }
                variant={'full'}
                direction={'row'}
                isLightColor={!program.closure}
            />
            {(program.cashbackAmountRange.low || program.cashbackAmountRange.high) && (
                <DetailsItem
                    label={'CB金額制限'}
                    content={(() => {
                        let str = '';
                        if (program.cashbackAmountRange.low) {
                            str += `${program.cashbackAmountRange.low.toLocaleString()}円以上`;
                        }
                        if (program.cashbackAmountRange.high) {
                            if (program.cashbackAmountRange.low) {
                                str += ' ';
                            }
                            str += `${program.cashbackAmountRange.high.toLocaleString()}円以下`;
                        }
                        return str;
                    })()}
                    variant={'full'}
                    direction={'row'}
                />
            )}
            {typeof program.condition.completeLimitDays === 'number' && (
                <DetailsItem
                    label={'成果期限'}
                    content={`応募から${program.condition.completeLimitDays}日以内。ただし、キャンペーンが終了した場合はその時点まで。`}
                    variant={'full'}
                    direction={'row'}
                />
            )}
            <DetailsItem
                label={'条件'}
                content={<ProgramConditionText condition={program.condition} />}
                variant={'full'}
                direction={'column'}
            />
            <DetailsItem
                label={'概要'}
                content={<ProgramOverview overviewMd={program.overviewMd} />}
                variant={'full'}
                direction={'column'}
            />
            <DetailsItem
                label={'メディア向け説明'}
                content={<ProgramDescriptionForMedia descriptionForMedia={program.descriptionForMedia} />}
                variant={'full'}
                direction={'column'}
            />
            <DetailsItem
                label={'trackingParamKey'}
                content={program.trackingParamKey}
                variant={'full'}
                direction={'row'}
            />
            <DetailsItem
                label={'メディアホワイトリスト(ID)'}
                content={getStrWithBr(
                    program.whitelistedMediaIds?.reduce((acc, prev, i) => {
                        if (i === 0) {
                            return prev;
                        }
                        return `${acc}\n${prev}`;
                    }, '') || 'なし'
                )}
                variant={'full'}
                direction={'row'}
            />
            <DetailsItem
                label={'ホワイトリスト外に表示'}
                content={program.isPublicVisible ? '表示' : '非表示'}
                variant={'full'}
                direction={'row'}
            />
        </>
    );
};
ConsoleTemplateAdminProgramData.displayName = 'ConsoleTemplateAdminProgramData';
