import { getGraphqlSdk } from '@/utils/MoneybackClient/graphql/graphqlSdk';
import { MoneybackAdminClient } from '@/utils/MoneybackClient/clients';
import { getMediaUserFromGql } from '@/utils/MoneybackClient/models';

export const initMoneybackAdminClient = async (input: { firebaseUserIdToken: string }) => {
    // ここではvalidationをしないので、前段で行う。
    const graphqlSdk = getGraphqlSdk(input);
    const { getMediaUser } = await graphqlSdk.getMediaUser().catch(({ message }) => {
        console.error('message->', message);
        throw new Error('initMoneybackAdminClient: invalid firebaseUserIdToken');
    });
    const { mediaUser } = getMediaUser;
    if (mediaUser) {
        return new MoneybackAdminClient({
            graphqlSdk,
            ...getMediaUserFromGql(mediaUser).data,
        });
    }
    throw new Error('initMoneybackAdminClient: No user account');
};
