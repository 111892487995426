import { useEffect, useState, useCallback } from 'react';

import { TicketForMedia } from '@/utils/MoneybackClient';
import { useMediaCtxAbsolutely } from '@/utils/ctxs';

export const useTicketForCampaigns = ({ programId, campaignId }: { programId: string; campaignId: string }) => {
    // HOOKS
    const { mediaClient } = useMediaCtxAbsolutely();
    const [tickets, setTickets] = useState<TicketForMedia[] | undefined>(undefined);

    // CALLBACK
    const initialize = useCallback(async () => {
        const fetchedTickets = await mediaClient.getTicketsForCampaign({ programId, campaignId });
        setTickets(fetchedTickets);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // USEEFFECT
    useEffect(() => {
        initialize();
    }, [initialize]);

    return {
        tickets,
    };
};
