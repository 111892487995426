import { FC, useState } from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { PopoverOrigin, PopoverPosition } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker as MuiDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import DateFnsUtils from '@date-io/date-fns';
import Button from '@/components/0_atom/Button';
import Popover from '@/components/0_atom/Popover';

const useStyles = makeStyles((theme) => ({
    clearButtonBox: {
        textAlign: 'right',
        padding: 7,
    },
    clearButton: {
        fontWeight: 600,
        fontSize: theme.typography.body2.fontSize,
    },
}));

const DatePicker: FC<{
    onClose: () => void;
    date?: Date;
    setDate: (date?: Date) => void;
    anchorEl?: Element;
    anchorOrigin?: PopoverOrigin;
    anchorPosition?: PopoverPosition;
    isEnablePast?: boolean;
    isDisableFuture?: boolean;
}> = ({ onClose, date, setDate, anchorEl, anchorOrigin, anchorPosition, isEnablePast, isDisableFuture }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const [selectedDate, setSelectedDate] = useState<MaterialUiPickersDate>(date || new Date());
    const handleChange = (date: MaterialUiPickersDate) => {
        setSelectedDate(date);
    };
    const handleAccept = (date: MaterialUiPickersDate) => {
        setDate(date || undefined);
        onClose();
    };

    return (
        <Popover
            isOpen={!!anchorEl}
            onClose={onClose}
            anchorEl={anchorEl}
            anchorOrigin={anchorOrigin}
            anchorPosition={anchorPosition}
        >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <MuiDatePicker
                    disableToolbar
                    disablePast={!isEnablePast}
                    disableFuture={isDisableFuture}
                    format="yyyy/dd/MM"
                    value={selectedDate}
                    onChange={handleChange}
                    onAccept={handleAccept}
                    inputVariant={'filled'}
                    variant={'static'}
                />
            </MuiPickersUtilsProvider>
            <div className={c.clearButtonBox}>
                {date && (
                    <Button
                        className={c.clearButton}
                        onClick={() => {
                            setDate(undefined);
                            onClose();
                        }}
                    >
                        {'選択をクリア'}
                    </Button>
                )}
            </div>
        </Popover>
    );
};
DatePicker.displayName = 'DatePicker';
export default DatePicker;
