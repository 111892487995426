import { FC, useState, useMemo } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { ProgramApiForAdmin } from '@/utils/MoneybackClient';
import { DetailsItem } from '@/components/0_atom/DetailsItem';
import { MarkdownInputWithLabel } from '@/components/1_mol/InputWithLabel';
import { ProgramOverview } from '@/components/1_mol/ProgramOverview';
import { ConsoleTemplateAdminProgramsAdminProgramCard } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramCard';
import { ModifyOverviewMdModalSubmitButton } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramModal/ModifyOverviewMdModal/SubmitButton';

const useStyles = makeStyles((theme) => ({
    root: {},
    input: {
        marginTop: 40,
        marginBottom: 14,
    },
}));

export const ModifyOverviewMdModalContent: FC<{
    programAPI: ProgramApiForAdmin;
    onClose: () => void;
}> = ({ programAPI, onClose }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const [newValue, setNewValue] = useState<string | undefined>(programAPI.overviewMd);

    // DATA
    const isOk = programAPI.overviewMd !== newValue;

    return (
        <>
            <ConsoleTemplateAdminProgramsAdminProgramCard
                programAPI={programAPI}
                enabledFields={{
                    advertiserProgramId: true,
                }}
            />
            <DetailsItem
                label={'これまでの概要'}
                content={<ProgramOverview overviewMd={programAPI.overviewMd} />}
                variant={'full'}
                direction={'column'}
            />
            <MarkdownInputWithLabel
                className={c.input}
                textMd={newValue || ''}
                setTextMd={(newText) => setNewValue(newText || undefined)}
                placeholder={'概要'}
                labelText={'概要'}
            />
            <ModifyOverviewMdModalSubmitButton
                programAPI={programAPI}
                newValue={newValue}
                isDisabled={!isOk}
                onComplete={onClose}
            />
        </>
    );
};
ModifyOverviewMdModalContent.displayName = 'ModifyOverviewMdModalContent';
