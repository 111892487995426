import { MoneybackObserverClient, MoneybackGeneralClient, MoneybackOwnerClient } from '@/utils/MoneybackClient';

export const getIsClientGeneralOrOwner = (
    client: MoneybackObserverClient | MoneybackGeneralClient | MoneybackOwnerClient
): client is MoneybackGeneralClient | MoneybackOwnerClient => {
    return client instanceof MoneybackGeneralClient || client instanceof MoneybackOwnerClient;
};

export const getIsClientOwner = (
    client: MoneybackObserverClient | MoneybackGeneralClient | MoneybackOwnerClient
): client is MoneybackOwnerClient => {
    return client instanceof MoneybackOwnerClient;
};
