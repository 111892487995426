import { FC } from 'react';

import { Price } from '@/utils/MoneybackClient';
import { Simple2ColumnsTable } from '@/components/0_atom/Simple2ColumnsTable';

export const MediaReward: FC<{
    mediaReward: Price | undefined;
}> = ({ mediaReward }) => {
    return (
        <Simple2ColumnsTable
            items={[
                ['本体', mediaReward ? `¥ ${mediaReward.beforeTax.toLocaleString()}` : '-'],
                ['消費税(10%)', mediaReward ? `¥ ${Math.floor(mediaReward.beforeTax * 0.1).toLocaleString()}` : '-'],
                ['税込', mediaReward ? `¥ ${mediaReward.total.toLocaleString()}` : '-'],
            ]}
        />
    );
};
MediaReward.displayName = 'MediaReward';
