import { FC } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import SvgBrand from '@/svgs/moneybackFulllogo';

const useStyles = makeStyles((theme) => ({
    root: {},
    svg: {},
    span: {
        color: theme.palette.text.disabled,
        fontWeight: 600,
        fontSize: theme.typography.caption.fontSize,
        display: 'block',
        textAlign: 'center',
    },
}));

export const Brand: FC<{
    className?: string;
}> = ({ className }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div className={clsx(c.root, className)}>
            <SvgBrand className={c.svg} />
            <span className={c.span}>{'メディア向けコンソール'}</span>
        </div>
    );
};
Brand.displayName = 'Brand';
