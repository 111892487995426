import { Price, DecimalRoundRule } from '@/utils/MoneybackClient';

export const getPrice = ({
    beforeTax,
    taxRate,
    decimalRoundRule,
}: {
    beforeTax: number;
    decimalRoundRule: DecimalRoundRule;
    taxRate: number;
}): Price =>
    new Price({
        beforeTax,
        taxRate,
        decimalRoundRule,
        total: (() => {
            const totalAmount = beforeTax * (1 + taxRate);
            if (decimalRoundRule === 'down') {
                return Math.floor(totalAmount);
            }
            if (decimalRoundRule === 'up') {
                return Math.ceil(totalAmount);
            }
            return Math.round(totalAmount);
        })(),
    });
