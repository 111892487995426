import {
    Campaign,
    CampaignForPublic,
    ProgramForMedia,
    ProgramForPublic,
    ProgramForAdmin,
    ProgramApiForAdmin,
} from '@/utils/MoneybackClient';

export const getCampaignEndReceptionDate = ({
    campaign,
    program,
}: {
    campaign: CampaignForPublic | Campaign;
    program: ProgramForPublic | ProgramForMedia | ProgramForAdmin | ProgramApiForAdmin;
}) => {
    const { endReceptionAt: campaignEndReceptionAt } = campaign;
    const { endReceptionAt: programEndReceptionAt, closeAt } = program;
    const dates = [campaignEndReceptionAt, programEndReceptionAt, closeAt].filter((date) => !!date) as Date[];
    if (dates.length > 0) {
        return dates.sort((a, b) => (a > b ? 1 : -1))[0];
    }
    return undefined;
};
