import { FC, ComponentProps } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { TextInput } from '@/components/0_atom/Input';

const useStyles = makeStyles((theme) => ({
    root: {},
    label: {
        fontWeight: 600,
        fontSize: theme.typography.body1.fontSize,
        display: 'block',
        marginBottom: 3,
    },
}));

export const TextInputWithLabel: FC<
    ComponentProps<typeof TextInput> & {
        labelText: string;
    }
> = ({ labelText, className, ...otherProps }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div className={className}>
            <span className={c.label}>{labelText}</span>
            <TextInput {...otherProps} fullWidth />
        </div>
    );
};
TextInputWithLabel.displayName = 'TextInputWithLabel';
