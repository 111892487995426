import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { LoadingIndicator } from '@/components/0_atom/LoadingIndicator';
import { Brand } from '@/components/0_atom/Brand';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateY(-50%) translateX(-50%)',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    brand: {
        width: 140,
    },
    loading: {
        padding: '40px 0 0 0',
    },
}));

export const LoadingTemplate: FC = () => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div className={c.root}>
            <Brand className={c.brand} />
            <LoadingIndicator className={c.loading} />
        </div>
    );
};
LoadingTemplate.displayName = 'LoadingTemplate';
