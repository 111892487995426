import { ProgramForPublic, ProgramForMedia, ProgramForAdmin, ProgramApiForAdmin } from '@/utils/MoneybackClient';

export const getProgramCloseDate = ({
    program,
}: {
    program: ProgramForPublic | ProgramForMedia | ProgramApiForAdmin | ProgramForAdmin | ProgramApiForAdmin;
}) => {
    const { closeAt, closure } = program;
    if (closeAt && closure) {
        return closeAt > closure.endedAt ? closure.endedAt : closeAt;
    }
    return closeAt || closure?.endedAt;
};
