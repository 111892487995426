import { MutationValidationResult } from '@/utils/MoneybackClient/graphql/generated/graphqlClient';
import { GraphQLSdk } from '@/utils/MoneybackClient/graphql/graphqlSdk';
import {
    Media,
    MediaType,
    BankAccountInputType,
    getMediaFromGql,
    getBankAccountFromGql,
} from '@/utils/MoneybackClient/models';
import { MoneybackGeneralClient } from '@/utils/MoneybackClient/clients/General';

type MoneybackOwnerClientType = {
    updateBankAccount: (input: { bankAccount: BankAccountInputType | undefined }) => Promise<{
        result: MutationValidationResult;
        media: Media;
    }>;
    // withdrawはまだ実装しない
    // getMediaMembersForMediaもまだ実装しない
};

type ConstructorInput = MediaType & {
    graphqlSdk: GraphQLSdk;
};

export class MoneybackOwnerClient extends MoneybackGeneralClient implements MoneybackOwnerClientType {
    protected _graphqlSdk: GraphQLSdk;

    constructor(input: ConstructorInput) {
        super(input);
        this._graphqlSdk = input.graphqlSdk;
    }

    async updateName({ name }: { name: string }) {
        const { updateMediaName } = await this._graphqlSdk.updateMediaName({
            input: {
                mediaId: this.id,
                oldValue: this.name,
                newValue: name,
                requiredInterval: 1,
            },
        });
        const { result, media } = updateMediaName;
        this._name = media.name;
        return {
            result,
            media: getMediaFromGql(media),
        };
    }

    async updateUrl({ url }: { url: string }) {
        const { updateMediaUrl } = await this._graphqlSdk.updateMediaUrl({
            input: {
                mediaId: this.id,
                oldValue: this.url,
                newValue: url,
                requiredInterval: 1,
            },
        });
        const { result, media } = updateMediaUrl;
        this._url = media.url;
        return {
            result,
            media: getMediaFromGql(media),
        };
    }

    async updateBankAccount({ bankAccount }: { bankAccount: BankAccountInputType | undefined }) {
        const { updateMediaBankAccount } = await this._graphqlSdk.updateMediaBankAccount({
            input: {
                mediaId: this.id,
                oldValue: this.bankAccount?.data,
                newValue: bankAccount,
                requiredInterval: 1,
            },
        });
        const { result, media } = updateMediaBankAccount;
        this._bankAccount = media.bankAccount ? getBankAccountFromGql(media.bankAccount) : undefined;
        return {
            result,
            media: getMediaFromGql(media),
        };
    }
}
