import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    DateTime: string;
    EmailAddress: string;
    LongText: string;
    MdText: string;
    PhoneNumber: string;
};

export type BankAccount = {
    accountHolderName: Scalars['String'];
    accountNumber: Scalars['String'];
    bankCode: Scalars['String'];
    branchCode: Scalars['String'];
};

export type BankAccountInput = {
    accountHolderName: Scalars['String'];
    accountNumber: Scalars['String'];
    bankCode: Scalars['String'];
    branchCode: Scalars['String'];
};

export type Campaign = {
    cashbackAmount: Scalars['Int'];
    createdAt: Scalars['DateTime'];
    endReceptionAt?: Maybe<Scalars['DateTime']>;
    id: Scalars['ID'];
    mediaReward: Price;
    updatedAt?: Maybe<Scalars['DateTime']>;
    uri: Scalars['ID'];
};

export type CampaignForPublic = {
    cashbackAmount: Scalars['Int'];
    createdAt: Scalars['DateTime'];
    endReceptionAt?: Maybe<Scalars['DateTime']>;
    id: Scalars['ID'];
    updatedAt?: Maybe<Scalars['DateTime']>;
    uri: Scalars['ID'];
};

export type CampaignWithProgramForMedia = {
    campaign: Campaign;
    program: ProgramForMedia;
};

export type CampaignWithProgramForPublic = {
    campaign: CampaignForPublic;
    program: ProgramForPublic;
};

export type CashbackAmountRange = {
    high?: Maybe<Scalars['Int']>;
    low?: Maybe<Scalars['Int']>;
};

export type CashbackAmountRangeInput = {
    high?: InputMaybe<Scalars['Int']>;
    low?: InputMaybe<Scalars['Int']>;
};

export type CloseProgramInput = {
    programId: Scalars['ID'];
    reason: Scalars['String'];
};

export type CloseProgramPayload = {
    program: Program;
    result: MutationValidationResult;
};

export type CreateCampaignInput = {
    cashbackAmount: Scalars['Int'];
    mediaId: Scalars['ID'];
    programId: Scalars['ID'];
};

export type CreateCampaignPayload = {
    campaign: Campaign;
};

export type CreateMediaInput = {
    bankAccount?: InputMaybe<BankAccountInput>;
    name: Scalars['String'];
    url: Scalars['String'];
};

export type CreateMediaPayload = {
    media: Media;
    mediaMember: MediaMember;
};

export type CreateMediaUserPayload = {
    mediaUser: MediaUser;
};

export type CreateProgramInput = {
    advertiserName: Scalars['String'];
    advertiserProgramId?: InputMaybe<Scalars['String']>;
    cashbackAmountRange: CashbackAmountRangeInput;
    closeAt?: InputMaybe<Scalars['DateTime']>;
    condition: ProgramConditionInput;
    description: Scalars['String'];
    descriptionForMedia?: InputMaybe<Scalars['LongText']>;
    endReceptionAt?: InputMaybe<Scalars['DateTime']>;
    isPublicVisible: Scalars['Boolean'];
    mediaFund: Scalars['Int'];
    overviewMd?: InputMaybe<Scalars['MdText']>;
    title: Scalars['String'];
    totalPrice: PriceInput;
    trackingParamKey: Scalars['String'];
    url: Scalars['String'];
    whitelistedMediaIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type CreateProgramPayload = {
    program: Program;
};

export type CreateTicketInput = {
    campaignId: Scalars['ID'];
    programId: Scalars['ID'];
    referer?: InputMaybe<Scalars['String']>;
    userAgent?: InputMaybe<Scalars['String']>;
};

export type CreateTicketPayload = {
    ticket: TicketForUser;
};

export type CreateUserInput = {
    bankAccount?: InputMaybe<BankAccountInput>;
};

export type CreateUserPayload = {
    user: User;
};

export type CreateWithdrawConfirmationInput = {
    amount: Scalars['Int'];
    bankAccount: BankAccountInput;
    mediaId: Scalars['ID'];
};

export type CreateWithdrawConfirmationPayload = {
    withdrawConfirmation: WithdrawConfirmation;
};

export type CreateWithdrawInput = {
    mediaId: Scalars['ID'];
    withdrawConfirmationId: Scalars['ID'];
};

export type CreateWithdrawPayload = {
    balance: MediaBalance;
};

export type DecimalRoundRule = 'down' | 'near' | 'up';

export type EndReceptionCampaignInput = {
    campaignId: Scalars['ID'];
    mediaId: Scalars['ID'];
    programId: Scalars['ID'];
};

export type EndReceptionCampaignPayload = {
    campaign: Campaign;
};

export type GetAllCampaignsPayload = {
    campaigns: Array<CampaignForPublic>;
};

export type GetCampaignForPublicInput = {
    campaignUri: Scalars['ID'];
};

export type GetCampaignForPublicPayload = {
    campaign: CampaignForPublic;
    media: MediaForPublic;
    program: ProgramForPublic;
};

export type GetCampaignsForMediaInput = {
    mediaId: Scalars['ID'];
};

export type GetCampaignsForMediaPayload = {
    campaigns: Array<CampaignWithProgramForMedia>;
};

export type GetMediaBalancesInput = {
    mediaId: Scalars['ID'];
};

export type GetMediaBalancesPayload = {
    balances: Array<MediaBalance>;
};

export type GetMediaInput = {
    mediaId: Scalars['ID'];
};

export type GetMediaMembersForMediaInput = {
    mediaId: Scalars['ID'];
};

export type GetMediaMembersForMediaPayload = {
    mediaMemberWithUsers: Array<MediaMemberWithUser>;
};

export type GetMediaMembersForMediaUserPayload = {
    mediaMemberWithMedias: Array<MediaMemberWithMedia>;
};

export type GetMediaPayload = {
    media: Media;
};

export type GetMediaUserPayload = {
    mediaUser?: Maybe<MediaUser>;
};

export type GetPrivacypolicyPayload = {
    privacypolicy: Scalars['LongText'];
};

export type GetProgramsForMediaInput = {
    mediaId: Scalars['ID'];
};

export type GetProgramsForMediaPayload = {
    programs: Array<ProgramForMedia>;
};

export type GetProgramsPayload = {
    programs: Array<Program>;
};

export type GetPublicCampaignsPayload = {
    campaigns: Array<CampaignWithProgramForPublic>;
};

export type GetRecentMediaBalanceInput = {
    mediaId: Scalars['ID'];
};

export type GetRecentMediaBalancePayload = {
    balance?: Maybe<MediaBalance>;
};

export type GetSignedUrlToUploadProgramAdvertiserIconInput = {
    extensionName: ImageExtension;
    programId: Scalars['ID'];
};

export type GetSignedUrlToUploadProgramAdvertiserIconPayload = {
    signedUrl: Scalars['String'];
};

export type GetSignedUrlToUploadProgramEyecatchInput = {
    extensionName: ImageExtension;
    programId: Scalars['ID'];
};

export type GetSignedUrlToUploadProgramEyecatchPayload = {
    signedUrl: Scalars['String'];
};

export type GetTicketForUserInput = {
    programId: Scalars['ID'];
};

export type GetTicketForUserPayload = {
    campaign?: Maybe<CampaignForPublic>;
    ticket?: Maybe<TicketForUser>;
};

export type GetTicketsForCampaignForMediaInput = {
    campaignId: Scalars['ID'];
    mediaId: Scalars['ID'];
    programId: Scalars['ID'];
};

export type GetTicketsForCampaignForMediaPayload = {
    tickets: Array<TicketForMedia>;
};

export type GetTicketsForUserPayload = {
    tickets: Array<TicketWithCampaignForUser>;
};

export type GetTicketsInput = {
    advertiserProgramId: Scalars['ID'];
    after?: InputMaybe<Scalars['DateTime']>;
    before?: InputMaybe<Scalars['DateTime']>;
};

export type GetTicketsPayload = {
    tickets: Array<TicketWithCampaign>;
};

export type GetTosForMediaPayload = {
    tosForMedia: Scalars['LongText'];
};

export type GetTosPayload = {
    tos: Scalars['LongText'];
};

export type GetUserInfoInput = {
    phone: Scalars['String'];
};

export type GetUserInfoPayload = {
    ticketWithCampaigns: Array<TicketWithCampaign>;
    user: User;
};

export type GetUserPayload = {
    user?: Maybe<User>;
};

export type ImageExtension = 'jpg' | 'png' | 'svg' | 'webp';

export type Media = {
    archivedAt?: Maybe<Scalars['DateTime']>;
    bankAccount?: Maybe<BankAccount>;
    id: Scalars['ID'];
    name: Scalars['String'];
    url: Scalars['String'];
};

export type MediaBalance = {
    amountIncrease: Scalars['Int'];
    createdAt: Scalars['DateTime'];
    currentAmount: Scalars['Int'];
    currentReserve: Scalars['Int'];
    eventType: MediaBalanceEventType;
    id: Scalars['ID'];
    reserveIncrease: Scalars['Int'];
    serialNumber: Scalars['Int'];
    ticket?: Maybe<TicketWithCampaignForMedia>;
    transfer?: Maybe<Transfer>;
};

export type MediaBalanceEventType =
    | 'other'
    | 'ticketApprove'
    | 'withdrawError'
    | 'withdrawFundsReturn'
    | 'withdrawIssue'
    | 'withdrawTry';

export type MediaForPublic = {
    archivedAt?: Maybe<Scalars['DateTime']>;
    id: Scalars['ID'];
    name: Scalars['String'];
    url: Scalars['String'];
};

export type MediaMember = {
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    role: MediaMemberRole;
    updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MediaMemberRole = 'general' | 'observer' | 'owner';

export type MediaMemberWithMedia = {
    media: Media;
    mediaMember: MediaMember;
};

export type MediaMemberWithUser = {
    mediaMember: MediaMember;
    mediaUser: MediaUser;
};

export type MediaUser = {
    createdAt: Scalars['DateTime'];
    email: Scalars['EmailAddress'];
    id: Scalars['ID'];
    updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ModifyProgramCloseAtInput = {
    newValue?: InputMaybe<Scalars['DateTime']>;
    oldValue?: InputMaybe<Scalars['DateTime']>;
    programId: Scalars['ID'];
    requiredInterval: Scalars['Int'];
};

export type ModifyProgramCloseAtPayload = {
    program: Program;
    result: MutationValidationResult;
};

export type ModifyProgramConditionCompleteLimitDaysInput = {
    newValue?: InputMaybe<Scalars['Int']>;
    oldValue?: InputMaybe<Scalars['Int']>;
    programId: Scalars['ID'];
    requiredInterval: Scalars['Int'];
};

export type ModifyProgramConditionCompleteLimitDaysPayload = {
    program: Program;
    result: MutationValidationResult;
};

export type ModifyProgramConditionPrecautionInput = {
    newValue?: InputMaybe<Scalars['LongText']>;
    oldValue?: InputMaybe<Scalars['LongText']>;
    programId: Scalars['ID'];
    requiredInterval: Scalars['Int'];
};

export type ModifyProgramConditionPrecautionPayload = {
    program: Program;
    result: MutationValidationResult;
};

export type ModifyProgramConditionToCompleteInput = {
    newValue?: InputMaybe<Scalars['LongText']>;
    oldValue?: InputMaybe<Scalars['LongText']>;
    programId: Scalars['ID'];
    requiredInterval: Scalars['Int'];
};

export type ModifyProgramConditionToCompletePayload = {
    program: Program;
    result: MutationValidationResult;
};

export type ModifyProgramConditionToRecognizeInput = {
    newValue: Scalars['LongText'];
    oldValue: Scalars['LongText'];
    programId: Scalars['ID'];
    requiredInterval: Scalars['Int'];
};

export type ModifyProgramConditionToRecognizePayload = {
    program: Program;
    result: MutationValidationResult;
};

export type ModifyProgramDescriptionForMediaInput = {
    newValue?: InputMaybe<Scalars['String']>;
    oldValue?: InputMaybe<Scalars['String']>;
    programId: Scalars['ID'];
    requiredInterval: Scalars['Int'];
};

export type ModifyProgramDescriptionForMediaPayload = {
    program: Program;
    result: MutationValidationResult;
};

export type ModifyProgramDescriptionInput = {
    newValue: Scalars['String'];
    oldValue: Scalars['String'];
    programId: Scalars['ID'];
    requiredInterval: Scalars['Int'];
};

export type ModifyProgramDescriptionPayload = {
    program: Program;
    result: MutationValidationResult;
};

export type ModifyProgramEndReceptionAtInput = {
    newValue?: InputMaybe<Scalars['DateTime']>;
    oldValue?: InputMaybe<Scalars['DateTime']>;
    programId: Scalars['ID'];
    requiredInterval: Scalars['Int'];
};

export type ModifyProgramEndReceptionAtPayload = {
    program: Program;
    result: MutationValidationResult;
};

export type ModifyProgramIsPublicVisibleInput = {
    newValue: Scalars['Boolean'];
    oldValue: Scalars['Boolean'];
    programId: Scalars['ID'];
    requiredInterval: Scalars['Int'];
};

export type ModifyProgramIsPublicVisiblePayload = {
    program: Program;
    result: MutationValidationResult;
};

export type ModifyProgramOverviewMdInput = {
    newValue?: InputMaybe<Scalars['MdText']>;
    oldValue?: InputMaybe<Scalars['MdText']>;
    programId: Scalars['ID'];
    requiredInterval: Scalars['Int'];
};

export type ModifyProgramOverviewMdPayload = {
    program: Program;
    result: MutationValidationResult;
};

export type ModifyProgramWhitelistedMediaIdsInput = {
    newValue?: InputMaybe<Array<Scalars['ID']>>;
    oldValue?: InputMaybe<Array<Scalars['ID']>>;
    programId: Scalars['ID'];
    requiredInterval: Scalars['Int'];
};

export type ModifyProgramWhitelistedMediaIdsPayload = {
    program: Program;
    result: MutationValidationResult;
};

export type Mutation = {
    closeProgram: CloseProgramPayload;
    createCampaign: CreateCampaignPayload;
    createMedia: CreateMediaPayload;
    createMediaUser: CreateMediaUserPayload;
    createProgram: CreateProgramPayload;
    createTicket: CreateTicketPayload;
    createUser: CreateUserPayload;
    createWithdraw: CreateWithdrawPayload;
    createWithdrawConfirmation: CreateWithdrawConfirmationPayload;
    endReceptionCampaign: EndReceptionCampaignPayload;
    modifyProgramCloseAt: ModifyProgramCloseAtPayload;
    modifyProgramConditionCompleteLimitDays: ModifyProgramConditionCompleteLimitDaysPayload;
    modifyProgramConditionPrecaution: ModifyProgramConditionPrecautionPayload;
    modifyProgramConditionToComplete: ModifyProgramConditionToCompletePayload;
    modifyProgramConditionToRecognize: ModifyProgramConditionToRecognizePayload;
    modifyProgramDescription: ModifyProgramDescriptionPayload;
    modifyProgramDescriptionForMedia: ModifyProgramDescriptionForMediaPayload;
    modifyProgramEndReceptionAt: ModifyProgramEndReceptionAtPayload;
    modifyProgramIsPublicVisible: ModifyProgramIsPublicVisiblePayload;
    modifyProgramOverviewMd: ModifyProgramOverviewMdPayload;
    modifyProgramWhitelistedMediaIds: ModifyProgramWhitelistedMediaIdsPayload;
    refreshCampaignUri: RefreshCampaignUriPayload;
    removeUser: RemoveUserPayload;
    updateMediaBankAccount: UpdateMediaBankAccountPayload;
    updateMediaName: UpdateMediaNamePayload;
    updateMediaUrl: UpdateMediaUrlPayload;
    updateUserBankAccount: UpdateUserBankAccountPayload;
};

export type MutationCloseProgramArgs = {
    input: CloseProgramInput;
};

export type MutationCreateCampaignArgs = {
    input: CreateCampaignInput;
};

export type MutationCreateMediaArgs = {
    input: CreateMediaInput;
};

export type MutationCreateProgramArgs = {
    input: CreateProgramInput;
};

export type MutationCreateTicketArgs = {
    input: CreateTicketInput;
};

export type MutationCreateUserArgs = {
    input: CreateUserInput;
};

export type MutationCreateWithdrawArgs = {
    input: CreateWithdrawInput;
};

export type MutationCreateWithdrawConfirmationArgs = {
    input: CreateWithdrawConfirmationInput;
};

export type MutationEndReceptionCampaignArgs = {
    input: EndReceptionCampaignInput;
};

export type MutationModifyProgramCloseAtArgs = {
    input: ModifyProgramCloseAtInput;
};

export type MutationModifyProgramConditionCompleteLimitDaysArgs = {
    input: ModifyProgramConditionCompleteLimitDaysInput;
};

export type MutationModifyProgramConditionPrecautionArgs = {
    input: ModifyProgramConditionPrecautionInput;
};

export type MutationModifyProgramConditionToCompleteArgs = {
    input: ModifyProgramConditionToCompleteInput;
};

export type MutationModifyProgramConditionToRecognizeArgs = {
    input: ModifyProgramConditionToRecognizeInput;
};

export type MutationModifyProgramDescriptionArgs = {
    input: ModifyProgramDescriptionInput;
};

export type MutationModifyProgramDescriptionForMediaArgs = {
    input: ModifyProgramDescriptionForMediaInput;
};

export type MutationModifyProgramEndReceptionAtArgs = {
    input: ModifyProgramEndReceptionAtInput;
};

export type MutationModifyProgramIsPublicVisibleArgs = {
    input: ModifyProgramIsPublicVisibleInput;
};

export type MutationModifyProgramOverviewMdArgs = {
    input: ModifyProgramOverviewMdInput;
};

export type MutationModifyProgramWhitelistedMediaIdsArgs = {
    input: ModifyProgramWhitelistedMediaIdsInput;
};

export type MutationRefreshCampaignUriArgs = {
    input: RefreshCampaignUriInput;
};

export type MutationUpdateMediaBankAccountArgs = {
    input: UpdateMediaBankAccountInput;
};

export type MutationUpdateMediaNameArgs = {
    input: UpdateMediaNameInput;
};

export type MutationUpdateMediaUrlArgs = {
    input: UpdateMediaUrlInput;
};

export type MutationUpdateUserBankAccountArgs = {
    input: UpdateUserBankAccountInput;
};

export type MutationValidationResult = 'incorrectOldValue' | 'intervalTooShort' | 'success';

export type Price = {
    beforeTax: Scalars['Int'];
    decimalRoundRule: DecimalRoundRule;
    taxRate: Scalars['Float'];
    total: Scalars['Int'];
};

export type PriceInput = {
    beforeTax: Scalars['Int'];
    decimalRoundRule: DecimalRoundRule;
    taxRate: Scalars['Float'];
};

export type Program = {
    advertiserName: Scalars['String'];
    advertiserProgramId?: Maybe<Scalars['String']>;
    cashbackAmountRange: CashbackAmountRange;
    closeAt?: Maybe<Scalars['DateTime']>;
    closure?: Maybe<ProgramClosure>;
    condition: ProgramCondition;
    createdAt: Scalars['DateTime'];
    description: Scalars['String'];
    descriptionForMedia?: Maybe<Scalars['LongText']>;
    endReceptionAt?: Maybe<Scalars['DateTime']>;
    id: Scalars['ID'];
    isPublicVisible: Scalars['Boolean'];
    mediaFund: Scalars['Int'];
    overviewMd?: Maybe<Scalars['MdText']>;
    title: Scalars['String'];
    totalPrice: Price;
    trackingParamKey: Scalars['String'];
    updatedAt?: Maybe<Scalars['DateTime']>;
    url: Scalars['String'];
    whitelistedMediaIds?: Maybe<Array<Scalars['ID']>>;
};

export type ProgramClosure = {
    endedAt: Scalars['DateTime'];
    reason: Scalars['String'];
};

export type ProgramCondition = {
    completeLimitDays?: Maybe<Scalars['Int']>;
    precaution?: Maybe<Scalars['LongText']>;
    toComplete?: Maybe<Scalars['LongText']>;
    toRecognize: Scalars['LongText'];
};

export type ProgramConditionInput = {
    completeLimitDays?: InputMaybe<Scalars['Int']>;
    precaution?: InputMaybe<Scalars['LongText']>;
    toComplete?: InputMaybe<Scalars['LongText']>;
    toRecognize: Scalars['LongText'];
};

export type ProgramForMedia = {
    advertiserName: Scalars['String'];
    cashbackAmountRange: CashbackAmountRange;
    closeAt?: Maybe<Scalars['DateTime']>;
    closure?: Maybe<ProgramClosure>;
    condition: ProgramCondition;
    createdAt: Scalars['DateTime'];
    description: Scalars['String'];
    descriptionForMedia?: Maybe<Scalars['LongText']>;
    endReceptionAt?: Maybe<Scalars['DateTime']>;
    id: Scalars['ID'];
    isAbleToCreateCampaign: Scalars['Boolean'];
    mediaFund: Scalars['Int'];
    overviewMd?: Maybe<Scalars['MdText']>;
    title: Scalars['String'];
    trackingParamKey: Scalars['String'];
    updatedAt?: Maybe<Scalars['DateTime']>;
    url: Scalars['String'];
};

export type ProgramForPublic = {
    advertiserName: Scalars['String'];
    closeAt?: Maybe<Scalars['DateTime']>;
    closure?: Maybe<ProgramClosure>;
    condition: ProgramCondition;
    createdAt: Scalars['DateTime'];
    description: Scalars['String'];
    endReceptionAt?: Maybe<Scalars['DateTime']>;
    id: Scalars['ID'];
    overviewMd?: Maybe<Scalars['MdText']>;
    title: Scalars['String'];
    trackingParamKey: Scalars['String'];
    updatedAt?: Maybe<Scalars['DateTime']>;
    url: Scalars['String'];
};

export type Query = {
    getAllCampaigns: GetAllCampaignsPayload;
    getCampaignForPublic?: Maybe<GetCampaignForPublicPayload>;
    getCampaignsForMedia: GetCampaignsForMediaPayload;
    getMedia: GetMediaPayload;
    getMediaBalances: GetMediaBalancesPayload;
    getMediaMembersForMedia: GetMediaMembersForMediaPayload;
    getMediaMembersForMediaUser: GetMediaMembersForMediaUserPayload;
    getMediaUser: GetMediaUserPayload;
    getPrivacypolicy: GetPrivacypolicyPayload;
    getPrograms: GetProgramsPayload;
    getProgramsForMedia: GetProgramsForMediaPayload;
    getPublicCampaigns: GetPublicCampaignsPayload;
    getRecentMediaBalance: GetRecentMediaBalancePayload;
    getServiceHealth: ServiceHealth;
    getSignedUrlToUploadProgramAdvertiserIcon: GetSignedUrlToUploadProgramAdvertiserIconPayload;
    getSignedUrlToUploadProgramEyecatch: GetSignedUrlToUploadProgramEyecatchPayload;
    getTicketForUser: GetTicketForUserPayload;
    getTickets: GetTicketsPayload;
    getTicketsForCampaignForMedia: GetTicketsForCampaignForMediaPayload;
    getTicketsForUser: GetTicketsForUserPayload;
    getTos: GetTosPayload;
    getTosForMedia: GetTosForMediaPayload;
    getUser: GetUserPayload;
    getUserInfo: GetUserInfoPayload;
    runAutoCron1day: Scalars['Boolean'];
    runManualCronForMediaReward: Scalars['Boolean'];
    runManualCronForNewCashback: Scalars['Boolean'];
    runManualCronForRetryingMediaReward: Scalars['Boolean'];
    runManualCronForSync: Scalars['Boolean'];
};

export type QueryGetCampaignForPublicArgs = {
    input: GetCampaignForPublicInput;
};

export type QueryGetCampaignsForMediaArgs = {
    input: GetCampaignsForMediaInput;
};

export type QueryGetMediaArgs = {
    input: GetMediaInput;
};

export type QueryGetMediaBalancesArgs = {
    input: GetMediaBalancesInput;
};

export type QueryGetMediaMembersForMediaArgs = {
    input: GetMediaMembersForMediaInput;
};

export type QueryGetProgramsForMediaArgs = {
    input: GetProgramsForMediaInput;
};

export type QueryGetRecentMediaBalanceArgs = {
    input: GetRecentMediaBalanceInput;
};

export type QueryGetSignedUrlToUploadProgramAdvertiserIconArgs = {
    input: GetSignedUrlToUploadProgramAdvertiserIconInput;
};

export type QueryGetSignedUrlToUploadProgramEyecatchArgs = {
    input: GetSignedUrlToUploadProgramEyecatchInput;
};

export type QueryGetTicketForUserArgs = {
    input: GetTicketForUserInput;
};

export type QueryGetTicketsArgs = {
    input: GetTicketsInput;
};

export type QueryGetTicketsForCampaignForMediaArgs = {
    input: GetTicketsForCampaignForMediaInput;
};

export type QueryGetUserInfoArgs = {
    input: GetUserInfoInput;
};

export type RefreshCampaignUriInput = {
    campaignId: Scalars['ID'];
    mediaId: Scalars['ID'];
    oldValue: Scalars['ID'];
    programId: Scalars['ID'];
    requiredInterval: Scalars['Int'];
};

export type RefreshCampaignUriPayload = {
    campaign: Campaign;
    result: MutationValidationResult;
};

export type RemittanceResult = {
    at: Scalars['DateTime'];
    code: RemittanceResultCode;
    fundsReturnedAt?: Maybe<Scalars['DateTime']>;
};

export type RemittanceResultCode =
    | 'bankError'
    | 'beneficiaryBankError'
    | 'fundsReturned'
    | 'inputError'
    | 'success'
    | 'uodkaError';

export type RemoveUserPayload = {
    result: MutationValidationResult;
    user: User;
};

export type ServiceHealth = {
    code: ServiceHealthCode;
    message?: Maybe<Scalars['String']>;
    url?: Maybe<Scalars['String']>;
};

export type ServiceHealthCode = 'ok' | 'outOfService';

export type Ticket = {
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    recognizedAt?: Maybe<Scalars['DateTime']>;
    referer?: Maybe<Scalars['String']>;
    settlement?: Maybe<TicketSettlement>;
    trackingCode: Scalars['ID'];
    transfer?: Maybe<Transfer>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    userAgent?: Maybe<Scalars['String']>;
};

export type TicketForMedia = {
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    recognizedAt?: Maybe<Scalars['DateTime']>;
    referer?: Maybe<Scalars['String']>;
    settlement?: Maybe<TicketSettlementForMedia>;
    trackingCode: Scalars['ID'];
    transfer?: Maybe<Transfer>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    userAgent?: Maybe<Scalars['String']>;
};

export type TicketForUser = {
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    recognizedAt?: Maybe<Scalars['DateTime']>;
    referer?: Maybe<Scalars['String']>;
    settlement?: Maybe<TicketSettlementForUser>;
    trackingCode: Scalars['ID'];
    transfer?: Maybe<Transfer>;
    updatedAt?: Maybe<Scalars['DateTime']>;
    userAgent?: Maybe<Scalars['String']>;
};

export type TicketJudgement = 'approve' | 'reducedApprove' | 'reject';

export type TicketSettlement = {
    at: Scalars['DateTime'];
    judgement: TicketJudgement;
    mediaReward: Price;
    totalPrice: Price;
};

export type TicketSettlementForMedia = {
    at: Scalars['DateTime'];
    judgement: TicketJudgement;
    mediaReward: Price;
};

export type TicketSettlementForUser = {
    at: Scalars['DateTime'];
    judgement: TicketJudgement;
};

export type TicketWithCampaign = {
    campaign: Campaign;
    media: Media;
    program: Program;
    ticket: Ticket;
};

export type TicketWithCampaignForMedia = {
    campaign: Campaign;
    program: ProgramForMedia;
    ticket: TicketForMedia;
};

export type TicketWithCampaignForUser = {
    campaign: CampaignForPublic;
    program: ProgramForPublic;
    ticket: TicketForUser;
};

export type Transfer = {
    amount: Scalars['Int'];
    bankAccount: BankAccount;
    createdAt: Scalars['DateTime'];
    feeOnIssuer: Price;
    id: Scalars['ID'];
    remitterNameKana: Scalars['String'];
    result?: Maybe<RemittanceResult>;
};

export type UpdateMediaBankAccountInput = {
    mediaId: Scalars['ID'];
    newValue?: InputMaybe<BankAccountInput>;
    oldValue?: InputMaybe<BankAccountInput>;
    requiredInterval: Scalars['Int'];
};

export type UpdateMediaBankAccountPayload = {
    media: Media;
    result: MutationValidationResult;
};

export type UpdateMediaNameInput = {
    mediaId: Scalars['ID'];
    newValue: Scalars['String'];
    oldValue: Scalars['String'];
    requiredInterval: Scalars['Int'];
};

export type UpdateMediaNamePayload = {
    media: Media;
    result: MutationValidationResult;
};

export type UpdateMediaUrlInput = {
    mediaId: Scalars['ID'];
    newValue: Scalars['String'];
    oldValue: Scalars['String'];
    requiredInterval: Scalars['Int'];
};

export type UpdateMediaUrlPayload = {
    media: Media;
    result: MutationValidationResult;
};

export type UpdateUserBankAccountInput = {
    newValue?: InputMaybe<BankAccountInput>;
    oldValue?: InputMaybe<BankAccountInput>;
    requiredInterval: Scalars['Int'];
};

export type UpdateUserBankAccountPayload = {
    result: MutationValidationResult;
    user: User;
};

export type User = {
    bankAccount?: Maybe<BankAccount>;
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    phone: Scalars['PhoneNumber'];
    removedAt?: Maybe<Scalars['DateTime']>;
    updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WithdrawConfirmation = {
    amount: Scalars['Int'];
    bankAccount: BankAccount;
    createdAt: Scalars['DateTime'];
    feeOnIssuer: Price;
    id: Scalars['ID'];
    remitterNameKana: Scalars['String'];
};

export type CreateUserMutationVariables = Exact<{
    input: CreateUserInput;
}>;

export type CreateUserMutation = {
    createUser: {
        user: {
            id: string;
            createdAt: string;
            updatedAt?: string | null;
            phone: string;
            removedAt?: string | null;
            bankAccount?: {
                bankCode: string;
                branchCode: string;
                accountNumber: string;
                accountHolderName: string;
            } | null;
        };
    };
};

export type CreateMediaUserMutationVariables = Exact<{ [key: string]: never }>;

export type CreateMediaUserMutation = {
    createMediaUser: { mediaUser: { id: string; createdAt: string; updatedAt?: string | null; email: string } };
};

export type CreateTicketMutationVariables = Exact<{
    input: CreateTicketInput;
}>;

export type CreateTicketMutation = {
    createTicket: {
        ticket: {
            id: string;
            createdAt: string;
            updatedAt?: string | null;
            trackingCode: string;
            recognizedAt?: string | null;
            userAgent?: string | null;
            referer?: string | null;
            settlement?: { at: string; judgement: TicketJudgement } | null;
            transfer?: {
                id: string;
                createdAt: string;
                amount: number;
                remitterNameKana: string;
                bankAccount: { bankCode: string; branchCode: string; accountNumber: string; accountHolderName: string };
                feeOnIssuer: { beforeTax: number; taxRate: number; decimalRoundRule: DecimalRoundRule; total: number };
                result?: { at: string; code: RemittanceResultCode; fundsReturnedAt?: string | null } | null;
            } | null;
        };
    };
};

export type RemoveUserMutationVariables = Exact<{ [key: string]: never }>;

export type RemoveUserMutation = {
    removeUser: {
        result: MutationValidationResult;
        user: {
            id: string;
            createdAt: string;
            updatedAt?: string | null;
            phone: string;
            removedAt?: string | null;
            bankAccount?: {
                bankCode: string;
                branchCode: string;
                accountNumber: string;
                accountHolderName: string;
            } | null;
        };
    };
};

export type UpdateUserBankAccountMutationVariables = Exact<{
    input: UpdateUserBankAccountInput;
}>;

export type UpdateUserBankAccountMutation = {
    updateUserBankAccount: {
        result: MutationValidationResult;
        user: {
            id: string;
            createdAt: string;
            updatedAt?: string | null;
            phone: string;
            removedAt?: string | null;
            bankAccount?: {
                bankCode: string;
                branchCode: string;
                accountNumber: string;
                accountHolderName: string;
            } | null;
        };
    };
};

export type CreateMediaMutationVariables = Exact<{
    input: CreateMediaInput;
}>;

export type CreateMediaMutation = {
    createMedia: {
        media: {
            id: string;
            name: string;
            url: string;
            archivedAt?: string | null;
            bankAccount?: {
                bankCode: string;
                branchCode: string;
                accountNumber: string;
                accountHolderName: string;
            } | null;
        };
        mediaMember: { id: string; createdAt: string; updatedAt?: string | null; role: MediaMemberRole };
    };
};

export type CreateCampaignMutationVariables = Exact<{
    input: CreateCampaignInput;
}>;

export type CreateCampaignMutation = {
    createCampaign: {
        campaign: {
            id: string;
            createdAt: string;
            updatedAt?: string | null;
            cashbackAmount: number;
            uri: string;
            endReceptionAt?: string | null;
            mediaReward: { beforeTax: number; taxRate: number; decimalRoundRule: DecimalRoundRule; total: number };
        };
    };
};

export type EndReceptionCampaignMutationVariables = Exact<{
    input: EndReceptionCampaignInput;
}>;

export type EndReceptionCampaignMutation = {
    endReceptionCampaign: {
        campaign: {
            id: string;
            createdAt: string;
            updatedAt?: string | null;
            cashbackAmount: number;
            uri: string;
            endReceptionAt?: string | null;
            mediaReward: { beforeTax: number; taxRate: number; decimalRoundRule: DecimalRoundRule; total: number };
        };
    };
};

export type RefreshCampaignUriMutationVariables = Exact<{
    input: RefreshCampaignUriInput;
}>;

export type RefreshCampaignUriMutation = {
    refreshCampaignUri: {
        result: MutationValidationResult;
        campaign: {
            id: string;
            createdAt: string;
            updatedAt?: string | null;
            cashbackAmount: number;
            uri: string;
            endReceptionAt?: string | null;
            mediaReward: { beforeTax: number; taxRate: number; decimalRoundRule: DecimalRoundRule; total: number };
        };
    };
};

export type CreateWithdrawMutationVariables = Exact<{
    input: CreateWithdrawInput;
}>;

export type CreateWithdrawMutation = {
    createWithdraw: {
        balance: {
            id: string;
            createdAt: string;
            serialNumber: number;
            eventType: MediaBalanceEventType;
            currentAmount: number;
            currentReserve: number;
            amountIncrease: number;
            reserveIncrease: number;
            ticket?: {
                ticket: {
                    id: string;
                    createdAt: string;
                    updatedAt?: string | null;
                    trackingCode: string;
                    recognizedAt?: string | null;
                    settlement?: {
                        at: string;
                        judgement: TicketJudgement;
                        mediaReward: {
                            beforeTax: number;
                            taxRate: number;
                            decimalRoundRule: DecimalRoundRule;
                            total: number;
                        };
                    } | null;
                    transfer?: {
                        id: string;
                        createdAt: string;
                        amount: number;
                        remitterNameKana: string;
                        bankAccount: {
                            bankCode: string;
                            branchCode: string;
                            accountNumber: string;
                            accountHolderName: string;
                        };
                        feeOnIssuer: {
                            beforeTax: number;
                            taxRate: number;
                            decimalRoundRule: DecimalRoundRule;
                            total: number;
                        };
                        result?: { at: string; code: RemittanceResultCode; fundsReturnedAt?: string | null } | null;
                    } | null;
                };
                campaign: {
                    id: string;
                    createdAt: string;
                    updatedAt?: string | null;
                    cashbackAmount: number;
                    uri: string;
                    endReceptionAt?: string | null;
                    mediaReward: {
                        beforeTax: number;
                        taxRate: number;
                        decimalRoundRule: DecimalRoundRule;
                        total: number;
                    };
                };
                program: {
                    id: string;
                    createdAt: string;
                    updatedAt?: string | null;
                    mediaFund: number;
                    title: string;
                    description: string;
                    descriptionForMedia?: string | null;
                    overviewMd?: string | null;
                    advertiserName: string;
                    url: string;
                    endReceptionAt?: string | null;
                    trackingParamKey: string;
                    isAbleToCreateCampaign: boolean;
                    cashbackAmountRange: { low?: number | null; high?: number | null };
                    closure?: { endedAt: string; reason: string } | null;
                    condition: {
                        toRecognize: string;
                        toComplete?: string | null;
                        precaution?: string | null;
                        completeLimitDays?: number | null;
                    };
                };
            } | null;
            transfer?: {
                id: string;
                createdAt: string;
                amount: number;
                remitterNameKana: string;
                bankAccount: { bankCode: string; branchCode: string; accountNumber: string; accountHolderName: string };
                feeOnIssuer: { beforeTax: number; taxRate: number; decimalRoundRule: DecimalRoundRule; total: number };
                result?: { at: string; code: RemittanceResultCode; fundsReturnedAt?: string | null } | null;
            } | null;
        };
    };
};

export type CreateWithdrawConfirmationMutationVariables = Exact<{
    input: CreateWithdrawConfirmationInput;
}>;

export type CreateWithdrawConfirmationMutation = {
    createWithdrawConfirmation: {
        withdrawConfirmation: {
            id: string;
            createdAt: string;
            amount: number;
            remitterNameKana: string;
            bankAccount: { bankCode: string; branchCode: string; accountNumber: string; accountHolderName: string };
            feeOnIssuer: { beforeTax: number; taxRate: number; decimalRoundRule: DecimalRoundRule; total: number };
        };
    };
};

export type UpdateMediaBankAccountMutationVariables = Exact<{
    input: UpdateMediaBankAccountInput;
}>;

export type UpdateMediaBankAccountMutation = {
    updateMediaBankAccount: {
        result: MutationValidationResult;
        media: {
            id: string;
            name: string;
            url: string;
            archivedAt?: string | null;
            bankAccount?: {
                bankCode: string;
                branchCode: string;
                accountNumber: string;
                accountHolderName: string;
            } | null;
        };
    };
};

export type UpdateMediaNameMutationVariables = Exact<{
    input: UpdateMediaNameInput;
}>;

export type UpdateMediaNameMutation = {
    updateMediaName: {
        result: MutationValidationResult;
        media: {
            id: string;
            name: string;
            url: string;
            archivedAt?: string | null;
            bankAccount?: {
                bankCode: string;
                branchCode: string;
                accountNumber: string;
                accountHolderName: string;
            } | null;
        };
    };
};

export type UpdateMediaUrlMutationVariables = Exact<{
    input: UpdateMediaUrlInput;
}>;

export type UpdateMediaUrlMutation = {
    updateMediaUrl: {
        result: MutationValidationResult;
        media: {
            id: string;
            name: string;
            url: string;
            archivedAt?: string | null;
            bankAccount?: {
                bankCode: string;
                branchCode: string;
                accountNumber: string;
                accountHolderName: string;
            } | null;
        };
    };
};

export type CloseProgramMutationVariables = Exact<{
    input: CloseProgramInput;
}>;

export type CloseProgramMutation = {
    closeProgram: {
        result: MutationValidationResult;
        program: {
            id: string;
            createdAt: string;
            updatedAt?: string | null;
            mediaFund: number;
            title: string;
            description: string;
            descriptionForMedia?: string | null;
            overviewMd?: string | null;
            advertiserName: string;
            url: string;
            endReceptionAt?: string | null;
            closeAt?: string | null;
            trackingParamKey: string;
            advertiserProgramId?: string | null;
            whitelistedMediaIds?: Array<string> | null;
            isPublicVisible: boolean;
            cashbackAmountRange: { low?: number | null; high?: number | null };
            closure?: { endedAt: string; reason: string } | null;
            condition: {
                toRecognize: string;
                toComplete?: string | null;
                precaution?: string | null;
                completeLimitDays?: number | null;
            };
            totalPrice: { beforeTax: number; taxRate: number; decimalRoundRule: DecimalRoundRule; total: number };
        };
    };
};

export type CreateProgramMutationVariables = Exact<{
    input: CreateProgramInput;
}>;

export type CreateProgramMutation = {
    createProgram: {
        program: {
            id: string;
            createdAt: string;
            updatedAt?: string | null;
            mediaFund: number;
            title: string;
            description: string;
            descriptionForMedia?: string | null;
            overviewMd?: string | null;
            advertiserName: string;
            url: string;
            endReceptionAt?: string | null;
            closeAt?: string | null;
            trackingParamKey: string;
            advertiserProgramId?: string | null;
            whitelistedMediaIds?: Array<string> | null;
            isPublicVisible: boolean;
            cashbackAmountRange: { low?: number | null; high?: number | null };
            closure?: { endedAt: string; reason: string } | null;
            condition: {
                toRecognize: string;
                toComplete?: string | null;
                precaution?: string | null;
                completeLimitDays?: number | null;
            };
            totalPrice: { beforeTax: number; taxRate: number; decimalRoundRule: DecimalRoundRule; total: number };
        };
    };
};

export type ModifyProgramCloseAtMutationVariables = Exact<{
    input: ModifyProgramCloseAtInput;
}>;

export type ModifyProgramCloseAtMutation = {
    modifyProgramCloseAt: {
        result: MutationValidationResult;
        program: {
            id: string;
            createdAt: string;
            updatedAt?: string | null;
            mediaFund: number;
            title: string;
            description: string;
            descriptionForMedia?: string | null;
            overviewMd?: string | null;
            advertiserName: string;
            url: string;
            endReceptionAt?: string | null;
            closeAt?: string | null;
            trackingParamKey: string;
            advertiserProgramId?: string | null;
            whitelistedMediaIds?: Array<string> | null;
            isPublicVisible: boolean;
            cashbackAmountRange: { low?: number | null; high?: number | null };
            closure?: { endedAt: string; reason: string } | null;
            condition: {
                toRecognize: string;
                toComplete?: string | null;
                precaution?: string | null;
                completeLimitDays?: number | null;
            };
            totalPrice: { beforeTax: number; taxRate: number; decimalRoundRule: DecimalRoundRule; total: number };
        };
    };
};

export type ModifyProgramConditionCompleteLimitDaysMutationVariables = Exact<{
    input: ModifyProgramConditionCompleteLimitDaysInput;
}>;

export type ModifyProgramConditionCompleteLimitDaysMutation = {
    modifyProgramConditionCompleteLimitDays: {
        result: MutationValidationResult;
        program: {
            id: string;
            createdAt: string;
            updatedAt?: string | null;
            mediaFund: number;
            title: string;
            description: string;
            descriptionForMedia?: string | null;
            overviewMd?: string | null;
            advertiserName: string;
            url: string;
            endReceptionAt?: string | null;
            closeAt?: string | null;
            trackingParamKey: string;
            advertiserProgramId?: string | null;
            whitelistedMediaIds?: Array<string> | null;
            isPublicVisible: boolean;
            cashbackAmountRange: { low?: number | null; high?: number | null };
            closure?: { endedAt: string; reason: string } | null;
            condition: {
                toRecognize: string;
                toComplete?: string | null;
                precaution?: string | null;
                completeLimitDays?: number | null;
            };
            totalPrice: { beforeTax: number; taxRate: number; decimalRoundRule: DecimalRoundRule; total: number };
        };
    };
};

export type ModifyProgramConditionPrecautionMutationVariables = Exact<{
    input: ModifyProgramConditionPrecautionInput;
}>;

export type ModifyProgramConditionPrecautionMutation = {
    modifyProgramConditionPrecaution: {
        result: MutationValidationResult;
        program: {
            id: string;
            createdAt: string;
            updatedAt?: string | null;
            mediaFund: number;
            title: string;
            description: string;
            descriptionForMedia?: string | null;
            overviewMd?: string | null;
            advertiserName: string;
            url: string;
            endReceptionAt?: string | null;
            closeAt?: string | null;
            trackingParamKey: string;
            advertiserProgramId?: string | null;
            whitelistedMediaIds?: Array<string> | null;
            isPublicVisible: boolean;
            cashbackAmountRange: { low?: number | null; high?: number | null };
            closure?: { endedAt: string; reason: string } | null;
            condition: {
                toRecognize: string;
                toComplete?: string | null;
                precaution?: string | null;
                completeLimitDays?: number | null;
            };
            totalPrice: { beforeTax: number; taxRate: number; decimalRoundRule: DecimalRoundRule; total: number };
        };
    };
};

export type ModifyProgramConditionToCompleteMutationVariables = Exact<{
    input: ModifyProgramConditionToCompleteInput;
}>;

export type ModifyProgramConditionToCompleteMutation = {
    modifyProgramConditionToComplete: {
        result: MutationValidationResult;
        program: {
            id: string;
            createdAt: string;
            updatedAt?: string | null;
            mediaFund: number;
            title: string;
            description: string;
            descriptionForMedia?: string | null;
            overviewMd?: string | null;
            advertiserName: string;
            url: string;
            endReceptionAt?: string | null;
            closeAt?: string | null;
            trackingParamKey: string;
            advertiserProgramId?: string | null;
            whitelistedMediaIds?: Array<string> | null;
            isPublicVisible: boolean;
            cashbackAmountRange: { low?: number | null; high?: number | null };
            closure?: { endedAt: string; reason: string } | null;
            condition: {
                toRecognize: string;
                toComplete?: string | null;
                precaution?: string | null;
                completeLimitDays?: number | null;
            };
            totalPrice: { beforeTax: number; taxRate: number; decimalRoundRule: DecimalRoundRule; total: number };
        };
    };
};

export type ModifyProgramConditionToRecognizeMutationVariables = Exact<{
    input: ModifyProgramConditionToRecognizeInput;
}>;

export type ModifyProgramConditionToRecognizeMutation = {
    modifyProgramConditionToRecognize: {
        result: MutationValidationResult;
        program: {
            id: string;
            createdAt: string;
            updatedAt?: string | null;
            mediaFund: number;
            title: string;
            description: string;
            descriptionForMedia?: string | null;
            overviewMd?: string | null;
            advertiserName: string;
            url: string;
            endReceptionAt?: string | null;
            closeAt?: string | null;
            trackingParamKey: string;
            advertiserProgramId?: string | null;
            whitelistedMediaIds?: Array<string> | null;
            isPublicVisible: boolean;
            cashbackAmountRange: { low?: number | null; high?: number | null };
            closure?: { endedAt: string; reason: string } | null;
            condition: {
                toRecognize: string;
                toComplete?: string | null;
                precaution?: string | null;
                completeLimitDays?: number | null;
            };
            totalPrice: { beforeTax: number; taxRate: number; decimalRoundRule: DecimalRoundRule; total: number };
        };
    };
};

export type ModifyProgramDescriptionMutationVariables = Exact<{
    input: ModifyProgramDescriptionInput;
}>;

export type ModifyProgramDescriptionMutation = {
    modifyProgramDescription: {
        result: MutationValidationResult;
        program: {
            id: string;
            createdAt: string;
            updatedAt?: string | null;
            mediaFund: number;
            title: string;
            description: string;
            descriptionForMedia?: string | null;
            overviewMd?: string | null;
            advertiserName: string;
            url: string;
            endReceptionAt?: string | null;
            closeAt?: string | null;
            trackingParamKey: string;
            advertiserProgramId?: string | null;
            whitelistedMediaIds?: Array<string> | null;
            isPublicVisible: boolean;
            cashbackAmountRange: { low?: number | null; high?: number | null };
            closure?: { endedAt: string; reason: string } | null;
            condition: {
                toRecognize: string;
                toComplete?: string | null;
                precaution?: string | null;
                completeLimitDays?: number | null;
            };
            totalPrice: { beforeTax: number; taxRate: number; decimalRoundRule: DecimalRoundRule; total: number };
        };
    };
};

export type ModifyProgramDescriptionForMediaMutationVariables = Exact<{
    input: ModifyProgramDescriptionForMediaInput;
}>;

export type ModifyProgramDescriptionForMediaMutation = {
    modifyProgramDescriptionForMedia: {
        result: MutationValidationResult;
        program: {
            id: string;
            createdAt: string;
            updatedAt?: string | null;
            mediaFund: number;
            title: string;
            description: string;
            descriptionForMedia?: string | null;
            overviewMd?: string | null;
            advertiserName: string;
            url: string;
            endReceptionAt?: string | null;
            closeAt?: string | null;
            trackingParamKey: string;
            advertiserProgramId?: string | null;
            whitelistedMediaIds?: Array<string> | null;
            isPublicVisible: boolean;
            cashbackAmountRange: { low?: number | null; high?: number | null };
            closure?: { endedAt: string; reason: string } | null;
            condition: {
                toRecognize: string;
                toComplete?: string | null;
                precaution?: string | null;
                completeLimitDays?: number | null;
            };
            totalPrice: { beforeTax: number; taxRate: number; decimalRoundRule: DecimalRoundRule; total: number };
        };
    };
};

export type ModifyProgramEndReceptionAtMutationVariables = Exact<{
    input: ModifyProgramEndReceptionAtInput;
}>;

export type ModifyProgramEndReceptionAtMutation = {
    modifyProgramEndReceptionAt: {
        result: MutationValidationResult;
        program: {
            id: string;
            createdAt: string;
            updatedAt?: string | null;
            mediaFund: number;
            title: string;
            description: string;
            descriptionForMedia?: string | null;
            overviewMd?: string | null;
            advertiserName: string;
            url: string;
            endReceptionAt?: string | null;
            closeAt?: string | null;
            trackingParamKey: string;
            advertiserProgramId?: string | null;
            whitelistedMediaIds?: Array<string> | null;
            isPublicVisible: boolean;
            cashbackAmountRange: { low?: number | null; high?: number | null };
            closure?: { endedAt: string; reason: string } | null;
            condition: {
                toRecognize: string;
                toComplete?: string | null;
                precaution?: string | null;
                completeLimitDays?: number | null;
            };
            totalPrice: { beforeTax: number; taxRate: number; decimalRoundRule: DecimalRoundRule; total: number };
        };
    };
};

export type ModifyProgramIsPublicVisibleMutationVariables = Exact<{
    input: ModifyProgramIsPublicVisibleInput;
}>;

export type ModifyProgramIsPublicVisibleMutation = {
    modifyProgramIsPublicVisible: {
        result: MutationValidationResult;
        program: {
            id: string;
            createdAt: string;
            updatedAt?: string | null;
            mediaFund: number;
            title: string;
            description: string;
            descriptionForMedia?: string | null;
            overviewMd?: string | null;
            advertiserName: string;
            url: string;
            endReceptionAt?: string | null;
            closeAt?: string | null;
            trackingParamKey: string;
            advertiserProgramId?: string | null;
            whitelistedMediaIds?: Array<string> | null;
            isPublicVisible: boolean;
            cashbackAmountRange: { low?: number | null; high?: number | null };
            closure?: { endedAt: string; reason: string } | null;
            condition: {
                toRecognize: string;
                toComplete?: string | null;
                precaution?: string | null;
                completeLimitDays?: number | null;
            };
            totalPrice: { beforeTax: number; taxRate: number; decimalRoundRule: DecimalRoundRule; total: number };
        };
    };
};

export type ModifyProgramOverviewMdMutationVariables = Exact<{
    input: ModifyProgramOverviewMdInput;
}>;

export type ModifyProgramOverviewMdMutation = {
    modifyProgramOverviewMd: {
        result: MutationValidationResult;
        program: {
            id: string;
            createdAt: string;
            updatedAt?: string | null;
            mediaFund: number;
            title: string;
            description: string;
            descriptionForMedia?: string | null;
            overviewMd?: string | null;
            advertiserName: string;
            url: string;
            endReceptionAt?: string | null;
            closeAt?: string | null;
            trackingParamKey: string;
            advertiserProgramId?: string | null;
            whitelistedMediaIds?: Array<string> | null;
            isPublicVisible: boolean;
            cashbackAmountRange: { low?: number | null; high?: number | null };
            closure?: { endedAt: string; reason: string } | null;
            condition: {
                toRecognize: string;
                toComplete?: string | null;
                precaution?: string | null;
                completeLimitDays?: number | null;
            };
            totalPrice: { beforeTax: number; taxRate: number; decimalRoundRule: DecimalRoundRule; total: number };
        };
    };
};

export type ModifyProgramWhitelistedMediaIdsMutationVariables = Exact<{
    input: ModifyProgramWhitelistedMediaIdsInput;
}>;

export type ModifyProgramWhitelistedMediaIdsMutation = {
    modifyProgramWhitelistedMediaIds: {
        result: MutationValidationResult;
        program: {
            id: string;
            createdAt: string;
            updatedAt?: string | null;
            mediaFund: number;
            title: string;
            description: string;
            descriptionForMedia?: string | null;
            overviewMd?: string | null;
            advertiserName: string;
            url: string;
            endReceptionAt?: string | null;
            closeAt?: string | null;
            trackingParamKey: string;
            advertiserProgramId?: string | null;
            whitelistedMediaIds?: Array<string> | null;
            isPublicVisible: boolean;
            cashbackAmountRange: { low?: number | null; high?: number | null };
            closure?: { endedAt: string; reason: string } | null;
            condition: {
                toRecognize: string;
                toComplete?: string | null;
                precaution?: string | null;
                completeLimitDays?: number | null;
            };
            totalPrice: { beforeTax: number; taxRate: number; decimalRoundRule: DecimalRoundRule; total: number };
        };
    };
};

export type RunAutoCron1dayQueryVariables = Exact<{ [key: string]: never }>;

export type RunAutoCron1dayQuery = { runAutoCron1day: boolean };

export type RunManualCronForMediaRewardQueryVariables = Exact<{ [key: string]: never }>;

export type RunManualCronForMediaRewardQuery = { runManualCronForMediaReward: boolean };

export type RunManualCronForNewCashbackQueryVariables = Exact<{ [key: string]: never }>;

export type RunManualCronForNewCashbackQuery = { runManualCronForNewCashback: boolean };

export type RunManualCronForRetryingMediaRewardQueryVariables = Exact<{ [key: string]: never }>;

export type RunManualCronForRetryingMediaRewardQuery = { runManualCronForRetryingMediaReward: boolean };

export type RunManualCronForSyncQueryVariables = Exact<{ [key: string]: never }>;

export type RunManualCronForSyncQuery = { runManualCronForSync: boolean };

export type GetAllCampaignsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllCampaignsQuery = {
    getAllCampaigns: {
        campaigns: Array<{
            id: string;
            createdAt: string;
            updatedAt?: string | null;
            cashbackAmount: number;
            uri: string;
            endReceptionAt?: string | null;
        }>;
    };
};

export type GetCampaignForPublicQueryVariables = Exact<{
    input: GetCampaignForPublicInput;
}>;

export type GetCampaignForPublicQuery = {
    getCampaignForPublic?: {
        campaign: {
            id: string;
            createdAt: string;
            updatedAt?: string | null;
            cashbackAmount: number;
            uri: string;
            endReceptionAt?: string | null;
        };
        program: {
            id: string;
            createdAt: string;
            updatedAt?: string | null;
            title: string;
            description: string;
            overviewMd?: string | null;
            advertiserName: string;
            url: string;
            endReceptionAt?: string | null;
            closeAt?: string | null;
            trackingParamKey: string;
            closure?: { endedAt: string; reason: string } | null;
            condition: {
                toRecognize: string;
                toComplete?: string | null;
                precaution?: string | null;
                completeLimitDays?: number | null;
            };
        };
        media: { id: string; name: string; url: string; archivedAt?: string | null };
    } | null;
};

export type GetPrivacypolicyQueryVariables = Exact<{ [key: string]: never }>;

export type GetPrivacypolicyQuery = { getPrivacypolicy: { privacypolicy: string } };

export type GetPublicCampaignsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPublicCampaignsQuery = {
    getPublicCampaigns: {
        campaigns: Array<{
            campaign: {
                id: string;
                createdAt: string;
                updatedAt?: string | null;
                cashbackAmount: number;
                uri: string;
                endReceptionAt?: string | null;
            };
            program: {
                id: string;
                createdAt: string;
                updatedAt?: string | null;
                title: string;
                description: string;
                overviewMd?: string | null;
                advertiserName: string;
                url: string;
                endReceptionAt?: string | null;
                closeAt?: string | null;
                trackingParamKey: string;
                closure?: { endedAt: string; reason: string } | null;
                condition: {
                    toRecognize: string;
                    toComplete?: string | null;
                    precaution?: string | null;
                    completeLimitDays?: number | null;
                };
            };
        }>;
    };
};

export type GetServiceHealthQueryVariables = Exact<{ [key: string]: never }>;

export type GetServiceHealthQuery = {
    getServiceHealth: { code: ServiceHealthCode; message?: string | null; url?: string | null };
};

export type GetTosQueryVariables = Exact<{ [key: string]: never }>;

export type GetTosQuery = { getTos: { tos: string } };

export type GetTosForMediaQueryVariables = Exact<{ [key: string]: never }>;

export type GetTosForMediaQuery = { getTosForMedia: { tosForMedia: string } };

export type GetUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserQuery = {
    getUser: {
        user?: {
            id: string;
            createdAt: string;
            updatedAt?: string | null;
            phone: string;
            removedAt?: string | null;
            bankAccount?: {
                bankCode: string;
                branchCode: string;
                accountNumber: string;
                accountHolderName: string;
            } | null;
        } | null;
    };
};

export type GetMediaUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetMediaUserQuery = {
    getMediaUser: { mediaUser?: { id: string; createdAt: string; updatedAt?: string | null; email: string } | null };
};

export type GetTicketForUserQueryVariables = Exact<{
    input: GetTicketForUserInput;
}>;

export type GetTicketForUserQuery = {
    getTicketForUser: {
        ticket?: {
            id: string;
            createdAt: string;
            updatedAt?: string | null;
            trackingCode: string;
            recognizedAt?: string | null;
            userAgent?: string | null;
            referer?: string | null;
            settlement?: { at: string; judgement: TicketJudgement } | null;
            transfer?: {
                id: string;
                createdAt: string;
                amount: number;
                remitterNameKana: string;
                bankAccount: { bankCode: string; branchCode: string; accountNumber: string; accountHolderName: string };
                feeOnIssuer: { beforeTax: number; taxRate: number; decimalRoundRule: DecimalRoundRule; total: number };
                result?: { at: string; code: RemittanceResultCode; fundsReturnedAt?: string | null } | null;
            } | null;
        } | null;
        campaign?: {
            id: string;
            createdAt: string;
            updatedAt?: string | null;
            cashbackAmount: number;
            uri: string;
            endReceptionAt?: string | null;
        } | null;
    };
};

export type GetTicketsForUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetTicketsForUserQuery = {
    getTicketsForUser: {
        tickets: Array<{
            ticket: {
                id: string;
                createdAt: string;
                updatedAt?: string | null;
                trackingCode: string;
                recognizedAt?: string | null;
                userAgent?: string | null;
                referer?: string | null;
                settlement?: { at: string; judgement: TicketJudgement } | null;
                transfer?: {
                    id: string;
                    createdAt: string;
                    amount: number;
                    remitterNameKana: string;
                    bankAccount: {
                        bankCode: string;
                        branchCode: string;
                        accountNumber: string;
                        accountHolderName: string;
                    };
                    feeOnIssuer: {
                        beforeTax: number;
                        taxRate: number;
                        decimalRoundRule: DecimalRoundRule;
                        total: number;
                    };
                    result?: { at: string; code: RemittanceResultCode; fundsReturnedAt?: string | null } | null;
                } | null;
            };
            campaign: {
                id: string;
                createdAt: string;
                updatedAt?: string | null;
                cashbackAmount: number;
                uri: string;
                endReceptionAt?: string | null;
            };
            program: {
                id: string;
                createdAt: string;
                updatedAt?: string | null;
                title: string;
                description: string;
                overviewMd?: string | null;
                advertiserName: string;
                url: string;
                endReceptionAt?: string | null;
                closeAt?: string | null;
                trackingParamKey: string;
                closure?: { endedAt: string; reason: string } | null;
                condition: {
                    toRecognize: string;
                    toComplete?: string | null;
                    precaution?: string | null;
                    completeLimitDays?: number | null;
                };
            };
        }>;
    };
};

export type GetMediaMembersForMediaUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetMediaMembersForMediaUserQuery = {
    getMediaMembersForMediaUser: {
        mediaMemberWithMedias: Array<{
            mediaMember: { id: string; createdAt: string; updatedAt?: string | null; role: MediaMemberRole };
            media: {
                id: string;
                name: string;
                url: string;
                archivedAt?: string | null;
                bankAccount?: {
                    bankCode: string;
                    branchCode: string;
                    accountNumber: string;
                    accountHolderName: string;
                } | null;
            };
        }>;
    };
};

export type GetCampaignsForMediaQueryVariables = Exact<{
    input: GetCampaignsForMediaInput;
}>;

export type GetCampaignsForMediaQuery = {
    getCampaignsForMedia: {
        campaigns: Array<{
            campaign: {
                id: string;
                createdAt: string;
                updatedAt?: string | null;
                cashbackAmount: number;
                uri: string;
                endReceptionAt?: string | null;
                mediaReward: { beforeTax: number; taxRate: number; decimalRoundRule: DecimalRoundRule; total: number };
            };
            program: {
                id: string;
                createdAt: string;
                updatedAt?: string | null;
                mediaFund: number;
                title: string;
                description: string;
                descriptionForMedia?: string | null;
                overviewMd?: string | null;
                advertiserName: string;
                url: string;
                endReceptionAt?: string | null;
                closeAt?: string | null;
                trackingParamKey: string;
                isAbleToCreateCampaign: boolean;
                cashbackAmountRange: { low?: number | null; high?: number | null };
                closure?: { endedAt: string; reason: string } | null;
                condition: {
                    toRecognize: string;
                    toComplete?: string | null;
                    precaution?: string | null;
                    completeLimitDays?: number | null;
                };
            };
        }>;
    };
};

export type GetMediaQueryVariables = Exact<{
    input: GetMediaInput;
}>;

export type GetMediaQuery = {
    getMedia: {
        media: {
            id: string;
            name: string;
            url: string;
            archivedAt?: string | null;
            bankAccount?: {
                bankCode: string;
                branchCode: string;
                accountNumber: string;
                accountHolderName: string;
            } | null;
        };
    };
};

export type GetMediaBalancesQueryVariables = Exact<{
    input: GetMediaBalancesInput;
}>;

export type GetMediaBalancesQuery = {
    getMediaBalances: {
        balances: Array<{
            id: string;
            createdAt: string;
            serialNumber: number;
            eventType: MediaBalanceEventType;
            currentAmount: number;
            currentReserve: number;
            amountIncrease: number;
            reserveIncrease: number;
            ticket?: {
                ticket: {
                    id: string;
                    createdAt: string;
                    updatedAt?: string | null;
                    trackingCode: string;
                    recognizedAt?: string | null;
                    settlement?: {
                        at: string;
                        judgement: TicketJudgement;
                        mediaReward: {
                            beforeTax: number;
                            taxRate: number;
                            decimalRoundRule: DecimalRoundRule;
                            total: number;
                        };
                    } | null;
                    transfer?: {
                        id: string;
                        createdAt: string;
                        amount: number;
                        remitterNameKana: string;
                        bankAccount: {
                            bankCode: string;
                            branchCode: string;
                            accountNumber: string;
                            accountHolderName: string;
                        };
                        feeOnIssuer: {
                            beforeTax: number;
                            taxRate: number;
                            decimalRoundRule: DecimalRoundRule;
                            total: number;
                        };
                        result?: { at: string; code: RemittanceResultCode; fundsReturnedAt?: string | null } | null;
                    } | null;
                };
                campaign: {
                    id: string;
                    createdAt: string;
                    updatedAt?: string | null;
                    cashbackAmount: number;
                    uri: string;
                    endReceptionAt?: string | null;
                    mediaReward: {
                        beforeTax: number;
                        taxRate: number;
                        decimalRoundRule: DecimalRoundRule;
                        total: number;
                    };
                };
                program: {
                    id: string;
                    createdAt: string;
                    updatedAt?: string | null;
                    mediaFund: number;
                    title: string;
                    description: string;
                    descriptionForMedia?: string | null;
                    overviewMd?: string | null;
                    advertiserName: string;
                    url: string;
                    endReceptionAt?: string | null;
                    closeAt?: string | null;
                    trackingParamKey: string;
                    isAbleToCreateCampaign: boolean;
                    cashbackAmountRange: { low?: number | null; high?: number | null };
                    closure?: { endedAt: string; reason: string } | null;
                    condition: {
                        toRecognize: string;
                        toComplete?: string | null;
                        precaution?: string | null;
                        completeLimitDays?: number | null;
                    };
                };
            } | null;
            transfer?: {
                id: string;
                createdAt: string;
                amount: number;
                remitterNameKana: string;
                bankAccount: { bankCode: string; branchCode: string; accountNumber: string; accountHolderName: string };
                feeOnIssuer: { beforeTax: number; taxRate: number; decimalRoundRule: DecimalRoundRule; total: number };
                result?: { at: string; code: RemittanceResultCode; fundsReturnedAt?: string | null } | null;
            } | null;
        }>;
    };
};

export type GetProgramsForMediaQueryVariables = Exact<{
    input: GetProgramsForMediaInput;
}>;

export type GetProgramsForMediaQuery = {
    getProgramsForMedia: {
        programs: Array<{
            id: string;
            createdAt: string;
            updatedAt?: string | null;
            mediaFund: number;
            title: string;
            description: string;
            descriptionForMedia?: string | null;
            overviewMd?: string | null;
            advertiserName: string;
            url: string;
            endReceptionAt?: string | null;
            closeAt?: string | null;
            trackingParamKey: string;
            isAbleToCreateCampaign: boolean;
            cashbackAmountRange: { low?: number | null; high?: number | null };
            closure?: { endedAt: string; reason: string } | null;
            condition: {
                toRecognize: string;
                toComplete?: string | null;
                precaution?: string | null;
                completeLimitDays?: number | null;
            };
        }>;
    };
};

export type GetRecentMediaBalanceQueryVariables = Exact<{
    input: GetRecentMediaBalanceInput;
}>;

export type GetRecentMediaBalanceQuery = {
    getRecentMediaBalance: {
        balance?: {
            id: string;
            createdAt: string;
            serialNumber: number;
            eventType: MediaBalanceEventType;
            currentAmount: number;
            currentReserve: number;
            amountIncrease: number;
            reserveIncrease: number;
            ticket?: {
                ticket: {
                    id: string;
                    createdAt: string;
                    updatedAt?: string | null;
                    trackingCode: string;
                    recognizedAt?: string | null;
                    settlement?: {
                        at: string;
                        judgement: TicketJudgement;
                        mediaReward: {
                            beforeTax: number;
                            taxRate: number;
                            decimalRoundRule: DecimalRoundRule;
                            total: number;
                        };
                    } | null;
                    transfer?: {
                        id: string;
                        createdAt: string;
                        amount: number;
                        remitterNameKana: string;
                        bankAccount: {
                            bankCode: string;
                            branchCode: string;
                            accountNumber: string;
                            accountHolderName: string;
                        };
                        feeOnIssuer: {
                            beforeTax: number;
                            taxRate: number;
                            decimalRoundRule: DecimalRoundRule;
                            total: number;
                        };
                        result?: { at: string; code: RemittanceResultCode; fundsReturnedAt?: string | null } | null;
                    } | null;
                };
                campaign: {
                    id: string;
                    createdAt: string;
                    updatedAt?: string | null;
                    cashbackAmount: number;
                    uri: string;
                    endReceptionAt?: string | null;
                    mediaReward: {
                        beforeTax: number;
                        taxRate: number;
                        decimalRoundRule: DecimalRoundRule;
                        total: number;
                    };
                };
                program: {
                    id: string;
                    createdAt: string;
                    updatedAt?: string | null;
                    mediaFund: number;
                    title: string;
                    description: string;
                    descriptionForMedia?: string | null;
                    overviewMd?: string | null;
                    advertiserName: string;
                    url: string;
                    endReceptionAt?: string | null;
                    closeAt?: string | null;
                    trackingParamKey: string;
                    isAbleToCreateCampaign: boolean;
                    cashbackAmountRange: { low?: number | null; high?: number | null };
                    closure?: { endedAt: string; reason: string } | null;
                    condition: {
                        toRecognize: string;
                        toComplete?: string | null;
                        precaution?: string | null;
                        completeLimitDays?: number | null;
                    };
                };
            } | null;
            transfer?: {
                id: string;
                createdAt: string;
                amount: number;
                remitterNameKana: string;
                bankAccount: { bankCode: string; branchCode: string; accountNumber: string; accountHolderName: string };
                feeOnIssuer: { beforeTax: number; taxRate: number; decimalRoundRule: DecimalRoundRule; total: number };
                result?: { at: string; code: RemittanceResultCode; fundsReturnedAt?: string | null } | null;
            } | null;
        } | null;
    };
};

export type GetTicketsForCampaignForMediaQueryVariables = Exact<{
    input: GetTicketsForCampaignForMediaInput;
}>;

export type GetTicketsForCampaignForMediaQuery = {
    getTicketsForCampaignForMedia: {
        tickets: Array<{
            id: string;
            createdAt: string;
            updatedAt?: string | null;
            trackingCode: string;
            recognizedAt?: string | null;
            settlement?: {
                at: string;
                judgement: TicketJudgement;
                mediaReward: { beforeTax: number; taxRate: number; decimalRoundRule: DecimalRoundRule; total: number };
            } | null;
            transfer?: {
                id: string;
                createdAt: string;
                amount: number;
                remitterNameKana: string;
                bankAccount: { bankCode: string; branchCode: string; accountNumber: string; accountHolderName: string };
                feeOnIssuer: { beforeTax: number; taxRate: number; decimalRoundRule: DecimalRoundRule; total: number };
                result?: { at: string; code: RemittanceResultCode; fundsReturnedAt?: string | null } | null;
            } | null;
        }>;
    };
};

export type GetMediaMembersForMediaQueryVariables = Exact<{
    input: GetMediaMembersForMediaInput;
}>;

export type GetMediaMembersForMediaQuery = {
    getMediaMembersForMedia: {
        mediaMemberWithUsers: Array<{
            mediaMember: { id: string; createdAt: string; updatedAt?: string | null; role: MediaMemberRole };
            mediaUser: { id: string; createdAt: string; updatedAt?: string | null; email: string };
        }>;
    };
};

export type GetProgramsQueryVariables = Exact<{ [key: string]: never }>;

export type GetProgramsQuery = {
    getPrograms: {
        programs: Array<{
            id: string;
            createdAt: string;
            updatedAt?: string | null;
            mediaFund: number;
            title: string;
            description: string;
            descriptionForMedia?: string | null;
            overviewMd?: string | null;
            advertiserName: string;
            url: string;
            endReceptionAt?: string | null;
            closeAt?: string | null;
            trackingParamKey: string;
            advertiserProgramId?: string | null;
            whitelistedMediaIds?: Array<string> | null;
            isPublicVisible: boolean;
            cashbackAmountRange: { low?: number | null; high?: number | null };
            closure?: { endedAt: string; reason: string } | null;
            condition: {
                toRecognize: string;
                toComplete?: string | null;
                precaution?: string | null;
                completeLimitDays?: number | null;
            };
            totalPrice: { beforeTax: number; taxRate: number; decimalRoundRule: DecimalRoundRule; total: number };
        }>;
    };
};

export type GetSignedUrlToUploadProgramAdvertiserIconQueryVariables = Exact<{
    input: GetSignedUrlToUploadProgramAdvertiserIconInput;
}>;

export type GetSignedUrlToUploadProgramAdvertiserIconQuery = {
    getSignedUrlToUploadProgramAdvertiserIcon: { signedUrl: string };
};

export type GetSignedUrlToUploadProgramEyecatchQueryVariables = Exact<{
    input: GetSignedUrlToUploadProgramEyecatchInput;
}>;

export type GetSignedUrlToUploadProgramEyecatchQuery = { getSignedUrlToUploadProgramEyecatch: { signedUrl: string } };

export type GetTicketsQueryVariables = Exact<{
    input: GetTicketsInput;
}>;

export type GetTicketsQuery = {
    getTickets: {
        tickets: Array<{
            ticket: {
                id: string;
                createdAt: string;
                updatedAt?: string | null;
                trackingCode: string;
                recognizedAt?: string | null;
                userAgent?: string | null;
                referer?: string | null;
                settlement?: {
                    at: string;
                    judgement: TicketJudgement;
                    totalPrice: {
                        beforeTax: number;
                        taxRate: number;
                        decimalRoundRule: DecimalRoundRule;
                        total: number;
                    };
                    mediaReward: {
                        beforeTax: number;
                        taxRate: number;
                        decimalRoundRule: DecimalRoundRule;
                        total: number;
                    };
                } | null;
                transfer?: {
                    id: string;
                    createdAt: string;
                    amount: number;
                    remitterNameKana: string;
                    bankAccount: {
                        bankCode: string;
                        branchCode: string;
                        accountNumber: string;
                        accountHolderName: string;
                    };
                    feeOnIssuer: {
                        beforeTax: number;
                        taxRate: number;
                        decimalRoundRule: DecimalRoundRule;
                        total: number;
                    };
                    result?: { at: string; code: RemittanceResultCode; fundsReturnedAt?: string | null } | null;
                } | null;
            };
            campaign: {
                id: string;
                createdAt: string;
                updatedAt?: string | null;
                cashbackAmount: number;
                uri: string;
                endReceptionAt?: string | null;
                mediaReward: { beforeTax: number; taxRate: number; decimalRoundRule: DecimalRoundRule; total: number };
            };
            program: {
                id: string;
                createdAt: string;
                updatedAt?: string | null;
                mediaFund: number;
                title: string;
                description: string;
                descriptionForMedia?: string | null;
                overviewMd?: string | null;
                advertiserName: string;
                url: string;
                endReceptionAt?: string | null;
                closeAt?: string | null;
                trackingParamKey: string;
                advertiserProgramId?: string | null;
                whitelistedMediaIds?: Array<string> | null;
                isPublicVisible: boolean;
                cashbackAmountRange: { low?: number | null; high?: number | null };
                closure?: { endedAt: string; reason: string } | null;
                condition: {
                    toRecognize: string;
                    toComplete?: string | null;
                    precaution?: string | null;
                    completeLimitDays?: number | null;
                };
                totalPrice: { beforeTax: number; taxRate: number; decimalRoundRule: DecimalRoundRule; total: number };
            };
            media: {
                id: string;
                name: string;
                url: string;
                archivedAt?: string | null;
                bankAccount?: {
                    bankCode: string;
                    branchCode: string;
                    accountNumber: string;
                    accountHolderName: string;
                } | null;
            };
        }>;
    };
};

export type GetUserInfoQueryVariables = Exact<{
    input: GetUserInfoInput;
}>;

export type GetUserInfoQuery = {
    getUserInfo: {
        user: {
            id: string;
            createdAt: string;
            updatedAt?: string | null;
            phone: string;
            removedAt?: string | null;
            bankAccount?: {
                bankCode: string;
                branchCode: string;
                accountNumber: string;
                accountHolderName: string;
            } | null;
        };
        ticketWithCampaigns: Array<{
            ticket: {
                id: string;
                createdAt: string;
                updatedAt?: string | null;
                trackingCode: string;
                recognizedAt?: string | null;
                userAgent?: string | null;
                referer?: string | null;
                settlement?: {
                    at: string;
                    judgement: TicketJudgement;
                    totalPrice: {
                        beforeTax: number;
                        taxRate: number;
                        decimalRoundRule: DecimalRoundRule;
                        total: number;
                    };
                    mediaReward: {
                        beforeTax: number;
                        taxRate: number;
                        decimalRoundRule: DecimalRoundRule;
                        total: number;
                    };
                } | null;
                transfer?: {
                    id: string;
                    createdAt: string;
                    amount: number;
                    remitterNameKana: string;
                    bankAccount: {
                        bankCode: string;
                        branchCode: string;
                        accountNumber: string;
                        accountHolderName: string;
                    };
                    feeOnIssuer: {
                        beforeTax: number;
                        taxRate: number;
                        decimalRoundRule: DecimalRoundRule;
                        total: number;
                    };
                    result?: { at: string; code: RemittanceResultCode; fundsReturnedAt?: string | null } | null;
                } | null;
            };
            campaign: {
                id: string;
                createdAt: string;
                updatedAt?: string | null;
                cashbackAmount: number;
                uri: string;
                endReceptionAt?: string | null;
                mediaReward: { beforeTax: number; taxRate: number; decimalRoundRule: DecimalRoundRule; total: number };
            };
            program: {
                id: string;
                createdAt: string;
                updatedAt?: string | null;
                mediaFund: number;
                title: string;
                description: string;
                descriptionForMedia?: string | null;
                overviewMd?: string | null;
                advertiserName: string;
                url: string;
                endReceptionAt?: string | null;
                closeAt?: string | null;
                trackingParamKey: string;
                advertiserProgramId?: string | null;
                whitelistedMediaIds?: Array<string> | null;
                isPublicVisible: boolean;
                cashbackAmountRange: { low?: number | null; high?: number | null };
                closure?: { endedAt: string; reason: string } | null;
                condition: {
                    toRecognize: string;
                    toComplete?: string | null;
                    precaution?: string | null;
                    completeLimitDays?: number | null;
                };
                totalPrice: { beforeTax: number; taxRate: number; decimalRoundRule: DecimalRoundRule; total: number };
            };
            media: {
                id: string;
                name: string;
                url: string;
                archivedAt?: string | null;
                bankAccount?: {
                    bankCode: string;
                    branchCode: string;
                    accountNumber: string;
                    accountHolderName: string;
                } | null;
            };
        }>;
    };
};

export const CreateUserDocument = gql`
    mutation createUser($input: CreateUserInput!) {
        createUser(input: $input) {
            user {
                id
                createdAt
                updatedAt
                phone
                bankAccount {
                    bankCode
                    branchCode
                    accountNumber
                    accountHolderName
                }
                removedAt
            }
        }
    }
`;
export const CreateMediaUserDocument = gql`
    mutation createMediaUser {
        createMediaUser {
            mediaUser {
                id
                createdAt
                updatedAt
                email
            }
        }
    }
`;
export const CreateTicketDocument = gql`
    mutation createTicket($input: CreateTicketInput!) {
        createTicket(input: $input) {
            ticket {
                id
                createdAt
                updatedAt
                trackingCode
                recognizedAt
                settlement {
                    at
                    judgement
                }
                transfer {
                    id
                    createdAt
                    bankAccount {
                        bankCode
                        branchCode
                        accountNumber
                        accountHolderName
                    }
                    amount
                    feeOnIssuer {
                        beforeTax
                        taxRate
                        decimalRoundRule
                        total
                    }
                    remitterNameKana
                    result {
                        at
                        code
                        fundsReturnedAt
                    }
                }
                userAgent
                referer
            }
        }
    }
`;
export const RemoveUserDocument = gql`
    mutation removeUser {
        removeUser {
            result
            user {
                id
                createdAt
                updatedAt
                phone
                bankAccount {
                    bankCode
                    branchCode
                    accountNumber
                    accountHolderName
                }
                removedAt
            }
        }
    }
`;
export const UpdateUserBankAccountDocument = gql`
    mutation updateUserBankAccount($input: UpdateUserBankAccountInput!) {
        updateUserBankAccount(input: $input) {
            result
            user {
                id
                createdAt
                updatedAt
                phone
                bankAccount {
                    bankCode
                    branchCode
                    accountNumber
                    accountHolderName
                }
                removedAt
            }
        }
    }
`;
export const CreateMediaDocument = gql`
    mutation createMedia($input: CreateMediaInput!) {
        createMedia(input: $input) {
            media {
                id
                name
                url
                bankAccount {
                    bankCode
                    branchCode
                    accountNumber
                    accountHolderName
                }
                archivedAt
            }
            mediaMember {
                id
                createdAt
                updatedAt
                role
            }
        }
    }
`;
export const CreateCampaignDocument = gql`
    mutation createCampaign($input: CreateCampaignInput!) {
        createCampaign(input: $input) {
            campaign {
                id
                createdAt
                updatedAt
                cashbackAmount
                mediaReward {
                    beforeTax
                    taxRate
                    decimalRoundRule
                    total
                }
                uri
                endReceptionAt
            }
        }
    }
`;
export const EndReceptionCampaignDocument = gql`
    mutation endReceptionCampaign($input: EndReceptionCampaignInput!) {
        endReceptionCampaign(input: $input) {
            campaign {
                id
                createdAt
                updatedAt
                cashbackAmount
                mediaReward {
                    beforeTax
                    taxRate
                    decimalRoundRule
                    total
                }
                uri
                endReceptionAt
            }
        }
    }
`;
export const RefreshCampaignUriDocument = gql`
    mutation refreshCampaignUri($input: RefreshCampaignUriInput!) {
        refreshCampaignUri(input: $input) {
            campaign {
                id
                createdAt
                updatedAt
                cashbackAmount
                mediaReward {
                    beforeTax
                    taxRate
                    decimalRoundRule
                    total
                }
                uri
                endReceptionAt
            }
            result
        }
    }
`;
export const CreateWithdrawDocument = gql`
    mutation createWithdraw($input: CreateWithdrawInput!) {
        createWithdraw(input: $input) {
            balance {
                id
                createdAt
                serialNumber
                eventType
                currentAmount
                currentReserve
                amountIncrease
                reserveIncrease
                ticket {
                    ticket {
                        id
                        createdAt
                        updatedAt
                        trackingCode
                        recognizedAt
                        settlement {
                            at
                            mediaReward {
                                beforeTax
                                taxRate
                                decimalRoundRule
                                total
                            }
                            judgement
                        }
                        transfer {
                            id
                            createdAt
                            bankAccount {
                                bankCode
                                branchCode
                                accountNumber
                                accountHolderName
                            }
                            amount
                            feeOnIssuer {
                                beforeTax
                                taxRate
                                decimalRoundRule
                                total
                            }
                            remitterNameKana
                            result {
                                at
                                code
                                fundsReturnedAt
                            }
                        }
                    }
                    campaign {
                        id
                        createdAt
                        updatedAt
                        cashbackAmount
                        mediaReward {
                            beforeTax
                            taxRate
                            decimalRoundRule
                            total
                        }
                        uri
                        endReceptionAt
                    }
                    program {
                        id
                        createdAt
                        updatedAt
                        mediaFund
                        cashbackAmountRange {
                            low
                            high
                        }
                        title
                        description
                        descriptionForMedia
                        overviewMd
                        advertiserName
                        url
                        endReceptionAt
                        closure {
                            endedAt
                            reason
                        }
                        condition {
                            toRecognize
                            toComplete
                            precaution
                            completeLimitDays
                        }
                        trackingParamKey
                        isAbleToCreateCampaign
                    }
                }
                transfer {
                    id
                    createdAt
                    bankAccount {
                        bankCode
                        branchCode
                        accountNumber
                        accountHolderName
                    }
                    amount
                    feeOnIssuer {
                        beforeTax
                        taxRate
                        decimalRoundRule
                        total
                    }
                    remitterNameKana
                    result {
                        at
                        code
                        fundsReturnedAt
                    }
                }
            }
        }
    }
`;
export const CreateWithdrawConfirmationDocument = gql`
    mutation createWithdrawConfirmation($input: CreateWithdrawConfirmationInput!) {
        createWithdrawConfirmation(input: $input) {
            withdrawConfirmation {
                id
                createdAt
                bankAccount {
                    bankCode
                    branchCode
                    accountNumber
                    accountHolderName
                }
                amount
                feeOnIssuer {
                    beforeTax
                    taxRate
                    decimalRoundRule
                    total
                }
                remitterNameKana
            }
        }
    }
`;
export const UpdateMediaBankAccountDocument = gql`
    mutation updateMediaBankAccount($input: UpdateMediaBankAccountInput!) {
        updateMediaBankAccount(input: $input) {
            result
            media {
                id
                name
                url
                bankAccount {
                    bankCode
                    branchCode
                    accountNumber
                    accountHolderName
                }
                archivedAt
            }
        }
    }
`;
export const UpdateMediaNameDocument = gql`
    mutation updateMediaName($input: UpdateMediaNameInput!) {
        updateMediaName(input: $input) {
            result
            media {
                id
                name
                url
                bankAccount {
                    bankCode
                    branchCode
                    accountNumber
                    accountHolderName
                }
                archivedAt
            }
        }
    }
`;
export const UpdateMediaUrlDocument = gql`
    mutation updateMediaUrl($input: UpdateMediaUrlInput!) {
        updateMediaUrl(input: $input) {
            result
            media {
                id
                name
                url
                bankAccount {
                    bankCode
                    branchCode
                    accountNumber
                    accountHolderName
                }
                archivedAt
            }
        }
    }
`;
export const CloseProgramDocument = gql`
    mutation closeProgram($input: CloseProgramInput!) {
        closeProgram(input: $input) {
            result
            program {
                id
                createdAt
                updatedAt
                mediaFund
                cashbackAmountRange {
                    low
                    high
                }
                title
                description
                descriptionForMedia
                overviewMd
                advertiserName
                url
                endReceptionAt
                closeAt
                closure {
                    endedAt
                    reason
                }
                condition {
                    toRecognize
                    toComplete
                    precaution
                    completeLimitDays
                }
                trackingParamKey
                advertiserProgramId
                totalPrice {
                    beforeTax
                    taxRate
                    decimalRoundRule
                    total
                }
                whitelistedMediaIds
                isPublicVisible
            }
        }
    }
`;
export const CreateProgramDocument = gql`
    mutation createProgram($input: CreateProgramInput!) {
        createProgram(input: $input) {
            program {
                id
                createdAt
                updatedAt
                mediaFund
                cashbackAmountRange {
                    low
                    high
                }
                title
                description
                descriptionForMedia
                overviewMd
                advertiserName
                url
                endReceptionAt
                closeAt
                closure {
                    endedAt
                    reason
                }
                condition {
                    toRecognize
                    toComplete
                    precaution
                    completeLimitDays
                }
                trackingParamKey
                advertiserProgramId
                totalPrice {
                    beforeTax
                    taxRate
                    decimalRoundRule
                    total
                }
                whitelistedMediaIds
                isPublicVisible
            }
        }
    }
`;
export const ModifyProgramCloseAtDocument = gql`
    mutation modifyProgramCloseAt($input: ModifyProgramCloseAtInput!) {
        modifyProgramCloseAt(input: $input) {
            result
            program {
                id
                createdAt
                updatedAt
                mediaFund
                cashbackAmountRange {
                    low
                    high
                }
                title
                description
                descriptionForMedia
                overviewMd
                advertiserName
                url
                endReceptionAt
                closeAt
                closure {
                    endedAt
                    reason
                }
                condition {
                    toRecognize
                    toComplete
                    precaution
                    completeLimitDays
                }
                trackingParamKey
                advertiserProgramId
                totalPrice {
                    beforeTax
                    taxRate
                    decimalRoundRule
                    total
                }
                whitelistedMediaIds
                isPublicVisible
            }
        }
    }
`;
export const ModifyProgramConditionCompleteLimitDaysDocument = gql`
    mutation modifyProgramConditionCompleteLimitDays($input: ModifyProgramConditionCompleteLimitDaysInput!) {
        modifyProgramConditionCompleteLimitDays(input: $input) {
            result
            program {
                id
                createdAt
                updatedAt
                mediaFund
                cashbackAmountRange {
                    low
                    high
                }
                title
                description
                descriptionForMedia
                overviewMd
                advertiserName
                url
                endReceptionAt
                closeAt
                closure {
                    endedAt
                    reason
                }
                condition {
                    toRecognize
                    toComplete
                    precaution
                    completeLimitDays
                }
                trackingParamKey
                advertiserProgramId
                totalPrice {
                    beforeTax
                    taxRate
                    decimalRoundRule
                    total
                }
                whitelistedMediaIds
                isPublicVisible
            }
        }
    }
`;
export const ModifyProgramConditionPrecautionDocument = gql`
    mutation modifyProgramConditionPrecaution($input: ModifyProgramConditionPrecautionInput!) {
        modifyProgramConditionPrecaution(input: $input) {
            result
            program {
                id
                createdAt
                updatedAt
                mediaFund
                cashbackAmountRange {
                    low
                    high
                }
                title
                description
                descriptionForMedia
                overviewMd
                advertiserName
                url
                endReceptionAt
                closeAt
                closure {
                    endedAt
                    reason
                }
                condition {
                    toRecognize
                    toComplete
                    precaution
                    completeLimitDays
                }
                trackingParamKey
                advertiserProgramId
                totalPrice {
                    beforeTax
                    taxRate
                    decimalRoundRule
                    total
                }
                whitelistedMediaIds
                isPublicVisible
            }
        }
    }
`;
export const ModifyProgramConditionToCompleteDocument = gql`
    mutation modifyProgramConditionToComplete($input: ModifyProgramConditionToCompleteInput!) {
        modifyProgramConditionToComplete(input: $input) {
            result
            program {
                id
                createdAt
                updatedAt
                mediaFund
                cashbackAmountRange {
                    low
                    high
                }
                title
                description
                descriptionForMedia
                overviewMd
                advertiserName
                url
                endReceptionAt
                closeAt
                closure {
                    endedAt
                    reason
                }
                condition {
                    toRecognize
                    toComplete
                    precaution
                    completeLimitDays
                }
                trackingParamKey
                advertiserProgramId
                totalPrice {
                    beforeTax
                    taxRate
                    decimalRoundRule
                    total
                }
                whitelistedMediaIds
                isPublicVisible
            }
        }
    }
`;
export const ModifyProgramConditionToRecognizeDocument = gql`
    mutation modifyProgramConditionToRecognize($input: ModifyProgramConditionToRecognizeInput!) {
        modifyProgramConditionToRecognize(input: $input) {
            result
            program {
                id
                createdAt
                updatedAt
                mediaFund
                cashbackAmountRange {
                    low
                    high
                }
                title
                description
                descriptionForMedia
                overviewMd
                advertiserName
                url
                endReceptionAt
                closeAt
                closure {
                    endedAt
                    reason
                }
                condition {
                    toRecognize
                    toComplete
                    precaution
                    completeLimitDays
                }
                trackingParamKey
                advertiserProgramId
                totalPrice {
                    beforeTax
                    taxRate
                    decimalRoundRule
                    total
                }
                whitelistedMediaIds
                isPublicVisible
            }
        }
    }
`;
export const ModifyProgramDescriptionDocument = gql`
    mutation modifyProgramDescription($input: ModifyProgramDescriptionInput!) {
        modifyProgramDescription(input: $input) {
            result
            program {
                id
                createdAt
                updatedAt
                mediaFund
                cashbackAmountRange {
                    low
                    high
                }
                title
                description
                descriptionForMedia
                overviewMd
                advertiserName
                url
                endReceptionAt
                closeAt
                closure {
                    endedAt
                    reason
                }
                condition {
                    toRecognize
                    toComplete
                    precaution
                    completeLimitDays
                }
                trackingParamKey
                advertiserProgramId
                totalPrice {
                    beforeTax
                    taxRate
                    decimalRoundRule
                    total
                }
                whitelistedMediaIds
                isPublicVisible
            }
        }
    }
`;
export const ModifyProgramDescriptionForMediaDocument = gql`
    mutation modifyProgramDescriptionForMedia($input: ModifyProgramDescriptionForMediaInput!) {
        modifyProgramDescriptionForMedia(input: $input) {
            result
            program {
                id
                createdAt
                updatedAt
                mediaFund
                cashbackAmountRange {
                    low
                    high
                }
                title
                description
                descriptionForMedia
                overviewMd
                advertiserName
                url
                endReceptionAt
                closeAt
                closure {
                    endedAt
                    reason
                }
                condition {
                    toRecognize
                    toComplete
                    precaution
                    completeLimitDays
                }
                trackingParamKey
                advertiserProgramId
                totalPrice {
                    beforeTax
                    taxRate
                    decimalRoundRule
                    total
                }
                whitelistedMediaIds
                isPublicVisible
            }
        }
    }
`;
export const ModifyProgramEndReceptionAtDocument = gql`
    mutation modifyProgramEndReceptionAt($input: ModifyProgramEndReceptionAtInput!) {
        modifyProgramEndReceptionAt(input: $input) {
            result
            program {
                id
                createdAt
                updatedAt
                mediaFund
                cashbackAmountRange {
                    low
                    high
                }
                title
                description
                descriptionForMedia
                overviewMd
                advertiserName
                url
                endReceptionAt
                closeAt
                closure {
                    endedAt
                    reason
                }
                condition {
                    toRecognize
                    toComplete
                    precaution
                    completeLimitDays
                }
                trackingParamKey
                advertiserProgramId
                totalPrice {
                    beforeTax
                    taxRate
                    decimalRoundRule
                    total
                }
                whitelistedMediaIds
                isPublicVisible
            }
        }
    }
`;
export const ModifyProgramIsPublicVisibleDocument = gql`
    mutation modifyProgramIsPublicVisible($input: ModifyProgramIsPublicVisibleInput!) {
        modifyProgramIsPublicVisible(input: $input) {
            result
            program {
                id
                createdAt
                updatedAt
                mediaFund
                cashbackAmountRange {
                    low
                    high
                }
                title
                description
                descriptionForMedia
                overviewMd
                advertiserName
                url
                endReceptionAt
                closeAt
                closure {
                    endedAt
                    reason
                }
                condition {
                    toRecognize
                    toComplete
                    precaution
                    completeLimitDays
                }
                trackingParamKey
                advertiserProgramId
                totalPrice {
                    beforeTax
                    taxRate
                    decimalRoundRule
                    total
                }
                whitelistedMediaIds
                isPublicVisible
            }
        }
    }
`;
export const ModifyProgramOverviewMdDocument = gql`
    mutation modifyProgramOverviewMd($input: ModifyProgramOverviewMdInput!) {
        modifyProgramOverviewMd(input: $input) {
            result
            program {
                id
                createdAt
                updatedAt
                mediaFund
                cashbackAmountRange {
                    low
                    high
                }
                title
                description
                descriptionForMedia
                overviewMd
                advertiserName
                url
                endReceptionAt
                closeAt
                closure {
                    endedAt
                    reason
                }
                condition {
                    toRecognize
                    toComplete
                    precaution
                    completeLimitDays
                }
                trackingParamKey
                advertiserProgramId
                totalPrice {
                    beforeTax
                    taxRate
                    decimalRoundRule
                    total
                }
                whitelistedMediaIds
                isPublicVisible
            }
        }
    }
`;
export const ModifyProgramWhitelistedMediaIdsDocument = gql`
    mutation modifyProgramWhitelistedMediaIds($input: ModifyProgramWhitelistedMediaIdsInput!) {
        modifyProgramWhitelistedMediaIds(input: $input) {
            result
            program {
                id
                createdAt
                updatedAt
                mediaFund
                cashbackAmountRange {
                    low
                    high
                }
                title
                description
                descriptionForMedia
                overviewMd
                advertiserName
                url
                endReceptionAt
                closeAt
                closure {
                    endedAt
                    reason
                }
                condition {
                    toRecognize
                    toComplete
                    precaution
                    completeLimitDays
                }
                trackingParamKey
                advertiserProgramId
                totalPrice {
                    beforeTax
                    taxRate
                    decimalRoundRule
                    total
                }
                whitelistedMediaIds
                isPublicVisible
            }
        }
    }
`;
export const RunAutoCron1dayDocument = gql`
    query runAutoCron1day {
        runAutoCron1day
    }
`;
export const RunManualCronForMediaRewardDocument = gql`
    query runManualCronForMediaReward {
        runManualCronForMediaReward
    }
`;
export const RunManualCronForNewCashbackDocument = gql`
    query runManualCronForNewCashback {
        runManualCronForNewCashback
    }
`;
export const RunManualCronForRetryingMediaRewardDocument = gql`
    query runManualCronForRetryingMediaReward {
        runManualCronForRetryingMediaReward
    }
`;
export const RunManualCronForSyncDocument = gql`
    query runManualCronForSync {
        runManualCronForSync
    }
`;
export const GetAllCampaignsDocument = gql`
    query getAllCampaigns {
        getAllCampaigns {
            campaigns {
                id
                createdAt
                updatedAt
                cashbackAmount
                uri
                endReceptionAt
            }
        }
    }
`;
export const GetCampaignForPublicDocument = gql`
    query getCampaignForPublic($input: GetCampaignForPublicInput!) {
        getCampaignForPublic(input: $input) {
            campaign {
                id
                createdAt
                updatedAt
                cashbackAmount
                uri
                endReceptionAt
            }
            program {
                id
                createdAt
                updatedAt
                title
                description
                overviewMd
                advertiserName
                url
                endReceptionAt
                closeAt
                closure {
                    endedAt
                    reason
                }
                condition {
                    toRecognize
                    toComplete
                    precaution
                    completeLimitDays
                }
                trackingParamKey
            }
            media {
                id
                name
                url
                archivedAt
            }
        }
    }
`;
export const GetPrivacypolicyDocument = gql`
    query getPrivacypolicy {
        getPrivacypolicy {
            privacypolicy
        }
    }
`;
export const GetPublicCampaignsDocument = gql`
    query getPublicCampaigns {
        getPublicCampaigns {
            campaigns {
                campaign {
                    id
                    createdAt
                    updatedAt
                    cashbackAmount
                    uri
                    endReceptionAt
                }
                program {
                    id
                    createdAt
                    updatedAt
                    title
                    description
                    overviewMd
                    advertiserName
                    url
                    endReceptionAt
                    closeAt
                    closure {
                        endedAt
                        reason
                    }
                    condition {
                        toRecognize
                        toComplete
                        precaution
                        completeLimitDays
                    }
                    trackingParamKey
                }
            }
        }
    }
`;
export const GetServiceHealthDocument = gql`
    query getServiceHealth {
        getServiceHealth {
            code
            message
            url
        }
    }
`;
export const GetTosDocument = gql`
    query getTos {
        getTos {
            tos
        }
    }
`;
export const GetTosForMediaDocument = gql`
    query getTosForMedia {
        getTosForMedia {
            tosForMedia
        }
    }
`;
export const GetUserDocument = gql`
    query getUser {
        getUser {
            user {
                id
                createdAt
                updatedAt
                phone
                bankAccount {
                    bankCode
                    branchCode
                    accountNumber
                    accountHolderName
                }
                removedAt
            }
        }
    }
`;
export const GetMediaUserDocument = gql`
    query getMediaUser {
        getMediaUser {
            mediaUser {
                id
                createdAt
                updatedAt
                email
            }
        }
    }
`;
export const GetTicketForUserDocument = gql`
    query getTicketForUser($input: GetTicketForUserInput!) {
        getTicketForUser(input: $input) {
            ticket {
                id
                createdAt
                updatedAt
                trackingCode
                recognizedAt
                settlement {
                    at
                    judgement
                }
                transfer {
                    id
                    createdAt
                    bankAccount {
                        bankCode
                        branchCode
                        accountNumber
                        accountHolderName
                    }
                    amount
                    feeOnIssuer {
                        beforeTax
                        taxRate
                        decimalRoundRule
                        total
                    }
                    remitterNameKana
                    result {
                        at
                        code
                        fundsReturnedAt
                    }
                }
                userAgent
                referer
            }
            campaign {
                id
                createdAt
                updatedAt
                cashbackAmount
                uri
                endReceptionAt
            }
        }
    }
`;
export const GetTicketsForUserDocument = gql`
    query getTicketsForUser {
        getTicketsForUser {
            tickets {
                ticket {
                    id
                    createdAt
                    updatedAt
                    trackingCode
                    recognizedAt
                    settlement {
                        at
                        judgement
                    }
                    transfer {
                        id
                        createdAt
                        bankAccount {
                            bankCode
                            branchCode
                            accountNumber
                            accountHolderName
                        }
                        amount
                        feeOnIssuer {
                            beforeTax
                            taxRate
                            decimalRoundRule
                            total
                        }
                        remitterNameKana
                        result {
                            at
                            code
                            fundsReturnedAt
                        }
                    }
                    userAgent
                    referer
                }
                campaign {
                    id
                    createdAt
                    updatedAt
                    cashbackAmount
                    uri
                    endReceptionAt
                }
                program {
                    id
                    createdAt
                    updatedAt
                    title
                    description
                    overviewMd
                    advertiserName
                    url
                    endReceptionAt
                    closeAt
                    closure {
                        endedAt
                        reason
                    }
                    condition {
                        toRecognize
                        toComplete
                        precaution
                        completeLimitDays
                    }
                    trackingParamKey
                }
            }
        }
    }
`;
export const GetMediaMembersForMediaUserDocument = gql`
    query getMediaMembersForMediaUser {
        getMediaMembersForMediaUser {
            mediaMemberWithMedias {
                mediaMember {
                    id
                    createdAt
                    updatedAt
                    role
                }
                media {
                    id
                    name
                    url
                    bankAccount {
                        bankCode
                        branchCode
                        accountNumber
                        accountHolderName
                    }
                    archivedAt
                }
            }
        }
    }
`;
export const GetCampaignsForMediaDocument = gql`
    query getCampaignsForMedia($input: GetCampaignsForMediaInput!) {
        getCampaignsForMedia(input: $input) {
            campaigns {
                campaign {
                    id
                    createdAt
                    updatedAt
                    cashbackAmount
                    mediaReward {
                        beforeTax
                        taxRate
                        decimalRoundRule
                        total
                    }
                    uri
                    endReceptionAt
                }
                program {
                    id
                    createdAt
                    updatedAt
                    mediaFund
                    cashbackAmountRange {
                        low
                        high
                    }
                    title
                    description
                    descriptionForMedia
                    overviewMd
                    advertiserName
                    url
                    endReceptionAt
                    closeAt
                    closure {
                        endedAt
                        reason
                    }
                    condition {
                        toRecognize
                        toComplete
                        precaution
                        completeLimitDays
                    }
                    trackingParamKey
                    isAbleToCreateCampaign
                }
            }
        }
    }
`;
export const GetMediaDocument = gql`
    query getMedia($input: GetMediaInput!) {
        getMedia(input: $input) {
            media {
                id
                name
                url
                bankAccount {
                    bankCode
                    branchCode
                    accountNumber
                    accountHolderName
                }
                archivedAt
            }
        }
    }
`;
export const GetMediaBalancesDocument = gql`
    query getMediaBalances($input: GetMediaBalancesInput!) {
        getMediaBalances(input: $input) {
            balances {
                id
                createdAt
                serialNumber
                eventType
                currentAmount
                currentReserve
                amountIncrease
                reserveIncrease
                ticket {
                    ticket {
                        id
                        createdAt
                        updatedAt
                        trackingCode
                        recognizedAt
                        settlement {
                            at
                            mediaReward {
                                beforeTax
                                taxRate
                                decimalRoundRule
                                total
                            }
                            judgement
                        }
                        transfer {
                            id
                            createdAt
                            bankAccount {
                                bankCode
                                branchCode
                                accountNumber
                                accountHolderName
                            }
                            amount
                            feeOnIssuer {
                                beforeTax
                                taxRate
                                decimalRoundRule
                                total
                            }
                            remitterNameKana
                            result {
                                at
                                code
                                fundsReturnedAt
                            }
                        }
                    }
                    campaign {
                        id
                        createdAt
                        updatedAt
                        cashbackAmount
                        mediaReward {
                            beforeTax
                            taxRate
                            decimalRoundRule
                            total
                        }
                        uri
                        endReceptionAt
                    }
                    program {
                        id
                        createdAt
                        updatedAt
                        mediaFund
                        cashbackAmountRange {
                            low
                            high
                        }
                        title
                        description
                        descriptionForMedia
                        overviewMd
                        advertiserName
                        url
                        endReceptionAt
                        closeAt
                        closure {
                            endedAt
                            reason
                        }
                        condition {
                            toRecognize
                            toComplete
                            precaution
                            completeLimitDays
                        }
                        trackingParamKey
                        isAbleToCreateCampaign
                    }
                }
                transfer {
                    id
                    createdAt
                    bankAccount {
                        bankCode
                        branchCode
                        accountNumber
                        accountHolderName
                    }
                    amount
                    feeOnIssuer {
                        beforeTax
                        taxRate
                        decimalRoundRule
                        total
                    }
                    remitterNameKana
                    result {
                        at
                        code
                        fundsReturnedAt
                    }
                }
            }
        }
    }
`;
export const GetProgramsForMediaDocument = gql`
    query getProgramsForMedia($input: GetProgramsForMediaInput!) {
        getProgramsForMedia(input: $input) {
            programs {
                id
                createdAt
                updatedAt
                mediaFund
                cashbackAmountRange {
                    low
                    high
                }
                title
                description
                descriptionForMedia
                overviewMd
                advertiserName
                url
                endReceptionAt
                closeAt
                closure {
                    endedAt
                    reason
                }
                condition {
                    toRecognize
                    toComplete
                    precaution
                    completeLimitDays
                }
                trackingParamKey
                isAbleToCreateCampaign
            }
        }
    }
`;
export const GetRecentMediaBalanceDocument = gql`
    query getRecentMediaBalance($input: GetRecentMediaBalanceInput!) {
        getRecentMediaBalance(input: $input) {
            balance {
                id
                createdAt
                serialNumber
                eventType
                currentAmount
                currentReserve
                amountIncrease
                reserveIncrease
                ticket {
                    ticket {
                        id
                        createdAt
                        updatedAt
                        trackingCode
                        recognizedAt
                        settlement {
                            at
                            mediaReward {
                                beforeTax
                                taxRate
                                decimalRoundRule
                                total
                            }
                            judgement
                        }
                        transfer {
                            id
                            createdAt
                            bankAccount {
                                bankCode
                                branchCode
                                accountNumber
                                accountHolderName
                            }
                            amount
                            feeOnIssuer {
                                beforeTax
                                taxRate
                                decimalRoundRule
                                total
                            }
                            remitterNameKana
                            result {
                                at
                                code
                                fundsReturnedAt
                            }
                        }
                    }
                    campaign {
                        id
                        createdAt
                        updatedAt
                        cashbackAmount
                        mediaReward {
                            beforeTax
                            taxRate
                            decimalRoundRule
                            total
                        }
                        uri
                        endReceptionAt
                    }
                    program {
                        id
                        createdAt
                        updatedAt
                        mediaFund
                        cashbackAmountRange {
                            low
                            high
                        }
                        title
                        description
                        descriptionForMedia
                        overviewMd
                        advertiserName
                        url
                        endReceptionAt
                        closeAt
                        closure {
                            endedAt
                            reason
                        }
                        condition {
                            toRecognize
                            toComplete
                            precaution
                            completeLimitDays
                        }
                        trackingParamKey
                        isAbleToCreateCampaign
                    }
                }
                transfer {
                    id
                    createdAt
                    bankAccount {
                        bankCode
                        branchCode
                        accountNumber
                        accountHolderName
                    }
                    amount
                    feeOnIssuer {
                        beforeTax
                        taxRate
                        decimalRoundRule
                        total
                    }
                    remitterNameKana
                    result {
                        at
                        code
                        fundsReturnedAt
                    }
                }
            }
        }
    }
`;
export const GetTicketsForCampaignForMediaDocument = gql`
    query getTicketsForCampaignForMedia($input: GetTicketsForCampaignForMediaInput!) {
        getTicketsForCampaignForMedia(input: $input) {
            tickets {
                id
                createdAt
                updatedAt
                trackingCode
                recognizedAt
                settlement {
                    at
                    mediaReward {
                        beforeTax
                        taxRate
                        decimalRoundRule
                        total
                    }
                    judgement
                }
                transfer {
                    id
                    createdAt
                    bankAccount {
                        bankCode
                        branchCode
                        accountNumber
                        accountHolderName
                    }
                    amount
                    feeOnIssuer {
                        beforeTax
                        taxRate
                        decimalRoundRule
                        total
                    }
                    remitterNameKana
                    result {
                        at
                        code
                        fundsReturnedAt
                    }
                }
            }
        }
    }
`;
export const GetMediaMembersForMediaDocument = gql`
    query getMediaMembersForMedia($input: GetMediaMembersForMediaInput!) {
        getMediaMembersForMedia(input: $input) {
            mediaMemberWithUsers {
                mediaMember {
                    id
                    createdAt
                    updatedAt
                    role
                }
                mediaUser {
                    id
                    createdAt
                    updatedAt
                    email
                }
            }
        }
    }
`;
export const GetProgramsDocument = gql`
    query getPrograms {
        getPrograms {
            programs {
                id
                createdAt
                updatedAt
                mediaFund
                cashbackAmountRange {
                    low
                    high
                }
                title
                description
                descriptionForMedia
                overviewMd
                advertiserName
                url
                endReceptionAt
                closeAt
                closure {
                    endedAt
                    reason
                }
                condition {
                    toRecognize
                    toComplete
                    precaution
                    completeLimitDays
                }
                trackingParamKey
                advertiserProgramId
                totalPrice {
                    beforeTax
                    taxRate
                    decimalRoundRule
                    total
                }
                whitelistedMediaIds
                isPublicVisible
            }
        }
    }
`;
export const GetSignedUrlToUploadProgramAdvertiserIconDocument = gql`
    query getSignedUrlToUploadProgramAdvertiserIcon($input: GetSignedUrlToUploadProgramAdvertiserIconInput!) {
        getSignedUrlToUploadProgramAdvertiserIcon(input: $input) {
            signedUrl
        }
    }
`;
export const GetSignedUrlToUploadProgramEyecatchDocument = gql`
    query getSignedUrlToUploadProgramEyecatch($input: GetSignedUrlToUploadProgramEyecatchInput!) {
        getSignedUrlToUploadProgramEyecatch(input: $input) {
            signedUrl
        }
    }
`;
export const GetTicketsDocument = gql`
    query getTickets($input: GetTicketsInput!) {
        getTickets(input: $input) {
            tickets {
                ticket {
                    id
                    createdAt
                    updatedAt
                    trackingCode
                    recognizedAt
                    settlement {
                        at
                        totalPrice {
                            beforeTax
                            taxRate
                            decimalRoundRule
                            total
                        }
                        mediaReward {
                            beforeTax
                            taxRate
                            decimalRoundRule
                            total
                        }
                        judgement
                    }
                    transfer {
                        id
                        createdAt
                        bankAccount {
                            bankCode
                            branchCode
                            accountNumber
                            accountHolderName
                        }
                        amount
                        feeOnIssuer {
                            beforeTax
                            taxRate
                            decimalRoundRule
                            total
                        }
                        remitterNameKana
                        result {
                            at
                            code
                            fundsReturnedAt
                        }
                    }
                    userAgent
                    referer
                }
                campaign {
                    id
                    createdAt
                    updatedAt
                    cashbackAmount
                    mediaReward {
                        beforeTax
                        taxRate
                        decimalRoundRule
                        total
                    }
                    uri
                    endReceptionAt
                }
                program {
                    id
                    createdAt
                    updatedAt
                    mediaFund
                    cashbackAmountRange {
                        low
                        high
                    }
                    title
                    description
                    descriptionForMedia
                    overviewMd
                    advertiserName
                    url
                    endReceptionAt
                    closeAt
                    closure {
                        endedAt
                        reason
                    }
                    condition {
                        toRecognize
                        toComplete
                        precaution
                        completeLimitDays
                    }
                    trackingParamKey
                    advertiserProgramId
                    totalPrice {
                        beforeTax
                        taxRate
                        decimalRoundRule
                        total
                    }
                    whitelistedMediaIds
                    isPublicVisible
                }
                media {
                    id
                    name
                    url
                    bankAccount {
                        bankCode
                        branchCode
                        accountNumber
                        accountHolderName
                    }
                    archivedAt
                }
            }
        }
    }
`;
export const GetUserInfoDocument = gql`
    query getUserInfo($input: GetUserInfoInput!) {
        getUserInfo(input: $input) {
            user {
                id
                createdAt
                updatedAt
                phone
                bankAccount {
                    bankCode
                    branchCode
                    accountNumber
                    accountHolderName
                }
                removedAt
            }
            ticketWithCampaigns {
                ticket {
                    id
                    createdAt
                    updatedAt
                    trackingCode
                    recognizedAt
                    settlement {
                        at
                        totalPrice {
                            beforeTax
                            taxRate
                            decimalRoundRule
                            total
                        }
                        mediaReward {
                            beforeTax
                            taxRate
                            decimalRoundRule
                            total
                        }
                        judgement
                    }
                    transfer {
                        id
                        createdAt
                        bankAccount {
                            bankCode
                            branchCode
                            accountNumber
                            accountHolderName
                        }
                        amount
                        feeOnIssuer {
                            beforeTax
                            taxRate
                            decimalRoundRule
                            total
                        }
                        remitterNameKana
                        result {
                            at
                            code
                            fundsReturnedAt
                        }
                    }
                    userAgent
                    referer
                }
                campaign {
                    id
                    createdAt
                    updatedAt
                    cashbackAmount
                    mediaReward {
                        beforeTax
                        taxRate
                        decimalRoundRule
                        total
                    }
                    uri
                    endReceptionAt
                }
                program {
                    id
                    createdAt
                    updatedAt
                    mediaFund
                    cashbackAmountRange {
                        low
                        high
                    }
                    title
                    description
                    descriptionForMedia
                    overviewMd
                    advertiserName
                    url
                    endReceptionAt
                    closeAt
                    closure {
                        endedAt
                        reason
                    }
                    condition {
                        toRecognize
                        toComplete
                        precaution
                        completeLimitDays
                    }
                    trackingParamKey
                    advertiserProgramId
                    totalPrice {
                        beforeTax
                        taxRate
                        decimalRoundRule
                        total
                    }
                    whitelistedMediaIds
                    isPublicVisible
                }
                media {
                    id
                    name
                    url
                    bankAccount {
                        bankCode
                        branchCode
                        accountNumber
                        accountHolderName
                    }
                    archivedAt
                }
            }
        }
    }
`;

export type SdkFunctionWrapper = <T>(
    action: (requestHeaders?: Record<string, string>) => Promise<T>,
    operationName: string,
    operationType?: string
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
    return {
        createUser(
            variables: CreateUserMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<CreateUserMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<CreateUserMutation>(CreateUserDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'createUser',
                'mutation'
            );
        },
        createMediaUser(
            variables?: CreateMediaUserMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<CreateMediaUserMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<CreateMediaUserMutation>(CreateMediaUserDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'createMediaUser',
                'mutation'
            );
        },
        createTicket(
            variables: CreateTicketMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<CreateTicketMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<CreateTicketMutation>(CreateTicketDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'createTicket',
                'mutation'
            );
        },
        removeUser(
            variables?: RemoveUserMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<RemoveUserMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<RemoveUserMutation>(RemoveUserDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'removeUser',
                'mutation'
            );
        },
        updateUserBankAccount(
            variables: UpdateUserBankAccountMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<UpdateUserBankAccountMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<UpdateUserBankAccountMutation>(UpdateUserBankAccountDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'updateUserBankAccount',
                'mutation'
            );
        },
        createMedia(
            variables: CreateMediaMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<CreateMediaMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<CreateMediaMutation>(CreateMediaDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'createMedia',
                'mutation'
            );
        },
        createCampaign(
            variables: CreateCampaignMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<CreateCampaignMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<CreateCampaignMutation>(CreateCampaignDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'createCampaign',
                'mutation'
            );
        },
        endReceptionCampaign(
            variables: EndReceptionCampaignMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<EndReceptionCampaignMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<EndReceptionCampaignMutation>(EndReceptionCampaignDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'endReceptionCampaign',
                'mutation'
            );
        },
        refreshCampaignUri(
            variables: RefreshCampaignUriMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<RefreshCampaignUriMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<RefreshCampaignUriMutation>(RefreshCampaignUriDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'refreshCampaignUri',
                'mutation'
            );
        },
        createWithdraw(
            variables: CreateWithdrawMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<CreateWithdrawMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<CreateWithdrawMutation>(CreateWithdrawDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'createWithdraw',
                'mutation'
            );
        },
        createWithdrawConfirmation(
            variables: CreateWithdrawConfirmationMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<CreateWithdrawConfirmationMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<CreateWithdrawConfirmationMutation>(CreateWithdrawConfirmationDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'createWithdrawConfirmation',
                'mutation'
            );
        },
        updateMediaBankAccount(
            variables: UpdateMediaBankAccountMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<UpdateMediaBankAccountMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<UpdateMediaBankAccountMutation>(UpdateMediaBankAccountDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'updateMediaBankAccount',
                'mutation'
            );
        },
        updateMediaName(
            variables: UpdateMediaNameMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<UpdateMediaNameMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<UpdateMediaNameMutation>(UpdateMediaNameDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'updateMediaName',
                'mutation'
            );
        },
        updateMediaUrl(
            variables: UpdateMediaUrlMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<UpdateMediaUrlMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<UpdateMediaUrlMutation>(UpdateMediaUrlDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'updateMediaUrl',
                'mutation'
            );
        },
        closeProgram(
            variables: CloseProgramMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<CloseProgramMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<CloseProgramMutation>(CloseProgramDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'closeProgram',
                'mutation'
            );
        },
        createProgram(
            variables: CreateProgramMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<CreateProgramMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<CreateProgramMutation>(CreateProgramDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'createProgram',
                'mutation'
            );
        },
        modifyProgramCloseAt(
            variables: ModifyProgramCloseAtMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<ModifyProgramCloseAtMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<ModifyProgramCloseAtMutation>(ModifyProgramCloseAtDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'modifyProgramCloseAt',
                'mutation'
            );
        },
        modifyProgramConditionCompleteLimitDays(
            variables: ModifyProgramConditionCompleteLimitDaysMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<ModifyProgramConditionCompleteLimitDaysMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<ModifyProgramConditionCompleteLimitDaysMutation>(
                        ModifyProgramConditionCompleteLimitDaysDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'modifyProgramConditionCompleteLimitDays',
                'mutation'
            );
        },
        modifyProgramConditionPrecaution(
            variables: ModifyProgramConditionPrecautionMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<ModifyProgramConditionPrecautionMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<ModifyProgramConditionPrecautionMutation>(
                        ModifyProgramConditionPrecautionDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'modifyProgramConditionPrecaution',
                'mutation'
            );
        },
        modifyProgramConditionToComplete(
            variables: ModifyProgramConditionToCompleteMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<ModifyProgramConditionToCompleteMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<ModifyProgramConditionToCompleteMutation>(
                        ModifyProgramConditionToCompleteDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'modifyProgramConditionToComplete',
                'mutation'
            );
        },
        modifyProgramConditionToRecognize(
            variables: ModifyProgramConditionToRecognizeMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<ModifyProgramConditionToRecognizeMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<ModifyProgramConditionToRecognizeMutation>(
                        ModifyProgramConditionToRecognizeDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'modifyProgramConditionToRecognize',
                'mutation'
            );
        },
        modifyProgramDescription(
            variables: ModifyProgramDescriptionMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<ModifyProgramDescriptionMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<ModifyProgramDescriptionMutation>(ModifyProgramDescriptionDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'modifyProgramDescription',
                'mutation'
            );
        },
        modifyProgramDescriptionForMedia(
            variables: ModifyProgramDescriptionForMediaMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<ModifyProgramDescriptionForMediaMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<ModifyProgramDescriptionForMediaMutation>(
                        ModifyProgramDescriptionForMediaDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'modifyProgramDescriptionForMedia',
                'mutation'
            );
        },
        modifyProgramEndReceptionAt(
            variables: ModifyProgramEndReceptionAtMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<ModifyProgramEndReceptionAtMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<ModifyProgramEndReceptionAtMutation>(
                        ModifyProgramEndReceptionAtDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'modifyProgramEndReceptionAt',
                'mutation'
            );
        },
        modifyProgramIsPublicVisible(
            variables: ModifyProgramIsPublicVisibleMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<ModifyProgramIsPublicVisibleMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<ModifyProgramIsPublicVisibleMutation>(
                        ModifyProgramIsPublicVisibleDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'modifyProgramIsPublicVisible',
                'mutation'
            );
        },
        modifyProgramOverviewMd(
            variables: ModifyProgramOverviewMdMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<ModifyProgramOverviewMdMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<ModifyProgramOverviewMdMutation>(ModifyProgramOverviewMdDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'modifyProgramOverviewMd',
                'mutation'
            );
        },
        modifyProgramWhitelistedMediaIds(
            variables: ModifyProgramWhitelistedMediaIdsMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<ModifyProgramWhitelistedMediaIdsMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<ModifyProgramWhitelistedMediaIdsMutation>(
                        ModifyProgramWhitelistedMediaIdsDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'modifyProgramWhitelistedMediaIds',
                'mutation'
            );
        },
        runAutoCron1day(
            variables?: RunAutoCron1dayQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<RunAutoCron1dayQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<RunAutoCron1dayQuery>(RunAutoCron1dayDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'runAutoCron1day',
                'query'
            );
        },
        runManualCronForMediaReward(
            variables?: RunManualCronForMediaRewardQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<RunManualCronForMediaRewardQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<RunManualCronForMediaRewardQuery>(RunManualCronForMediaRewardDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'runManualCronForMediaReward',
                'query'
            );
        },
        runManualCronForNewCashback(
            variables?: RunManualCronForNewCashbackQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<RunManualCronForNewCashbackQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<RunManualCronForNewCashbackQuery>(RunManualCronForNewCashbackDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'runManualCronForNewCashback',
                'query'
            );
        },
        runManualCronForRetryingMediaReward(
            variables?: RunManualCronForRetryingMediaRewardQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<RunManualCronForRetryingMediaRewardQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<RunManualCronForRetryingMediaRewardQuery>(
                        RunManualCronForRetryingMediaRewardDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'runManualCronForRetryingMediaReward',
                'query'
            );
        },
        runManualCronForSync(
            variables?: RunManualCronForSyncQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<RunManualCronForSyncQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<RunManualCronForSyncQuery>(RunManualCronForSyncDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'runManualCronForSync',
                'query'
            );
        },
        getAllCampaigns(
            variables?: GetAllCampaignsQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<GetAllCampaignsQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetAllCampaignsQuery>(GetAllCampaignsDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'getAllCampaigns',
                'query'
            );
        },
        getCampaignForPublic(
            variables: GetCampaignForPublicQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<GetCampaignForPublicQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetCampaignForPublicQuery>(GetCampaignForPublicDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'getCampaignForPublic',
                'query'
            );
        },
        getPrivacypolicy(
            variables?: GetPrivacypolicyQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<GetPrivacypolicyQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetPrivacypolicyQuery>(GetPrivacypolicyDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'getPrivacypolicy',
                'query'
            );
        },
        getPublicCampaigns(
            variables?: GetPublicCampaignsQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<GetPublicCampaignsQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetPublicCampaignsQuery>(GetPublicCampaignsDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'getPublicCampaigns',
                'query'
            );
        },
        getServiceHealth(
            variables?: GetServiceHealthQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<GetServiceHealthQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetServiceHealthQuery>(GetServiceHealthDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'getServiceHealth',
                'query'
            );
        },
        getTos(variables?: GetTosQueryVariables, requestHeaders?: Dom.RequestInit['headers']): Promise<GetTosQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetTosQuery>(GetTosDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'getTos',
                'query'
            );
        },
        getTosForMedia(
            variables?: GetTosForMediaQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<GetTosForMediaQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetTosForMediaQuery>(GetTosForMediaDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'getTosForMedia',
                'query'
            );
        },
        getUser(variables?: GetUserQueryVariables, requestHeaders?: Dom.RequestInit['headers']): Promise<GetUserQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetUserQuery>(GetUserDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'getUser',
                'query'
            );
        },
        getMediaUser(
            variables?: GetMediaUserQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<GetMediaUserQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetMediaUserQuery>(GetMediaUserDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'getMediaUser',
                'query'
            );
        },
        getTicketForUser(
            variables: GetTicketForUserQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<GetTicketForUserQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetTicketForUserQuery>(GetTicketForUserDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'getTicketForUser',
                'query'
            );
        },
        getTicketsForUser(
            variables?: GetTicketsForUserQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<GetTicketsForUserQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetTicketsForUserQuery>(GetTicketsForUserDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'getTicketsForUser',
                'query'
            );
        },
        getMediaMembersForMediaUser(
            variables?: GetMediaMembersForMediaUserQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<GetMediaMembersForMediaUserQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetMediaMembersForMediaUserQuery>(GetMediaMembersForMediaUserDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'getMediaMembersForMediaUser',
                'query'
            );
        },
        getCampaignsForMedia(
            variables: GetCampaignsForMediaQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<GetCampaignsForMediaQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetCampaignsForMediaQuery>(GetCampaignsForMediaDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'getCampaignsForMedia',
                'query'
            );
        },
        getMedia(
            variables: GetMediaQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<GetMediaQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetMediaQuery>(GetMediaDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'getMedia',
                'query'
            );
        },
        getMediaBalances(
            variables: GetMediaBalancesQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<GetMediaBalancesQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetMediaBalancesQuery>(GetMediaBalancesDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'getMediaBalances',
                'query'
            );
        },
        getProgramsForMedia(
            variables: GetProgramsForMediaQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<GetProgramsForMediaQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetProgramsForMediaQuery>(GetProgramsForMediaDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'getProgramsForMedia',
                'query'
            );
        },
        getRecentMediaBalance(
            variables: GetRecentMediaBalanceQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<GetRecentMediaBalanceQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetRecentMediaBalanceQuery>(GetRecentMediaBalanceDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'getRecentMediaBalance',
                'query'
            );
        },
        getTicketsForCampaignForMedia(
            variables: GetTicketsForCampaignForMediaQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<GetTicketsForCampaignForMediaQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetTicketsForCampaignForMediaQuery>(
                        GetTicketsForCampaignForMediaDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'getTicketsForCampaignForMedia',
                'query'
            );
        },
        getMediaMembersForMedia(
            variables: GetMediaMembersForMediaQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<GetMediaMembersForMediaQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetMediaMembersForMediaQuery>(GetMediaMembersForMediaDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'getMediaMembersForMedia',
                'query'
            );
        },
        getPrograms(
            variables?: GetProgramsQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<GetProgramsQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetProgramsQuery>(GetProgramsDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'getPrograms',
                'query'
            );
        },
        getSignedUrlToUploadProgramAdvertiserIcon(
            variables: GetSignedUrlToUploadProgramAdvertiserIconQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<GetSignedUrlToUploadProgramAdvertiserIconQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetSignedUrlToUploadProgramAdvertiserIconQuery>(
                        GetSignedUrlToUploadProgramAdvertiserIconDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'getSignedUrlToUploadProgramAdvertiserIcon',
                'query'
            );
        },
        getSignedUrlToUploadProgramEyecatch(
            variables: GetSignedUrlToUploadProgramEyecatchQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<GetSignedUrlToUploadProgramEyecatchQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetSignedUrlToUploadProgramEyecatchQuery>(
                        GetSignedUrlToUploadProgramEyecatchDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'getSignedUrlToUploadProgramEyecatch',
                'query'
            );
        },
        getTickets(
            variables: GetTicketsQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<GetTicketsQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetTicketsQuery>(GetTicketsDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'getTickets',
                'query'
            );
        },
        getUserInfo(
            variables: GetUserInfoQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<GetUserInfoQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetUserInfoQuery>(GetUserInfoDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders,
                    }),
                'getUserInfo',
                'query'
            );
        },
    };
}
export type Sdk = ReturnType<typeof getSdk>;
