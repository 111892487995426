import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import { ProgramForMedia, Campaign, ProgramForAdmin, ProgramApiForAdmin } from '@/utils/MoneybackClient';
import {
    getDateStr,
    getCampaignEndedDate,
    getCampaignEndReceptionDate,
    getProgramEndReceptionDate,
    getProgramEndedDate,
    getProgramCloseDate,
    getProgramClosedDate,
    getPrevDate2359,
} from '@/utils/utilFunctions';
import { Simple2ColumnsTable } from '@/components/0_atom/Simple2ColumnsTable';

const useStyles = makeStyles((theme) => ({
    description: {
        color: theme.palette.text.disabled,
        fontSize: theme.typography.body2.fontSize,
        margin: '4px 0 0 0',
        display: 'flex',
        '&::before': {
            content: '"*"',
            padding: '2px 4px 0 0',
        },
    },
    lightColor: {
        color: theme.palette.text.disabled,
    },
}));

export const EndSchedule: FC<{
    program: ProgramForMedia | ProgramForAdmin | ProgramApiForAdmin;
    campaign: Campaign | undefined;
}> = ({ program, campaign }) => {
    // STYLE
    const c = useStyles(useTheme());

    // DATA
    const endDate = (() => {
        if (campaign) {
            return getCampaignEndReceptionDate({ program, campaign });
        }
        return getProgramEndReceptionDate({ program });
    })();
    const endedDate = (() => {
        if (campaign) {
            return getCampaignEndedDate({ program, campaign });
        }
        return getProgramEndedDate({ program });
    })();
    const closeDate = getProgramCloseDate({ program });
    const closedDate = getProgramClosedDate({ program });

    return (
        <>
            <Simple2ColumnsTable
                items={[
                    [
                        '受付終了',
                        endedDate ? (
                            getDateStr({ date: getPrevDate2359({ date: endedDate }), isWithDay: true })
                        ) : endDate ? (
                            `${getDateStr({ date: getPrevDate2359({ date: endDate }), isWithDay: true })} 予定`
                        ) : (
                            <span className={c.lightColor}>{'未定'}</span>
                        ),
                    ],
                    [
                        '終了(成果締切)',
                        closedDate ? (
                            getDateStr({ date: getPrevDate2359({ date: closedDate }), isWithDay: true })
                        ) : closeDate ? (
                            `${getDateStr({ date: getPrevDate2359({ date: closeDate }), isWithDay: true })} 予定`
                        ) : (
                            <span className={c.lightColor}>{'未定'}</span>
                        ),
                    ],
                ]}
            />
            <MuiTypography className={c.description}>
                {'キャンペーンが受付終了すると、新たに参加できません。参加済みの方への影響はありません。'}
            </MuiTypography>
            <MuiTypography className={c.description}>
                {'キャンペーンが終了(成果締切)すると、それ以降の条件達成は無効になります。'}
            </MuiTypography>
        </>
    );
};
EndSchedule.displayName = 'EndSchedule';
