import { FC, useMemo } from 'react';
import clsx from 'clsx';
import SimpleMDE from 'react-simplemde-editor';
import { Options as EasymdeOptions } from 'easymde';
import 'easymde/dist/easymde.min.css';

import { makeStyles, useTheme } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        fontWeight: 'normal',
        '& .EasyMDEContainer': {
            display: 'flex',
            flexDirection: 'column-reverse',
        },
        '& .EasyMDEContainer > div': {
            border: 'none',
            background: 'none',
        },
        '& .EasyMDEContainer .CodeMirror .CodeMirror-scroll': {
            maxHeight: 400,
        },
        '& .EasyMDEContainer .editor-toolbar': {
            borderTop: 'solid 1px rgba(0,0,0,.1)',
            borderRadius: 0,
            // textAlign: 'right',
        },
    },
}));

export const MarkdownEditor: FC<{
    textMd: string;
    setTextMd: (text: string) => void;
    className?: string;
    placeholder?: string;
}> = ({ textMd, setTextMd, className, placeholder }) => {
    // STYLE
    const c = useStyles(useTheme());

    // MEMO
    const options: EasymdeOptions = useMemo(
        () => ({
            placeholder,
            status: false,
            spellChecker: false,
            toolbar: [
                'bold',
                'link',
                '|',
                'heading-2',
                'heading-3',
                '|',
                'unordered-list',
                'ordered-list',
                'table',
                'quote',
                '|',
                'guide',
            ],
        }),
        [placeholder]
    );

    return <SimpleMDE value={textMd} onChange={setTextMd} className={clsx(c.root, className)} options={options} />;
};
MarkdownEditor.displayName = 'MarkdownEditor';
