import { useEffect, useState, useCallback } from 'react';

import { initMoneybackPublicClient } from '@/utils/MoneybackClient';

export const useTosForMedia = ({ isStartFetch }: { isStartFetch: boolean }) => {
    // HOOKS
    const [tosForMedia, setTosForMedia] = useState<string | undefined>(undefined);

    // CALLBACK
    const initialize = useCallback(async () => {
        const client = initMoneybackPublicClient();
        const fetchedTosForMedia = await client.getTosForMedia();
        setTosForMedia(fetchedTosForMedia);
    }, []);

    // USEEFFECT
    useEffect(() => {
        if (isStartFetch && !tosForMedia) {
            initialize();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialize, isStartFetch]);

    return {
        tosForMedia,
    };
};
