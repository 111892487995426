import { GqlDateTimeData } from '@/utils/MoneybackClient/graphql/customScalars';
import { Campaign as GqlCampaign } from '@/utils/MoneybackClient/graphql/generated/graphqlClient';
import { Price, getPriceFromGql } from '@/utils/MoneybackClient/models/Price';

export type CampaignType = {
    id: string;
    createdAt: Date;
    updatedAt?: Date;
    cashbackAmount: number;
    mediaReward: Price;
    uri: string;
    endReceptionAt?: Date;
};

export class Campaign implements CampaignType {
    protected _id: string;

    protected _createdAt: Date;

    protected _updatedAt?: Date;

    protected _cashbackAmount: number;

    protected _mediaReward: Price;

    protected _uri: string;

    protected _endReceptionAt?: Date;

    get id() {
        return this._id;
    }

    get createdAt() {
        return this._createdAt;
    }

    get updatedAt() {
        return this._updatedAt;
    }

    get cashbackAmount() {
        return this._cashbackAmount;
    }

    get mediaReward() {
        return this._mediaReward;
    }

    get uri() {
        return this._uri;
    }

    get endReceptionAt() {
        return this._endReceptionAt;
    }

    get data() {
        return {
            id: this.id,
            createdAt: this.createdAt,
            updatedAt: this.updatedAt,
            cashbackAmount: this.cashbackAmount,
            mediaReward: this.mediaReward,
            uri: this.uri,
            endReceptionAt: this.endReceptionAt,
        };
    }

    constructor({ id, createdAt, updatedAt, cashbackAmount, mediaReward, uri, endReceptionAt }: CampaignType) {
        this._id = id;
        this._createdAt = createdAt;
        this._updatedAt = updatedAt;
        this._cashbackAmount = cashbackAmount;
        this._mediaReward = mediaReward;
        this._uri = uri;
        this._endReceptionAt = endReceptionAt;
    }
}

export const getCampaignFromGql = ({
    id,
    createdAt,
    updatedAt,
    cashbackAmount,
    mediaReward,
    uri,
    endReceptionAt,
}: GqlCampaign) => {
    return new Campaign({
        id,
        createdAt: GqlDateTimeData.serialize(createdAt),
        updatedAt: updatedAt ? GqlDateTimeData.serialize(updatedAt) : undefined,
        cashbackAmount,
        mediaReward: getPriceFromGql(mediaReward),
        uri,
        endReceptionAt: endReceptionAt ? GqlDateTimeData.serialize(endReceptionAt) : undefined,
    });
};
