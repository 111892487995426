import { FC } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { BankAccount } from '@/utils/MoneybackClient';
import SvgCheck from '@/svgs/fa-check-light';
import SvgTimes from '@/svgs/fa-times-light';

const useStyles = makeStyles((theme) => ({
    root: {
        border: 'solid 1px rgba(0,0,0,.1)',
        borderRadius: 4,
        padding: '16px 17px',
        width: '100%',
    },
    status: {},
    content: {
        fontWeight: 600,
        '& th': {
            textAlign: 'left',
            verticalAlign: 'top',
            color: theme.palette.text.disabled,
            paddingRight: '1em',
            paddingTop: 4,
            paddingBottom: 4,
        },
        '& td': {
            textAlign: 'left',
            verticalAlign: 'top',
            paddingTop: 4,
            paddingBottom: 4,
        },
    },
    verified: {},
    failed: {},
}));

export const BankAccountCard: FC<{
    className?: string;
    bankAccount: BankAccount;
}> = ({ className, bankAccount }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div className={clsx(c.root, className)}>
            {/* {bankAccount.verifiedAt ? (
                <div className={clsx(c.status, c.verified)}>
                    <SvgCheck />
                    <span>{'有効な銀行口座'}</span>
                </div>
            ) : bankAccount.failedAt ? (
                <div className={clsx(c.status, c.failed)}>
                    <SvgTimes />
                    <span>{'無効な銀行口座'}</span>
                </div>
            ) : (
                <></>
            )} */}
            <table className={c.content}>
                <tbody>
                    <tr>
                        <th>{'銀行コード'}</th>
                        <td>{bankAccount.bankCode}</td>
                    </tr>
                    <tr>
                        <th>{'支店コード'}</th>
                        <td>{bankAccount.branchCode}</td>
                    </tr>
                    <tr>
                        <th>{'口座番号'}</th>
                        <td>{bankAccount.accountNumber}</td>
                    </tr>
                    <tr>
                        <th>{'名義人'}</th>
                        <td>{bankAccount.accountHolderName}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};
BankAccountCard.displayName = 'BankAccountCard';
