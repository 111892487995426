import { FC, useState, useEffect } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { ProgramForMedia } from '@/utils/MoneybackClient';
import { useErrorCtx } from '@/utils/ctxs';
import { usePrograms, useRouting } from '@/utils/customHooks';
import { LoadingIndicator } from '@/components/0_atom/LoadingIndicator';
import { ProgramCard } from '@/components/2_org/ProgramCard';
import { ProgramModal } from '@/components/2_org/ProgramModal';

const useStyles = makeStyles((theme) => ({
    root: {},
    empty: {
        fontWeight: 600,
        color: theme.palette.text.disabled,
    },
    card: {
        '&:not(:last-child)': {
            marginBottom: 14,
        },
    },
}));

export const ConsoleTemplatePrograms: FC = () => {
    // STYLE

    const c = useStyles(useTheme());

    // HOOKS
    const { setIs404 } = useErrorCtx();
    const { programs } = usePrograms();
    const { setViewSlug1, viewSlug1 } = useRouting();
    const [selectedProgram, setSelectedProgram] = useState<ProgramForMedia | undefined>(undefined);

    // USEEFFECT
    useEffect(() => {
        if (programs && viewSlug1) {
            const target = programs.find(({ id }) => id === viewSlug1);
            if (target) {
                setSelectedProgram(target);
            } else {
                setIs404(true);
            }
        } else if (programs && !viewSlug1) {
            setSelectedProgram(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewSlug1, programs]);

    return (
        <>
            {programs ? (
                programs.length > 0 ? (
                    programs.map((program) => (
                        <ProgramCard
                            className={c.card}
                            key={program.id}
                            program={program}
                            onClick={() => setViewSlug1({ slug1: program.id })}
                            enabledFields={{
                                mediaFund: true,
                                cashbackAmountRange: true,
                                schedule: true,
                                eyecatch: true,
                            }}
                        />
                    ))
                ) : (
                    <p className={c.empty}>{'現在、利用いただけるプログラムがありません。'}</p>
                )
            ) : (
                <LoadingIndicator />
            )}
            <ProgramModal
                program={selectedProgram}
                onClose={() => setViewSlug1({ slug1: undefined })}
                isWithCreateCampaignButton={true}
            />
        </>
    );
};
ConsoleTemplatePrograms.displayName = 'ConsoleTemplatePrograms';
