import { FC, ReactNode, useState, useCallback, useEffect } from 'react';

import { useUserCtx, MediaCtx, InitializeClient, useErrorCtx } from '@/utils/ctxs';
import { useRouting } from '@/utils/customHooks';
import {
    initMoneybackGeneralClient,
    initMoneybackObserverClient,
    initMoneybackOwnerClient,
    MoneybackGeneralClient,
    MoneybackObserverClient,
    MoneybackOwnerClient,
} from '@/utils/MoneybackClient';

const MediaProvider: FC<{
    children: ReactNode;
}> = ({ children }) => {
    // HOOKS
    const { setIs404 } = useErrorCtx();
    const { mediaId } = useRouting();
    const { firebaseUser, userClient, mediaMembers } = useUserCtx();
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [mediaClient, setMediaClient] = useState<
        MoneybackGeneralClient | MoneybackObserverClient | MoneybackOwnerClient | undefined
    >(undefined);

    // INITIALIZE
    const initialize: InitializeClient = useCallback(
        async ({ mediaId, role }) => {
            if (firebaseUser && userClient) {
                setIsFetching(true);
                const client = await (() => {
                    if (role === 'owner') {
                        return initMoneybackOwnerClient({
                            firebaseUserIdToken: firebaseUser.idToken,
                            mediaId,
                        });
                    }
                    if (role === 'general') {
                        return initMoneybackGeneralClient({
                            firebaseUserIdToken: firebaseUser.idToken,
                            mediaId,
                        });
                    }
                    return initMoneybackObserverClient({
                        firebaseUserIdToken: firebaseUser.idToken,
                        mediaId,
                    });
                })();
                setMediaClient(client);
                setIsFetching(false);
                return client;
            }
            throw new Error('MediaProvider.initialize: missing firebaseUser or userClient');
        },
        [firebaseUser, userClient]
    );
    const handleSwitch = useCallback(
        async ({ newMediaId }: { newMediaId: string }) => {
            if (mediaMembers) {
                const target = mediaMembers.find(({ media }) => media.id === newMediaId);
                if (target) {
                    await initialize({
                        mediaId: target.media.id,
                        role: target.mediaMember.role,
                    });
                } else {
                    setIs404(true);
                }
            }
        },
        [mediaMembers, initialize, setIs404]
    );

    // USEEFFECT
    useEffect(() => {
        if (mediaId && firebaseUser && userClient) {
            handleSwitch({ newMediaId: mediaId });
        }
    }, [handleSwitch, mediaId, firebaseUser, userClient]);

    return (
        <MediaCtx.Provider
            value={{
                mediaClient,
                isFetching,
                reInitialize: initialize,
            }}
        >
            {children}
        </MediaCtx.Provider>
    );
};
MediaProvider.displayName = 'MediaProvider';
export default MediaProvider;
