import { FC } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import { ProgramForMedia } from '@/utils/MoneybackClient';
import { getDateStr, getProgramEndedDate, getProgramClosedDate, getPrevDate2359 } from '@/utils/utilFunctions';
import { DetailsItem } from '@/components/0_atom/DetailsItem';
import { Card } from '@/components/0_atom/Card';
import { EndSchedule } from '@/components/1_mol/EndSchedule';
import { ProgramHeading } from '@/components/1_mol/ProgramHeading';

const useStyles = makeStyles((theme) => ({
    root: {
        background: '#fff',
    },
    eyecatch: {
        maxHeight: 200,
        objectFit: 'contain',
        borderRadius: 4,
        marginBottom: 14,
        marginLeft: 0,
        marginRight: 'auto',
        border: 'solid 1px rgba(0,0,0,.05)',
    },
    title: {
        fontWeight: 600,
    },
    description: {
        fontSize: theme.typography.body2.fontSize,
        marginTop: 11,
    },
    end: {
        fontSize: theme.typography.body2.fontSize,
        color: theme.palette.text.disabled,
    },
    cannotCreateCampaign: {
        textAlign: 'right',
        marginBottom: 4,
        '& > span': {
            fontSize: theme.typography.caption.fontSize,
            color: theme.palette.text.disabled,
            fontWeight: 600,
            padding: '2px 4px',
            border: 'solid 1px rgba(0,0,0,.1)',
            display: 'inline-block',
        },
    },
}));

export const ProgramCard: FC<{
    className?: string;
    program: ProgramForMedia;
    onClick?: () => void;
    enabledFields: {
        eyecatch?: boolean;
        mediaFund?: boolean;
        schedule?: boolean;
        cashbackAmountRange?: boolean;
    };
}> = ({ className, program, onClick, enabledFields }) => {
    // STYLE
    const c = useStyles(useTheme());

    // DATA
    const endedDate = getProgramEndedDate({ program });
    const closedDate = getProgramClosedDate({ program });

    return (
        <Card className={clsx(c.root, className)} onClick={onClick}>
            {program.eyecatchSrc && enabledFields.eyecatch && (
                <img src={program.eyecatchSrc} alt={'eyecatch'} className={c.eyecatch} />
            )}
            {!program.isAbleToCreateCampaign && (
                <div className={c.cannotCreateCampaign}>
                    <span>{'審査が必要'}</span>
                </div>
            )}
            {closedDate ? (
                <MuiTypography className={c.end}>
                    {`${getDateStr({ date: getPrevDate2359({ date: closedDate }), isWithDay: true })}で終了(成果締切)`}
                </MuiTypography>
            ) : endedDate ? (
                <MuiTypography className={c.end}>
                    {`${getDateStr({ date: getPrevDate2359({ date: endedDate }), isWithDay: true })}で受付終了`}
                </MuiTypography>
            ) : (
                <></>
            )}
            <ProgramHeading program={program} />
            <MuiTypography className={c.description}>
                {program.description.replace(/\[cashbackAmount\]/g, '<CB金額>')}
            </MuiTypography>
            {enabledFields.mediaFund && (
                <DetailsItem
                    label={'報酬全額'}
                    content={`¥ ${program.mediaFund.toLocaleString()}`}
                    variant={'full'}
                    direction={'row'}
                />
            )}
            {enabledFields.schedule && (
                <DetailsItem
                    label={'終了日'}
                    content={<EndSchedule program={program} campaign={undefined} />}
                    variant={'full'}
                    direction={'row'}
                />
            )}
            {enabledFields.cashbackAmountRange &&
                (program.cashbackAmountRange.low || program.cashbackAmountRange.high) && (
                    <DetailsItem
                        label={'CB金額制限'}
                        content={(() => {
                            let str = '';
                            if (program.cashbackAmountRange.low) {
                                str += `${program.cashbackAmountRange.low.toLocaleString()}円以上`;
                            }
                            if (program.cashbackAmountRange.high) {
                                if (program.cashbackAmountRange.low) {
                                    str += ' ';
                                }
                                str += `${program.cashbackAmountRange.high.toLocaleString()}円以下`;
                            }
                            return str;
                        })()}
                        variant={'full'}
                        direction={'row'}
                    />
                )}
        </Card>
    );
};
ProgramCard.displayName = 'ProgramCard';
