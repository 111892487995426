import { FC, useState } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import {
    CreateProgramInput,
    ProgramForAdmin,
    CashbackAmountRange,
    ProgramCondition,
    Price,
} from '@/utils/MoneybackClient';
import Button from '@/components/0_atom/Button';
import { Modal } from '@/components/0_atom/Modal';
import { LoadingIndicator } from '@/components/0_atom/LoadingIndicator';
import { ConsoleTemplateAdminProgramData } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramData';
import { CreateProgramModalSubmitButton } from '@/components/3_template/ConsoleTemplate/Admin/Programs/CreateProgramModal/SubmitButton';

const useStyles = makeStyles((theme) => ({
    root: {},
    confirmButton: {
        marginTop: 40,
    },
    heading: {
        fontSize: theme.typography.body1.fontSize,
        margin: '0 0 60px 0',
        textAlign: 'center',
    },
    buttons: {
        display: 'flex',
        marginTop: 40,
    },
    cancelButton: {
        flexGrow: 1,
        width: 'calc(50% - 14px)',
        border: 'solid 2px rgba(0,0,0,.05)',
        fontWeight: 600,
    },
    submitButton: {
        flexGrow: 1,
        marginLeft: 14,
        width: 'calc(50% - 14px)',
    },
}));

export const CreateProgramModalConfirmationButton: FC<{
    createData: CreateProgramInput | undefined;
    onComplete: () => Promise<void>;
}> = ({ createData, onComplete }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <Button disabled={!createData} onClick={() => setIsOpen(true)} role={'submit'} className={c.confirmButton}>
                {'確認'}
            </Button>
            <Modal open={!!createData && isOpen} onClose={() => setIsOpen(false)} anchor={'bottom'}>
                <MuiTypography variant={'h1'} className={c.heading}>
                    {'以下の内容でプログラムを作成しますか？'}
                </MuiTypography>
                {createData ? (
                    <>
                        <ConsoleTemplateAdminProgramData
                            program={
                                new ProgramForAdmin({
                                    ...createData,
                                    id: 'mock',
                                    createdAt: new Date(),
                                    cashbackAmountRange: new CashbackAmountRange(createData.cashbackAmountRange),
                                    condition: new ProgramCondition(createData.condition),
                                    totalPrice: new Price(createData.totalPrice),
                                    advertiserIconSrc: createData.advertiserIcon
                                        ? window.URL.createObjectURL(createData.advertiserIcon)
                                        : undefined,
                                    eyecatchSrc: createData.eyecatch
                                        ? window.URL.createObjectURL(createData.eyecatch)
                                        : undefined,
                                })
                            }
                        />
                        <div className={c.buttons}>
                            <Button onClick={() => setIsOpen(false)} className={c.cancelButton}>
                                {'修正'}
                            </Button>
                            <CreateProgramModalSubmitButton
                                className={c.submitButton}
                                createData={createData}
                                onComplete={onComplete}
                            />
                        </div>
                    </>
                ) : (
                    <LoadingIndicator />
                )}
            </Modal>
        </>
    );
};
CreateProgramModalConfirmationButton.displayName = 'CreateProgramModalConfirmationButton';
