import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { ProgramForMedia, MoneybackGeneralClient, MoneybackOwnerClient } from '@/utils/MoneybackClient';
import { useRouting } from '@/utils/customHooks';
import MutationButton from '@/components/0_atom/MutationButton';

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: 40,
    },
}));

export const CreateCampaignModalSubmitButton: FC<{
    program: ProgramForMedia;
    cashbackAmount: number;
    client: MoneybackGeneralClient | MoneybackOwnerClient;
    isDisabled?: boolean;
}> = ({ program, cashbackAmount, client, isDisabled }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { setViewSlug1 } = useRouting();

    // MUTATION
    const run = async () => {
        const newCampaign = await client.createCampaign({
            programId: program.id,
            cashbackAmount,
        });
        setViewSlug1({
            viewName: 'campaigns',
            slug1: newCampaign.id,
        });
        return 'success' as const;
    };

    return (
        <MutationButton
            className={c.button}
            mutation={{
                run,
                description: '新しいキャンペーンを作成',
            }}
            disabled={isDisabled}
            role={'submit'}
        >
            {'作成'}
        </MutationButton>
    );
};
CreateCampaignModalSubmitButton.displayName = 'CreateCampaignModalSubmitButton';
