import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Brand } from '@/components/0_atom/Brand';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateY(-50%) translateX(-50%)',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    brand: {
        width: 140,
    },
    h1: {
        margin: '40px 0 0 0',
        fontSize: 18,
    },
    description: {
        marginTop: 14,
        color: theme.palette.text.disabled,
    },
}));

export const ErrorTemplate: FC<{
    errorCode: 404 | 500;
}> = ({ errorCode }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div className={c.root}>
            <Brand className={c.brand} />
            <h1 className={c.h1}>{`Error: ${String(errorCode)}`}</h1>
            <p className={c.description}>{errorCode === 404 ? 'URLが正しくありません' : 'エラーが発生しました。'}</p>
        </div>
    );
};
ErrorTemplate.displayName = 'ErrorTemplate';
