import { FC, useState } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { ProgramApiForAdmin } from '@/utils/MoneybackClient';
import { DetailsItem } from '@/components/0_atom/DetailsItem';
import { ImgInputWithLabel } from '@/components/1_mol/InputWithLabel';
import { ConsoleTemplateAdminProgramsAdminProgramCard } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramCard';
import { ModifyAdvertiserIconModalSubmitButton } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramModal/ModifyAdvertiserIconModal/SubmitButton';

const useStyles = makeStyles((theme) => ({
    root: {},
    input: {
        marginTop: 40,
        marginBottom: 14,
    },
    existingImg: {
        width: '100%',
        maxWidth: 200,
        borderRadius: 4,
    },
}));

export const ModifyAdvertiserIconModalContent: FC<{
    programAPI: ProgramApiForAdmin;
    onClose: () => void;
}> = ({ programAPI, onClose }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const [newValue, setNewValue] = useState<File | undefined>(undefined);

    return (
        <>
            <ConsoleTemplateAdminProgramsAdminProgramCard
                programAPI={programAPI}
                enabledFields={{
                    advertiserProgramId: true,
                }}
            />
            <DetailsItem
                label={'これまでの広告主アイコン'}
                content={
                    programAPI.advertiserIconSrc ? (
                        <img
                            src={programAPI.advertiserIconSrc}
                            alt={'これまでの広告主アイコン'}
                            className={c.existingImg}
                        />
                    ) : (
                        'なし'
                    )
                }
                variant={'full'}
                direction={'column'}
            />
            <ImgInputWithLabel
                className={c.input}
                file={newValue}
                setFile={setNewValue}
                labelText={'新しい広告主アイコン'}
            />
            <ModifyAdvertiserIconModalSubmitButton
                programAPI={programAPI}
                newValue={newValue}
                isDisabled={undefined}
                onComplete={onClose}
            />
        </>
    );
};
ModifyAdvertiserIconModalContent.displayName = 'ModifyAdvertiserIconModalContent';
