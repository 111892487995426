import { forwardRef, ComponentProps, ReactNode } from 'react';
import clsx from 'clsx';

import { useTheme } from '@material-ui/core/styles';
import MuiInputBase from '@material-ui/core/InputBase';
import MuiInputAdornment from '@material-ui/core/InputAdornment';
import { useStyles, InputHelpers } from '@/components/0_atom/Input/common';

export const TextInput = forwardRef<
    HTMLElement,
    ComponentProps<typeof MuiInputBase> & {
        value: string | undefined;
        setValue: (value: string | undefined) => void;
        messages?: {
            isError?: boolean;
            message: ReactNode;
        }[];
        isSatisfied?: boolean;
    }
>(({ value, setValue, messages, isSatisfied, error, className, startAdornment, endAdornment, ...otherProps }, ref) => {
    // STYLE
    const c = useStyles(useTheme());

    // HANDLER
    const handleChange = (value: string) => {
        setValue(value || undefined);
    };

    return (
        <>
            <MuiInputBase
                ref={ref}
                {...otherProps}
                className={clsx(c.root, className, {
                    [c.rootSatisfied]: isSatisfied,
                })}
                value={value || ''}
                onChange={({ target }) => handleChange(target.value)}
                error={!!messages?.find(({ isError }) => isError) || error}
                startAdornment={
                    startAdornment ? (
                        <MuiInputAdornment position={'start'}>{startAdornment}</MuiInputAdornment>
                    ) : undefined
                }
                endAdornment={
                    endAdornment ? <MuiInputAdornment position={'end'}>{endAdornment}</MuiInputAdornment> : undefined
                }
            />
            <InputHelpers items={messages} />
        </>
    );
});
TextInput.displayName = 'TextInput';
