import { TicketWithCampaign } from '@/utils/MoneybackClient';

export type TicketsArrRow = [
    Date,
    string,
    string,
    string | undefined,
    string | undefined,
    Date | undefined,
    Date | undefined,
    Date | undefined,
    number,
    number,
    number
];
export const headerRow = [
    'クリック日',
    'ADDパラメータ',
    '掲載メディア',
    'リファラ',
    'ユーザーエージェント',
    '成果発生日',
    '成果確定日',
    '成果却下日',
    'キャッシュバック額',
    'メディア報酬(税抜)',
    '売上(税抜)',
];

export const getTicketsArrRow = ({ ticket, media, campaign, program }: TicketWithCampaign): TicketsArrRow => {
    return [
        ticket.createdAt,
        ticket.trackingCode,
        media.url,
        ticket.referer,
        ticket.userAgent,
        ticket.recognizedAt,
        ticket.settlement &&
        (ticket.settlement.judgement === 'approve' || ticket.settlement.judgement === 'reducedApprove')
            ? ticket.settlement.at
            : undefined,
        ticket.settlement && ticket.settlement.judgement === 'reject' ? ticket.settlement.at : undefined,
        campaign.cashbackAmount,
        campaign.mediaReward.beforeTax,
        program.totalPrice.beforeTax,
    ];
};
