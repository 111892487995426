import { FC, useEffect } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useRouting } from '@/utils/customHooks';
import { GlobalHeader } from '@/components/2_org/GlobalHeader';
import { ConsoleTemplateCampaigns } from '@/components/3_template/ConsoleTemplate/Campaigns';
import { ConsoleTemplatePrograms } from '@/components/3_template/ConsoleTemplate/Programs';
import { ConsoleTemplateBalances } from '@/components/3_template/ConsoleTemplate/Balances';
import { ConsoleTemplateConfig } from '@/components/3_template/ConsoleTemplate/Config';
import { ConsoleTemplateResources } from '@/components/3_template/ConsoleTemplate/Resources';
import { ConsoleTemplateAdmin } from '@/components/3_template/ConsoleTemplate/Admin';
import { ConsoleTemplateStaff } from '@/components/3_template/ConsoleTemplate/Staff';

const useStylesM = makeStyles((theme) => ({
    root: {
        marginTop: 0,
        paddingTop: 30 + 83,
        padding: 22,
    },
    heading: {
        margin: 0,
        fontSize: 18,
        borderBottom: 'solid 2px rgba(0,0,0,.03)',
        lineHeight: '2',
    },
    content: {
        padding: '65px 0 20px 0',
    },
}));
const useStylesD = makeStyles((theme) => ({
    root: {
        marginTop: 0,
        paddingTop: 88 + 77,
        padding: 71,
        maxWidth: 700,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    heading: {
        margin: 0,
        fontSize: 18,
        borderBottom: 'solid 2px rgba(0,0,0,.03)',
        lineHeight: '2',
    },
    content: {
        padding: '65px 0 20px 0',
    },
}));

export const ConsoleTemplate: FC = () => {
    // STYLE
    const t = useTheme();
    const m = useStylesM(t);
    const d = useStylesD(t);

    // HOOKS
    const isDesktop = useMediaQuery('(min-width:700px)');
    const { viewName, switchView, mediaId } = useRouting();

    // USEEFFECT
    useEffect(() => {
        if (!viewName && mediaId) {
            switchView({ mediaId, viewName: 'campaigns' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewName]);

    // DATA
    const viewNameStr = (() => {
        if (viewName === 'campaigns') {
            return 'キャンペーン一覧';
        }
        if (viewName === 'programs') {
            return 'プログラムを探す';
        }
        if (viewName === 'balances') {
            return '売上';
        }
        if (viewName === 'config') {
            return 'メディアの情報';
        }
        if (viewName === 'resources') {
            return 'その他の情報';
        }
        if (viewName === 'adminprograms') {
            return 'Admin: プログラムを管理';
        }
        if (viewName === 'adminreports') {
            return 'Admin: レポート';
        }
        if (viewName === 'staffuserinfo') {
            return 'Staff: ユーザー調査';
        }
        return undefined;
    })();

    const content = (() => {
        if (viewName === 'campaigns') {
            return <ConsoleTemplateCampaigns />;
        }
        if (viewName === 'programs') {
            return <ConsoleTemplatePrograms />;
        }
        if (viewName === 'balances') {
            return <ConsoleTemplateBalances />;
        }
        if (viewName === 'config') {
            return <ConsoleTemplateConfig />;
        }
        if (viewName === 'resources') {
            return <ConsoleTemplateResources />;
        }
        if (viewName === 'adminprograms') {
            return <ConsoleTemplateAdmin viewName={'programs'} />;
        }
        if (viewName === 'adminreports') {
            return <ConsoleTemplateAdmin viewName={'reports'} />;
        }
        if (viewName === 'admincron') {
            return <ConsoleTemplateAdmin viewName={'cron'} />;
        }
        if (viewName === 'staffuserinfo') {
            return <ConsoleTemplateStaff viewName={'userinfo'} />;
        }
        return undefined;
    })();

    return (
        <>
            <GlobalHeader />
            {viewName && (
                <div className={isDesktop ? d.root : m.root}>
                    <h1 className={isDesktop ? d.heading : m.heading}>{viewNameStr}</h1>
                    <div className={isDesktop ? d.content : m.content}>{content}</div>
                </div>
            )}
        </>
    );
};
ConsoleTemplate.displayName = 'ConsoleTemplate';
