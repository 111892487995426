import { FC, useState } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import { ProgramApiForAdmin } from '@/utils/MoneybackClient';
import { TextInputWithLabel } from '@/components/1_mol/InputWithLabel';
import { ConsoleTemplateAdminProgramsAdminProgramCard } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramCard';
import { CloseModalSubmitButton } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramModal/CloseModal/SubmitButton';

const useStyles = makeStyles((theme) => ({
    root: {},
    input: {
        marginTop: 40,
        marginBottom: 14,
    },
}));

export const CloseModalContent: FC<{
    programAPI: ProgramApiForAdmin;
    onClose: () => void;
}> = ({ programAPI, onClose }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const [reason, setReason] = useState<string | undefined>(undefined);

    // DATA
    const isOk = !!reason;

    return (
        <>
            <MuiTypography>{'以下のプログラムを終了します。これは取り消せません。'}</MuiTypography>
            <ConsoleTemplateAdminProgramsAdminProgramCard
                programAPI={programAPI}
                enabledFields={{
                    advertiserProgramId: true,
                }}
            />
            <TextInputWithLabel
                className={c.input}
                value={reason}
                setValue={setReason}
                messages={undefined}
                isSatisfied={undefined}
                labelText={'終了の理由'}
                placeholder={'終了の理由'}
            />
            <CloseModalSubmitButton
                programAPI={programAPI}
                reason={reason || ''}
                isDisabled={!isOk}
                onComplete={onClose}
            />
        </>
    );
};
CloseModalContent.displayName = 'CloseModalContent';
