import { FC, useState } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { MoneybackOwnerClient } from '@/utils/MoneybackClient';
import { DetailsItem } from '@/components/0_atom/DetailsItem';
import { TextInputWithLabel } from '@/components/1_mol/InputWithLabel';
import { ModifyNameModalSubmitButton } from '@/components/3_template/ConsoleTemplate/Config/ModifyNameModal/SubmitButton';

const useStyles = makeStyles((theme) => ({
    root: {},
    input: {
        marginTop: 40,
        marginBottom: 14,
    },
}));

export const ModifyNameModalContent: FC<{
    client: MoneybackOwnerClient;
    onClose: () => void;
}> = ({ client, onClose }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const [newValue, setNewValue] = useState<string | undefined>(client.name);

    // DATA
    const isOk = !!newValue && client.name !== newValue;

    return (
        <>
            <DetailsItem label={'これまでの名称'} content={client.name} variant={'full'} direction={'column'} />
            <TextInputWithLabel
                className={c.input}
                value={newValue}
                setValue={setNewValue}
                messages={undefined}
                isSatisfied={isOk}
                labelText={'新しい名称'}
                placeholder={'新しい名称'}
            />
            <ModifyNameModalSubmitButton
                client={client}
                newValue={newValue || ''}
                isDisabled={!isOk}
                onComplete={onClose}
            />
        </>
    );
};
ModifyNameModalContent.displayName = 'ModifyNameModalContent';
