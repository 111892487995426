import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import { MoneybackMediaUserClient } from '@/utils/MoneybackClient';
import { Modal } from '@/components/0_atom/Modal';
import { CreateMediaModalContent } from '@/components/2_org/CreateMediaModal/Content';

const useStyles = makeStyles((theme) => ({
    root: {},
    heading: {
        fontSize: 18,
        margin: '0 0 60px 0',
        textAlign: 'center',
    },
}));

export const CreateMediaModal: FC<{
    userClient: MoneybackMediaUserClient;
    isOpen: boolean;
    onClose: () => void;
    onComplete: () => void;
}> = ({ userClient, isOpen, onClose, onComplete }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <Modal open={isOpen} onClose={onClose} anchor={'bottom'}>
            <MuiTypography variant={'h1'} className={c.heading}>
                {'新しいメディアを追加'}
            </MuiTypography>
            <CreateMediaModalContent
                userClient={userClient}
                onComplete={() => {
                    onComplete();
                    onClose();
                }}
            />
        </Modal>
    );
};
CreateMediaModal.displayName = 'CreateMediaModal';
