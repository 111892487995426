import { FC } from 'react';

import { CreateProgramInput } from '@/utils/MoneybackClient';
import { useAdminCtxAbsolutely } from '@/utils/ctxs';
import MutationButton from '@/components/0_atom/MutationButton';

export const CreateProgramModalSubmitButton: FC<{
    className?: string;
    createData: CreateProgramInput;
    isDisabled?: boolean;
    onComplete: () => Promise<void>;
}> = ({ className, createData, isDisabled, onComplete }) => {
    // HOOKS
    const { adminClient } = useAdminCtxAbsolutely();

    // MUTATION
    const run = async () => {
        await adminClient.createProgram(createData);
        await onComplete();
        return 'success' as const;
    };

    return (
        <MutationButton
            className={className}
            mutation={{
                run,
                description: '新しいプログラムを作成',
            }}
            disabled={isDisabled}
            role={'submit'}
        >
            {'作成'}
        </MutationButton>
    );
};
CreateProgramModalSubmitButton.displayName = 'CreateProgramModalSubmitButton';
