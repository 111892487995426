import { FC, useState } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { MoneybackMediaUserClient, BankAccountInputType } from '@/utils/MoneybackClient';
import { TextInputWithLabel, BankAccountInputWithLabel } from '@/components/1_mol/InputWithLabel';
import { CreateMediaModalSubmitButton } from '@/components/2_org/CreateMediaModal/SubmitButton';

const useStyles = makeStyles((theme) => ({
    root: {},
    inputs: {
        '& > div:not(:last-child)': {
            marginBottom: 21,
        },
    },
}));

export const CreateMediaModalContent: FC<{
    userClient: MoneybackMediaUserClient;
    onComplete: () => void;
}> = ({ userClient, onComplete }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const [name, setName] = useState<string | undefined>(undefined);
    const [url, setUrl] = useState<string | undefined>(undefined);
    const [bankAccount, setBankAccount] = useState<BankAccountInputType | undefined>(undefined);

    // DATA
    const isUrlValid = !!url && !!url.match(/^https:\/\/.+/);

    return (
        <>
            <div className={c.inputs}>
                <TextInputWithLabel
                    value={name}
                    setValue={setName}
                    messages={undefined}
                    isSatisfied={!!name}
                    labelText={'メディアの名称'}
                    placeholder={'メディアの名称'}
                />
                <TextInputWithLabel
                    value={url}
                    setValue={setUrl}
                    messages={[
                        {
                            message: 'メディアのURLは https:// で始まる必要があります。',
                            isError: !!url && !isUrlValid,
                        },
                    ]}
                    isSatisfied={isUrlValid}
                    labelText={'メディアのURL'}
                    placeholder={'メディアのURL'}
                />
                <BankAccountInputWithLabel
                    labelText={'銀行口座'}
                    bankAccount={bankAccount}
                    setBankAccount={setBankAccount}
                />
            </div>
            <CreateMediaModalSubmitButton
                userClient={userClient}
                name={name || ''}
                url={url || ''}
                bankAccount={bankAccount}
                isDisabled={!(name && isUrlValid)}
                onComplete={onComplete}
            />
        </>
    );
};
CreateMediaModalContent.displayName = 'CreateMediaModalContent';
