import { FC, ReactNode, useState, useEffect, useCallback } from 'react';

import { AdminCtx, useUserCtx, StaffCtx } from '@/utils/ctxs';
import {
    initMoneybackAdminClient,
    MoneybackAdminClient,
    MoneybackStaffClient,
    initMoneybackStaffClient,
} from '@/utils/MoneybackClient';
import { getEnvVariables } from '@/utils/envVariables';

// initializeをuseEffect内とreturnに使いたいので、useCallbackを使用。
// https://ja.reactjs.org/docs/hooks-faq.html

const AdminProvider: FC<{
    children: ReactNode;
}> = ({ children }) => {
    // HOOKS
    const { firebaseUser, mediaMembers } = useUserCtx();
    const [adminClient, setAdminClient] = useState<MoneybackAdminClient | undefined>(undefined);
    const [staffClient, setStaffClient] = useState<MoneybackStaffClient | undefined>(undefined);
    const initialize = useCallback(async () => {
        if (firebaseUser && mediaMembers) {
            const target = mediaMembers.find((member) => member.media.id === getEnvVariables().systemAdmin.mediaId);
            const adminClient = await (async () => {
                if (target?.mediaMember.role === 'owner') {
                    const client = await initMoneybackAdminClient({ firebaseUserIdToken: firebaseUser.idToken });
                    setAdminClient(client);
                    return client;
                }
                return undefined;
            })();
            const staffClient = await (async () => {
                if (target) {
                    const client = await initMoneybackStaffClient({ firebaseUserIdToken: firebaseUser.idToken });
                    setStaffClient(client);
                    return client;
                }
                return undefined;
            })();

            return {
                adminClient,
                staffClient,
            };
        }
        return {
            adminClient: undefined,
            staffClient: undefined,
        };
    }, [firebaseUser, mediaMembers]);

    // USEEFFECT
    useEffect(() => {
        initialize();
    }, [initialize]);
    console.log({
        adminClient,
        staffClient,
    });
    return (
        <AdminCtx.Provider
            value={{
                adminClient,
            }}
        >
            <StaffCtx.Provider
                value={{
                    staffClient,
                }}
            >
                {children}
            </StaffCtx.Provider>
        </AdminCtx.Provider>
    );
};
AdminProvider.displayName = 'AdminProvider';
export default AdminProvider;
