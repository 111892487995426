import { FC } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        '& img': {
            width: 40,
            height: 40,
            borderRadius: '50%',
            display: 'block',
            marginRight: 14,
            objectFit: 'cover',
            border: 'solid 1px rgba(0,0,0,.05)',
        },
        '& span': {},
    },
}));

export const ProgramAdvertiser: FC<{
    className?: string;
    name: string;
    iconSrc: string | undefined;
}> = ({ className, name, iconSrc }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <span className={clsx(c.root, className)}>
            {iconSrc && <img src={iconSrc} alt={name} />}
            <span>{name}</span>
        </span>
    );
};
ProgramAdvertiser.displayName = 'ProgramAdvertiser';
