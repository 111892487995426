import { createContext, useContext } from 'react';

import { MoneybackStaffClient } from '@/utils/MoneybackClient';

type StaffCtxType = {
    staffClient?: MoneybackStaffClient;
};
export const StaffCtx = createContext<StaffCtxType>({
    staffClient: undefined,
});
export const useStaffCtx = () => {
    return useContext(StaffCtx);
};
export const useStaffCtxAbsolutely = () => {
    const { staffClient } = useStaffCtx();
    if (!staffClient) {
        throw new Error('useStaffCtxAbsolutely: !staffClient.');
    }
    return {
        staffClient,
    };
};
