import {
    Campaign,
    CampaignForPublic,
    ProgramForMedia,
    ProgramForPublic,
    ProgramForAdmin,
    ProgramApiForAdmin,
} from '@/utils/MoneybackClient';

export const getCampaignEndedDate = ({
    campaign,
    program,
}: {
    campaign: CampaignForPublic | Campaign;
    program: ProgramForPublic | ProgramForMedia | ProgramForAdmin | ProgramApiForAdmin;
}) => {
    const now = new Date();
    const campaignEndedDate = (() => {
        const { endReceptionAt } = campaign;
        if (endReceptionAt && endReceptionAt <= now) {
            return endReceptionAt;
        }
        return undefined;
    })();
    const programEndedDate = (() => {
        const { endReceptionAt } = program;
        if (endReceptionAt && endReceptionAt <= now) {
            return endReceptionAt;
        }
        return undefined;
    })();
    const programClosedDate = (() => {
        const { closeAt } = program;
        if (closeAt && closeAt <= now) {
            return closeAt;
        }
        return undefined;
    })();
    const programClosureDate = (() => {
        const { closure } = program;
        if (closure) {
            return closure.endedAt;
        }
        return undefined;
    })();
    const dates = [campaignEndedDate, programEndedDate, programClosedDate, programClosureDate].filter(
        (date) => !!date
    ) as Date[];
    if (dates.length > 0) {
        return dates.sort((a, b) => (a > b ? 1 : -1))[0];
    }
    return undefined;
};
