import { FC, ComponentProps } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { MarkdownEditor } from '@/components/0_atom/MarkdownEditor';

const useStyles = makeStyles((theme) => ({
    root: {},
    label: {
        fontWeight: 600,
        fontSize: theme.typography.body1.fontSize,
        display: 'block',
        marginBottom: 3,
    },
    input: {
        background: '#fff',
        border: 'solid 2px rgba(0,0,0,.05)',
        borderRadius: 4,
    },
}));

export const MarkdownInputWithLabel: FC<
    ComponentProps<typeof MarkdownEditor> & {
        labelText: string;
    }
> = ({ labelText, className, textMd, setTextMd, placeholder }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <div className={className}>
            <span className={c.label}>{labelText}</span>
            <MarkdownEditor textMd={textMd} setTextMd={setTextMd} placeholder={placeholder} className={c.input} />
        </div>
    );
};
MarkdownInputWithLabel.displayName = 'MarkdownInputWithLabel';
