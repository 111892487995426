import { FC, useState } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { ProgramApiForAdmin } from '@/utils/MoneybackClient';
import { getStrWithBr } from '@/utils/utilFunctions';
import { DetailsItem } from '@/components/0_atom/DetailsItem';
import { BooleanInputWithLabel } from '@/components/1_mol/InputWithLabel';
import { ConsoleTemplateAdminProgramsAdminProgramCard } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramCard';
import { ModifyIsPublicVisibleModalSubmitButton } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramModal/ModifyIsPublicVisibleModal/SubmitButton';

const useStyles = makeStyles((theme) => ({
    root: {},
    input: {
        marginTop: 40,
        marginBottom: 14,
    },
}));

export const ModifyIsPublicVisibleModalContent: FC<{
    programAPI: ProgramApiForAdmin;
    onClose: () => void;
}> = ({ programAPI, onClose }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const [newValue, setNewValue] = useState<boolean>(programAPI.isPublicVisible);

    // DATA
    const isOk = programAPI.isPublicVisible !== newValue;

    return (
        <>
            <ConsoleTemplateAdminProgramsAdminProgramCard
                programAPI={programAPI}
                enabledFields={{
                    advertiserProgramId: true,
                }}
            />
            <DetailsItem
                label={'メディアホワイトリスト'}
                content={getStrWithBr(
                    programAPI.whitelistedMediaIds?.reduce((acc, prev, i) => {
                        if (i === 0) {
                            return prev;
                        }
                        return `${acc}\n${prev}`;
                    }, '') || 'なし'
                )}
                variant={'full'}
                direction={'column'}
            />
            <BooleanInputWithLabel
                className={c.input}
                labelText={'新しいホワイトリスト外表示'}
                value={newValue}
                setValue={setNewValue}
                texts={{
                    true: 'ホワイトリスト外に表示する',
                    false: 'ホワイトリスト外に表示する',
                }}
            />
            <ModifyIsPublicVisibleModalSubmitButton
                programAPI={programAPI}
                newValue={newValue}
                isDisabled={!isOk}
                onComplete={onClose}
            />
        </>
    );
};
ModifyIsPublicVisibleModalContent.displayName = 'ModifyIsPublicVisibleModalContent';
