import { TicketWithCampaign as GqlTicketWithCampaign } from '@/utils/MoneybackClient/graphql/generated/graphqlClient';
import { Ticket, getTicketFromGql } from '@/utils/MoneybackClient/models/Ticket/data/Ticket';
import { Campaign, getCampaignFromGql } from '@/utils/MoneybackClient/models/Campaign';
import { ProgramForAdmin, getProgramForAdminFromGql } from '@/utils/MoneybackClient/models/Program';
import { Media, getMediaFromGql } from '@/utils/MoneybackClient/models/Media';

export type TicketWithCampaignType = {
    ticket: Ticket;
    campaign: Campaign;
    program: ProgramForAdmin;
    media: Media;
};

export class TicketWithCampaign implements TicketWithCampaignType {
    protected _ticket: Ticket;

    protected _campaign: Campaign;

    protected _program: ProgramForAdmin;

    protected _media: Media;

    get ticket() {
        return this._ticket;
    }

    get campaign() {
        return this._campaign;
    }

    get program() {
        return this._program;
    }

    get media() {
        return this._media;
    }

    get data() {
        return {
            ticket: this.ticket,
            campaign: this.campaign,
            program: this.program,
            media: this.media,
        };
    }

    constructor({ ticket, campaign, program, media }: TicketWithCampaignType) {
        this._ticket = ticket;
        this._campaign = campaign;
        this._program = program;
        this._media = media;
    }
}

export const getTicketWithCampaignFromGql = async ({ ticket, campaign, program, media }: GqlTicketWithCampaign) => {
    return new TicketWithCampaign({
        ticket: getTicketFromGql(ticket),
        campaign: getCampaignFromGql(campaign),
        program: await getProgramForAdminFromGql(program),
        media: getMediaFromGql(media),
    });
};
