import { FC, useState } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { ProgramApiForAdmin } from '@/utils/MoneybackClient';
import { DetailsItem } from '@/components/0_atom/DetailsItem';
import { TextInputWithLabel } from '@/components/1_mol/InputWithLabel';
import { ProgramDescriptionForMedia } from '@/components/1_mol/ProgramDescriptionForMedia';
import { ConsoleTemplateAdminProgramsAdminProgramCard } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramCard';
import { ModifyDescriptionForMediaModalSubmitButton } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramModal/ModifyDescriptionForMediaModal/SubmitButton';

const useStyles = makeStyles((theme) => ({
    root: {},
    input: {
        marginTop: 40,
        marginBottom: 14,
    },
}));

export const ModifyDescriptionForMediaModalContent: FC<{
    programAPI: ProgramApiForAdmin;
    onClose: () => void;
}> = ({ programAPI, onClose }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const [newValue, setNewValue] = useState<string | undefined>(programAPI.descriptionForMedia);

    // DATA
    const isOk = programAPI.description !== newValue;

    return (
        <>
            <ConsoleTemplateAdminProgramsAdminProgramCard
                programAPI={programAPI}
                enabledFields={{
                    advertiserProgramId: true,
                }}
            />
            <DetailsItem
                label={'これまでのメディア向け説明'}
                content={<ProgramDescriptionForMedia descriptionForMedia={programAPI.descriptionForMedia} />}
                variant={'full'}
                direction={'column'}
            />
            <TextInputWithLabel
                className={c.input}
                value={newValue}
                setValue={setNewValue}
                messages={undefined}
                isSatisfied={true}
                labelText={'新しいメディア向け説明'}
                placeholder={'説明'}
                multiline
            />
            <ModifyDescriptionForMediaModalSubmitButton
                programAPI={programAPI}
                newValue={newValue}
                isDisabled={!isOk}
                onComplete={onClose}
            />
        </>
    );
};
ModifyDescriptionForMediaModalContent.displayName = 'ModifyDescriptionForMediaModalContent';
