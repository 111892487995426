import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import { ProgramForMedia } from '@/utils/MoneybackClient';
import { Modal } from '@/components/0_atom/Modal';
import { LoadingIndicator } from '@/components/0_atom/LoadingIndicator';
import { ProgramModalContent } from '@/components/2_org/ProgramModal/Content';

const useStyles = makeStyles((theme) => ({
    root: {},
    heading: {
        fontSize: 18,
        margin: '0 0 60px 0',
        textAlign: 'center',
    },
}));

export const ProgramModal: FC<{
    program: ProgramForMedia | undefined;
    onClose: () => void;
    isWithCreateCampaignButton: boolean;
}> = ({ program, onClose, isWithCreateCampaignButton }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS

    return (
        <Modal open={!!program} onClose={onClose} anchor={'bottom'}>
            <MuiTypography variant={'h1'} className={c.heading}>
                {'プログラム詳細'}
            </MuiTypography>
            {program ? (
                <ProgramModalContent program={program} isWithCreateCampaignButton={isWithCreateCampaignButton} />
            ) : (
                <LoadingIndicator />
            )}
        </Modal>
    );
};
ProgramModal.displayName = 'ProgramModal';
