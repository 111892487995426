import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { useTosForMedia } from '@/utils/customHooks';
import { Modal } from '@/components/0_atom/Modal';
import { LegalTextTemplate } from '@/components/3_template/LegalTextTemplate';

const useStyles = makeStyles((theme) => ({}));

export const TosForMediaModal: FC<{
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}> = ({ isOpen, setIsOpen }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { tosForMedia } = useTosForMedia({ isStartFetch: isOpen });

    return (
        <Modal anchor={'bottom'} open={isOpen} onClose={() => setIsOpen(false)}>
            <LegalTextTemplate title={'利用規約'} textMd={tosForMedia} />
        </Modal>
    );
};
TosForMediaModal.displayName = 'TosForMediaModal';
