import { FC, useState } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { ProgramApiForAdmin } from '@/utils/MoneybackClient';
import { getDateStr } from '@/utils/utilFunctions';
import { DetailsItem } from '@/components/0_atom/DetailsItem';
import { DateInputWithLabel } from '@/components/1_mol/InputWithLabel';
import { ConsoleTemplateAdminProgramsAdminProgramCard } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramCard';
import { ModifyEndReceptionAtModalSubmitButton } from '@/components/3_template/ConsoleTemplate/Admin/Programs/AdminProgramModal/ModifyEndReceptionAt/SubmitButton';

const useStyles = makeStyles((theme) => ({
    root: {},
    input: {
        marginTop: 40,
        marginBottom: 14,
    },
}));

export const ModifyEndReceptionAtModalContent: FC<{
    programAPI: ProgramApiForAdmin;
    onClose: () => void;
}> = ({ programAPI, onClose }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const [newValue, setNewValue] = useState<Date | undefined>(programAPI.endReceptionAt);

    // DATA
    const isOk = programAPI.endReceptionAt !== newValue;

    return (
        <>
            <ConsoleTemplateAdminProgramsAdminProgramCard
                programAPI={programAPI}
                enabledFields={{
                    advertiserProgramId: true,
                }}
            />
            <DetailsItem
                label={'これまでの受付終了日'}
                content={
                    programAPI.endReceptionAt
                        ? getDateStr({ date: programAPI.endReceptionAt, isWithDay: true })
                        : '未定'
                }
                variant={'full'}
                direction={'row'}
            />
            <DateInputWithLabel
                className={c.input}
                date={newValue}
                setDate={setNewValue}
                labelText={'新しい受付終了日'}
            >
                {newValue ? getDateStr({ date: newValue, isWithDay: true }) : '未定'}
            </DateInputWithLabel>
            <ModifyEndReceptionAtModalSubmitButton
                programAPI={programAPI}
                newValue={newValue}
                isDisabled={!isOk}
                onComplete={onClose}
            />
        </>
    );
};
ModifyEndReceptionAtModalContent.displayName = 'ModifyEndReceptionAtModalContent';
