import { createContext, useContext } from 'react';

import { MoneybackMediaUserClient, Media, MediaMember } from '@/utils/MoneybackClient';

type UserCtxType = {
    userClient?: MoneybackMediaUserClient;
    isFetching: boolean;
    reInitialize: () => Promise<void>;
    signOut: () => void;
    firebaseUser?: {
        uid: string;
        email: string;
        idToken: string;
    };
    isFirebaseError?: boolean;
    mediaMembers?: {
        media: Media;
        mediaMember: MediaMember;
    }[];
    refetchMediaMembers: () => Promise<void>;
};
export const UserCtx = createContext<UserCtxType>({
    isFetching: true,
    reInitialize: () => new Promise((r) => r()),
    signOut: () => undefined,
    refetchMediaMembers: () => new Promise((r) => r()),
});
export const useUserCtx = () => {
    return useContext(UserCtx);
};
export const useUserCtxAbsolutely = () => {
    const { userClient, mediaMembers, ...otherProps } = useUserCtx();
    if (!userClient || !mediaMembers) {
        throw new Error('useUserCtxAbsolutely: !userClient || !mediaMembers.');
    }
    return {
        userClient,
        mediaMembers,
        ...otherProps,
    };
};
