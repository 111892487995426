import { forwardRef, ComponentProps, useEffect, ReactNode, useState } from 'react';
import clsx from 'clsx';

import { useTheme } from '@material-ui/core/styles';
import MuiInputBase from '@material-ui/core/InputBase';
import MuiInputAdornment from '@material-ui/core/InputAdornment';
import { useStyles, InputHelpers, getValidNumber } from '@/components/0_atom/Input/common';

export const FloatInput = forwardRef<
    HTMLElement,
    ComponentProps<typeof MuiInputBase> & {
        value: number | undefined;
        setValue: (value: number | undefined) => void;
        messages?: {
            isError?: boolean;
            message: ReactNode;
        }[];
        isSatisfied?: boolean;
    }
>(({ value, setValue, messages, isSatisfied, error, className, startAdornment, endAdornment, ...otherProps }, ref) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const [strValue, setStrValue] = useState(value === undefined ? '' : String(value));

    // HANDLER
    const handleChange = (value: string) => {
        if (!value) {
            setStrValue('');
        } else {
            const numbered = getValidNumber(value);
            if (typeof numbered === 'number') {
                setStrValue(value);
            }
        }
    };

    // USEEFFECT
    useEffect(() => {
        const isAbleToSync = !![strValue.match(/^(\d*)(\.)(\d*)$/), strValue.match(/^(\d*)$/)].find((item) => item);
        if (!strValue && value !== undefined) {
            setValue(undefined);
        } else {
            const newValue = Number(strValue);
            if (isAbleToSync && newValue !== value) {
                setValue(newValue);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [strValue]);

    return (
        <>
            <MuiInputBase
                ref={ref}
                {...otherProps}
                className={clsx(c.root, className, {
                    [c.rootSatisfied]: isSatisfied,
                })}
                value={strValue}
                onChange={({ target }) => handleChange(target.value)}
                error={!!messages?.find(({ isError }) => isError) || error}
                startAdornment={
                    startAdornment ? (
                        <MuiInputAdornment position={'start'}>{startAdornment}</MuiInputAdornment>
                    ) : undefined
                }
                endAdornment={
                    endAdornment ? <MuiInputAdornment position={'end'}>{endAdornment}</MuiInputAdornment> : undefined
                }
                inputMode={'numeric'}
            />
            <InputHelpers items={messages} />
        </>
    );
});
FloatInput.displayName = 'FloatInput';
