import { FC } from 'react';
import clsx from 'clsx';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import { ProgramApiForAdmin } from '@/utils/MoneybackClient';
import { getDateStr, getProgramEndedDate, getProgramClosedDate, getPrevDate2359 } from '@/utils/utilFunctions';
import { DetailsItem } from '@/components/0_atom/DetailsItem';
import { Card } from '@/components/0_atom/Card';
import { EndSchedule } from '@/components/1_mol/EndSchedule';
import { ProgramAdvertiser } from '@/components/1_mol/ProgramAdvertiser';
import { ProgramHeading } from '@/components/1_mol/ProgramHeading';

const useStyles = makeStyles((theme) => ({
    root: {
        background: '#fff',
    },
    eyecatch: {
        maxHeight: 200,
        objectFit: 'contain',
        borderRadius: 4,
        marginBottom: 14,
        marginLeft: 0,
        marginRight: 'auto',
        border: 'solid 1px rgba(0,0,0,.05)',
    },
    title: {
        fontWeight: 600,
    },
    description: {
        fontSize: theme.typography.body2.fontSize,
        marginTop: 11,
    },
    end: {
        fontSize: theme.typography.body2.fontSize,
        color: theme.palette.text.disabled,
    },
}));

export const ConsoleTemplateAdminProgramsAdminProgramCard: FC<{
    className?: string;
    programAPI: ProgramApiForAdmin;
    onClick?: () => void;
    enabledFields: {
        advertiserProgramId?: boolean;
        totalPrice?: boolean;
        mediaFund?: boolean;
        margin?: boolean;
        advertiserName?: boolean;
        schedule?: boolean;
        cashbackAmountRange?: boolean;
    };
}> = ({ className, programAPI, onClick, enabledFields }) => {
    // STYLE
    const c = useStyles(useTheme());

    // DATA
    const endedDate = getProgramEndedDate({ program: programAPI });
    const closedDate = getProgramClosedDate({ program: programAPI });

    return (
        <Card className={clsx(c.root, className)} onClick={onClick}>
            {closedDate ? (
                <MuiTypography className={c.end}>
                    {`${getDateStr({ date: getPrevDate2359({ date: closedDate }), isWithDay: true })}で終了(成果締切)`}
                </MuiTypography>
            ) : endedDate ? (
                <MuiTypography className={c.end}>
                    {`${getDateStr({ date: getPrevDate2359({ date: endedDate }), isWithDay: true })}で受付終了`}
                </MuiTypography>
            ) : (
                <></>
            )}
            {programAPI.eyecatchSrc && <img src={programAPI.eyecatchSrc} alt={'eyecatch'} className={c.eyecatch} />}
            <ProgramHeading program={programAPI} />
            <MuiTypography className={c.description}>
                {programAPI.description.replace(/\[cashbackAmount\]/g, '<CB金額>')}
            </MuiTypography>
            {enabledFields.advertiserProgramId && (
                <DetailsItem
                    label={'広告主プログラムID'}
                    content={programAPI.advertiserProgramId || 'なし'}
                    variant={'full'}
                    direction={'row'}
                    isLightColor={!programAPI.advertiserProgramId}
                />
            )}
            {enabledFields.totalPrice && (
                <DetailsItem
                    label={'総単価(税抜)'}
                    content={`¥ ${programAPI.totalPrice.beforeTax.toLocaleString()}`}
                    variant={'full'}
                    direction={'row'}
                />
            )}
            {enabledFields.mediaFund && (
                <DetailsItem
                    label={'メディア単価'}
                    content={`¥ ${programAPI.mediaFund.toLocaleString()}`}
                    variant={'full'}
                    direction={'row'}
                />
            )}
            {enabledFields.margin && (
                <DetailsItem
                    label={'粗利益(税抜)'}
                    content={`¥ ${(programAPI.totalPrice.beforeTax - programAPI.mediaFund).toLocaleString()}`}
                    variant={'full'}
                    direction={'row'}
                />
            )}
            {enabledFields.advertiserName && (
                <DetailsItem
                    label={'広告主'}
                    content={
                        <ProgramAdvertiser name={programAPI.advertiserName} iconSrc={programAPI.advertiserIconSrc} />
                    }
                    variant={'full'}
                    direction={'row'}
                />
            )}
            {enabledFields.schedule && (
                <DetailsItem
                    label={'終了日'}
                    content={<EndSchedule program={programAPI} campaign={undefined} />}
                    variant={'full'}
                    direction={'row'}
                />
            )}
            {enabledFields.cashbackAmountRange &&
                (programAPI.cashbackAmountRange.low || programAPI.cashbackAmountRange.high) && (
                    <DetailsItem
                        label={'CB金額制限'}
                        content={(() => {
                            let str = '';
                            if (programAPI.cashbackAmountRange.low) {
                                str += `${programAPI.cashbackAmountRange.low.toLocaleString()}円以上`;
                            }
                            if (programAPI.cashbackAmountRange.high) {
                                if (programAPI.cashbackAmountRange.low) {
                                    str += ' ';
                                }
                                str += `${programAPI.cashbackAmountRange.high.toLocaleString()}円以下`;
                            }
                            return str;
                        })()}
                        variant={'full'}
                        direction={'row'}
                    />
                )}
        </Card>
    );
};
ConsoleTemplateAdminProgramsAdminProgramCard.displayName = 'ConsoleTemplateAdminProgramsAdminProgramCard';
