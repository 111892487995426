import { useLocation, useHistory } from 'react-router-dom';

import { useErrorCtx } from '@/utils/ctxs';

type ViewName =
    | 'campaigns'
    | 'programs'
    | 'balances'
    | 'config'
    | 'resources'
    | 'adminprograms'
    | 'adminreports'
    | 'admincron'
    | 'staffuserinfo';
const viewNames = [
    'campaigns',
    'programs',
    'balances',
    'config',
    'resources',
    'adminprograms',
    'adminreports',
    'admincron',
    'staffuserinfo',
];
type Routing = {
    mediaId?: string;
    viewName?: ViewName;
    viewSlug1?: string;
    viewSlug2?: string;
    viewSlug3?: string;
};

export const useRouting = () => {
    // HOOKS
    const { pathname } = useLocation();
    const { push } = useHistory();
    const { setIs404 } = useErrorCtx();

    const routing: Routing = (() => {
        const matchArr = pathname.match(/^\/([^/?#]+)?\/?([^/?#]+)?\/?([^/?#]+)?\/?([^/?#]+)?\/?([^/?#]+)?/);
        if (matchArr) {
            const [_, mediaId, viewNameStr, viewSlug1, viewSlug2, viewSlug3]: (string | undefined)[] = matchArr;
            const viewName = (() => {
                if (viewNameStr) {
                    if (viewNames.includes(viewNameStr)) {
                        return viewNameStr as ViewName;
                    }
                    setIs404(true);
                }
                return undefined;
            })();
            return {
                mediaId,
                viewName,
                viewSlug1,
                viewSlug2,
                viewSlug3,
            };
        }
        return {};
    })();

    // HANDLER
    const switchMediaId = ({ mediaId }: { mediaId: string }) => {
        push(`/${mediaId}`);
    };
    const switchView = ({ mediaId, viewName }: { mediaId: string; viewName: ViewName }) => {
        push(`/${mediaId}/${viewName}`);
    };
    const setViewSlug1 = <T1 extends string = string>({
        mediaId = routing.mediaId,
        viewName = routing.viewName,
        slug1,
    }: {
        mediaId?: string;
        viewName?: ViewName;
        slug1?: T1;
    }) => {
        if (!mediaId || !viewName) {
            setIs404(true);
        }
        if (slug1) {
            push(`/${mediaId}/${viewName}/${slug1}`);
        } else {
            push(`/${mediaId}/${viewName}`);
        }
    };
    const setViewSlug2 = <T1 extends string = string, T2 extends string = string>({
        mediaId = routing.mediaId,
        viewName = routing.viewName,
        slug1,
        slug2,
    }: {
        mediaId?: string;
        viewName?: ViewName;
        slug1: T1;
        slug2?: T2;
    }) => {
        if (!mediaId || !viewName) {
            setIs404(true);
        }
        if (slug2) {
            push(`/${mediaId}/${viewName}/${slug1}/${slug2}`);
        } else {
            push(`/${mediaId}/${viewName}/${slug1}`);
        }
    };
    const setViewSlug3 = <T1 extends string = string, T2 extends string = string, T3 extends string = string>({
        mediaId = routing.mediaId,
        viewName = routing.viewName,
        slug1,
        slug2,
        slug3,
    }: {
        mediaId?: string;
        viewName?: ViewName;
        slug1: T1;
        slug2: T2;
        slug3?: T3;
    }) => {
        if (!mediaId || !viewName) {
            setIs404(true);
        }
        if (slug3) {
            push(`/${mediaId}/${viewName}/${slug1}/${slug2}/${slug3}`);
        } else {
            push(`/${mediaId}/${viewName}/${slug1}/${slug2}`);
        }
    };

    return {
        ...routing,
        switchMediaId,
        switchView,
        setViewSlug1,
        setViewSlug2,
        setViewSlug3,
    };
};
