import {
    TicketWithCampaign,
    getTicketWithCampaignFromGql,
    ServiceUser,
    getServiceUserFromGql,
} from '@/utils/MoneybackClient/models';
import { MoneybackMediaUserClient } from '@/utils/MoneybackClient/clients/MediaUser';

type MoneybackStaffClientType = {
    getUserInfo: (input: { phone: string }) => Promise<{
        user: ServiceUser;
        ticketWithCampaigns: TicketWithCampaign[];
    }>;
};

export class MoneybackStaffClient extends MoneybackMediaUserClient implements MoneybackStaffClientType {
    async getUserInfo({ phone }: { phone: string }) {
        const { getUserInfo } = await this._graphqlSdk.getUserInfo({
            input: {
                phone,
            },
        });
        const { user, ticketWithCampaigns } = getUserInfo;
        return {
            user: getServiceUserFromGql(user),
            ticketWithCampaigns: await Promise.all(
                ticketWithCampaigns.map((ticketWithCampaign) => getTicketWithCampaignFromGql(ticketWithCampaign))
            ),
        };
    }
}
