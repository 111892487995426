import { FC, useState, useEffect } from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import MuiCircularProgress from '@material-ui/core/CircularProgress';
import { handleStartAuth } from '@/utils/GoogleCloud/firebase';
import { useUserCtx } from '@/utils/ctxs';
import Button from '@/components/0_atom/Button';

import SvgGoogle from '@/svgs/google-g';
import SvgCheck from '@/svgs/fa-check-light';

const useStyles = makeStyles((theme) => ({
    button: {
        border: 'solid 2px rgba(0,0,0,.03)',
        padding: '14px 23px',
        width: 230,
        justifyContent: 'flex-start',
        background: '#fff',
    },
    progress: {
        margin: 5,
    },
    googleIcon: {
        width: 24,
        height: 24,
    },
    text: {
        fontWeight: 600,
        fontSize: theme.typography.body2.fontSize,
        display: 'inline-block',
        marginLeft: 19,
    },
}));

const FirebaseAuth: FC<{
    redirectUrl?: string;
    onAuthed?: (firebaseUser: { uid: string; email: string; idToken: string }) => void;
}> = ({ redirectUrl, onAuthed }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const { firebaseUser } = useUserCtx();
    const [isClicked, setIsClicked] = useState(false);

    // USEEFFECT
    useEffect(() => {
        if (firebaseUser && onAuthed) {
            onAuthed(firebaseUser);
        }
    }, [firebaseUser, onAuthed]);

    return firebaseUser ? (
        <Button className={c.button} disabled>
            <SvgCheck className={c.googleIcon} />
            <span className={c.text}>{'Googleでログイン'}</span>
        </Button>
    ) : (
        <Button
            className={c.button}
            disabled={isClicked}
            onClick={() => {
                setIsClicked(true);
                handleStartAuth({ language: 'ja', redirectUrl }).then(() => {
                    // popupに変更して、取り急ぎ。
                    window.location.reload();
                });
            }}
        >
            {isClicked ? (
                <MuiCircularProgress className={c.progress} size={14} />
            ) : (
                <SvgGoogle className={c.googleIcon} />
            )}
            <span className={c.text}>{'Googleでログイン'}</span>
        </Button>
    );
};
FirebaseAuth.displayName = 'FirebaseAuth';
export default FirebaseAuth;
