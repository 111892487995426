import { FC, useState } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import { BankAccountInputType } from '@/utils/MoneybackClient';
import { Modal } from '@/components/0_atom/Modal';
import Button from '@/components/0_atom/Button';
import { BankAccountForm } from '@/components/1_mol/BankAccountForm';

const useStyles = makeStyles((theme) => ({
    root: {},
    heading: {
        textAlign: 'center',
        marginBottom: 50,
    },
    form: {},
    buttons: {
        display: 'flex',
        marginTop: 37,
    },
    submitButton: {
        background: theme.palette.text.primary,
        fontWeight: 600,
        color: '#fff',
        padding: 14,
        width: '100%',
        '&.Mui-disabled': {
            background: 'rgba(0,0,0,.2)',
            color: '#fff',
        },
    },
}));

export const EditBankAccountModal: FC<{
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    bankAccount: BankAccountInputType | undefined;
    setBankAccount: (bankAccount: BankAccountInputType | undefined) => void;
}> = ({ isOpen, setIsOpen, bankAccount, setBankAccount }) => {
    // STYLE
    const c = useStyles(useTheme());

    // HOOKS
    const [isHasError, setIsHasError] = useState(false);

    return (
        <Modal anchor={'bottom'} open={isOpen} onClose={() => setIsOpen(false)}>
            <MuiTypography variant={'h1'} className={c.heading}>
                {'銀行口座を編集'}
            </MuiTypography>
            <BankAccountForm
                className={c.form}
                initialBankAccount={bankAccount}
                setBankAccount={setBankAccount}
                setIsHasError={setIsHasError}
            />
            <div className={c.buttons}>
                <Button onClick={() => setIsOpen(false)} className={c.submitButton}>
                    {'閉じる'}
                </Button>
            </div>
        </Modal>
    );
};
EditBankAccountModal.displayName = 'EditBankAccountModal';
