const getDayStr = (input: Date): string => {
    const dayNumber = input.getDay();
    if (dayNumber === 0) {
        return '日';
    }
    if (dayNumber === 1) {
        return '月';
    }
    if (dayNumber === 2) {
        return '火';
    }
    if (dayNumber === 3) {
        return '水';
    }
    if (dayNumber === 4) {
        return '木';
    }
    if (dayNumber === 5) {
        return '金';
    }
    if (dayNumber === 6) {
        return '土';
    }
    throw new Error('getDayStr: unexpected case');
};

export const getTimeStr = ({ date }: { date: Date }): string => {
    const hourStr = (() => {
        const hourNumber = date.getHours();
        if (hourNumber < 10) {
            return `0${hourNumber}`;
        }
        return `${hourNumber}`;
    })();
    const minStr = (() => {
        const minNumber = date.getMinutes();
        if (minNumber < 10) {
            return `0${minNumber}`;
        }
        return `${minNumber}`;
    })();
    return `${hourStr}:${minStr}`;
};

export const getDateStr = ({
    date,
    isWithDay,
    isWithTime,
}: {
    date: Date;
    isWithDay: boolean;
    isWithTime?: boolean;
}): string => {
    const dayExtension = isWithDay ? ` (${getDayStr(date)})` : '';
    const timeExtension = isWithTime ? ` ${getTimeStr({ date })}` : '';
    return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}${dayExtension}${timeExtension}`;
};
