import { FC } from 'react';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import { MoneybackOwnerClient } from '@/utils/MoneybackClient';
import { Modal } from '@/components/0_atom/Modal';
import { ModifyNameModalContent } from '@/components/3_template/ConsoleTemplate/Config/ModifyNameModal/Content';

const useStyles = makeStyles((theme) => ({
    root: {},
    heading: {
        fontSize: 18,
        margin: '0 0 60px 0',
        textAlign: 'center',
    },
}));

export const ModifyNameModal: FC<{
    client: MoneybackOwnerClient;
    isOpen: boolean;
    onClose: () => void;
}> = ({ client, isOpen, onClose }) => {
    // STYLE
    const c = useStyles(useTheme());

    return (
        <Modal open={isOpen} onClose={onClose} anchor={'bottom'}>
            <MuiTypography variant={'h1'} className={c.heading}>
                {'メディアの名称を編集'}
            </MuiTypography>
            <ModifyNameModalContent client={client} onClose={onClose} />
        </Modal>
    );
};
ModifyNameModal.displayName = 'ModifyNameModal';
